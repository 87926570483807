import type { ThemeState } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';
import { getSessionData } from '@confluence/session-data';
import { getEffectiveThemeStateSerialized } from '@confluence/theming-utils';

import { LocalThemeStorage } from './localThemeStorage';
import { RemoteThemeStorage } from './remoteThemeStorage';
import { ErrorSubjectIds, ErrorSubjects, reportThemingError } from './errorHandling';

export class ThemeAPI {
	public static async saveTheme(theme: Partial<ThemeState>): Promise<void> {
		if (fg('confluence_typography_refreshed')) {
			theme.typography = 'typography-modernized';
		}

		LocalThemeStorage.saveThemeLocal(theme);
		const { isLoggedIn } = await getSessionData();
		if (isLoggedIn) {
			await RemoteThemeStorage.saveThemeRemote(theme);
		}
	}

	public static async getSavedTheme(): Promise<Partial<ThemeState>> {
		const persistedLocalTheme = ThemeAPI.getSavedThemeLocal();

		const { isLoggedIn } = await getSessionData();

		let persistedRemoteTheme: Partial<ThemeState> | undefined = undefined;
		if (isLoggedIn) {
			try {
				persistedRemoteTheme = await RemoteThemeStorage.getThemeRemote();

				// Theme from the DB should indicate last preference regardless of browser
				if (!ThemeAPI.localAndRemoteAreEqual(persistedLocalTheme, persistedRemoteTheme)) {
					LocalThemeStorage.saveThemeLocal(persistedRemoteTheme);
				}
			} catch (e) {
				// Fall through to local
				const attributes = {
					message: e.message,
					stack: e.stack,
					persistedRemoteTheme,
					persistedLocalTheme,
				};
				await reportThemingError(ErrorSubjects.themeAPI, ErrorSubjectIds.getSavedTheme, attributes);
			}
		}

		const effectiveTheme = persistedRemoteTheme || persistedLocalTheme;
		if (fg('confluence_typography_refreshed')) {
			effectiveTheme.typography = 'typography-refreshed';
		}
		return effectiveTheme;
	}

	public static getSavedThemeLocal(): Partial<ThemeState> {
		return LocalThemeStorage.getThemeLocal();
	}

	private static localAndRemoteAreEqual(
		local: Partial<ThemeState>,
		remote: Partial<ThemeState>,
	): boolean {
		return getEffectiveThemeStateSerialized(remote) === getEffectiveThemeStateSerialized(local);
	}
}
