import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	title: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.title',
		defaultMessage: 'Build an advanced prompt',
		description: 'Describes an option to use Atlassian Intelligence to show a more advanced prompt',
	},
	description: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.description',
		defaultMessage: 'Specify task, instructions and rules',
		description: 'Description for the the Atlassian Intelligence "Build an advanced prompt".',
	},
	promptLabel: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.promptLabel',
		defaultMessage: 'Build an advanced prompt',
		description: 'Label text in the prompt input.',
	},
	taskLabel: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.task.label',
		defaultMessage: 'Task',
		description: 'Label for the Task input in the prompt builder.',
	},
	taskPlaceholder: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.task.placeholder',
		defaultMessage: 'Describe the task that needs completing.',
		description: 'Placeholder for the Task input in the prompt builder.',
	},
	taskHelperMessage: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.task.helperMessage',
		defaultMessage:
			'For example: Create an onboarding documentation for new employees joining the team.',
		description: 'Helper message for the Task input in the prompt builder.',
	},
	taskRequired: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.task.error.required',
		defaultMessage: 'Please provide task',
		description: 'Error message for the Task input in the prompt builder.',
	},
	instructionsLabel: {
		id: 'fabric.editor.ai.config.item.advanced-prompt.form.instructions.label',
		defaultMessage: 'Instructions',
		description: 'Label for the Instructions input in the prompt builder.',
	},
	instructionsPlaceholder: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.instructions.placeholder',
		defaultMessage: 'Provide task instructions to help generate specific outputs.',
		description: 'Placeholder for the Instructions input in the prompt builder.',
	},
	instructionsHelperMessage: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.instructions.helperMessage',
		defaultMessage:
			'For example: 1. Start the document with a brief overview of our company goals. 2. Create a list of key team members to meet in their first week.',
		description: 'Helper message for the Instructions input in the prompt builder.',
	},
	rulesLabel: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.rules.label',
		defaultMessage: 'Rules',
		description: 'Label for the Rules input in the prompt builder.',
	},
	rulesPlaceholder: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.rules.placeholder',
		defaultMessage: 'Outline any rules you want followed.',
		description: 'Placeholder for the Rules input in the prompt builder.',
	},
	rulesHelperMessage: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.rules.helperMessage',
		defaultMessage:
			'For example: The output must follow the company style guidelines and not use unverified data.',
		description: 'Helper message for the Rules input in the prompt builder.',
	},
	toneLabel: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.tone.label',
		defaultMessage: 'Tone',
		description: 'Label for the Tone input in the prompt builder.',
	},
	toneProfessional: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.tone.option.professional',
		defaultMessage: 'Professional',
		description: 'Option for the Tone input in the prompt builder.',
	},
	toneEmpathetic: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.tone.option.empathetic',
		defaultMessage: 'Empathetic',
		description: 'Option for the Tone input in the prompt builder.',
	},
	toneCasual: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.tone.option.casual',
		defaultMessage: 'Casual',
		description: 'Option for the Tone input in the prompt builder.',
	},
	toneNeutral: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.tone.option.neutral',
		defaultMessage: 'Neutral',
		description: 'Option for the Tone input in the prompt builder.',
	},
	toneEducational: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.tone.option.educational',
		defaultMessage: 'Educational',
		description: 'Option for the Tone input in the prompt builder.',
	},
	creativityLabel: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.creativity.label',
		defaultMessage: 'Creativity',
		description: 'Label for the Creativity input in the prompt builder.',
	},
	creativityLow: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.creativity.option.low',
		defaultMessage: 'Low',
		description: 'Option for the Creativity input in the prompt builder.',
	},
	creativityMedium: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.creativity.option.medium',
		defaultMessage: 'Medium',
		description: 'Option for the Creativity input in the prompt builder.',
	},
	creativityHigh: {
		id: 'fabric.editor.ai.config.item.advancedPrompt.form.creativity.option.high',
		defaultMessage: 'High',
		description: 'Option for the Creativity input in the prompt builder.',
	},
});
