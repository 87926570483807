export enum ITEM_TYPE {
	LINK = 'link',
	MENU = 'menu',
}
type BaseItemType = {
	itemId: string;
	type: ITEM_TYPE;
	title: string;
};
export interface LinkItemType extends BaseItemType {
	type: ITEM_TYPE.LINK;
	link: string;
}
export interface MenuItemType extends BaseItemType {
	type: ITEM_TYPE.MENU;
	childItems?: (MenuItemType | LinkItemType)[];
}
export enum ALIGNMENT {
	LEFT = 'left',
	CENTER = 'center',
	RIGHT = 'right',
}
export enum BORDER {
	NONE = 'none',
	ALL_SIDES = 'all_sides',
	BOTTOM = 'bottom',
}
export enum CORNERS {
	ROUND = 'round',
	SHARP = 'sharp',
}
export type Styles = {
	alignment: ALIGNMENT;
	fillColor: string;
	borderColor: string;
	border: BORDER;
	cornerStyle: CORNERS;
	textColor: string;
	highlightColor: string;
};
export type LinksMenuParameters = {
	version: string;
	items?: (MenuItemType | LinkItemType)[];
	styles: Styles;
};
