/** @jsxFrag jsx fragment pragma required */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { token } from '@atlaskit/tokens';
import { useIsMacro } from '../utils/useIsMacro';
import type { RenderFn } from '@atlassian/forge-ui-types';

// This overrides Editor styling on text components that affects chart components in macro module
const chartStyle = css({
	// Styling override for UI Kit chart title
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div[data-element="layout"] h2': {
		font: token('font.heading.small'),
		color: token('color.text.subtle'),
	},
	// Styling override for UI Kit chart subtitle
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'div[data-element="layout"] h3': {
		font: token('font.body'),
		color: token('color.text.subtle'),
	},
	// Styling override for UI Kit chart legend
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-element="chart"] p': {
		marginTop: token('space.100'),
		marginBottom: token('space.100'),
		marginLeft: token('space.0'),
		marginRight: token('space.0'),
		font: token('font.body.small'),
		color: token('color.text.subtlest'),
	},
	// Styling override for UI Kit single value chart value
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'span [data-element="chart"] p': {
		font: token('font.heading.large'),
		color: token('color.text'),
		margin: token('space.0'),
		paddingLeft: token('space.0'),
		paddingRight: token('space.0'),
		paddingTop: token('space.0'),
		paddingBottom: token('space.100'),
	},
});

export const withMacroStyleWrapper = (
	ChartComponent: (props: Parameters<RenderFn>[0]) => React.JSX.Element,
) => {
	return function MacroStyleWrapper(props: Parameters<RenderFn>[0]) {
		const isMacro = useIsMacro();

		return isMacro ? (
			<div css={chartStyle}>
				<ChartComponent {...props} />
			</div>
		) : (
			<ChartComponent {...props} />
		);
	};
};
