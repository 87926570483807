import { useIntl, defineMessages } from 'react-intl-next';
import React, { useCallback } from 'react';

import Heading from '@atlaskit/heading';
import { LinkButton } from '@atlaskit/button/new';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';

import { ADMIN_BULK_PERMISSIONS } from '@confluence/named-routes';
import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';

// TODO (pleaton): Update these messages when content is final
const i18n = defineMessages({
	title: {
		id: 'bulk-permissions.space.title',
		defaultMessage: 'Add people to all spaces',
		description: 'Title text of the bulk permissions page',
	},
	bulkRolesTitle: {
		id: 'bulk-permissions.space.bulk-roles-title',
		defaultMessage: 'Manage space access in bulk',
		description: 'Title text of the bulk access page',
	},
	description: {
		id: 'bulk-permissions.space.description',
		defaultMessage:
			'Quickly add (or remove) individual users or groups to all spaces on your Confluence site.',
		description: 'Description text of the bulk permissions page',
	},
	bulkRolesDescription: {
		id: 'bulk-permissions.space.bulk-roles-description',
		defaultMessage:
			'Quickly add, change, or remove access for individual users or groups across all spaces on your Confluence site.',
		description: 'Description text of the bulk access page',
	},
	button: {
		id: 'bulk-permissions.space.button',
		defaultMessage: 'Choose a user or group',
		description: 'Button text to bulk permissions page',
	},
	bulkRolesButton: {
		id: 'bulk-permissions.space.bulk-roles-button',
		defaultMessage: 'Start bulk access action',
		description: 'Button text to bulk access page',
	},
});

const containerStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
});

const BulkSpacePermissionsButtonComponent = () => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: 'bulkPermissionsEntry',
				source: 'defaultspaceperms',
			},
		}).fire();
	}, [createAnalyticsEvent]);
	return (
		<Box xcss={containerStyles}>
			<Heading size="medium">
				{fg('cc_perms_exp_rbac_fe_milestone_3')
					? intl.formatMessage(i18n.bulkRolesTitle)
					: intl.formatMessage(i18n.title)}
			</Heading>
			<Box paddingBlock="space.100">
				{fg('cc_perms_exp_rbac_fe_milestone_3')
					? intl.formatMessage(i18n.bulkRolesDescription)
					: intl.formatMessage(i18n.description)}
			</Box>
			<Box>
				<LinkButton href={ADMIN_BULK_PERMISSIONS.toUrl()} onClick={handleClick}>
					{fg('cc_perms_exp_rbac_fe_milestone_3')
						? intl.formatMessage(i18n.bulkRolesButton)
						: intl.formatMessage(i18n.button)}
				</LinkButton>
			</Box>
		</Box>
	);
};

export const BulkSpacePermissionsButton = withErrorBoundary({
	attribution: Attribution.PERMISSIONS_EXPERIENCE,
})(BulkSpacePermissionsButtonComponent);
