import { type AIExperienceMachineContext } from './xstate/get-ai-experience-service';

export function shouldDiscardEndExperienceImmediately(
	promptTrigger: AIExperienceMachineContext['promptTrigger'] | undefined,
	history: AIExperienceMachineContext['responseHistory'],
) {
	const isPreConfiguredPrompt = promptTrigger !== 'generate'; // like immediate 'improve writing' from toolbar
	const isInitialPrompt = history?.entries?.length === 1;
	return isPreConfiguredPrompt && isInitialPrompt;
}
