import { Transaction, EditorState, Selection } from '@atlaskit/editor-prosemirror/state';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';

import { createCommand, getPluginState } from './plugin-factory';
import { ACTIONS, type InlineSuggestion } from './states';
import { preserveExistingMarks } from './utils';

export function insertInlineSuggestion() {
	return createCommand(
		() => ({ type: ACTIONS.CLEAR_INLINE_SUGGESTION }),
		(tr: Transaction, state: EditorState) => {
			const { inlineSuggestion } = getPluginState(state);
			if (!inlineSuggestion) {
				return tr;
			}
			const replacement = preserveExistingMarks(
				tr.doc,
				inlineSuggestion.from,
				inlineSuggestion.suggestedNode.copy(),
			);
			tr.replaceWith(inlineSuggestion.from, inlineSuggestion.to, replacement);
			tr.setSelection(Selection.near(tr.doc.resolve(inlineSuggestion.from + replacement.nodeSize)));
			return tr;
		},
	);
}

export function clearSuggestion() {
	return createCommand(() => ({ type: ACTIONS.CLEAR_INLINE_SUGGESTION }));
}

export function updateInlineSuggestion(
	inlineSuggestion: InlineSuggestion | null,
	decorationSet: DecorationSet,
) {
	return createCommand(() => ({
		type: ACTIONS.UPDATE_INLINE_SUGGESTION,
		payload: {
			inlineSuggestion,
			decorationSet,
		},
	}));
}

export function updateLocale(locale: string) {
	return createCommand(() => ({
		type: ACTIONS.UPDATE_LOCALE,
		payload: {
			locale,
		},
	}));
}
