import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import TextArea from '@atlaskit/textarea';
import { Stack, xcss, Text, Inline } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';

const i18n = defineMessages({
	roleDescriptionHeading: {
		id: 'default-space-permissions.set-role-modal.role-description-heading',
		defaultMessage: 'Role description<asterisk>*</asterisk>',
		description: 'Heading for the input field where the user can enter the role description',
	},
	roleDescriptionPlaceholder: {
		id: 'default-space-permissions.set-role-modal.role-description-placeholder',
		defaultMessage: 'Add a description',
		description: 'Placeholder for the input field where the user can enter the role description',
	},
	roleDescriptionMaxLength: {
		id: 'default-space-permissions.set-role-modal.role-description-max-length',
		defaultMessage: 'Max length of 100',
		description: 'Text that indicates the maximum length of the role description',
	},
});

const roleDescriptionHeadingStyle = xcss({
	paddingBottom: 'space.100',
});

const roleDescriptionSectionStyle = xcss({});

export const SetRoleDescriptionSection = ({
	roleDescription,
	setRoleDescription,
	asterisk,
}: {
	roleDescription: string;
	setRoleDescription: React.Dispatch<React.SetStateAction<string>>;
	asterisk: () => React.ReactNode;
}) => {
	const { formatMessage } = useIntl();

	return (
		<Stack xcss={roleDescriptionSectionStyle}>
			<Heading size="xsmall">
				<Inline xcss={roleDescriptionHeadingStyle}>
					{formatMessage(i18n.roleDescriptionHeading, {
						asterisk,
					})}
				</Inline>
			</Heading>
			<TextArea
				value={roleDescription}
				onChange={(e) => setRoleDescription(e.target.value)}
				placeholder={formatMessage(i18n.roleDescriptionPlaceholder)}
				maxLength={100}
			/>
			<Text color="color.text.subtlest" size="small">
				{formatMessage(i18n.roleDescriptionMaxLength)}
			</Text>
		</Stack>
	);
};
