import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import TextArea from '@atlaskit/textarea';
import { Stack, xcss, Text, Inline } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';

const i18n = defineMessages({
	roleNameHeading: {
		id: 'default-space-permissions.set-role-modal.role-name-heading',
		defaultMessage: 'Role name<asterisk>*</asterisk>',
		description: 'Heading for the input field where the user can enter the role name',
	},
	roleNamePlaceholder: {
		id: 'default-space-permissions.set-role-modal.role-name-placeholder',
		defaultMessage: 'Add a name',
		description: 'Placeholder for the input field where the user can enter the role name',
	},
	roleNameMaxLength: {
		id: 'default-space-permissions.set-role-modal.role-name-max-length',
		defaultMessage: 'Max length of 25',
		description: 'Text that indicates the maximum length of the role name',
	},
	roleNameExtraInfo: {
		id: 'default-space-permissions.set-role-modal.role-name-extra-info',
		defaultMessage: 'Who can see your role name?',
		description: 'Extra information about the role name',
	},
});

const roleNameHeadingStyle = xcss({
	paddingBottom: 'space.100',
});

const roleNameSectionStyle = xcss({});

export const SetRoleNameSection = ({
	roleName,
	setRoleName,
	asterisk,
}: {
	roleName: string;
	setRoleName: React.Dispatch<React.SetStateAction<string>>;
	asterisk: () => React.ReactNode;
}) => {
	const { formatMessage } = useIntl();

	return (
		<Stack xcss={roleNameSectionStyle}>
			<Heading size="xsmall">
				<Inline xcss={roleNameHeadingStyle}>
					{formatMessage(i18n.roleNameHeading, {
						asterisk,
					})}
				</Inline>
			</Heading>
			<TextArea
				value={roleName}
				onChange={(e) => setRoleName(e.target.value)}
				placeholder={formatMessage(i18n.roleNamePlaceholder)}
				maxLength={25}
			/>
			<Text color="color.text.subtlest" size="small">
				{formatMessage(i18n.roleNameMaxLength)}
			</Text>
			<Inline alignBlock="center" space="space.100">
				<Text color="color.text.subtlest" size="small">
					{formatMessage(i18n.roleNameExtraInfo)}
				</Text>
			</Inline>
		</Stack>
	);
};
