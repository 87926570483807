import type { Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import type { Selection } from '@atlaskit/editor-prosemirror/state';
import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import { type RovoPublish } from '../../types/types';
import { type DecorationPositions } from '../decoration/actions';

/**
 * This function is defined in the PublishToRovo component in editor-plugin-ai
 * where we have access to React. It sends the Editor state to rovo on
 * doc change, selection change and Editor mount/unmount.
 */
export type PublishContextAction = ({
	doc,
	selection,
}: {
	doc?: PMNode;
	selection?: Selection;
}) => void;

export interface RovoAgentsPluginState {
	agents: EditorAgent[];
	defaultAgent?: EditorAgent;
	/**
	 * Resolves based on whether all requisite flags and entitlement
	 * checks are enabled.
	 */
	enabled: boolean;
	publish: RovoPublish;
	isBrowseModalOpen: boolean;
	/**
	 * This is used to store the selection positions when the browse agents modal is opened.
	 */
	positions?: DecorationPositions;
}

export enum ACTIONS {
	UPDATE_PLUGIN_STATE,
}

export type RovoAgentAction = {
	type: ACTIONS.UPDATE_PLUGIN_STATE;
	data: {
		agents: EditorAgent[];
		defaultAgent?: EditorAgent;
		publish: RovoPublish;
		isBrowseModalOpen: boolean;
		enabled: boolean;
		positions?: DecorationPositions;
	};
};
