import type { FC } from 'react';
import React from 'react';

import { Stack, Text, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export type RoleRowType = {
	name: string;
	id: string;
	description: string;
	permissions: string[];
	actions: React.ReactNode;
};

const rowBorderedStyle = xcss({
	borderBottom: `1px solid ${token('color.border')}`,
});

const roleContentStyle = xcss({
	padding: 'space.150',
});

export const RoleRow: FC<
	RoleRowType & {
		bottomBorder: boolean;
	}
> = ({ name, description, actions, bottomBorder }) => {
	return (
		<Inline
			xcss={bottomBorder ? rowBorderedStyle : undefined}
			spread="space-between"
			alignBlock="center"
		>
			<Stack xcss={roleContentStyle}>
				<Text size="large">{name}</Text>
				<Text color="color.text.subtlest">{description}</Text>
			</Stack>
			{actions}
		</Inline>
	);
};
