import type { FC } from 'react';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ModalDialog, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '@atlaskit/modal-dialog';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/new';

const i18n = defineMessages({
	title: {
		id: 'default-space-permissions.friction-modal.title.non-final',
		defaultMessage: 'Unsaved changes',
		description: 'Title for the friction modal',
	},
	body: {
		id: 'default-space-permissions.space-roles.friction-modal.body.non-final',
		defaultMessage: 'You have unsaved changes that will be lost if you close without saving.',
		description: 'Body text for the friction modal',
	},
	additional: {
		id: 'default-space-permissions.friction-modal.body-additional.non-final',
		defaultMessage: 'Do you want to discard them?',
		description: 'Additional body text for the friction modal',
	},
	cancel: {
		id: 'default-space-permissions.friction-modal.cancel.non-final',
		defaultMessage: 'Cancel',
		description: 'Cancel button text',
	},
	confirm: {
		id: 'default-space-permissions.friction-modal.confirm.non-final',
		defaultMessage: 'Discard',
		description: 'Confirm button text',
	},
});

export const FrictionModal: FC<{
	onClose: () => void;
	onConfirm: () => void;
}> = ({ onClose, onConfirm }) => {
	const { formatMessage } = useIntl();

	return (
		<ModalDialog width="small" onClose={onClose}>
			<ModalHeader>
				<ModalTitle appearance="warning">{formatMessage(i18n.title)}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<>
					{formatMessage(i18n.body)}
					<br />
					{formatMessage(i18n.additional)}
				</>
			</ModalBody>
			<ModalFooter>
				<ButtonGroup>
					<Button onClick={onClose}>{formatMessage(i18n.cancel)}</Button>
					<Button appearance="warning" onClick={onConfirm}>
						{formatMessage(i18n.confirm)}
					</Button>
				</ButtonGroup>
			</ModalFooter>
		</ModalDialog>
	);
};
