import React from 'react';

import { RainbowBorder } from '../../components/RainbowBorder/RainbowBorder';

import type { IconProps } from './types';

export const RephraseIcon = (props: IconProps) => {
	const { shownAt } = props;

	// TODO swap to @atlaskit/icon once available
	return (
		<RainbowBorder shownAt={shownAt}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M13.5 10.2507H5M17.5 14L19.0581 12.4419C19.3021 12.1979 19.3021 11.8021 19.0581 11.5581L17.9419 10.4419C17.6979 10.1979 17.3021 10.1979 17.0581 10.4419L15.5 12M17.5 14L14.5176 16.9824C14.3431 17.1569 14.1209 17.2758 13.8789 17.3242L11.75 17.75L12.1758 15.6211C12.2242 15.3791 12.3431 15.1569 12.5176 14.9824L15.5 12M17.5 14L15.5 12M18 6.7511H5M9 17.25H5M10.5 13.7504H5"
					stroke="#44546F"
					strokeWidth="1.5"
					strokeLinejoin="round"
				/>
			</svg>
		</RainbowBorder>
	);
};
