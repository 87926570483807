import { fragmentContainsNodeType } from '@atlaskit/editor-common/insert';
import { Slice } from '@atlaskit/editor-prosemirror/model';
import { safeInsert } from '@atlaskit/editor-prosemirror/utils';
import { fg } from '@atlaskit/platform-feature-flags';

import { eventHub } from '../../../pm-plugins/ai-event-hub/event-hub';
import { findEditorIdFromView } from '../../../pm-plugins/ai-event-hub/utils/isolated-utils';
import type {
	ActionAppearance,
	EditorPluginAIConfigItemMarkdownAction,
} from '../../../prebuilt/config-items/config-items';
import {
	beforeDispatchAIRovoAgentTransaction,
	beforeDispatchAITransaction,
	beforeDispatchingTransactionForBlocks,
	insertContentAfterTable,
	insertAIContentAtCurrentPosition,
	replaceWithAIContent,
	replaceWithAIContentForBlock,
	shouldInsertAfterTable,
} from '../utils/action-utils';

import { messages } from './messages';

export type CreateInsertProps = { appearance: ActionAppearance; isRovoAgentAction?: boolean };

export const createInsertAtTop = ({
	appearance,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.insertAtTop,
	appearance,
	run({ editorView, pmFragment }) {
		const { state } = editorView;
		const transaction = state.tr;

		const slice = new Slice(pmFragment, 0, 0);
		transaction.replace(0, 0, slice);

		beforeDispatchAITransaction(transaction, state);
		editorView.dispatch(transaction);
	},
});

export const createInsertBelow = ({
	appearance,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.insertBelow,
	appearance,
	run({ editorView, positions, pmFragment, triggeredFor }) {
		const { state } = editorView;
		const transaction = state.tr;

		if (
			fragmentContainsNodeType(pmFragment, state.schema.nodes.table) &&
			shouldInsertAfterTable({
				selection: state.selection,
				tableSchema: state.schema.nodes.table,
			}) &&
			fg('platform_editor_use_nested_table_pm_nodes')
		) {
			insertContentAfterTable({ pmFragment, transaction });
		} else {
			if (triggeredFor?.isBlock) {
				const insertPos = positions[1];
				safeInsert(pmFragment, insertPos)(transaction);
				beforeDispatchingTransactionForBlocks(pmFragment, transaction, editorView.state);
			} else {
				const insertPos = state.doc.resolve(positions[1]).after();
				safeInsert(pmFragment, insertPos)(transaction);
			}
		}

		beforeDispatchAITransaction(transaction, state);

		editorView.dispatch(transaction);
	},
});

export const createInsertAtCurrentPosition = ({
	appearance,
	isRovoAgentAction,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.insert,
	appearance,
	isRovoAgentAction,
	run({ editorView, positions, pmFragment }) {
		const tr = insertAIContentAtCurrentPosition({ editorView, positions, pmFragment });
		if (isRovoAgentAction) {
			beforeDispatchAIRovoAgentTransaction(tr);
		}

		editorView.dispatch(tr);
	},
});

export const createReplace = ({
	appearance,
	isRovoAgentAction,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.replace,
	appearance,
	isRovoAgentAction,
	run({ editorView, positions, pmFragment, triggeredFor }) {
		const tr = triggeredFor?.isBlock
			? replaceWithAIContentForBlock({ editorView, positions, pmFragment, triggeredFor })
			: replaceWithAIContent({ editorView, positions, pmFragment });

		if (isRovoAgentAction) {
			beforeDispatchAIRovoAgentTransaction(tr);
		}

		editorView.dispatch(tr);
	},
});

/**
 * EXPERIMENTAL: Util for experimental flow where 'Suggest title' in
 * Confluence title toolbar will trigger the 'Suggest a title' flow in Editor AI.
 * This util allows replacing the title in Confluence.
 */
export const createReplaceExistingConfluenceTitle = ({
	appearance,
}: CreateInsertProps): EditorPluginAIConfigItemMarkdownAction => ({
	type: 'markdown',
	title: messages.replaceTitle,
	appearance,
	getDisabledState: ({ markdown, formatMessage }) => {
		const responseLength = markdown?.length ?? 0;
		// Source: next/packages/editor-title/src/EditorTitle.tsx
		const MAX_TITLE_LENGTH = 255;
		if (responseLength >= MAX_TITLE_LENGTH) {
			return {
				isDisabled: true,
				tooltip: formatMessage(messages.replaceTitleTooLongDisabledTooltip, {
					maxLength: MAX_TITLE_LENGTH,
				}),
			};
		}
		return {
			isDisabled: false,
		};
	},
	run({ editorView, pmFragment }) {
		const fragmentToInsert = pmFragment;
		let textContent = '';
		fragmentToInsert.forEach((node) => {
			textContent += node.textContent;
		});
		const confluenceTitle = document.querySelector(
			'textarea[data-ai-suggest-title-target="true"]',
		) as HTMLTextAreaElement;
		if (confluenceTitle) {
			const sourceEditorId = findEditorIdFromView(editorView);
			if (sourceEditorId) {
				eventHub.publishFromEditor({
					event: 'replace title in confluence',
					data: {
						analyticSourceId: 'confluenceTitleToolbar',
						sourceEditorId,
						title: textContent,
					},
				});
			}

			confluenceTitle.scrollIntoView();
		}
	},
});
