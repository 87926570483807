import { v4 as uuid } from 'uuid';

import { type SuggestionHandler } from '../types';

import { DateParser, DateUtils, LocaleManager } from './utils';

export const dateSuggestionHandler: SuggestionHandler = {
	type: 'date',

	detect(state, cursorPhrases) {
		const locale = LocaleManager.getSelectedLocale(state);
		for (const phrase of cursorPhrases) {
			const parsedDate = DateParser.parse(phrase.phrase, locale);
			if (parsedDate) {
				return {
					id: uuid(),
					type: 'date',
					from: phrase.from,
					to: phrase.to,
					metadata: { date: parsedDate },
				};
			}
		}

		return null;
	},

	createMarkers(suggestion) {
		const { from, to } = suggestion;
		return [
			{
				style: 'highlight',
				from,
				to,
			},
			{
				style: 'underline',
				from,
				to,
			},
		];
	},

	createReplacement(suggestion, schema) {
		const { date } = suggestion.metadata;
		const timestamp = DateUtils.getDateOnlyUtcTimestamp(date);
		return schema.nodes.date.create({ timestamp: String(timestamp) });
	},
};
