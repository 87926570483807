import gql from 'graphql-tag';

export const SPAViewContextQuery = gql`
	query SPAViewContextQuery {
		spaViewContext {
			userCanCreateContent
			isAnonymous
			homepageUri
			homepageTitle
		}
		siteConfiguration {
			siteLogoUrl
			siteTitle
			showSiteTitle
			frontCoverState
			companyHubName
		}
		isNewUser
		isSiteAdmin
		abTestCohorts
		experimentFeatures
		tenantContext {
			licenseStates {
				confluence {
					ccpEntitlementId
				}
			}
		}
		tenant {
			cloudId
		}
	}
`;
