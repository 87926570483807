import gql from 'graphql-tag';

export const TopNavigationQuery = gql`
	query TopNavigationQuery($includeEmails: Boolean!) {
		siteConfiguration {
			customSiteLogo
			siteLogoUrl
			siteTitle
			showSiteTitle
		}
		user(current: true) {
			id
			displayName
			emails @include(if: $includeEmails) {
				value
				isPrimary
			}
			photos {
				value
			}
		}
	}
`;
