import React from 'react';
import { defineMessages, type IntlFormatters } from 'react-intl-next';

import PeopleGroupIcon from '@atlaskit/icon/core/migration/people-group';
import { ConfluenceIcon } from '@atlaskit/logo';
import GlobeIcon from '@atlaskit/icon/core/globe';

import type { SpacePermissionListQuery_spacePermissionsAll_nodes as SpacePermissionListNode } from '../graphql/__types__/SpacePermissionListQuery';
import type { GroupCountsQuery_groupCounts_groupCounts as GroupCountRecord } from '../graphql/__types__/GroupCountsQuery';
import type {
	SpaceRoleAssignmentsByCriteriaQuery_spaceRoleAssignmentsByCriteria_nodes as SpaceRoleAssignmentNode,
	SpaceRoleAssignmentsByCriteriaQuery_spaceRoleAssignmentsByCriteria_nodes_principal as SpaceRoleAssignmentNodePrincipal,
} from '../graphql/__types__/SpaceRoleAssignmentsByCriteriaQuery';
import type { SpaceRolesByCriteriaQuery_spaceRolesByCriteria_nodes as SpaceRoleNode } from '../graphql/__types__/SpaceRolesByCriteriaQuery';

import { RoleAssignmentPrincipalType } from './space-roles-types';
import type { PrincipalRoleAssignment, SpacePermission, SpaceRole } from './space-roles-types';

const AccessClassIcon = () => React.createElement(ConfluenceIcon, { appearance: 'brand' });
const getPrincipalIcon = (principal: SpaceRoleAssignmentNodePrincipal) => {
	switch (principal.__typename) {
		case 'SpaceRoleGroupPrincipal':
			return PeopleGroupIcon;
		case 'SpaceRoleAccessClassPrincipal':
			return principal.principalId === 'anonymous-users' ? GlobeIcon : AccessClassIcon;
		default:
			return null;
	}
};

export const normalizeSpacePermissionAll = (data: SpacePermissionListNode[]): SpacePermission[] => {
	return (
		data
			.map<SpacePermission>((item) => ({
				key: item.id,
				groupName: item.group.displayName,
				groupPriority: item.group.priority,
				actionName: item.displayName,
				priority: item.priority,
				description: item.description,
				requiredPermissions: item.requiredSpacePermissions,
			}))
			// Filter out all actions without display names before the deprecation of some permissions is completed
			.filter((item) => !!item.actionName)
	);
};

export const normalizeSpaceRoleList = (rawData: SpaceRoleNode[]): SpaceRole[] => {
	return rawData.map<SpaceRole>((item) => ({
		id: item.roleId,
		name: item.roleDisplayName,
		description: item.roleDescription,
		permissions: item.spacePermissionList.map((permission) => permission.id),
	}));
};

export const getPrincipalTypeFromGraphqlTypename = (type: string): RoleAssignmentPrincipalType => {
	switch (type) {
		case 'SpaceRoleGuestPrincipal':
			// Guests are users.
			return RoleAssignmentPrincipalType.USER;
		case 'SpaceRoleGroupPrincipal':
			return RoleAssignmentPrincipalType.GROUP;
		case 'SpaceRoleUserPrincipal':
			return RoleAssignmentPrincipalType.USER;
		case 'SpaceRoleAccessClassPrincipal':
			return RoleAssignmentPrincipalType.ACCESS_CLASS;
		default:
			return RoleAssignmentPrincipalType.GROUP;
	}
};

export const getPrincipalTypeFromUserPickerType = (
	type: string | undefined,
): RoleAssignmentPrincipalType => {
	switch (type) {
		case 'user':
			return RoleAssignmentPrincipalType.USER;
		case 'group':
			return RoleAssignmentPrincipalType.GROUP;
		case 'external_user':
			return RoleAssignmentPrincipalType.USER;
		default:
			return RoleAssignmentPrincipalType.USER;
	}
};

export const normalizeAssignments = (
	rawData: SpaceRoleAssignmentNode[] | null,
): PrincipalRoleAssignment[] => {
	if (!rawData) return [];

	return rawData.map<PrincipalRoleAssignment>((item) => ({
		assignmentId: item.principal.principalId, // BE will support the assignmentId in the future
		principal: {
			id: item.principal.principalId,
			displayName: item.principal.displayName,
			type: getPrincipalTypeFromGraphqlTypename(item.principal.__typename),
			email: 'email' in item.principal ? item.principal?.email : undefined,
			avatarUrl:
				'profilePicture' in item.principal ? item.principal.profilePicture?.path : undefined,
			icon: getPrincipalIcon(item.principal),
			isGuest: item.principal.__typename === 'SpaceRoleGuestPrincipal',
		},
		roleId: item.role?.roleId || null,
		roleLabel: item.role?.roleDisplayName || null,
		// Principal can either be assigned granular space permissions, or permissions through a role
		permissions:
			item?.permissions?.map((permission) => permission.id) ||
			item.role?.spacePermissionList.map((permission) => permission.id) ||
			[],
		availableRoles: [],
	}));
};

export type GroupCountMap = Record<string, number | null>;

export const mergeGroupCountWithAssignments = (
	groupCountRecords: GroupCountRecord[],
	assignments: PrincipalRoleAssignment[],
): PrincipalRoleAssignment[] => {
	const groupCountMap: GroupCountMap = groupCountRecords.reduce((acc, { key, value }) => {
		if (key !== null) acc[key] = value;
		return acc;
	}, {});

	return assignments.map((assignment) => ({
		...assignment,
		principal:
			assignment.principal.type === RoleAssignmentPrincipalType.GROUP
				? {
						...assignment.principal,
						groupCount: groupCountMap[assignment.principal.id] ?? undefined,
					}
				: assignment.principal,
	}));
};

const accessClassNamesI18n = defineMessages({
	licensedUsers: {
		id: 'space-roles.access-class.licensed-users',
		defaultMessage: 'All Confluence users',
		description: 'Display name for the Confluence users access class',
	},
	productAdmins: {
		id: 'space-roles.access-class.product-admins',
		defaultMessage: 'All Confluence admins',
		description: 'Display name for the Confluence admins access class',
	},
	anonymousUsers: {
		id: 'space-roles.access-class.anonymous-users',
		defaultMessage: 'Anonymous users',
		description: 'Display name for the anonymous users access class',
	},
});

export const mergeAccessClassesWithAssignments = (
	assignments: PrincipalRoleAssignment[],
	isFiltered: boolean,
	formatMessage: IntlFormatters['formatMessage'],
) => {
	const ANONYMOUS_USERS_ID = 'anonymous-users';
	const LICENSED_USERS_ID = 'all-licensed-users';
	const PRODUCT_ADMINS_ID = 'all-product-admins';

	const findAssignmentData = (assignments: PrincipalRoleAssignment[], principalId: string) =>
		assignments.find((assignment) => assignment.principal.id === principalId);
	const anonymousUsersAssignment = findAssignmentData(assignments, ANONYMOUS_USERS_ID);
	const licensedUsersAssignment = findAssignmentData(assignments, LICENSED_USERS_ID);
	const productAdminsAssignment = findAssignmentData(assignments, PRODUCT_ADMINS_ID);

	return [
		// If the result is filtered, display as needed.
		// If the result is not filtered, display 2 access classes permanently.
		...(licensedUsersAssignment || !isFiltered
			? [
					{
						assignmentId: LICENSED_USERS_ID,
						principal: {
							id: LICENSED_USERS_ID,
							displayName: formatMessage(accessClassNamesI18n.licensedUsers),
							type: RoleAssignmentPrincipalType.ACCESS_CLASS,
							icon: licensedUsersAssignment?.principal.icon || AccessClassIcon,
						},
						roleId: licensedUsersAssignment?.roleId || null,
						roleLabel: licensedUsersAssignment?.roleLabel || null,
						availableRoles: [],
						permissions: licensedUsersAssignment?.permissions || [],
					},
				]
			: []),
		...(productAdminsAssignment || !isFiltered
			? [
					{
						assignmentId: PRODUCT_ADMINS_ID,
						principal: {
							id: PRODUCT_ADMINS_ID,
							displayName: formatMessage(accessClassNamesI18n.productAdmins),
							type: RoleAssignmentPrincipalType.ACCESS_CLASS,
							icon: productAdminsAssignment?.principal.icon || AccessClassIcon,
						},
						roleId: productAdminsAssignment?.roleId || null,
						roleLabel: productAdminsAssignment?.roleLabel || null,
						availableRoles: [],
						permissions: productAdminsAssignment?.permissions || [],
					},
				]
			: []),
		...(anonymousUsersAssignment
			? [
					{
						assignmentId: ANONYMOUS_USERS_ID,
						principal: {
							id: ANONYMOUS_USERS_ID,
							displayName: formatMessage(accessClassNamesI18n.anonymousUsers),
							type: RoleAssignmentPrincipalType.ACCESS_CLASS,
							icon: anonymousUsersAssignment.principal.icon || GlobeIcon,
						},
						roleId: anonymousUsersAssignment.roleId || null,
						roleLabel: anonymousUsersAssignment.roleLabel || null,
						availableRoles: [],
						permissions: anonymousUsersAssignment.permissions || [],
					},
				]
			: []),
		...assignments.filter(
			(assignment) => assignment.principal.type !== RoleAssignmentPrincipalType.ACCESS_CLASS,
		),
	];
};
