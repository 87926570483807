import React from 'react';

import { MakeLongerIcon } from '../../../../ui/icons/prompt/makeLonger';
import { createInsertBelow, createReplace } from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

import { messages } from './messages';

const intentSchemaId = 'make_longer.json';

export const makeLonger: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.MAKE_LONGER,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <MakeLongerIcon shownAt={shownAt} />,
	statusLozengeType: 'beta',
	intentSchemaId,
	getBackendModel: () => 'assistance-service',
	showInRefineDropdown: true,
	actions: {
		empty: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
};

export const makeLongerWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: makeLonger,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};
