import React from 'react';
import { type RenderFn } from '@atlassian/forge-ui-types';
import { Text } from '@atlaskit/primitives';
import { useIsMacro } from '../../utils/useIsMacro';

export const Strong = (props: Parameters<RenderFn>[0]) => {
	const isMacro = useIsMacro();
	return (
		// // `p` tag in editor/page is set to 16px. The strong element is using the Text component which sets it to 14px. Resetting to large to match with the rest of the editor/page styles. In other modules, this would default to 14px to match with the rest of the typography styles.
		<Text size={isMacro ? 'large' : undefined} as="strong">
			{props.forgeDoc.children.map((c) => (
				<React.Fragment key={c.key}>{props.render(c)}</React.Fragment>
			))}
		</Text>
	);
};
