import React from 'react';
import type { IntlShape } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';

import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';

import { LoadableAfterPaint } from '@confluence/loadable';

import { getExtensionManifest } from '../shared-components/customSitesManifestBuilder';
import type { CustomSitesManifestArguments } from '../shared-components/customSitesManifestBuilder';

import { linksMenuExtensionType } from './linksMenuExtensionType';
import type { LinksMenuExtensionProps } from './LinksMenuExtension';
import { ALIGNMENT, BORDER, CORNERS } from './linksMenuTypes';
import type { LinksMenuParameters } from './linksMenuTypes';

const LINKS_MENU_VERSION_1 = '1';
const LinksMenuExtension = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LinksMenuExtension" */ './LinksMenuExtension'))
			.LinksMenuExtension,
});

const i18n = defineMessages({
	linksMenuTitle: {
		id: 'custom-sites-extensions.links-menu.manifest.extension-title',
		defaultMessage: 'Links menu',
		description:
			'Title of links menu extension to be displayed in toolbar/shortcut macros dropdown.',
	},
	linksMenuDescription: {
		id: 'custom-sites-extensions.links-menu.manifest.extension-description',
		defaultMessage: 'Add multiple links',
		description:
			'Description of links menu extension to be displayed in toolbar/shortcut macros dropdown',
	},
});

type LinksMenuManifestArguments = {
	intl: IntlShape;
} & Pick<LinksMenuExtensionProps, 'contentId' | 'openConfigPanel' | 'editorActions'> &
	Pick<CustomSitesManifestArguments<LinksMenuParameters>, 'setUpsellModalSource'>;

const getParameters = (): LinksMenuParameters => ({
	version: LINKS_MENU_VERSION_1,
	styles: {
		// @TODO in COMPHUB-2420: set proper defaults
		alignment: ALIGNMENT.CENTER,
		fillColor: '#FFFFFF',
		borderColor: '#FFFFFF',
		border: BORDER.NONE,
		cornerStyle: CORNERS.ROUND,
		textColor: '#FFFFFF',
		highlightColor: '#FFFFFF',
	},
	items: [],
});

export const linksMenuExtensionProvider = async ({
	editorActions,
	openConfigPanel,
	intl,
	...extensionPassThroughProps
}: LinksMenuManifestArguments) => {
	const parameters = getParameters();
	return new DefaultExtensionProvider([
		getExtensionManifest<LinksMenuParameters>({
			title: intl.formatMessage(i18n.linksMenuTitle),
			type: linksMenuExtensionType,
			key: 'links-menu',
			description: intl.formatMessage(i18n.linksMenuDescription),
			icons: {
				'48': () =>
					import(
						/* webpackChunkName: "loadable-LinksMenuExtensionIcon" */
						'@confluence/icons/entry-points/LinksMenuExtensionIcon'
					).then((mod) => mod.LinksMenuExtensionIcon),
			},
			parameters,
			update: async () => {
				const selectedNode = editorActions?.getSelectedNode()?.toJSON();
				const selectedLocalId = selectedNode?.attrs?.localId;

				if (!!selectedLocalId && openConfigPanel) {
					openConfigPanel(selectedLocalId);
				}
			},
			render:
				async () =>
				({ node }) => {
					return (
						<LinksMenuExtension
							extensionNode={node}
							openConfigPanel={openConfigPanel}
							editorActions={editorActions}
							{...extensionPassThroughProps}
						/>
					);
				},
			// @TODO add links menu upsell in COMPHUB-2408
			setUpsellModalSource: undefined,
			source: 'premiumCarouselInsert',
		}),
	]);
};
