import type { FC } from 'react';
import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import type { RoleRowType } from './RoleRow';
import { RoleRow } from './RoleRow';

const roleListStyle = xcss({
	border: `1px solid ${token('color.border')}`,
	borderRadius: '5px',
});

export const RoleList: FC<{
	roleRows: RoleRowType[];
}> = ({ roleRows }) => {
	return roleRows.length === 0 ? null : (
		<Box xcss={roleListStyle}>
			{roleRows.map((roleRow: RoleRowType, index: number) => (
				<RoleRow
					key={`${roleRow.id}.role-row`}
					id={roleRow.id}
					name={roleRow.name}
					description={roleRow.description}
					permissions={roleRow.permissions}
					actions={roleRow.actions}
					bottomBorder={index !== roleRows.length - 1}
				/>
			))}
		</Box>
	);
};
