import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { FixSpellingIcon } from '../../../../ui/icons/prompt/fixSpelling';
import { ImproveWritingIcon } from '../../../../ui/icons/prompt/improveWriting';
import { createInsertBelow, createReplace } from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

import { messages as enhancedMessages } from './messages';

export const improveWriting: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.IMPROVE_WRITING,
	title: enhancedMessages.improveWritingTitle,
	description: enhancedMessages.improveWritingDescription,
	icon: ({ shownAt }) => <ImproveWritingIcon shownAt={shownAt} />,
	intentSchemaId: 'improve_quality_intent_schema.json',
	actions: {
		empty: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}
		return 'xp-gen-ai';
	},
};

export const improveWritingWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: improveWriting,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};

export const fixSpellingAndGrammar: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.FIX_SPELLING_AND_GRAMMAR,
	title: enhancedMessages.fixSpellingGrammarTitle,
	description: enhancedMessages.fixSpellingGrammarDescription,
	icon: ({ shownAt }) => <FixSpellingIcon shownAt={shownAt} />,
	intentSchemaId: 'grammar_spelling_intent_schema.json',
	actions: {
		empty: [createReplace({ appearance: 'primary' })],
		selection: [createReplace({ appearance: 'primary' })],
	},
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}
		return 'xp-gen-ai';
	},
};

export const fixSpellingAndGrammarWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: fixSpellingAndGrammar,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};
