import { useQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import gql from 'graphql-tag';

import { SITE_RBAC_VIEW_EXPERIENCE } from '@confluence/experience-tracker';
import type { SpacePermissionListQueryResult } from '@confluence/space-roles/entry-points/graphql';
import { useResponseHandler } from '@confluence/space-roles/entry-points/graphql';

export type SpacePermissionNodes = NonNullable<
	SpacePermissionListQueryResult['spacePermissionsAll']
>['nodes'];

interface SpacePermissions {
	loading: boolean;
	permissions: SpacePermissionNodes;
}

const useSpacePermissionsQuery = gql`
	query useSpacePermissionsQuery {
		spacePermissionsAll {
			nodes {
				id
				displayName
				description
				priority
				group {
					displayName
					priority
				}
				requiredSpacePermissions
			}
		}
	}
`;

export const useSpacePermissions = (): SpacePermissions => {
	const [experienceStarted, setExperienceStarted] = useState(false);

	const { startExperience, handleResponse } = useResponseHandler({
		experience: SITE_RBAC_VIEW_EXPERIENCE,
	});

	const { data, loading } = useQuery<SpacePermissionListQueryResult>(useSpacePermissionsQuery, {
		onCompleted: () => {
			setExperienceStarted(false);
			handleResponse({});
		},
		onError: (error) => {
			setExperienceStarted(false);
			handleResponse({ error });
		},
	});

	useEffect(() => {
		if (loading && !experienceStarted) {
			startExperience();
		}
	}, [loading, experienceStarted, startExperience]);

	return {
		loading,
		permissions: data?.spacePermissionsAll?.nodes || [],
	};
};
