import gql from 'graphql-tag';

export const ContentTypesHeaderQuery = gql`
	query ContentTypesHeaderQuery($contentId: ID, $useObjectHeader: Boolean) {
		content(id: $contentId, status: ["archived", "current", "draft"]) {
			nodes {
				id
				title
				operations {
					operation
					targetType
				}
				space {
					id
					name
					key
					alias
				}
				type
				status
				version @include(if: $useObjectHeader) {
					contentTypeModified
					friendlyWhen
					number
				}
			}
		}
	}
`;
