import React from 'react';

import { ConvertToTableIcon } from '../../../../ui/icons/prompt/convertToTable';
import { createInsertBelow, createReplace } from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

import { messages } from './messages';

const intentSchemaId = 'convert_to_table.json';

export const convertToTable: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.CONVERT_TO_TABLE,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <ConvertToTableIcon shownAt={shownAt} />,
	statusLozengeType: 'beta',
	intentSchemaId,
	actions: {
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
};

export const convertToTableWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: convertToTable,
	triggers: { empty: false, selection: true },
};
