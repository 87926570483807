/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ExtensionAPI, type ExtensionManifest } from '@atlaskit/editor-common/extensions';
import { shouldBlockExtensionDueToAppAccessPolicy } from '../../render';
import { parse } from '@atlassian/cs-ari';
import { getExtensionType, isCustomUI as getIsCustomUI } from '@atlassian/forge-ui';

import { type ForgeUIExtensionType } from '@atlassian/forge-ui-types';
import { jsx } from '@compiled/react';
import { EXTENSION_NAMESPACE } from '../../utils/constants';
import { createTitleWithEnvironmentInfo } from '../getForgeExtensionProvider';
import { createLocalId } from '../../utils/createLocalId';
import { extractKeyFromId } from '../../utils/extractKeyFromId';
import Icon from '../icon';
import {
	type ForgeConfigPayload,
	type renderConfigHelper,
	validateForgeConfigPayload,
	handleNodeUpdateError,
	transformConfigAfter,
} from '../../config';
import {
	type ForgeExtensionProviderSharedParams,
	type ForgeCreateExtensionProps,
	type ForgeExtensionManifest,
	type ForgeExtensionParameters,
	type UpdateFunction,
} from '../../types';
import { createConnectToForgeGetFieldsDefinitionFunction } from '../../config/getFieldsDefinition';
import { AnalyticsAction, type AnalyticsClient } from './analytics';

export const manifestIcons = {
	'16': () => import('../icon'),
	'24': () => import('../icon'),
	'48': () => import('../icon'),
};

type ExtensionMapperParams = Pick<
	ForgeExtensionProviderSharedParams,
	'editorActions' | 'createRenderFunction' | 'getFieldsDefinitionFunction'
> & {
	analyticsClient: AnalyticsClient;
	eventSource: string;
	fieldsModules: ExtensionManifest<ForgeExtensionParameters>['modules']['fields'];
	renderConfigHelper: typeof renderConfigHelper;
	connectToForgeBlockedExtensions: Set<unknown>;
	updateFunction: UpdateFunction;
};

export const updateNodeWithPayload = (
	actions: ExtensionAPI,
	localId: string,
	payload: ForgeConfigPayload,
) => {
	try {
		actions.doc.update(localId, (currentValue) =>
			mergeNodeWithPayload(currentValue?.attrs || {}, payload),
		);
	} catch (error: unknown) {
		handleNodeUpdateError(error);
	}
};

export const mergeNodeWithPayload = <T extends Record<string, unknown>>(
	attrs: T,
	{ config, body }: ForgeConfigPayload,
): { attrs: T } & Pick<ForgeCreateExtensionProps, 'content'> => {
	return {
		attrs: {
			...attrs,
			parameters: {
				...(attrs.parameters || {}),
				...transformConfigAfter(config),
			},
		},
		...(body ? { content: body.content } : {}),
	};
};

export const getExtensionManifestMapper =
	({
		analyticsClient,
		editorActions,
		eventSource,
		fieldsModules,
		renderConfigHelper,
		createRenderFunction,
		connectToForgeBlockedExtensions,
		getFieldsDefinitionFunction,
		updateFunction,
	}: ExtensionMapperParams) =>
	(extension: ForgeUIExtensionType): ForgeExtensionManifest => {
		const { sendEvent } = analyticsClient;
		const { id, properties, environmentType, migrationKey, environmentKey } = extension;
		const title = createTitleWithEnvironmentInfo(
			properties.title || 'Forge UI Macro',
			environmentType,
			environmentKey,
		);

		const extensionIdWithoutPrefix = parse(id).resourceId!;
		const isCustomUI = getIsCustomUI(extension);
		const isUiKitTwo = extension.properties.render === 'native';

		const hasUiKitOneConfig = !!properties.config && !!properties.config.function;
		const hasConfig = isUiKitTwo || isCustomUI ? !!properties.config : hasUiKitOneConfig;
		const hasCustomConfig = (isUiKitTwo || isCustomUI) && !!properties.config?.resource;

		const connectModuleKey = extractKeyFromId(extensionIdWithoutPrefix);
		const createExtensionProps = (macroId?: string): ForgeCreateExtensionProps => {
			const localId = macroId || createLocalId();
			const extensionId = id;
			const type = getExtensionType(extension);
			return {
				type: type,
				attrs: {
					extensionKey: extensionIdWithoutPrefix,
					extensionType: EXTENSION_NAMESPACE,
					parameters: {
						localId,
						extensionId,
						extensionTitle: title,
					},
					text: title,
					localId,
				},
				...(type === 'bodiedExtension'
					? {
							// include empty content so bodiedExtension doesn't throw error
							content: [{ type: 'paragraph', content: [] }],
						}
					: {}),
			};
		};

		const icon = <Icon label={properties?.title ?? ''} imgUrl={properties.icon} />;
		const macroIcons =
			properties.icon && properties.icon.length > 0
				? {
						'16': () => Promise.resolve(() => icon),
						'24': () => Promise.resolve(() => icon),
						'48': () => Promise.resolve(() => icon),
					}
				: manifestIcons;

		const getFieldsDefinition = createConnectToForgeGetFieldsDefinitionFunction({
			analyticsClient,
			connectToForgeBlockedExtensions,
			eventSource,
			extension,
			hasCustomConfig,
			hasUiKitOneConfig,
			isCustomUI,
			isUiKitTwo,
			uiKitOneGetFieldsDefinitionFunction: getFieldsDefinitionFunction,
		});

		return {
			title,
			description: properties.description,
			type: EXTENSION_NAMESPACE,
			key: extensionIdWithoutPrefix,
			icons: macroIcons,
			categories: properties.categories,
			connectModuleKey,
			migrationKey,
			createExtensionProps,
			modules: {
				...(shouldBlockExtensionDueToAppAccessPolicy(extension)
					? {}
					: {
							quickInsert: [
								{
									key: 'forge-macro',
									action: async (actions) => {
										let extensionProps = createExtensionProps();
										const { localId } = extensionProps.attrs.parameters;
										const { type: extensionType } = extensionProps;
										const isBodiedExtension = extensionType === 'bodiedExtension';

										if (hasCustomConfig) {
											const forgeExtensionNode = {
												...extensionProps.attrs,
												type: extensionType,
											};
											const payload = await new Promise<ForgeConfigPayload>((resolve) => {
												let isInitialInsertion = true;
												renderConfigHelper({
													extension,
													forgeExtensionNode,
													hasCustomConfig,
													isInitialInsertion: true, // We don't use the variable here because this is only executed once
													createRenderFunction,
													onSubmit: (payload: ForgeConfigPayload) => {
														if (isInitialInsertion) {
															isInitialInsertion = false;
															resolve(payload);
														} else if (actions) {
															// Update existing node
															updateNodeWithPayload(actions, localId, payload);
														}
													},
													onValidate: (data: unknown) =>
														validateForgeConfigPayload(data, {
															isBodiedExtension,
															isInitialInsertion,
															schema: editorActions?._privateGetEditorView?.()?.state.schema,
														}),
												});
											});
											extensionProps = {
												...extensionProps,
												...mergeNodeWithPayload(extensionProps.attrs, payload),
											};
										}

										sendEvent(AnalyticsAction.FORGE_EXTENSION_INSERTED, {
											extension,
											localId,
										});

										return extensionProps;
									},
								},
							],
						}),
				nodes: {
					default: {
						type: 'extension',
						render: () => createRenderFunction(extension),
						update: hasConfig
							? (params, actions) => updateFunction(params, actions, extension, hasCustomConfig)
							: undefined,
						getFieldsDefinition,
					},
				},
				fields: fieldsModules,
			},
		};
	};
