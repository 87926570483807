import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';

import { getApolloClient } from '@confluence/graphql';
import { useNativeCollabState } from '@confluence/native-collab';
import { fg } from '@confluence/feature-gating';

import {
	fetchContentAppearanceProperty,
	getContentAppearanceFromQueryData,
	getContentAppearanceQuery,
} from './contentAppearance';
import type { ContentAppearance } from './types';
import { ContentAppearancePropertyKey, ContentAppearanceType } from './types';

type ContentAppearanceContextType = {
	contentAppearance: ContentAppearance;
	setContentAppearance(contentAppearance: ContentAppearance): void;
	contentAppearanceLoading: boolean;
};
const ContentAppearanceContext = React.createContext<ContentAppearanceContextType>({
	contentAppearance: {
		appearance: ContentAppearanceType.DEFAULT,
		version: 0,
	},
	setContentAppearance: () => {},
	contentAppearanceLoading: false,
});

type ContentAppearanceContextProviderProps = {
	contentId: string;
	children: React.ReactNode;
};
export const ContentAppearanceContextProvider = ({
	contentId,
	children,
}: ContentAppearanceContextProviderProps) => {
	const [contentAppearanceLoading, setContentAppearanceLoading] = useState(false);
	const [contentAppearance, setContentAppearance] = useState<ContentAppearance>({
		appearance: ContentAppearanceType.DEFAULT,
		version: 0,
	});
	const [contentAppearanceOverride, setContentAppearanceOverride] = useState<ContentAppearance>();

	const [{ collabEditProvider }] = useNativeCollabState();
	// Subscribe to metadata:changed events to update the content appearance for collaborators
	useEffect(() => {
		if (!collabEditProvider) {
			return;
		}

		const handleMetadataChanged = (metadata: any) => {
			const widthValue = metadata?.editorWidth?.value;
			const version = metadata?.editorWidth?.version;

			if (widthValue) {
				const setContentAppearanceFn = fg('cc_draft_appearance_fast_init')
					? setContentAppearanceOverride
					: setContentAppearance;
				setContentAppearanceFn((currentAppearance) => {
					if (!currentAppearance || version > currentAppearance.version) {
						return {
							appearance: widthValue,
							version,
						};
					} else {
						return currentAppearance;
					}
				});
			}
		};

		collabEditProvider.on('metadata:changed', handleMetadataChanged);
		return () => {
			collabEditProvider.off('metadata:changed', handleMetadataChanged);
		};
	}, [collabEditProvider]);

	// Get the initial content appearance for this draft
	useEffect(() => {
		if (fg('cc_draft_appearance_fast_init')) {
			return;
		}

		const getDraftAppearance = async () => {
			setContentAppearanceLoading(true);
			try {
				const client = getApolloClient();
				const draftAppearance = await fetchContentAppearanceProperty(
					contentId,
					ContentAppearancePropertyKey.DRAFT,
					client,
				);
				setContentAppearance(draftAppearance);
			} finally {
				setContentAppearanceLoading(false);
			}
		};

		void getDraftAppearance();
	}, [contentId]);

	const draftAppearanceQuery = getContentAppearanceQuery(ContentAppearancePropertyKey.DRAFT);
	const { data: initialContentAppearanceData, loading: initialContentAppearanceLoading } = useQuery(
		draftAppearanceQuery,
		{
			variables: {
				contentId,
				status: ['current', 'draft'],
			},
			skip: !fg('cc_draft_appearance_fast_init'),
		},
	);

	const context = useMemo(() => {
		if (fg('cc_draft_appearance_fast_init')) {
			const initialContentAppearance = getContentAppearanceFromQueryData(
				initialContentAppearanceData,
				ContentAppearancePropertyKey.DRAFT,
			);
			return {
				contentAppearance: contentAppearanceOverride || initialContentAppearance,
				setContentAppearance: setContentAppearanceOverride,
				contentAppearanceLoading: initialContentAppearanceLoading,
			};
		}
		return {
			contentAppearance,
			setContentAppearance,
			contentAppearanceLoading,
		};
	}, [
		contentAppearance,
		setContentAppearance,
		contentAppearanceLoading,
		initialContentAppearanceData,
		initialContentAppearanceLoading,
		contentAppearanceOverride,
	]);

	return (
		<ContentAppearanceContext.Provider value={context}>
			{children}
		</ContentAppearanceContext.Provider>
	);
};

type ContentAppearanceContextConsumerProps = {
	children: (props: ContentAppearanceContextType) => JSX.Element;
};
export const ContentAppearanceContextConsumer = ({
	children,
}: ContentAppearanceContextConsumerProps) => {
	const context = useContentAppearance();
	return children(context);
};

export const useContentAppearance = () => {
	return useContext(ContentAppearanceContext);
};
