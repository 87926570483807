import gql from 'graphql-tag';

export const SiteInformationQuery = gql`
	query SiteInformationQuery @SLA {
		tenant {
			shard
			cloudId
			environment
			activationId
			editions {
				edition
			}
		}
		organization {
			orgId
		}
		userForAnalytics: user {
			id
		}
		user(current: true) {
			id
			displayName
			confluence {
				accessStatus
				accountId
				userKey
				locale
			}
			timeZone
		}
		getAIConfig(product: CONFLUENCE) {
			isEnabled
			isRovoEnabled
		}
		spaViewContext {
			userCanCreateContent
			isAnonymous
			homepageUri
			homepageTitle
		}
		siteConfiguration {
			siteLogoUrl
			siteTitle
			showSiteTitle
			frontCoverState
			companyHubName
			ccpEntitlementId
		}
		isNewUser
		isSiteAdmin
		abTestCohorts
		experimentFeatures
		tenantContext {
			licenseStates {
				confluence {
					ccpEntitlementId
				}
			}
		}
	}
`;
