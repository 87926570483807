import React from 'react';

import { useIntl } from 'react-intl-next';
import { di } from 'react-magnetic-di';

import AtlassianIcon from '@atlaskit/icon/glyph/emoji/atlassian';
import { Mention } from '@atlaskit/mention';
import { Box, Inline } from '@atlaskit/primitives';
import { ProfileCardTrigger } from '@atlaskit/profilecard';
import Skeleton from '@atlaskit/skeleton';
import { token } from '@atlaskit/tokens';
import {
	type Agent,
	type AssistanceServiceProduct,
	useQuery,
} from '@atlassian/conversation-assistant-service';

import { isCustomerAgent, isForgeAgent, isSystemAgent } from '../../common/types';
import {
	DEFAULT_AGENTS_NAMED_ID_LIST,
	DEFAULT_OOTB_AGENTS_NAMED_IDS,
} from '../../controllers/agents/constants';
import { useProfileClient } from '../../controllers/profile-client';

import messages from './messages';

type AgentCreatedByProps = {
	agent: Agent;
	siteId: string;
	simplified?: boolean;
};

export const getAAIDFromARI = (ari: string): string | undefined => {
	const matched = ari.match(/\/([a-zA-Z0-9_\|\-\:]{1,128})$/);
	return matched ? matched[1] : undefined;
};

export const getARIFromAAID = (aaid: string): string => {
	return `ari:cloud:identity::user/${aaid}`;
};

/**
 * Renders an at-mention for the user who created this agent
 * @param agent - Agent
 * @returns JSX
 */
export const AgentCreatedBy = ({ agent, siteId, simplified }: AgentCreatedByProps) => {
	if (isCustomerAgent(agent)) {
		const aaid = agent.creator ? getAAIDFromARI(agent.creator) : undefined;
		if (!aaid) {
			return null;
		}

		return <ResolvingMention userId={aaid} siteId={siteId} profileCard simplified={simplified} />;
	}

	if (isSystemAgent(agent) || !agent.creator) {
		return (
			<Inline alignBlock="center" testId="atlassian-icon">
				{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- TODO: no icon available (https://product-fabric.atlassian.net/browse/DSP-19853) */}
				<AtlassianIcon primaryColor={token('color.icon.brand')} label="Atlassian" />
				<Box>Atlassian</Box>
			</Inline>
		);
	}

	if (isForgeAgent(agent)) {
		return <Box>{agent.creator}</Box>;
	}

	return null;
};

type ResolvingMentionProps = {
	userId: string;
	siteId: string;
	profileCard?: boolean;
	simplified?: boolean;
	renderWhenHidden?: boolean;
};

const ResolvingMention = ({
	userId,
	siteId,
	profileCard = false,
	simplified = false,
}: ResolvingMentionProps) => {
	di(useProfileResolution);
	const { profile, client } = useProfileResolution(userId, siteId);
	const { formatMessage } = useIntl();

	const fullName =
		profile.data?.status === 'inactive' && profile.data.fullName
			? formatMessage(messages.deactivatedCreatorName, { name: profile.data.fullName })
			: profile.data?.fullName;

	if (profile.isFetching || !profile.data) {
		return <Skeleton isShimmering height={18} width={100} borderRadius={3} />;
	}

	if (simplified) {
		return (
			<a href={`/people/${userId}`} target="_blank">
				{fullName}
			</a>
		);
	}

	return profileCard ? (
		<ProfileCardTrigger userId={userId} resourceClient={client} ariaLabel={fullName}>
			<Mention id={userId} isHighlighted={profile.data.isCurrentUser} text={`@${fullName}`} />
		</ProfileCardTrigger>
	) : (
		<Mention id={userId} isHighlighted={profile.data.isCurrentUser} text={`@${fullName}`} />
	);
};

export type ProfileState = {
	avatarUrl?: string;
	fullName?: string;
	nickname?: string;
	email?: string;
	location?: string;
	isCurrentUser?: boolean;
	timestring?: string;
	status?: 'active' | 'inactive';
	companyName?: string;
	isBot?: boolean;
	meta?: string;
	statusModifiedDate?: string;
};

export function useProfileResolution(userId: string, siteId: string, shouldFetch: boolean = true) {
	const profileClient = useProfileClient();

	const profile = useQuery({
		queryKey: ['fetchProfile', siteId, userId],
		queryFn: async (): Promise<ProfileState> => {
			try {
				const response = await profileClient.getProfile(siteId, userId);
				return response;
			} catch (error) {
				return {};
			}
		},
		enabled: shouldFetch,
	});

	return { profile, client: profileClient };
}

export const filterAndSortAgents = (
	agents: Agent[],
	filters: {
		searchValue: string;
	},
) => {
	return agents
		.filter(
			(agent) =>
				agent.name.toLocaleLowerCase().includes(filters.searchValue.toLocaleLowerCase()) ||
				agent.description?.toLocaleLowerCase().includes(filters.searchValue.toLocaleLowerCase()),
		)
		.sort((a, b) => a.name.localeCompare(b.name));
};

export const getEnabledKnowlegeArisFromAgent = (agent: Agent): string[] => {
	if (!agent.knowledge_sources?.enabled) {
		return [];
	}

	return agent.knowledge_sources.sources
		.filter((source) => source.enabled)
		.reduce((acc: string[], source) => {
			switch (source.source) {
				case 'confluence':
					acc.push(...(source.configuration?.parent_filter ?? []));
					acc.push(...(source.configuration?.space_filter ?? []));
					break;
				case 'jira':
					acc.push(...(source.configuration?.project_filter ?? []));
					break;
			}

			return acc;
		}, []);
};

export const getSuggestedOotbAgents = ({
	availableAgents,
	product,
}: {
	availableAgents: Agent[];
	product?: AssistanceServiceProduct;
}) => {
	// If no product is provided / product is not supported, fallback to using default agents
	const defaultAgentsNamedIds = product
		? DEFAULT_OOTB_AGENTS_NAMED_IDS[product] ?? DEFAULT_AGENTS_NAMED_ID_LIST
		: DEFAULT_AGENTS_NAMED_ID_LIST;

	const defaultAgents = availableAgents.filter(
		(agent) =>
			defaultAgentsNamedIds.has(agent.named_id) ||
			(agent.external_config_reference &&
				defaultAgentsNamedIds.has(agent.external_config_reference)),
	);

	return defaultAgents;
};
