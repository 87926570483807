import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { Stack, xcss, Inline } from '@atlaskit/primitives';
import Link from '@atlaskit/link';

import type { RoleNodes } from '../../roles-list/useRoleList';
import { RoleList } from '../../roles-list/RoleList';
import type { ActiveModalType } from '../RolesManagementView';
import type { SpacePermissionNodes } from '../useSpacePermissions';

import { PermissionsLozenge } from './PermissionsLozenge';
import { CustomRolesMenu } from './CustomRolesMenu';
import { CustomRoleAddition } from './CustomRoleAddition';

const sectionStyle = xcss({
	gap: 'space.200',
});

const actionsStyle = xcss({
	paddingRight: 'space.100',
	gap: 'space.150',
});

export const CustomRolesSection = ({
	customRoleNodes,
	allRoles,
	allPermissions,
	activeModal,
	setActiveModal,
}: {
	customRoleNodes: RoleNodes;
	allRoles: RoleNodes;
	allPermissions: SpacePermissionNodes;
	activeModal: ActiveModalType;
	setActiveModal: React.Dispatch<React.SetStateAction<ActiveModalType>>;
}) => (
	<>
		<Stack xcss={sectionStyle}>
			<Stack>
				<Heading size="small">
					<FormattedMessage
						id="default-space-permissions.roles-management.custom-roles-heading"
						defaultMessage="Custom Roles"
						description="Heading for the custom roles section in the roles management page"
					/>
				</Heading>
				<FormattedMessage
					id="default-space-permissions.roles-management.custom-roles-body"
					defaultMessage="These are the roles that that will be available to use at the space level. <learnmorelink>Learn more about custom roles</learnmorelink>"
					description="Heading for the custom roles section in the roles management page"
					// TODO: Add the correct href to the Link component
					values={{ learnmorelink: (text: React.ReactNode) => <Link href="/noop">{text}</Link> }}
				/>
			</Stack>
			<FormattedMessage
				defaultMessage="{count, plural, one {# custom role} other {# custom roles}} available"
				description="Label text indicating the number of custom roles that are available to be assigned within a space"
				id="default-space-permissions.roles-management.custom-roles-count"
				values={{ count: customRoleNodes.length }}
			/>
		</Stack>
		<RoleList
			roleRows={customRoleNodes.map((role) => ({
				id: role.roleId,
				name: role.roleDisplayName,
				description: role.roleDescription,
				permissions: role.spacePermissionList.map((permission) => permission.displayName),
				actions: (
					<Inline alignBlock="center" xcss={actionsStyle} key={`${role.roleId}.custom-actions`}>
						<PermissionsLozenge permissionsCount={role.spacePermissionList.length} />
						<CustomRolesMenu
							activeModal={activeModal}
							setActiveModal={setActiveModal}
							allRoles={allRoles}
							allPermissions={allPermissions}
							roleId={role.roleId}
							roleName={role.roleDisplayName}
							roleDescription={role.roleDescription}
							rolePermissions={role.spacePermissionList.map((permission) => permission.id)}
						/>
					</Inline>
				),
			}))}
		/>
		<CustomRoleAddition allRoles={allRoles} allPermissions={allPermissions} />
	</>
);
