import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Stack, Box, xcss } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';

import type { SpaceRole } from '@confluence/space-roles/entry-points/space-role-types';
import { RoleSelector } from '@confluence/space-roles/entry-points/TableRow';

import type { RoleNodes } from '../roles-list/useRoleList';

import type { DeletionResultSelectionType } from './DeleteRoleModal';

const i18n = defineMessages({
	reassignOption: {
		id: 'default-space-permissions.roles-management.delete-role-modal.reassign-option',
		defaultMessage:
			'Assign all users with this role, a different role (this will apply for all spaces)',
		description: 'Label for a radio button option to reassign users to a different role',
	},
	deleteOption: {
		id: 'default-space-permissions.roles-management.delete-role-modal.delete-option',
		defaultMessage: 'Delete their current assignment (this will remove them from spaces)',
		description: 'Label for a radio button option to delete the role without reassigning users',
	},
});

const roleSelectorStyle = xcss({
	paddingTop: 'space.100',
	paddingLeft: 'space.300',
	marginLeft: 'space.050',
});

// The prefilled role will be Collaborator, due to product requirements
const ROLE_PREFILL = '0c103ff5-44d0-4b2a-b80d-8690442c95f0';

export const DeletionResultSelection = ({
	roleId,
	deletionResult,
	setDeletionResult,
	allRoles,
}: {
	roleId: string;
	deletionResult: DeletionResultSelectionType;
	setDeletionResult: React.Dispatch<React.SetStateAction<DeletionResultSelectionType>>;
	allRoles: RoleNodes;
}) => {
	const { formatMessage } = useIntl();
	const pickerOptions: SpaceRole[] = allRoles
		.filter((role) => role.roleId !== roleId)
		.map((role) => ({
			id: role.roleId,
			name: role.roleDisplayName,
			description: role.roleDescription,
			permissions: role.spacePermissionList.map((permission) => permission.displayName),
		}));

	const [rolePickerValue, setRolePickerValue] = useState<SpaceRole | null>(null);

	return (
		<Stack space="space.200">
			<Box>
				<Radio
					isChecked={deletionResult === 'reassign'}
					onChange={() => setDeletionResult('reassign')}
					name="reassign"
					value="reassign"
					label={formatMessage(i18n.reassignOption)}
				/>
				{deletionResult === 'reassign' && (
					<Box xcss={roleSelectorStyle}>
						<RoleSelector
							value={rolePickerValue?.id || ROLE_PREFILL}
							onChange={(value) => setRolePickerValue(value)}
							displayType="table"
							appearance="default"
							spaceRoleOptions={pickerOptions}
						/>
					</Box>
				)}
			</Box>
			<Radio
				isChecked={deletionResult === 'delete'}
				onChange={() => setDeletionResult('delete')}
				name="delete"
				value="delete"
				label={formatMessage(i18n.deleteOption)}
			/>
		</Stack>
	);
};
