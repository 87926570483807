import { useCallback } from 'react';

import { type IntlShape, useIntl } from 'react-intl-next';

import type {
	AdvancedPromptValue,
	EditorPluginAIConfigItem,
} from '../../prebuilt/config-items/config-items';
import type { AIExperienceMachineContext } from '../../utils/xstate/get-ai-experience-service';

export function useCreateGenericChatActionPayload() {
	const { formatMessage } = useIntl();

	return useCallback(
		(history: AIExperienceMachineContext['responseHistory']) => {
			const title = history.entries[0].configItem.title;
			const name = title ? formatMessage(title) : '';

			const dialogues = history.entries.map((entry, index) => {
				const configItemTitle = formatMessage(entry.configItem.title);

				let humanMessage = entry.advancedPromptValue
					? getAdvancedPromptHumanMessage({
							formatMessage,
							configItem: entry.configItem,
							advancedPromptValue: entry.advancedPromptValue,
						})
					: entry.userInput;

				// The first message should show the config item title
				if (index === 0) {
					humanMessage = `${configItemTitle}\n\n${humanMessage}`;
				}
				// Use the config item title as fallback if the human message is empty
				humanMessage = humanMessage.trim() || configItemTitle;

				return {
					agent_message: { content: entry.markdown },
					human_message: { content: humanMessage },
				};
			});

			return {
				name,
				dialogues,
			};
		},
		[formatMessage],
	);
}

interface GetAdvancedPromptHumanMessageProps {
	formatMessage: IntlShape['formatMessage'];
	configItem: EditorPluginAIConfigItem;
	advancedPromptValue: AdvancedPromptValue;
}

function getAdvancedPromptHumanMessage({
	formatMessage,
	configItem,
	advancedPromptValue,
}: GetAdvancedPromptHumanMessageProps) {
	if (configItem.advancedPrompt?.historyMessageBuilder) {
		return configItem.advancedPrompt.historyMessageBuilder(formatMessage, advancedPromptValue);
	}

	// The default message builder
	return Object.entries(advancedPromptValue).reduce((message, entry) => {
		const [key, value] = entry;

		const valueText = typeof value === 'object' ? value.text : value;

		// Map each key-value pair to a markdown title and content
		return message + `### ${key}\n\n${valueText}\n\n`;
	}, '');
}
