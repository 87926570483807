/* eslint-disable import/dynamic-import-chunkname */
import React, { useContext, useEffect, Suspense } from 'react';
import {
	type ForgeDoc,
	type Dispatch,
	type ForgeExtensionPoints,
	type ProductEnvironment,
	type ExtensionData,
} from '@atlassian/forge-ui-types';
import { IntlProvider } from 'react-intl-next';
import SectionMessage from '@atlaskit/section-message';
import { fg } from '@atlaskit/platform-feature-flags';

import {
	ComponentErrorBoundary,
	GenericErrorBoundary,
	IntegrationErrorHandlerBoundary,
} from '../error-boundary';
import { PortalProvider } from '../context/portal';
import { WidthProvider, RendererContext } from '../context';
import { Loader } from '../web-runtime/loader';
import { type ComponentMap, type ModalExtension } from './util';
import StyleErrorBoundary from '../error-boundary/StyleErrorBoundary';
import { type Extension } from '../web-client';
import { RendererNextLegacy } from './RendererWithComponents';
import FrameCountProvider from '../provider/FrameCountProvider';
import { getExtensionType, parseExtensionId } from '../utils';
import { EnvironmentContext } from '../context';
import { createSrcFromExtension } from '../custom-ui/iframe/utils';
import { useForgeUiAnalyticsEvent } from '../analytics/useForgeUiAnalyticsEvent';

export interface RendererProps {
	/* ForgeDoc to be rendered */
	forgeDoc?: ForgeDoc;
	/* Map of component types to render functions */
	components?: (defaults: ComponentMap) => ComponentMap;
	/* Function used by components to dispatch effects */
	dispatch?: Dispatch;
	/* Error message to show the user. Set when an unexpected client-side error happens. */
	error?: string;
	/* Allow integration to get notified error within the render inorder to perform custom error handling */
	onError?: (error: Error) => void;
	/* Whether a dispatched effect is pending. */
	loading?: boolean;
	/* Replace the default spinner with a custom loading component. */
	loadingComponent?: React.ReactNode;
	/* Indicates whether a Native UI app is being rendered*/
	isNative?: boolean;
	/* Object that stores all necessary properties for a Modal Extension */
	modalExtension?: ModalExtension;
	/* Contains all the details for the extension*/
	extension: Extension;
	/* Locale to use for rendering, it used in the IntlProvider for components depends on useIntl and it is the product locale to the user */
	locale?: string;
	/* Static object containing context values for the extension */
	extensionData?: ExtensionData;
	/* Contains the module type of the extension */
	extensionType?: ForgeExtensionPoints;
}

const getLoadingComponent = (loadingComponent?: React.ReactNode) => {
	if (loadingComponent) {
		// Wrapping loadingComponent in a valid react element (fragment) for change `fix-react-18-concurrent-upgrade-infinite-rendering`
		// ReactNode type is not necessarily a valid React element, unable to change this type as different extension points pass in various loadingComponent types
		return <>{loadingComponent}</>;
	}
	return <Loader />;
};

const getAppDomainName = (extension: Extension, environment: ProductEnvironment) => {
	const parsedExtension = extension && extension?.id ? parseExtensionId(extension.id) : undefined;

	if (parsedExtension) {
		const iframeSrc = createSrcFromExtension(parsedExtension.appId, extension, environment, '');
		const iframeUrl = new URL(iframeSrc);

		return iframeUrl.host;
	}
	return;
};

const RendererNextLatest = React.lazy(() =>
	/* webpackChunkName: "forge-ui-renderer-with-components" */
	import('./RendererWithComponents').then((module) => ({
		default: module.RendererNextLatest,
	})),
);

const renderUIKit1DeprecationWarning = () => {
	const displayUIKit1DeprecationWarning = fg('platform_display_ui_kit_1_deprecation_warning');

	if (!displayUIKit1DeprecationWarning) {
		return;
	}

	return (
		<SectionMessage title="App update required" appearance="warning">
			{`Your app version is outdated and no longer supported.\nPlease ask your admin to update it through Atlassian Administration.`}
		</SectionMessage>
	);
};

// This extra component exists so errors from the "render" function are caught in the an error boundary
// Also the error boundary requires a suspense fallback
const RendererNextWithProviders = (props: RendererProps) => {
	const { extension, extensionData, forgeDoc, dispatch, locale } = props;

	const defaultDispatch = React.useCallback(async () => {}, []);

	const forgeReactMajorVersion = forgeDoc?.forgeReactMajorVersion;

	const environment = useContext(EnvironmentContext);
	const domainName = getAppDomainName(extension, environment);

	const { trackExtensionLoaded } = useForgeUiAnalyticsEvent();

	useEffect(() => {
		trackExtensionLoaded({
			renderType: props.isNative ? 'UIKit' : 'UIKit1',
			forgeEnvironment: extension?.environmentType,
			extensionType: getExtensionType(extension),
			isConfiguring: extensionData?.macro?.isConfiguring,
			isInserting: extensionData?.macro?.isInserting,
		});
		// Adding the dependencies, including trackExtensionLoaded, causes this effect to fire too often
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (props.isNative && forgeDoc && !forgeReactMajorVersion) {
		return (
			<SectionMessage title="App update required" appearance="error">
				This app version is outdated and is no longer supported. To continue using this app, please
				ask your organisation's admin to update to the latest version from Admin Hub
			</SectionMessage>
		);
	}

	// true while fetching initial forgeDoc
	if (fg('fix-react-18-concurrent-upgrade-infinite-rendering')) {
		if (props.loading && !forgeDoc) {
			return getLoadingComponent(props.loadingComponent);
		}
	}

	return (
		<Suspense fallback={getLoadingComponent(props.loadingComponent)}>
			<GenericErrorBoundary dispatch={dispatch}>
				<ComponentErrorBoundary dispatch={dispatch}>
					<StyleErrorBoundary>
						<IntegrationErrorHandlerBoundary onError={props.onError}>
							<WidthProvider>
								<IntlProvider locale={locale ?? 'en'} defaultLocale="en">
									<PortalProvider>
										<RendererContext.Provider
											value={{
												egress: extension?.egress,
												forgeEnvironment: extension?.environmentType,
												forgeReactMajorVersion: forgeReactMajorVersion,
												appDomainName: domainName,
												extensionType: extension?.type,
											}}
										>
											{props.isNative && forgeReactMajorVersion && forgeReactMajorVersion >= 10 ? (
												<FrameCountProvider>
													<RendererNextLatest {...props} dispatch={dispatch ?? defaultDispatch} />
												</FrameCountProvider>
											) : (
												<>
													{renderUIKit1DeprecationWarning()}
													<RendererNextLegacy {...props} dispatch={dispatch ?? defaultDispatch} />
												</>
											)}
										</RendererContext.Provider>
									</PortalProvider>
								</IntlProvider>
							</WidthProvider>
						</IntegrationErrorHandlerBoundary>
					</StyleErrorBoundary>
				</ComponentErrorBoundary>
			</GenericErrorBoundary>
		</Suspense>
	);
};

export default (props: RendererProps) => {
	return <RendererNextWithProviders {...props} />;
};
