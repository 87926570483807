import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { ModalFooter } from '@atlaskit/modal-dialog';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/new';

import type { DeletionResultSelectionType } from './DeleteRoleModal';

export const DeleteRoleFooter = ({
	deletionResult,
	onClose,
	onCancel,
}: {
	deletionResult: DeletionResultSelectionType;
	onClose: () => void;
	onCancel: () => void;
}) => (
	<ModalFooter>
		<ButtonGroup>
			<Button appearance="subtle" onClick={onCancel}>
				<FormattedMessage
					id="default-space-permissions.roles-management.delete-role-modal.cancel-button"
					defaultMessage="Cancel"
					description="Label for a button to cancel the deletion of a custom role"
				/>
			</Button>
			<Button appearance={deletionResult === 'delete' ? 'danger' : 'primary'} onClick={onClose}>
				{deletionResult === 'delete' ? (
					<FormattedMessage
						id="default-space-permissions.roles-management.delete-role-modal.delete-option-confirm"
						defaultMessage="Delete and remove"
						description="Label for a confirmation button to delete a role and remove user access"
					/>
				) : (
					<FormattedMessage
						id="default-space-permissions.roles-management.delete-role-modal.reassign-option-confirm"
						defaultMessage="Delete and reassign"
						description="Label for a confirmation button to delete a role and reassign users"
					/>
				)}
			</Button>
		</ButtonGroup>
	</ModalFooter>
);
