import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	appearance: true,
	children: true,
	isMultiline: true,
	testId: true,
};
/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::5cc97bb82831c44b87edc9882fde5c5c>>
 * @codegenId #modal-title
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen modal-title
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/modal-title.tsx <<SignedSource::2d89dbe8905b58d80b06db2a980dd550>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/hooks.tsx <<SignedSource::5062e27591547b31bd9e5702d12d3685>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/constants.tsx <<SignedSource::ada7ffa5504f59d6cd6568e5046b743c>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/types.tsx <<SignedSource::56a08f53d6eb931343ded881245e172c>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ModalTitle as PlatformModalTitle } from '@atlaskit/modal-dialog';

type PlatformModalTitleProps = React.ComponentProps<typeof PlatformModalTitle>;

export type ModalTitleProps = Pick<
  PlatformModalTitleProps,
  'appearance' | 'children' | 'isMultiline' | 'testId'
>;

/**
 * A modal dialog displays content that requires user interaction, in a layer above the page.
 */
export const ModalTitle = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    isMultiline,
    testId,
  } = props.forgeDoc.props as ModalTitleProps;
  return (
    <PlatformModalTitle
      appearance={appearance}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      isMultiline={isMultiline}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
