import type { FC } from 'react';
import React from 'react';

import { FlagsProvider } from '@atlaskit/flag';

import { RolesManagementView } from '../../roles-management/page/RolesManagementView';

export const RolesManagementTab: FC = () => (
	<FlagsProvider>
		<RolesManagementView />
	</FlagsProvider>
);
