import { useStableObject } from '@atlassian/post-office-context';

import { ERROR_BOUNDARY_LOCATION } from './constants';
import { useComponentErrorHandling } from './hooks/useComponentError';
import { PerformanceTracking, StableProviders } from './providers';
import {
	type ErrorBoundariedProps,
	type PlacementComponent,
	type PlacementComponentPropsWithRoute,
} from './types';
import { withErrorBoundary } from '../withErrorBoundary';

export const createBasePlacementComponent = <P,>(
	placementId: string,
	Component: PlacementComponent<P>,
) =>
	withErrorBoundary<PlacementComponentPropsWithRoute<P>>((props) => {
		const { context: contextWithRoute, onEvent, initialData, ...componentProps } = props;

		const { postOfficeRoute: route, ...context } = contextWithRoute;

		const stableContext = useStableObject(context);

		return (
			<PerformanceTracking initialData={initialData} onEvent={onEvent}>
				<StableProviders route={route} context={stableContext} placement={{ placementId }}>
					<Component
						context={stableContext}
						initialData={initialData}
						{...(componentProps as unknown as P)}
					/>
				</StableProviders>
			</PerformanceTracking>
		);
	});

export const createPlacementComponent = <P,>(
	placementId: string,
	Component: PlacementComponent<P>,
) => {
	const placement = createBasePlacementComponent(placementId, Component);

	return (props: ErrorBoundariedProps<P>) => {
		const { onError, fallbackRender } = useComponentErrorHandling(props);

		return placement({
			...props,
			errorBoundaryLocation: ERROR_BOUNDARY_LOCATION,
			onError,
			fallbackRender,
		});
	};
};
