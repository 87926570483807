import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ModalDialog, { ModalBody, ModalHeader } from '@atlaskit/modal-dialog';
import { Box, Stack, xcss, Text } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';

import type { NormalizedCheckboxesType } from '@confluence/space-roles/entry-points/normalizers';

import { SetRoleDescriptionSection } from './SetRoleDescriptionSection';
import { SetRoleModalFooter } from './SetRoleModalFooter';
import { SetRoleNameSection } from './SetRoleNameSection';
import { SetRolePermissionsSection } from './SetRolePermissionsSection';

const i18n = defineMessages({
	title: {
		id: 'default-space-permissions.set-role-modal.title',
		defaultMessage: 'Create a role',
		description: 'Title for a dialog that allows the user to create a new permissions role',
	},
	titleSubtext: {
		id: 'default-space-permissions.set-role-modal.title-subtext',
		defaultMessage:
			'Give your role a name and description so it can be easily identified by space admins.',
		description: 'Subtext for a dialog that allows the user to create a new permissions role',
	},
});

const titleStyle = xcss({
	paddingBottom: 'space.200',
});

const modalBodyStyle = xcss({
	paddingTop: 'space.200',
	gap: 'space.400',
});

const RequiredAsterisk = () => <Text color="color.text.accent.red">*</Text>;

export const RoleFormModal = ({
	newRoleName,
	newRoleDescription,
	newRolePermissionsObject,
	setNewRoleName,
	setNewRoleDescription,
	setNewRolePermissionsObject,
	areChangesStaged,
	hasRequiredFields,
	onSave,
	onCancel,
}: {
	newRoleName: string;
	newRoleDescription: string;
	newRolePermissionsObject: Record<number, NormalizedCheckboxesType>;
	setNewRoleName: React.Dispatch<React.SetStateAction<string>>;
	setNewRoleDescription: React.Dispatch<React.SetStateAction<string>>;
	setNewRolePermissionsObject: React.Dispatch<
		React.SetStateAction<Record<number, NormalizedCheckboxesType>>
	>;
	areChangesStaged: boolean;
	hasRequiredFields: boolean;
	onSave: () => void;
	onCancel: () => void;
}) => {
	const { formatMessage } = useIntl();
	return (
		<ModalDialog onClose={onCancel} width="719px">
			<ModalHeader>
				<Stack>
					<Box xcss={titleStyle}>
						<Heading size="medium">{formatMessage(i18n.title)}</Heading>
					</Box>
					<Text color="color.text.subtlest">{formatMessage(i18n.titleSubtext)}</Text>
				</Stack>
			</ModalHeader>
			<ModalBody>
				<Stack xcss={modalBodyStyle}>
					<SetRoleNameSection
						roleName={newRoleName}
						setRoleName={setNewRoleName}
						asterisk={RequiredAsterisk}
					/>
					<SetRoleDescriptionSection
						roleDescription={newRoleDescription}
						setRoleDescription={setNewRoleDescription}
						asterisk={RequiredAsterisk}
					/>
				</Stack>
				<SetRolePermissionsSection
					rolePermissionsObject={newRolePermissionsObject}
					setRolePermissionsObject={setNewRolePermissionsObject}
					asterisk={RequiredAsterisk}
				/>
			</ModalBody>
			<SetRoleModalFooter
				areChangesStaged={areChangesStaged}
				hasRequiredFields={hasRequiredFields}
				onSave={onSave}
				onCancel={onCancel}
			/>
		</ModalDialog>
	);
};
