import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import { Stack, Box, xcss } from '@atlaskit/primitives';
import Link from '@atlaskit/link';
import Button from '@atlaskit/button/new';

import type { RoleNodes } from '../../roles-list/useRoleList';
import { RoleList } from '../../roles-list/RoleList';

const i18n = defineMessages({
	viewPermissionsLabel: {
		id: 'default-space-permissions.roles-management.role-list.view-permissions',
		defaultMessage: 'View Permissions',
		description:
			'Label for the button to view permissions for a role that will be shown in a list item',
	},
});

const viewPermissionsStyle = xcss({
	paddingRight: 'space.100',
});

export const DefaultRolesSection = ({ defaultRoleNodes }: { defaultRoleNodes: RoleNodes }) => {
	const { formatMessage } = useIntl();
	return (
		<>
			<Stack>
				<Heading size="small">
					<FormattedMessage
						id="default-space-permissions.roles-management.default-roles-heading"
						defaultMessage="Default Roles"
						description="Heading for the default roles section in the roles management page"
					/>
				</Heading>
				<FormattedMessage
					id="default-space-permissions.roles-management.default-roles-body"
					defaultMessage="These are custom roles that you can assign at the space level. <learnmorelink>Learn more about roles</learnmorelink>"
					description="Heading for the default roles section in the roles management page"
					// TODO: Add the correct href to the Link component
					values={{ learnmorelink: (text: React.ReactNode) => <Link href="/noop">{text}</Link> }}
				/>
			</Stack>
			<RoleList
				roleRows={defaultRoleNodes.map((role) => ({
					id: role.roleId,
					name: role.roleDisplayName,
					description: role.roleDescription,
					permissions: role.spacePermissionList.map((permission) => permission.displayName),
					actions: (
						<Box xcss={viewPermissionsStyle} key={`${role.roleId}.view-permissions`}>
							<Button appearance="subtle">{formatMessage(i18n.viewPermissionsLabel)}</Button>
						</Box>
					),
				}))}
			/>
		</>
	);
};
