/** @jsx jsx */
import React, { useMemo, useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { jsx } from '@compiled/react';
import type { SingleValue } from 'react-select';

import type { SingleValueProps, OptionProps, OptionType } from '@atlaskit/select';
import Select, { components } from '@atlaskit/select';
import { xcss, Box, Text } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { SpaceRole } from '../model/space-roles-types';
interface Option {
	id: string;
	label: string;
	value: string;
	description: string;
}

interface RoleSelectorProps {
	initialRoleId?: string | null;
	value?: string | null | OptionType;
	isLoading?: boolean;
	onChange?: (role: SpaceRole) => void;
	displayType: 'table' | 'modal' | 'page';
	spaceRoleOptions: SpaceRole[];
	onMenuOpen?: () => void;
	onMenuClose?: () => void;
	menuPlacement?: 'auto' | 'bottom' | 'top';
	appearance?: 'none' | 'default' | 'subtle';
}

const i18n = defineMessages({
	adminTitleText: {
		id: 'space-roles.table.admin-text',
		defaultMessage: 'Admin',
		description: 'Admin dropdown selection title text',
	},
	adminDescription: {
		id: 'space-roles.table.admin-description',
		defaultMessage: 'Manage space, people, and settings',
		description: 'Admin dropdown selection description text',
	},
	collaboratorTitleText: {
		id: 'space-roles.table.collaborator-title',
		defaultMessage: 'Collaborator',
		description: 'Collaborator dropdown selection title text',
	},
	collaboratorDescription: {
		id: 'space-roles.table.collaborator-description',
		defaultMessage: 'Add and edit content',
		description: 'Collaborator dropdown selection description text',
	},
	managerTitleText: {
		id: 'space-roles.table.manager-title',
		defaultMessage: 'Manager',
		description: 'Manager dropdown selection title text',
	},
	managerDescription: {
		id: 'space-roles.table.manager-description',
		defaultMessage: 'Manage people and content',
		description: 'Manager dropdown selection description text',
	},
	viewerTitleText: {
		id: 'space-roles.table.viewer-title',
		defaultMessage: 'Viewer',
		description: 'Viewer dropdown selection title text',
	},
	viewerDescription: {
		id: 'space-roles.table.viewer-description',
		defaultMessage: 'View and comment on content',
		description: 'Viewer dropdown selection description text',
	},
	placeholderText: {
		id: 'space-roles.table.placeholder-text',
		defaultMessage: 'Custom access',
		description: 'Role dropdown selection placeholder text',
	},
	selectRolePlaceholder: {
		id: 'space-roles.table.select-role-placeholder',
		defaultMessage: 'Select a role',
		description:
			'Role dropdown selection placeholder text when the role selector is being used in a page',
	},
});

const menuItemStyle = xcss({
	display: 'flex',
	flexDirection: 'column',
	gap: 'space.050',
});

const selectContainer = xcss({
	position: 'relative',
});

const selectWidth = {
	table: xcss({ width: '180px', overflow: 'fit-content', position: 'relative' }),
	modal: xcss({ width: '150px', overflow: 'fit-content', position: 'relative' }),
	page: xcss({ minWidth: '300px' }),
};

export const RoleSelector: React.FC<RoleSelectorProps> = ({
	initialRoleId,
	value,
	isLoading,
	onChange,
	displayType,
	spaceRoleOptions,
	onMenuClose,
	onMenuOpen,
	menuPlacement = 'auto',
	appearance = 'subtle',
}) => {
	const [internalVal, setInternalVal] = useState<string | OptionType | null>(
		(value === undefined ? initialRoleId : value) || null,
	);
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// The component is controlled if value is not `undefined`. `initialRole` will ignored in controlled mode.
	const isControlledComponent = value !== undefined;

	const options: Option[] = useMemo(
		() =>
			spaceRoleOptions.map((role) => ({
				id: role?.id ?? '',
				label: role?.name ?? '',
				value: role?.id ?? '',
				description: role?.description ?? '',
			})),
		[spaceRoleOptions],
	);

	const getOptionFromValue = useCallback(
		(val: OptionType | string | null | undefined): Option | null => {
			if (!val) return null;

			return typeof val === 'string'
				? options.find((option) => option.value === val) || null
				: {
						id: val.value.toString(),
						label: val.label,
						value: val.value.toString(),
						description: '',
					};
		},
		[options],
	);

	const CustomSingleValue = React.memo((props: SingleValueProps<Option>) => (
		<components.SingleValue {...props}>{props.data.label}</components.SingleValue>
	));

	const CustomOption = React.memo((props: OptionProps<Option>) => (
		<components.Option {...props}>
			<Box xcss={menuItemStyle}>
				<Text>{props.data.label}</Text>
				<Text color="color.text.subtlest">{props.data.description}</Text>
			</Box>
		</components.Option>
	));

	const handleChange = (newSelection: SingleValue<Option>) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: 'change',
				source: 'spaceRoles-roleSelector',
				attributes: { newSelection: newSelection?.label },
			},
		}).fire();

		setInternalVal(newSelection?.value || null);
		onChange?.(spaceRoleOptions.find((role) => role?.id === newSelection?.value) as SpaceRole);
	};

	const handleMenuOpen = (currentRoleSelection: Option | null) => {
		onMenuOpen?.();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: 'open',
				source: 'spaceRoles-roleSelector',
				attributes: { currentRoleSelection: currentRoleSelection?.label },
			},
		}).fire();
	};

	const SELECT_STYLE_PROPS = {
		isSearchable: false,
		styles: {
			menu: (base: any) => ({
				...base,
				width: '320px',
				padding: 0,
				margin: 0,
			}),
		},
	};

	const placeHolder = (
		<Text color="color.text">
			{displayType == 'page'
				? formatMessage(i18n.selectRolePlaceholder)
				: formatMessage(i18n.placeholderText)}
		</Text>
	);

	return (
		<Box xcss={[selectContainer, selectWidth[displayType]]}>
			<Select
				data-testid="space-roles-table-role-selector"
				placeholder={isLoading ? '' : placeHolder}
				options={options}
				appearance={appearance}
				isLoading={isLoading}
				onChange={handleChange}
				onMenuOpen={() =>
					handleMenuOpen(getOptionFromValue(isControlledComponent ? value : internalVal))
				}
				onMenuClose={onMenuClose}
				components={{ SingleValue: CustomSingleValue, Option: CustomOption }}
				menuPosition="fixed"
				menuPlacement={menuPlacement}
				maxMenuHeight={320}
				scrollBehavior="outside"
				defaultValue={getOptionFromValue(initialRoleId)}
				value={isControlledComponent ? getOptionFromValue(value) : getOptionFromValue(internalVal)}
				{...SELECT_STYLE_PROPS}
			/>
		</Box>
	);
};
