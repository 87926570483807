/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum RoleAssignmentPrincipalType {
  ACCESS_CLASS = "ACCESS_CLASS",
  GROUP = "GROUP",
  TEAM = "TEAM",
  USER = "USER",
}

export enum SpaceRoleType {
  CUSTOM = "CUSTOM",
  SYSTEM = "SYSTEM",
}

export interface RoleAssignmentPrincipalInput {
  principalId: string;
  principalType: RoleAssignmentPrincipalType;
}

//==============================================================
// END Enums and Input Objects
//==============================================================

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpaceRolesByCriteriaQuery
// ====================================================

export interface SpaceRolesByCriteriaQuery_spaceRolesByCriteria_nodes_spacePermissionList {
  id: string;
  priority: number;
  displayName: string;
  description: string | null;
  requiredSpacePermissions: (string | null)[] | null;
}

export interface SpaceRolesByCriteriaQuery_spaceRolesByCriteria_nodes {
  roleId: string;
  roleDisplayName: string;
  roleDescription: string;
  roleType: SpaceRoleType;
  spacePermissionList: SpaceRolesByCriteriaQuery_spaceRolesByCriteria_nodes_spacePermissionList[];
}

export interface SpaceRolesByCriteriaQuery_spaceRolesByCriteria_pageInfo {
  endCursor: string | null;
  hasNextPage: boolean | null;
}

export interface SpaceRolesByCriteriaQuery_spaceRolesByCriteria {
  nodes: SpaceRolesByCriteriaQuery_spaceRolesByCriteria_nodes[];
  pageInfo: SpaceRolesByCriteriaQuery_spaceRolesByCriteria_pageInfo;
}

export interface SpaceRolesByCriteriaQuery {
  spaceRolesByCriteria: SpaceRolesByCriteriaQuery_spaceRolesByCriteria;
}

export interface SpaceRolesByCriteriaQueryVariables {
  spaceId?: any | null;
  principal?: RoleAssignmentPrincipalInput | null;
}
