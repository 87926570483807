import type { IntlShape, MessageDescriptor } from 'react-intl-next';

import type {
	Command,
	CommandDispatch,
	FloatingToolbarButton,
	FloatingToolbarDropdown,
	FloatingToolbarItem,
} from '@atlaskit/editor-common/types';
import { type NodeType } from '@atlaskit/editor-prosemirror/model';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import sharedMessages from '@atlassian/generative-ai-modal/utils/messages';

import type { EditorPluginAIConfigItem } from '../../prebuilt/config-items/config-items';
import { improveWriting } from '../../prebuilt/config-items/prompts/enhance/enhance';
import type {
	AIGlobalOptIn,
	EditorPluginAIProvider,
	SelectionToolbarDropdownConfig,
	SelectionToolbarDropdownOption,
} from '../../types/types';
import { Logo } from '../../ui/components/Logo/Logo';
import { createOpenAIModalCommand } from '../decoration/actions';

export const floatingToolbarAIButtonTestID = `ak-editor-ai-selection-toolbar-ai-button`;
export const floatingToolbarAIDropdownTestIDPrefix = `ak-editor-ai-selection-toolbar-dropdown-`;

import { messages } from './selection-toolbar-messages';

export const getAIExperienceButton = ({
	intl,
	rangeBaseGenerate,
	hideTitle,
	aiGlobalOptIn,
	title,
	tooltip,
	triggeredFor,
	pulse,
	onMount,
	onClick,
	disabled,
}: {
	intl: IntlShape;
	rangeBaseGenerate: EditorPluginAIConfigItem;
	hideTitle?: boolean;
	aiGlobalOptIn: AIGlobalOptIn;
	title?: MessageDescriptor;
	tooltip?: MessageDescriptor;
	triggeredFor?: NodeType;
	pulse?: boolean;
	onMount?: () => void;
	onClick?: () => void;
	disabled: boolean;
}) => {
	let didMouseEnter = false;

	return {
		type: 'button',
		pulse,
		showTitle: true,
		testId: floatingToolbarAIButtonTestID,
		icon: AtlassianIntelligenceIcon,
		iconFallback: Logo,
		...(!hideTitle && {
			title: intl.formatMessage(title ?? messages.aiToolbarTitle),
		}),
		tooltipContent: intl.formatMessage(tooltip ?? sharedMessages.aiUniversalShortCallToAction),
		onMount,
		onMouseEnter: () => {
			didMouseEnter = true;
			return false;
		},
		onClick: (state: EditorState, dispatch?: CommandDispatch, view?: EditorView) => {
			const openAIModalCommand = createOpenAIModalCommand({
				state,
				configItem: rangeBaseGenerate,
				lastTriggeredFrom: 'floatingToolbar',
				// ED-18194 Added analytics for floating toolbar to determine
				// the % of users who click the button vs shift tab to it
				triggerMethod: !didMouseEnter ? 'keyboard' : 'click',
				aiGlobalOptIn,
				triggeredFor,
			});

			openAIModalCommand(state, dispatch);
			if (onClick) {
				onClick();
			}

			return false;
		},
		disabled,
	} as FloatingToolbarButton<Command>;
};

export const getImproveWritingAIButton = ({
	intl,
	hideTitle,
	aiGlobalOptIn,
	disabled,
}: {
	intl: IntlShape;
	hideTitle?: boolean;
	aiGlobalOptIn: AIGlobalOptIn;
	disabled: boolean;
}) => {
	let didMouseEnter = false;

	return {
		type: 'button',
		showTitle: true,
		testId: `${floatingToolbarAIButtonTestID}-improve-writing`,
		icon: undefined,
		...(!hideTitle && {
			title: intl.formatMessage(messages.improveWritingToolbarIconTitle),
		}),
		tooltipContent: intl.formatMessage(messages.improveWritingToolbarIconTooltip),
		onMouseEnter: () => {
			didMouseEnter = true;
			return false;
		},
		onClick: (state: EditorState, dispatch?: CommandDispatch, view?: EditorView) => {
			const openAIModalCommand = createOpenAIModalCommand({
				state,
				configItem: improveWriting,
				lastTriggeredFrom: 'floatingToolbar',
				triggerMethod: !didMouseEnter ? 'keyboard' : 'click',
				aiGlobalOptIn,
			});

			openAIModalCommand(state, dispatch);
			return false;
		},
		disabled,
	} as FloatingToolbarButton<Command>;
};

const mapSelectionToolbarDropdowns = ({
	selectionToolbarDropdowns,
	intl,
	aiGlobalOptIn,
	disabled,
}: {
	selectionToolbarDropdowns?: SelectionToolbarDropdownConfig[];
	intl: IntlShape;
	aiGlobalOptIn: AIGlobalOptIn;
	disabled: boolean;
}) => {
	if (!selectionToolbarDropdowns) {
		return [];
	}

	return selectionToolbarDropdowns.map((dropdownConfig: SelectionToolbarDropdownConfig) => {
		let title = intl.formatMessage(dropdownConfig.label);
		if (dropdownConfig?.beta) {
			title = `${title} (${intl.formatMessage(sharedMessages.betaLabel)})`;
		}
		return {
			type: 'dropdown',
			testId: floatingToolbarAIDropdownTestIDPrefix + dropdownConfig.id,
			title: title,
			disabled,
			options: dropdownConfig.options.map(
				(option: SelectionToolbarDropdownOption, index: number) => ({
					title: intl.formatMessage(option.label),
					testId: `ak-editor-ai-selection-toolbar-${dropdownConfig.id}-button-${index}`,
					onClick: (state: EditorState, dispatch?: CommandDispatch, view?: EditorView) => {
						const openAIModalCommand = createOpenAIModalCommand({
							state,
							configItem: option.configItem,
							lastTriggeredFrom: 'floatingToolbarDropdown',
							// ED-18194 Added analytics for floating toolbar to determine
							// the % of users who click the button vs shift tab to it
							// triggerMethod: !didMouseEnter ? 'keyboard' : 'click',
							aiGlobalOptIn,
						});

						openAIModalCommand(state, dispatch);
						return false;
					},
				}),
			),
		};
	}) as FloatingToolbarDropdown<Command>[];
};

export const getAIExperienceDropdowns = ({
	intl,
	getSelectionToolbarDropdowns,
	aiGlobalOptIn,
	disabled,
}: {
	intl: IntlShape;
	getSelectionToolbarDropdowns: EditorPluginAIProvider['getSelectionToolbarDropdowns'];
	aiGlobalOptIn: AIGlobalOptIn;
	disabled: boolean;
}) => {
	const selectionToolbarDropdowns = getSelectionToolbarDropdowns?.();
	const aiDropdowns = mapSelectionToolbarDropdowns({
		selectionToolbarDropdowns,
		intl,
		aiGlobalOptIn,
		disabled,
	});

	type DropdownOrSeparator = FloatingToolbarDropdown<Command> | FloatingToolbarItem<Command>;

	const separator: FloatingToolbarItem<Command> = {
		type: 'separator',
	};

	const separatedAIDropdowns = aiDropdowns.reduce<DropdownOrSeparator[]>(
		(acc: DropdownOrSeparator[], val: DropdownOrSeparator) => acc.concat(separator, val),
		[],
	);

	return separatedAIDropdowns;
};
