import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type { PMPluginFactoryParams } from '@atlaskit/editor-common/types';
import { keydownHandler } from '@atlaskit/editor-prosemirror/keymap';

import { updateLocale } from './commands';
import { suggestionManager } from './handlers/suggestion-manager';
import { inlineSuggestionPluginKey } from './inline-suggestion-plugin-key';
import {
	createInitialState,
	createPluginState,
	getPluginState as getInlineSuggestionState,
} from './plugin-factory';
import { docHasChanged, handleEscapeKey, localeHasChanged } from './utils';

export const createInlineSuggestionPlugin = (options: {
	dispatch: PMPluginFactoryParams['dispatch'];
	getIntl: PMPluginFactoryParams['getIntl'];
}) => {
	const { dispatch, getIntl } = options;
	const { locale } = getIntl();

	return new SafePlugin({
		key: inlineSuggestionPluginKey,
		state: createPluginState(dispatch, createInitialState({ locale })),
		props: {
			decorations: (state) => {
				const { decorationSet } = getInlineSuggestionState(state);
				return decorationSet;
			},
			handleKeyDown: keydownHandler({
				Escape: handleEscapeKey,
			}),
		},
		view: () => {
			return {
				update: (view, prevState) => {
					if (docHasChanged(prevState, view.state)) {
						suggestionManager.scheduleScanForSuggestions(view);
					}
					const currentLocale = getIntl().locale;
					if (localeHasChanged(view.state, currentLocale)) {
						updateLocale(currentLocale)(view.state, view.dispatch);
					}
				},
				destroy: () => {
					suggestionManager.cancelSuggestionScans();
				},
			};
		},
	});
};
