import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { ModalFooter } from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/new';

import { FrictionModal } from './FrictionModal';

const i18n = defineMessages({
	saveButton: {
		id: 'default-space-permissions.set-role-modal.save-button',
		defaultMessage: 'Save',
		description: 'Text for the save button',
	},
	cancelButton: {
		id: 'default-space-permissions.set-role-modal.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Text for the cancel button',
	},
});

export const SetRoleModalFooter = ({
	areChangesStaged,
	hasRequiredFields,
	onSave,
	onCancel,
}: {
	areChangesStaged: boolean;
	hasRequiredFields: boolean;
	onSave: () => void;
	onCancel: () => void;
}) => {
	const { formatMessage } = useIntl();
	const [showFrictionModal, setShowFrictionModal] = useState(false);

	const onCancelClick = () => {
		if (areChangesStaged) {
			setShowFrictionModal(true);
		} else {
			onCancel();
		}
	};

	return (
		<>
			<ModalFooter>
				<Button appearance="subtle" onClick={onCancelClick}>
					{formatMessage(i18n.cancelButton)}
				</Button>
				<Button
					isDisabled={!areChangesStaged || !hasRequiredFields}
					appearance="primary"
					onClick={onSave}
				>
					{formatMessage(i18n.saveButton)}
				</Button>
			</ModalFooter>
			{showFrictionModal && (
				<FrictionModal onClose={() => setShowFrictionModal(false)} onConfirm={onCancel} />
			)}
		</>
	);
};
