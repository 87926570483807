import { useEffect, useRef } from 'react';

interface UseUpdateEditorSizeProps {
	modalElement: HTMLElement | null;
	editorViewElement: HTMLElement;
}

export const HEIGHT_ELEMENT_CLASS_NAME = 'ai-height-div';
/**
 * whenever we update modal size - we want to update editor size to accommodate for it
 * This hook listens for modal size changes, and updates editor content area height accordingly
 */
export function useUpdateEditorHeight({
	modalElement,
	editorViewElement,
}: UseUpdateEditorSizeProps): void {
	const heightDivRef = useRef<HTMLDivElement | null>(null);

	// listen to changes to modal size
	useEffect(() => {
		if (!modalElement) {
			return;
		}

		const resizeObserver = new ResizeObserver((entries) => {
			if (!heightDivRef.current || !modalElement) {
				return;
			}

			const modalEntry = entries.find((e) => e.target === modalElement);
			// entry should always exist for modaRef as we're observing on it, this check makes ts happy
			if (!modalEntry) {
				return;
			}
			const modalBottom =
				// Ignored via go/ees005
				// eslint-disable-next-line @atlaskit/editor/no-as-casting
				(modalEntry.target as HTMLElement).offsetTop + modalEntry.contentRect.height;
			const editorBottom = editorViewElement.offsetTop + editorViewElement.offsetHeight;

			// The height of the extra div should be expanded to reach
			// the bottom of the modal -- so that no modal content is outside
			// the editor
			const heightToSet = Math.max(modalBottom - editorBottom, 0);
			heightDivRef.current.style.height = `${heightToSet}px`;
		});

		resizeObserver.observe(modalElement);

		return () => {
			resizeObserver.disconnect();
		};
	}, [modalElement, editorViewElement]);

	// Create an element for adjusting height of editor when modal is visible
	useEffect(() => {
		if (heightDivRef.current) {
			// In future react will fire useEffect twice in dev mode
			// this early exit avoids issues with this
			return;
		}
		const newDiv = document.createElement('div');
		newDiv.className = HEIGHT_ELEMENT_CLASS_NAME;
		newDiv.style.height = `0px`;

		// Ignored via go/ees005
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		editorViewElement.parentNode!.appendChild(newDiv);
		heightDivRef.current = newDiv;

		return () => {
			editorViewElement.parentNode?.removeChild(newDiv);
		};
	}, [editorViewElement]);
}
