import React from 'react';

import CustomizeIcon from '@atlaskit/icon/core/customize';

import { PromptBuilder } from '../../../../ui/components/PromptBuilder/PromptBuilder';
import {
	createInsertAtCurrentPosition,
	createInsertBelow,
	createReplace,
} from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	AdvancedPromptConfig,
	EditorPluginAIConfigItem,
	EditorPluginAIConfigItemWithOptions,
} from '../../config-items';

import { historyMessageBuilder } from './historyMessageBuilder';
import { messages } from './messages';
import type { PromptValue } from './types';

export const advancedPrompt: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.ADVANCED_PROMPT,
	title: messages.title,
	description: messages.description,
	icon: () => <CustomizeIcon label="" />,
	promptLabel: messages.promptLabel,
	statusLozengeType: 'new',
	agentId: 'advanced_prompting_editor_agent',
	intentSchemaId: 'advanced_prompt_schema.json',
	advancedPrompt: {
		promptBuilder: PromptBuilder,
		historyMessageBuilder,
	} satisfies AdvancedPromptConfig<PromptValue>,
	actions: {
		empty: [createInsertAtCurrentPosition({ appearance: 'primary' })],
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
	getBackendModel: () => {
		return 'assistance-service';
	},
};

export const advancedPromptWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: advancedPrompt,
	triggers: { empty: true, selection: true },
};
