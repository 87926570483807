/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { useIntl } from 'react-intl-next';

import { TOOLBAR_ACTION_SUBJECT_ID } from '@atlaskit/editor-common/analytics';
import { useSharedPluginState } from '@atlaskit/editor-common/hooks';
import { separatorStyles, wrapperStyle } from '@atlaskit/editor-common/styles';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import { ToolbarButton } from '@atlaskit/editor-common/ui-menu';
import type { WidthPlugin } from '@atlaskit/editor-plugin-width';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import { fg } from '@atlaskit/platform-feature-flags';
import { LogoSvg as AtlassianIntelligenceLogo } from '@atlassian/generative-ai-modal/assets/Logo';
import sharedMessages from '@atlassian/generative-ai-modal/utils/messages';

import { useEditorToolbarChangeDetector } from '../../hooks/useEditorToolbarChangeDetector';

import { svgIconStyles } from './styles';

type Props = {
	onClick: () => void;
	disabled: boolean;
	api?: PublicPluginAPI<[WidthPlugin]> | undefined;
	ButtonWrapper?: React.ComponentType<{ children: React.ReactNode }>;
	view?: EditorView;
};

// This const is derived from the breakpoint where the toolbar hides its icons. It is used to hide the text in the AI button.
// Derived from values from platform/packages/editor/editor-core/src/ui/Appearance/FullPage/MainToolbar.tsx
const AI_BUTTON_WIDTH_BREAKPOINT = 1076;

// eslint-disable-next-line @repo/internal/deprecations/deprecation-ticket-required -- Ignored via go/ED-25883
/**
 * @deprecated This method is deprecated and should be cleaned up once all products using AI
 * are on composable editor.
 */
const deprecatedGetWidthState = (view: EditorView) => {
	const editorWidthPlugin = view.state.plugins.find(
		// Ignored via go/ees005
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(plugin) => (plugin as any).key === 'widthPlugin$',
	);

	return { widthState: editorWidthPlugin?.getState(view.state) };
};

const shouldShowSeparator = (node: HTMLSpanElement | null) => {
	if (!node) {
		return false;
	}

	const prevSeparator = node.previousElementSibling?.querySelector(
		`:scope > span:last-child:empty`,
	);
	const prevSeparatorVisible = prevSeparator && prevSeparator.clientWidth > 0;

	return !prevSeparatorVisible;
};

const WriteAIButton = ({
	api,
	view,
	onClick,
	isDisabled,
}: {
	onClick: () => void;
	api?: PublicPluginAPI<[WidthPlugin]> | undefined;
	view?: EditorView;
	isDisabled: boolean;
}) => {
	const { formatMessage } = useIntl();
	const title = formatMessage(sharedMessages.aiUniversalDescriptor);

	const { widthState } = useSharedPluginState(api, ['width']);

	let shouldShowLabel = widthState?.width ? widthState.width >= AI_BUTTON_WIDTH_BREAKPOINT : true;

	if (!widthState?.width && view) {
		const { widthState: deprecatedWidthState } = deprecatedGetWidthState(view);
		shouldShowLabel = deprecatedWidthState?.width >= AI_BUTTON_WIDTH_BREAKPOINT;
	}

	return (
		<ToolbarButton
			onClick={onClick}
			buttonId={TOOLBAR_ACTION_SUBJECT_ID.AI}
			aria-label={title}
			testId="atlassian-intelligence-toolbar-button"
			title={title}
			iconBefore={
				fg('platform-visual-refresh-icons') ? (
					<AtlassianIntelligenceIcon
						label="atlassian-intelligence-toolbar-button-icon"
						spacing="spacious"
					/>
				) : (
					<AtlassianIntelligenceLogo alt={''} isDisabled={isDisabled} />
				)
			}
			disabled={isDisabled}
		>
			{shouldShowLabel && formatMessage(sharedMessages.aiUniversalShortCallToAction)}
		</ToolbarButton>
	);
};

export const AtlassianIntelligenceToolbarButton = ({
	onClick,
	disabled,
	ButtonWrapper,
	api,
	view,
}: Props) => {
	const [showSeparator, setShowSeperator] = useState(false);
	const callbackRef = (node: HTMLSpanElement | null) => {
		const result = shouldShowSeparator(node);
		setShowSeperator(result);
	};

	const aiButton = <WriteAIButton api={api} view={view} onClick={onClick} isDisabled={disabled} />;
	const toolbarMutationCount = useEditorToolbarChangeDetector(ButtonWrapper);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		<span ref={callbackRef} css={[wrapperStyle, svgIconStyles]}>
			{showSeparator && (
				/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766 */
				<span data-testid="ai-toolbar-button-seperator" css={separatorStyles} />
			)}
			{ButtonWrapper ? (
				<ButtonWrapper key={`${toolbarMutationCount}-key-wrapper`}>{aiButton}</ButtonWrapper>
			) : (
				aiButton
			)}
		</span>
	);
};
