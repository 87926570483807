import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ErrorIcon from '@atlaskit/icon/core/migration/error';
import Button from '@atlaskit/button/new';
import Link from '@atlaskit/link';
import Lozenge from '@atlaskit/lozenge';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import { Box, Grid, xcss, Text } from '@atlaskit/primitives';
import PeopleGroupIcon from '@atlaskit/icon/core/migration/people-group';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import {
	SitePermissionType,
	SitePermissionTypeFilter,
	UserAndGroupSearchPicker,
} from '@confluence/user-and-group-search';
import { ErrorDisplay } from '@confluence/error-boundary';

import type { SpaceRole } from '../../model/space-roles-types';
import type { UpdateRoleParams } from '../../model/api-endpoints';
import { RoleSelector } from '../../page/RoleSelector';
import type { UserPickerPrincipalOption } from '../../page/principal-parser';
import {
	parseUserPickerOptionIntoPrincipal,
	getIdFromPrincipal,
} from '../../page/principal-parser';
import { getPrincipalTypeFromUserPickerType } from '../../model/normalizers';
import { PrincipalCell } from '../../table/PrincipalCell';
import { FrictionModal } from '../FrictionModal';
import { useResponseHandler } from '../../graphql/hooks/useResponseHandler';

import type { StagedPrincipal } from './addPrincipalModal.types';
import { GuestRoleWarningMessageIcon } from './GuestRoleWarningMessageIcon';
import { useRoleCompatibilityCheck } from './useRoleCompatibilityCheck';

const i18n = defineMessages({
	usersAndGroupsTitle: {
		id: 'space-roles.dialog-add-principal.title',
		defaultMessage: 'Add people',
		description: 'Title for adding users or groups in a modal',
	},
	groupsTitle: {
		id: 'space-roles.dialog-add-principal.groups-title',
		defaultMessage: 'Add default groups',
		description: 'Title for adding groups in a modal',
	},
	removeBtn: {
		id: 'space-roles.dialog-add-principal.remove-button',
		defaultMessage: 'Remove',
		description: 'A button to remove a principal from the staging change list',
	},
	addBtn: {
		id: 'space-roles.dialog-add-principal.add-button',
		defaultMessage: 'Add',
		description: 'A button to add a principal to the staging change list',
	},
	cancelBtn: {
		id: 'space-roles.dialog-add-principal.cancel-button',
		defaultMessage: 'Cancel',
		description: 'A button to cancel the add principal dialog',
	},
	saveBtn: {
		id: 'space-roles.dialog-add-principal.save-button',
		defaultMessage: 'Save',
		description: 'A button to save the add principal dialog',
	},
	frictionModalConfirmButton: {
		id: 'space-roles.add-principal-modal.friction-modal.confirm-button',
		defaultMessage: 'Discard',
		description: 'Confirm button text for a friction modal',
	},
	frictionModalCancelButton: {
		id: 'space-roles.add-principal-modal.friction-modal.cancel-button',
		defaultMessage: 'Keep editing',
		description: 'Cancel button text for a friction modal',
	},
	guestLozengeText: {
		id: 'space-roles.dialog-add-principal.guest-lozenge',
		defaultMessage: 'Guest',
		description: 'Display text for guest lozenge in the add principal modal',
	},
	incompatibleGuestRoleAssingmentWarning: {
		id: 'space-roles.dialog-add-principal.incompatible-guest-role-assignment-warning',
		defaultMessage: 'Guests can’t be assigned this role.',
		description: 'Warning message for incompatible guest role assignment',
	},
});

// Styles
const modalBodyContainerStyles = xcss({
	minHeight: '108px',
});

const principalWithIconContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
});

const errorMessageStyles = xcss({
	gridColumn: '1 / -1',
	height: token('space.300'),
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	textAlign: 'center',
});
export interface AddPrincipalModalProps {
	onClose: () => void;
	onSave: (principalIds: UpdateRoleParams[], stagedPrincipalCount: number) => Promise<void>;
	experience?: string;
	allowUsers: boolean;
	spaceId: string | null;
	// These props are desgined for the space access request flow.
	initialPrincipalList?: UserPickerPrincipalOption[];
	defaultAccessRequestRoleId?: string;
	defaultRoleOptionList?: SpaceRole[];
}

// The prefilled role will be Collaborator, due to product requirements
const ROLE_PREFILL = '0c103ff5-44d0-4b2a-b80d-8690442c95f0';

/**
 * This component should not be dependent on any space context, and should be able to
 * present UI and functionalities on any pages if the required props are provided. The
 * only API involved is for fetching the role options.
 *
 * The business logics are as follows:
 * 1. Admin selects principals from the picker.
 * 2. Requests are sent to fetch role options once principals are selected, before they
 * 	are added to the staged list.
 * 3. Users are added to the staged list with the selected role.
 * 4. Warning icon is shown if the role is not compatible with the principal.
 */
export const AddPrincipalModal: FC<AddPrincipalModalProps> = ({
	onClose,
	onSave,
	experience,
	initialPrincipalList,
	defaultAccessRequestRoleId,
	defaultRoleOptionList,
	spaceId,
	allowUsers,
}) => {
	const initialPrincipalListWithRole =
		initialPrincipalList?.map((principal) => ({
			...principal,
			role:
				(defaultRoleOptionList || []).find((role) => role.id === defaultAccessRequestRoleId) ||
				null,
		})) || [];

	// States
	const [stagedPrincipalList, setStagedPrincipalList] = useState<StagedPrincipal[]>(
		initialPrincipalListWithRole || [],
	);
	const [rolePickerValue, setRolePickerValue] = useState<SpaceRole | null>(null);
	const [principalPickerOptions, setPrincipalPickerOptions] = useState<
		UserPickerPrincipalOption[] | null
	>(null);
	const [isSaving, setIsSaving] = useState(false);
	const [showStagedChangesWarningModal, setShowStagedChangesWarningModal] = useState(false);
	const { startExperience, abortExperience } = useResponseHandler({
		experience,
	});
	const areChangesStaged = stagedPrincipalList.length > 0;

	// Hooks
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		error: roleOptionsError,
		loadRoleOptionsForPrincipal,
		showIncompatibleRoleWarning,
		areRolesCompatible,
		spaceRoleOptionMap,
		updateIncompatibleRoleWarning,
		resetWarningMessage,
		checkRoleCompatibility,
	} = useRoleCompatibilityCheck({
		spaceId,
		principalPickerOptions,
		rolePickerValue,
		stagedPrincipalList,
	});

	// Check if all principals have a role
	const isNullRoleInStagedList = stagedPrincipalList.some((principal) => !principal.role);

	// Prop callbacks

	const closeModal = () => {
		if (areChangesStaged) {
			setShowStagedChangesWarningModal(true);
		} else {
			abortExperience();
			onClose();
		}
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'close',
				actionSubject: 'modal',
				actionSubjectId: 'addPrincipalModal',
				attributes: {
					source: 'spaceRolesTable',
				},
			},
		}).fire();
	};

	const saveResults = async () => {
		setIsSaving(true);
		// NO error handling inside this component to improve reusability
		await onSave(
			stagedPrincipalList.map((principal) => ({
				principalId: principal.id,
				principalType: getPrincipalTypeFromUserPickerType(principal.type),
				roleId: principal.role!.id,
			})),
			stagedPrincipalList.length,
		);
		onClose();
		setIsSaving(false);
	};

	const onRolePickerChange = async (role: SpaceRole) => {
		setRolePickerValue(role);
		updateIncompatibleRoleWarning(
			principalPickerOptions?.map((option) => getIdFromPrincipal(option)),
			role.id,
			spaceRoleOptionMap,
		);
	};

	const addPrincipalToStagedList = () => {
		const prefilledRole = defaultRoleOptionList?.find((role) => role.id === ROLE_PREFILL) || null;
		if (principalPickerOptions === null || (rolePickerValue === null && prefilledRole === null))
			return;

		const newPrincipals: StagedPrincipal[] = principalPickerOptions.map((option) => {
			return {
				...parseUserPickerOptionIntoPrincipal(option),
				role: rolePickerValue || prefilledRole,
			};
		});
		const idMap = newPrincipals.reduce((acc, principal) => {
			acc[principal.id] = true;
			return acc;
		}, {} as any);

		setStagedPrincipalList([
			...stagedPrincipalList.filter((princiapl) => !idMap[princiapl.id]),
			...newPrincipals,
		]);
		setPrincipalPickerOptions(null);
		setRolePickerValue(null);
		resetWarningMessage();
	};

	const onPrincipalPickerChange = (newOptionList: UserPickerPrincipalOption[]) => {
		setPrincipalPickerOptions(newOptionList);
		const newPrincipal = newOptionList.slice(-1).pop();
		if (!newPrincipal) return;
		return loadRoleOptionsForPrincipal(
			getIdFromPrincipal(newPrincipal),
			getPrincipalTypeFromUserPickerType(newPrincipal.type),
		);
	};

	// Inline action handlers

	const onInlinePrincipalRemovedFromList = (evt: any, id: string) => {
		evt.preventDefault();
		setStagedPrincipalList(stagedPrincipalList.filter((principal) => principal.id !== id));
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'addPeopleRemoveStaged',
				source: 'spaceRoles',
			},
		}).fire();
	};

	const onInlineRolePickerChange = (principalId: string, newRole: SpaceRole) => {
		const updatedPrincipalList = stagedPrincipalList.map((principal) => {
			if (principal.id === principalId) {
				return { ...principal, role: newRole };
			}
			return principal;
		});
		setStagedPrincipalList(updatedPrincipalList);
	};

	const stagedPrincipalRowsComps = stagedPrincipalList.map(
		({ id, name, type, avatarUrl, subText, role, isGuest }) => {
			const isRoleCompatible = checkRoleCompatibility(id, role?.id || '');
			return (
				<>
					<Box xcss={principalWithIconContainerStyles}>
						<PrincipalCell
							key={`principal-${id}`}
							name={name}
							subText={subText}
							avatarSrc={avatarUrl}
							Icon={type === 'group' ? PeopleGroupIcon : null}
							lozenge={
								isGuest && (
									<Lozenge appearance="new">{formatMessage(i18n.guestLozengeText)}</Lozenge>
								)
							}
						/>
						{!isRoleCompatible && (
							<GuestRoleWarningMessageIcon
								noRoleOptions={!spaceRoleOptionMap[id] || spaceRoleOptionMap[id].length === 0}
							/>
						)}
					</Box>
					<RoleSelector
						key={`role-${id}`}
						value={role ? { label: role.name, value: role.id } : null}
						onChange={(newRole) => onInlineRolePickerChange(id, newRole)}
						displayType="modal"
						spaceRoleOptions={spaceRoleOptionMap[id] || []}
						appearance="default"
					/>
					<Link
						key={`link-${id}`}
						appearance="subtle"
						// NOTE: href is a required prop but should not redirect the user.
						href="/"
						onClick={(evt) => onInlinePrincipalRemovedFromList(evt, id)}
						data-testid={`remove-${id}`}
						newWindowLabel={formatMessage(i18n.removeBtn)}
					>
						{formatMessage(i18n.removeBtn)}
					</Link>
				</>
			);
		},
	);

	useEffect(
		() => {
			startExperience();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	// For Space access request feature, we need to load the role options for the initial principal list.
	useEffect(() => {
		void (async () => {
			if ((initialPrincipalList || []).length > 0) {
				await Promise.allSettled(
					(initialPrincipalList || []).map((principal) => {
						return loadRoleOptionsForPrincipal(
							getIdFromPrincipal(principal),
							getPrincipalTypeFromUserPickerType(principal.type),
						);
					}),
				);
			}
		})();
		// Will only be executed once at the initial rendering
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{roleOptionsError && <ErrorDisplay error={roleOptionsError} />}
			<ModalTransition>
				<Modal onClose={closeModal}>
					<ModalHeader>
						<ModalTitle>
							{formatMessage(allowUsers ? i18n.usersAndGroupsTitle : i18n.groupsTitle)}
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Box xcss={modalBodyContainerStyles}>
							<Grid
								templateColumns="312px 150px 1fr"
								columnGap="space.100"
								rowGap="space.250"
								alignItems="center"
							>
								<UserAndGroupSearchPicker
									data-test-id="add-principal-modal-picker"
									fieldId="add-member-principal-picker"
									includeGroup
									withUsers={allowUsers}
									onChange={onPrincipalPickerChange as any}
									value={principalPickerOptions}
									isMulti
									smart={false}
									sitePermissionTypeFilter={SitePermissionTypeFilter.ALL}
									placeholder=" "
									noOptionsMessage={() => null}
									menuPosition="fixed" // To ensure dropdown is not hidden by the modal
									filterSearchResults={(value) => {
										// NAND to filter out the guest group because it shouldn't be assigned permissions
										const isExternal = value?.extra?.permissionType === SitePermissionType.EXTERNAL;
										const isGroup = value?.type === 'group';
										return !(isExternal && isGroup);
									}}
								/>

								<RoleSelector
									value={rolePickerValue?.id || ROLE_PREFILL}
									onChange={onRolePickerChange}
									displayType="modal"
									spaceRoleOptions={defaultRoleOptionList || []}
									appearance="default"
								/>
								<Button
									isDisabled={(principalPickerOptions || []).length <= 0}
									onClick={addPrincipalToStagedList}
								>
									{formatMessage(i18n.addBtn)}
								</Button>
								{showIncompatibleRoleWarning && (
									<Box xcss={errorMessageStyles}>
										<ErrorIcon
											color={token('color.icon.warning')}
											label="guest-role-assignment-warning"
										/>
										<Text>{formatMessage(i18n.incompatibleGuestRoleAssingmentWarning)}</Text>
									</Box>
								)}
								{stagedPrincipalRowsComps}
							</Grid>
						</Box>
					</ModalBody>
					<ModalFooter>
						<Button appearance="subtle" onClick={closeModal}>
							{formatMessage(i18n.cancelBtn)}
						</Button>
						<Button
							appearance="primary"
							isDisabled={
								stagedPrincipalList.length === 0 || isNullRoleInStagedList || !areRolesCompatible
							}
							isLoading={isSaving}
							onClick={saveResults}
						>
							{formatMessage(i18n.saveBtn)}
						</Button>
					</ModalFooter>
				</Modal>
				{showStagedChangesWarningModal && (
					<FrictionModal
						buttons={[
							{
								onClick: () => setShowStagedChangesWarningModal(false),
								label: formatMessage(i18n.frictionModalCancelButton),
								appearance: 'subtle',
								key: 'add-principal-modal-staged-cancel',
							},
							{
								onClick: onClose,
								label: formatMessage(i18n.frictionModalConfirmButton),
								appearance: 'warning',
								key: 'add-principal-modal-staged-confirm',
							},
						]}
						onClose={() => setShowStagedChangesWarningModal(false)}
					/>
				)}
			</ModalTransition>
		</>
	);
};
