import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';

import { useChangeStager } from '@confluence/change-stager';
import { isContentTypeEnabledInCurrentEnv } from '@confluence/content-types-utils';
import { mergeLiveDocI18n } from '@confluence/live-pages-utils/entry-points/mergeLiveDocI18n';

import { SpacePermissionsTabKey } from '../tabs/spacePermissionKeys';

import { HeaderCell } from './HeaderCell';
import { PrincipalHeader } from './PrincipalHeader';

const principalsI18n = defineMessages({
	group: {
		id: 'space-permissions.table.header.principals.group',
		description: "Title of the principals column 'Group'",
		defaultMessage: 'Group',
	},
	user: {
		id: 'space-permissions.table.header.principals.user',
		description: "Title of the principals column 'User'",
		defaultMessage: 'User',
	},
	guest: {
		id: 'space-permissions.table.header.principals.guest',
		description: "Title of the principals column 'Guest'",
		defaultMessage: 'Guest',
	},
});

const groupI18nBase = defineMessages({
	selectAll: {
		id: 'space-permissions.table.header.group.select-all',
		description: "Title of the 'Select all' column",
		defaultMessage: 'Select all',
	},
	all: {
		id: 'space-permissions.table.header.group.all',
		description: "Title of the column group 'All'",
		defaultMessage: 'All',
	},
	pages: {
		id: 'space-permissions.table.header.group.pages',
		description: "Title of the column group for content types, named 'Pages'",
		defaultMessage: 'Pages',
	},
	pagesEmbeds: {
		id: 'space-permissions.table.header.group.pages-embeds',
		description: 'Title of the column group for the page and embed smart link content types',
		defaultMessage: 'Pages and Smart Links',
	},
	pagesWhiteboardsEmbeds: {
		id: 'space-permissions.table.header.group.pages-whiteboards-embeds',
		description:
			'Title of the column group for the page, whiteboard, and embed smart link content types',
		defaultMessage: 'Pages, whiteboards, and Smart Links',
	},
	pagesDatabasesEmbeds: {
		id: 'space-permissions.table.header.group.pages-databases-embeds',
		defaultMessage: 'Pages, databases, and Smart Links',
		description:
			'Title of the column group for the page, database, and embed smart link content types',
	},
	pagesWhiteboardsDatabasesEmbeds: {
		id: 'space-permissions.table.header.group.pages-whiteboards-databases-embeds',
		defaultMessage: 'Pages, whiteboards, databases, and Smart Links',
		description:
			'Title of the column group for the page, whiteboard, database, and embed smart link content types',
	},
	blogs: {
		id: 'space-permissions.table.header.group.blogs',
		description: "Title of the column group 'Blogs'",
		defaultMessage: 'Blogs',
	},
	comments: {
		id: 'space-permissions.table.header.group.comments',
		description: "Title of the column group 'Comments'",
		defaultMessage: 'Comments',
	},
	attachments: {
		id: 'space-permissions.table.header.group.attachments',
		description: "Title of the column group 'Attachments'",
		defaultMessage: 'Attachments',
	},
	restrictions: {
		id: 'space-permissions.table.header.group.restrictions',
		description: "Title of the column group 'Restrictions'",
		defaultMessage: 'Restrictions',
	},
	mail: {
		id: 'space-permissions.table.header.group.mail',
		description: "Title of the column group 'Mail'",
		defaultMessage: 'Mail',
	},
	space: {
		id: 'space-permissions.table.header.group.space',
		description: "Title of the column group 'Space'",
		defaultMessage: 'Space',
	},
});

const groupI18nLiveDocs = defineMessages({
	pages: {
		id: 'space-permissions.table.header.group.pages.livedocs',
		defaultMessage: 'Pages and live docs',
		description: 'Updated version of space-permissions.table.header.group.pages',
	},

	pagesEmbeds: {
		id: 'space-permissions.table.header.group.pages-embeds.livedocs',
		defaultMessage: 'Pages, live docs, and Smart Links',
		description: 'Updated version of space-permissions.table.header.group.pages-embeds',
	},

	pagesWhiteboardsEmbeds: {
		id: 'space-permissions.table.header.group.pages-whiteboards-embeds.livedocs',
		defaultMessage: 'Pages, live docs, whiteboards, and Smart Links',
		description: 'Updated version of space-permissions.table.header.group.pages-whiteboards-embeds',
	},

	pagesDatabasesEmbeds: {
		id: 'space-permissions.table.header.group.pages-databases-embeds.livedocs',
		defaultMessage: 'Pages, live docs, databases, and Smart Links',
		description: 'Updated version of space-permissions.table.header.group.pages-databases-embeds',
	},

	pagesWhiteboardsDatabasesEmbeds: {
		id: 'space-permissions.table.header.group.pages-whiteboards-databases-embeds.livedocs',
		defaultMessage: 'Pages, live docs, whiteboards, databases, and Smart Links',
		description:
			'Updated version of space-permissions.table.header.group.pages-whiteboards-databases-embeds',
	},
});

const groupI18n = mergeLiveDocI18n(groupI18nBase, groupI18nLiveDocs);

const columnI18n = defineMessages({
	view: {
		id: 'space-permissions.table.header.column.view',
		description: "Title of the column 'View'",
		defaultMessage: 'View',
	},
	deleteOwn: {
		id: 'space-permissions.table.header.column.delete-own',
		description: "Title of the column 'Delete own'",
		defaultMessage: 'Delete own',
	},
	add: {
		id: 'space-permissions.table.header.column.Add',
		description: "Title of the column 'Add'",
		defaultMessage: 'Add',
	},
	delete: {
		id: 'space-permissions.table.header.column.delete',
		description: "Title of the column 'delete'",
		defaultMessage: 'Delete',
	},
	archive: {
		id: 'space-permissions.table.header.column.archive',
		description: "Title of the column 'Archive'",
		defaultMessage: 'Archive',
	},
	addDelete: {
		id: 'space-permissions.table.header.column.add-delete',
		description: "Title of the column 'Add/Delete'",
		defaultMessage: 'Add/Delete',
	},
	export: {
		id: 'space-permissions.table.header.column.export',
		description: "Title of the column 'Export'",
		defaultMessage: 'Export',
	},
	admin: {
		id: 'space-permissions.table.header.column.admin',
		description: "Title of the column 'admin'",
		defaultMessage: 'Admin',
	},
});

const i18n = defineMessages({
	allViewTooltipContent: {
		id: 'space-permissions.table.header.allViewTooltipContent',
		defaultMessage: 'View permission determines who has access to the space.',
		description: "Tooltip content for the 'All View' column header",
	},
	allViewTooltipIconLabel: {
		id: 'space-permissions.table.header.allViewTooltipIconLabel',
		defaultMessage: 'Learn more about view permissions',
		description: "Tooltip icon label for the 'All View' column header",
	},
	deleteOwnTooltipContent: {
		id: 'space-permissions.table.header.deleteOwnTooltipContent',
		defaultMessage:
			'Lets users delete their own pages, blogs, and attachments. (For comments, the “Add” permission includes “Delete Own”)',
		description: "Tooltip content for the 'Delete own' column header",
	},
	deleteOwnTooltipIconLabel: {
		id: 'space-permissions.table.header.deleteOwnTooltipIconLabel',
		defaultMessage: 'Learn more about delete own',
		description: "Tooltip icon label for the 'Delete own' column header",
	},
	restrictionsTooltipContent: {
		id: 'space-permissions.table.header.restrictionsTooltipContent',
		defaultMessage:
			'Lets users add and remove restrictions directly on content. Does not affect inherited restrictions.',
		description: "Tooltip content for the 'Restrictions' column header",
	},
	restrictionsTooltipIconLabel: {
		id: 'space-permissions.table.header.restrictionsTooltipIconLabel',
		defaultMessage: 'Restrictions Add/Delete tooltip',
		description: "Tooltip icon label for the 'Restrictions' column header",
	},
});

export enum ColumnKeys {
	SELECT_ALL = 'select-all',
	PRINCIPAL = 'principal',
	ALL_VIEW = 'all-view',
	ALL_DELETE_OWN = 'all-delete-own',
	CONTENT_ADD = 'content-add',
	CONTENT_ARCHIVE = 'content-archive',
	CONTENT_DELETE = 'content-delete',
	BLOG_ADD = 'blog-add',
	BLOG_DELETE = 'blog-delete',
	COMMENTS_ADD = 'comments-add',
	COMMENTS_DELETE = 'comments-delete',
	ATTACHMENTS_ADD = 'attachments-add',
	ATTACHMENTS_DELETE = 'attachments-delete',
	RESTRICTIONS_ADD_DELETE = 'restrictions-add-delete',
	SPACE_EXPORT = 'space-export',
	SPACE_ADMIN = 'space-admin',
}

export const CELL_BORDER = {
	borderLeft: `1px solid ${token('color.border', '#DCDFE4')}`,
};

export const usePermissionsTableHeaders = (tab: SpacePermissionsTabKey) => {
	const isWhiteboardsEnabled = isContentTypeEnabledInCurrentEnv('whiteboard');
	const isDatabasesEnabled = isContentTypeEnabledInCurrentEnv('database');
	const { isEditing } = useChangeStager();
	const { formatMessage } = useIntl();

	const getContentGroupName = (
		tab: SpacePermissionsTabKey,
		isWhiteboardsEnabled: boolean,
		isDatabasesEnabled: boolean,
	) => {
		if (tab === SpacePermissionsTabKey.AnonymousAccess) {
			return formatMessage(groupI18n.pages);
		} else if (!isWhiteboardsEnabled && !isDatabasesEnabled) {
			return formatMessage(groupI18n.pagesEmbeds);
		} else if (isWhiteboardsEnabled && !isDatabasesEnabled) {
			return formatMessage(groupI18n.pagesWhiteboardsEmbeds);
		} else if (!isWhiteboardsEnabled && isDatabasesEnabled) {
			return formatMessage(groupI18n.pagesDatabasesEmbeds);
		} else {
			return formatMessage(groupI18n.pagesWhiteboardsDatabasesEmbeds);
		}
	};

	const getPrincipalHeaderName = (tab: SpacePermissionsTabKey) => {
		switch (tab) {
			case SpacePermissionsTabKey.Guests:
				return formatMessage(principalsI18n.guest);
			case SpacePermissionsTabKey.AnonymousAccess:
				return formatMessage(principalsI18n.user);
			case SpacePermissionsTabKey.Groups:
				return formatMessage(principalsI18n.group);
			default:
				return formatMessage(principalsI18n.user);
		}
	};

	const groupNames = {
		SELECT_ALL: formatMessage(groupI18n.selectAll),
		ALL: formatMessage(groupI18n.all),
		CONTENT: getContentGroupName(tab, isWhiteboardsEnabled, isDatabasesEnabled),
		BLOGS: formatMessage(groupI18n.blogs),
		COMMENTS: formatMessage(groupI18n.comments),
		ATTACHMENTS: formatMessage(groupI18n.attachments),
		RESTRICTIONS: formatMessage(groupI18n.restrictions),
		MAIL: formatMessage(groupI18n.mail),
		SPACE: formatMessage(groupI18n.space),
	};

	const columnNames = {
		VIEW: formatMessage(columnI18n.view),
		DELETE_OWN: formatMessage(columnI18n.deleteOwn),
		ADD: formatMessage(columnI18n.add),
		DELETE: formatMessage(columnI18n.delete),
		ARCHIVE: formatMessage(columnI18n.archive),
		ADD_DELETE: formatMessage(columnI18n.addDelete),
		EXPORT: formatMessage(columnI18n.export),
		ADMIN: formatMessage(columnI18n.admin),
	};

	const permissionsTableHeaders = [
		{
			key: ColumnKeys.PRINCIPAL,
			content: <PrincipalHeader headerName={getPrincipalHeaderName(tab)} />,
			width: 100,
		},
		{
			key: ColumnKeys.SELECT_ALL,
			// Adding this ternary here instead of at the object level reserves this space in the table,
			// ensuring that the other columns do not shift when switching between view and edit modes
			content: isEditing ? <HeaderCell groupName="" columnName={groupNames.SELECT_ALL} /> : null,
			'aria-hidden': !isEditing,
		},
		{
			key: ColumnKeys.ALL_VIEW,
			content: (
				<HeaderCell
					groupName={groupNames.ALL}
					columnName={columnNames.VIEW}
					tooltipContent={i18n.allViewTooltipContent}
					tooltipIconLabel={i18n.allViewTooltipIconLabel}
					showGroupName
				/>
			),
			inlineStyles: CELL_BORDER,
		},
		{
			key: ColumnKeys.ALL_DELETE_OWN,
			content: (
				<HeaderCell
					groupName={groupNames.ALL}
					columnName={columnNames.DELETE_OWN}
					tooltipContent={i18n.deleteOwnTooltipContent}
					tooltipIconLabel={i18n.deleteOwnTooltipIconLabel}
				/>
			),
		},
		{
			key: ColumnKeys.CONTENT_ADD,
			content: (
				<HeaderCell
					showGroupName={tab === SpacePermissionsTabKey.AnonymousAccess}
					groupName={groupNames.CONTENT}
					columnName={columnNames.ADD}
				/>
			),
			inlineStyles: CELL_BORDER,
		},
		...(tab === SpacePermissionsTabKey.AnonymousAccess
			? []
			: [
					{
						key: ColumnKeys.CONTENT_ARCHIVE,
						content: (
							<HeaderCell
								showGroupName
								groupName={groupNames.CONTENT}
								columnName={columnNames.ARCHIVE}
								centerGroupName
								setWidth
							/>
						),
						inlineStyles: {
							maxWidth: token('space.1000', '80px'),
						},
					},
				]),
		{
			key: ColumnKeys.CONTENT_DELETE,
			content: <HeaderCell groupName={groupNames.CONTENT} columnName={columnNames.DELETE} />,
		},
		{
			key: ColumnKeys.BLOG_ADD,
			content: (
				<HeaderCell groupName={groupNames.BLOGS} columnName={columnNames.ADD} showGroupName />
			),
			inlineStyles: CELL_BORDER,
		},
		{
			key: ColumnKeys.BLOG_DELETE,
			content: <HeaderCell groupName={groupNames.BLOGS} columnName={columnNames.DELETE} />,
		},
		{
			key: ColumnKeys.COMMENTS_ADD,
			content: (
				<HeaderCell groupName={groupNames.COMMENTS} columnName={columnNames.ADD} showGroupName />
			),
			inlineStyles: CELL_BORDER,
		},
		{
			key: ColumnKeys.COMMENTS_DELETE,
			content: <HeaderCell groupName={groupNames.COMMENTS} columnName={columnNames.DELETE} />,
		},
		{
			key: ColumnKeys.ATTACHMENTS_ADD,
			content: (
				<HeaderCell groupName={groupNames.ATTACHMENTS} columnName={columnNames.ADD} showGroupName />
			),
			inlineStyles: CELL_BORDER,
		},
		{
			key: ColumnKeys.ATTACHMENTS_DELETE,
			content: <HeaderCell groupName={groupNames.ATTACHMENTS} columnName={columnNames.DELETE} />,
		},
		...(tab === SpacePermissionsTabKey.Guests || tab === SpacePermissionsTabKey.AnonymousAccess
			? []
			: [
					{
						key: ColumnKeys.RESTRICTIONS_ADD_DELETE,
						content: (
							<HeaderCell
								groupName={groupNames.RESTRICTIONS}
								columnName={columnNames.ADD_DELETE}
								showGroupName
								tooltipContent={i18n.restrictionsTooltipContent}
								tooltipIconLabel={i18n.restrictionsTooltipIconLabel}
							/>
						),
						inlineStyles: CELL_BORDER,
					},
				]),
		...(tab === SpacePermissionsTabKey.Guests
			? []
			: [
					{
						key: ColumnKeys.SPACE_EXPORT,
						content: (
							<HeaderCell
								groupName={groupNames.SPACE}
								columnName={columnNames.EXPORT}
								showGroupName
							/>
						),
						inlineStyles: CELL_BORDER,
					},
				]),
		...(tab === SpacePermissionsTabKey.AnonymousAccess || tab === SpacePermissionsTabKey.Guests
			? []
			: [
					{
						key: ColumnKeys.SPACE_ADMIN,
						content: <HeaderCell groupName={groupNames.SPACE} columnName={columnNames.ADMIN} />,
					},
				]),
	];
	return { cells: permissionsTableHeaders };
};
