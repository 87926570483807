import { preloadPersonalSpaceDetail } from '@confluence/space-utils/entry-points';
import { preloadIntentBasedOnboarding } from '@confluence/home/entry-points/preloadIntentBasedOnboarding';
import { preloadHome } from '@confluence/home/entry-points/preloadHome';
import { preloadTemplatesCollectionEligible } from '@confluence/home/entry-points/preloadTemplatesCollectionEligible';
import { bestEffortTask, getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { preloadPostOfficeConfluenceHomeBanner } from '@confluence/experiment-post-office-home-banner/entry-points/preloadConfuenceHomeBanner';
import { AccessStatus } from '@confluence/session-data';
import type { RouteMatch } from '@confluence/route';
import { isContentTypeEnabledInCurrentEnv } from '@confluence/content-types-utils';
import { expValEqualsNoExposure } from '@confluence/feature-experiments';

export const preloadHomeRoute = async (match: RouteMatch) => {
	const preloadedSessionContext = await getPreloaderFnContext();
	const { userId, cloudId, accessStatus } = preloadedSessionContext;

	const isDatabasesEnabled = isContentTypeEnabledInCurrentEnv('database');
	const isWhiteboardsEnabled = isContentTypeEnabledInCurrentEnv('whiteboard');

	const tasks: Promise<any>[] = [];

	const isLicensed =
		accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS ||
		accessStatus === AccessStatus.LICENSED_USE_ACCESS;

	const canAccessHome =
		accessStatus !== 'ANONYMOUS_ACCESS' && accessStatus !== 'UNLICENSED_AUTHENTICATED_ACCESS';

	if (isLicensed && canAccessHome) {
		tasks.push(
			preloadHome({
				cloudId,
				isLicensed,
				userId,
				matchParams: match.params,
				isWhiteboardViewedEnabled: isWhiteboardsEnabled,
				isDatabaseEnabled: isDatabasesEnabled,
			}),
		);
	}

	tasks.push(preloadPersonalSpaceDetail());

	if (userId) {
		tasks.push(preloadIntentBasedOnboarding(), preloadTemplatesCollectionEligible());
	}

	if (
		userId &&
		// suppress exposure because if feed is empty, users do not see the banner even if they are initially assigned to be in Variation
		expValEqualsNoExposure(
			'post_office_confluence_home_banner_experiment_-_v2',
			'cohort',
			'variation',
		)
	) {
		// Confluence Home Banner in SSR
		const preloadPostOfficeHome = async () => {
			return preloadPostOfficeConfluenceHomeBanner(preloadedSessionContext);
		};

		tasks.push(
			bestEffortTask(
				'preloadPostOfficeConfluenceHomeBanner',
				preloadPostOfficeHome,
				process.env.CLOUD_ENV === 'dev', // await in local dev
			),
		);
	}

	return Promise.all(tasks);
};
