import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type { ExtractInjectionAPI, PMPluginFactoryParams } from '@atlaskit/editor-common/types';

import type { AIPlugin } from '../../aiPluginType';

import { createInitialState, createPluginState, getPluginState } from './decoration-plugin-factory';
import { aiExperienceDecorationPluginKey } from './decoration-plugin-key';
import { onClearGeneratedContentDecorator } from './utils/onClearGeneratedContentDecorator';
import { manageSelectionMarkerView } from './utils/selection-marker';
/**
 * This sets up a prosemirror plugin which currently mixes various concerns:
 * - It handles adding the modal decorations to the document
 * - It handles the clearing the decorations applied to the editor after the user inserts ai generated content
 *
 * In future -- these seperate concerns are intended to be split into seperate plugins.
 *
 */
export const createDecorationPlugin = (options: {
	dispatchAnalyticsEvent: PMPluginFactoryParams['dispatchAnalyticsEvent'];
	dispatch: PMPluginFactoryParams['dispatch'];
	api: ExtractInjectionAPI<AIPlugin> | undefined;
}): SafePlugin => {
	const { dispatch, api, dispatchAnalyticsEvent } = options;

	return new SafePlugin({
		key: aiExperienceDecorationPluginKey,
		state: createPluginState(dispatch, createInitialState(dispatchAnalyticsEvent)),
		props: {
			handleDOMEvents: {
				click: onClearGeneratedContentDecorator,
			},
			decorations: (editorState) => {
				const pluginState = getPluginState(editorState);

				return pluginState.modalDecorationSet;
			},
		},
		view: manageSelectionMarkerView(getPluginState, api),
	});
};
