import React from 'react';

import { RainbowBorder } from '../../components/RainbowBorder/RainbowBorder';

import type { IconProps } from './types';

export const MakeLongerIcon = (props: IconProps) => {
	const { shownAt } = props;

	// TODO swap to @atlaskit/icon once available
	return (
		<RainbowBorder shownAt={shownAt}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M5 5.75L19 5.75M5 9.91667L19 9.91667M5 14.0833L19 14.0833M5 18.25L12 18.25"
					stroke="#44546F"
					strokeWidth="1.5"
				/>
			</svg>
		</RainbowBorder>
	);
};
