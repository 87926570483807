import type { Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';

export type TextPhrase = { phrase: string; from: number; to: number };

interface Segment {
	text: string;
	from: number;
	to: number;
}

interface WordInfo {
	text: string;
	start: number;
	end: number;
}

export class TextPhraseExtractor {
	/** Matches one or more Unicode letters, numbers, '.', '-', or '/', used to extract words (including date delimiters) from text. */
	public static readonly WORD_REGEX = /[\p{L}\p{N}.\-\/]+/gu;
	private static readonly WORDS_BEFORE_CENTRAL = 2;
	private static readonly WORDS_AFTER_CENTRAL = 2;

	public static getTextPhrasesAroundCursor(state: EditorState): TextPhrase[] {
		const { selection } = state;
		if (!selection || selection.from !== selection.to) {
			return [];
		}

		const { $from } = selection;
		const parent = $from.parent;
		const parentStart = $from.start($from.depth);
		const parentOffset = $from.parentOffset;

		const segments = this.extractPlainTextSegments(parent);
		const segment = this.findSegmentForCursor(segments, parentOffset);
		if (!segment) {
			return [];
		}

		const offsetInSegment = parentOffset - segment.from;
		const words = this.extractWordsFromSegment(segment);
		if (!words.length) {
			return [];
		}

		const centralWordIndex = this.findCentralWordIndex(words, offsetInSegment);
		const nearbyWords = this.extractNearbyWords(words, centralWordIndex, parentStart, segment.from);
		const adjustedCentralIndex = Math.min(centralWordIndex + 1, 3) - 1;
		return this.generateTextPhrases(nearbyWords, adjustedCentralIndex);
	}

	private static extractPlainTextSegments(parent: PMNode): Segment[] {
		const segments: Segment[] = [];
		let currentSegment: Segment | null = null;

		const flushSegment = () => {
			if (currentSegment) {
				segments.push(currentSegment);
				currentSegment = null;
			}
		};

		const isPlainText = (child: PMNode): boolean =>
			child.isText && !child.marks?.some((mark) => mark.type.name === 'link');

		parent.forEach((child, offset) => {
			if (isPlainText(child)) {
				const text = child.text || '';
				if (currentSegment && offset === currentSegment.to) {
					currentSegment.text += text;
					currentSegment.to += text.length;
				} else {
					flushSegment();
					currentSegment = { text, from: offset, to: offset + text.length };
				}
			} else {
				flushSegment();
			}
		});
		flushSegment();
		return segments;
	}

	private static findSegmentForCursor(segments: Segment[], parentOffset: number): Segment | null {
		return segments.find((seg) => parentOffset >= seg.from && parentOffset <= seg.to) || null;
	}

	private static extractWordsFromSegment(segment: Segment): WordInfo[] {
		const words: WordInfo[] = [];
		for (const match of segment.text.matchAll(this.WORD_REGEX)) {
			words.push({
				text: match[0],
				start: match.index,
				end: match.index + match[0].length,
			});
		}
		return words;
	}

	private static findCentralWordIndex(words: WordInfo[], cursorOffset: number): number {
		if (words.length === 0) {
			return 0;
		}
		const lastIndex = words.length - 1;
		if (cursorOffset > words[lastIndex].end) {
			return lastIndex;
		}
		let idx = words.findIndex((w) => w.start <= cursorOffset && w.end >= cursorOffset);
		if (idx === -1) {
			idx = words.findIndex((w) => w.end === cursorOffset);
		}
		return idx === -1 ? lastIndex : idx;
	}

	private static extractNearbyWords(
		words: WordInfo[],
		centralIndex: number,
		basePos: number,
		segmentFrom: number,
	): Array<{ word: string; from: number; to: number }> {
		const adjustWord = (w: WordInfo) => ({
			word: w.text,
			from: basePos + segmentFrom + w.start,
			to: basePos + segmentFrom + w.end,
		});

		const leftWords = words
			.slice(Math.max(0, centralIndex - this.WORDS_BEFORE_CENTRAL), centralIndex + 1)
			.map(adjustWord);
		const rightWords = words
			.slice(centralIndex + 1, centralIndex + 1 + this.WORDS_AFTER_CENTRAL)
			.map(adjustWord);

		return leftWords.concat(rightWords);
	}

	private static generateTextPhrases(
		nearbyWords: Array<{ word: string; from: number; to: number }>,
		centralIndex: number,
	): TextPhrase[] {
		const phrases: TextPhrase[] = [];
		for (let start = 0; start <= centralIndex; start++) {
			for (let end = centralIndex; end < nearbyWords.length; end++) {
				const phrase = nearbyWords
					.slice(start, end + 1)
					.map((w) => w.word)
					.join(' ');
				phrases.push({
					phrase,
					from: nearbyWords[start].from,
					to: nearbyWords[end].to,
				});
			}
		}
		return phrases;
	}
}
