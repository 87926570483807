import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::041c2858714a1d218037834dc36de0c1>>
 * @codegenId #modal-body
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen modal-body
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/modal-body.tsx <<SignedSource::bc05f64168acd6a26a653a7b825cbfe7>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/hooks.tsx <<SignedSource::5062e27591547b31bd9e5702d12d3685>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/components/scroll-container.tsx <<SignedSource::bbe933407a64b4542f7b13a981c5ec89>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/constants.tsx <<SignedSource::ada7ffa5504f59d6cd6568e5046b743c>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/hooks/use-scroll.tsx <<SignedSource::f12edad503dc45bae3887f1b1fbfe75c>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ModalBody as PlatformModalBody } from '@atlaskit/modal-dialog';

type PlatformModalBodyProps = React.ComponentProps<typeof PlatformModalBody>;

export type ModalBodyProps = Pick<
  PlatformModalBodyProps,
  'children' | 'testId'
>;

/**
 * A modal dialog displays content that requires user interaction, in a layer above the page.
 */
export const ModalBody = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as ModalBodyProps;
  return (
    <PlatformModalBody
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
