import { type ExtensionAPI } from '@atlaskit/editor-common/extensions';
import { ForgeExtensionProvider } from '../forgeExtensionProvider';
import {
	type ConnectToForgeParameters,
	type ForgeCreateExtensionProps,
	type ForgeExtensionManifest,
	type ForgeExtensionParameters,
	type ForgeExtensionProviderSharedParams,
} from '../../types';

import {
	CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE,
	EXTENSION_TYPE_MULTI_BODIED,
} from '../../utils/constants';
import { getConnectMacroId } from '../../utils/getConnectMacroId';
import { AnalyticsAction, type AnalyticsClient } from './analytics';

export type UpdateADFEntity = Parameters<Parameters<ExtensionAPI['doc']['update']>[1]>[0];

export const upgradeConnectNodeToForge = (
	node: UpdateADFEntity,
	extensionManifest: ForgeExtensionManifest,
): UpdateADFEntity => {
	const { attrs } = node;
	// Ensure extension hasn't been upgraded since the last check
	if (attrs?.extensionType === CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE) {
		// Use the existing Connect macro ID as the Forge localId
		const macroId = getConnectMacroId(attrs.parameters);
		const extensionProps = extensionManifest?.createExtensionProps(macroId);
		return {
			...node,
			attrs: {
				...attrs,
				...extensionProps.attrs,
				// We shouldn't override the Editor localId of an existing node once it's been created
				localId: attrs.localId || extensionProps.attrs.localId,
				parameters: {
					...convertConnectParametersToForge(attrs.parameters),
					...extensionProps.attrs.parameters,
				},
			},
		};
	}
	return node;
};

const convertConnectParametersToForge = ({
	guestParams = {},
	macroParams = {},
	...restParams
}: ConnectToForgeParameters = {}): ConnectToForgeParameters => {
	const migratedGuestParams = { ...guestParams };
	Object.entries(macroParams).forEach(([key, obj]) => {
		// Don't override existing entries
		if (typeof migratedGuestParams[key] === 'undefined' && obj && 'value' in obj) {
			migratedGuestParams[key] = obj.value;
		}
	});
	return {
		macroParams,
		guestParams: migratedGuestParams,
		...restParams,
	};
};

const upgradeConnectExtensionToForge = async (
	attrs: Record<string, string>,
	actions: ExtensionAPI<ForgeExtensionParameters>,
	extensionProvider: ForgeExtensionProvider,
): Promise<UpdateADFEntity> => {
	const { localId, extensionKey } = attrs;
	const extensionManifest = await extensionProvider.getConnectToForgeExtension(extensionKey);
	if (!extensionManifest || !extensionManifest.createExtensionProps) {
		throw new Error(
			`Connect to Forge macro upgrade: No ${
				!extensionManifest ? 'extension manifest' : 'createExtensionProps function'
			} found`,
		);
	}

	return new Promise<UpdateADFEntity>((resolve) => {
		actions.doc.update(localId, (currentValue) => {
			const updatedNode = upgradeConnectNodeToForge(currentValue, extensionManifest);
			resolve(updatedNode);
			return updatedNode;
		});
	});
};

export const maybeUpgradeConnectExtensionToForge = async ({
	connectToForgeBlockedExtensions,
	extensionProvider,
	actions,
	analyticsClient,
	editorActions,
}: {
	analyticsClient: AnalyticsClient;
	connectToForgeBlockedExtensions: Set<unknown>;
	extensionProvider: ForgeExtensionProvider;
	actions?: ExtensionAPI<ForgeExtensionParameters>;
} & Pick<ForgeExtensionProviderSharedParams, 'editorActions'>) => {
	// Only passed through when we are in the Editor view
	if (!editorActions) {
		return;
	}

	const { sendEvent } = analyticsClient;

	const node = editorActions.getSelectedNode();
	const attrs = node?.attrs;
	if (attrs?.extensionType === CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE) {
		const { extensionKey, localId: nodeLocalId } = attrs;
		const macroId = getConnectMacroId(attrs.parameters);
		const extensionType = node?.type.name;

		if (extensionType === EXTENSION_TYPE_MULTI_BODIED) {
			// Upgrade to forge blocked because:
			//     Extension is multi-bodied which forge doesn't support
			connectToForgeBlockedExtensions.add(macroId);
			sendEvent(AnalyticsAction.H11N_UPGRADE_RICH_TEST_SKIPPED, {
				nodeLocalId,
			});
			return;
		}

		/* At this point, we know the extension should be upgraded from Connect to Forge
		 * If anything goes wrong after this point, it should be logged as an upgrade failure */
		try {
			if (!actions?.doc?.update) {
				throw new Error('Connect to Forge macro upgrade: Missing ExtensionAPI update action');
			}

			const updatedNode = await upgradeConnectExtensionToForge(attrs, actions, extensionProvider);

			const { localId, extensionId } = (updatedNode.attrs as ForgeCreateExtensionProps['attrs'])
				.parameters;

			sendEvent(AnalyticsAction.H11N_UPGRADE_SUCCESS, {
				extensionId,
				extensionKey,
				localId,
				nodeLocalId,
				extensionType,
			});
		} catch (error) {
			sendEvent(AnalyticsAction.H11N_UPGRADE_FAILED, {
				extensionKey,
				error,
				nodeLocalId,
				extensionType,
			});
			throw error;
		}
	}
};
