import type { Command, CommandDispatch } from '@atlaskit/editor-common/types';
import { Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import type { EditorState } from '@atlaskit/editor-prosemirror/state';

import { clearSuggestion } from './commands';
import { getPluginState as getInlineSuggestionState } from './plugin-factory';

export function preserveExistingMarks(doc: PMNode, pos: number, node: PMNode): PMNode {
	const nodeAfter = doc.resolve(pos).nodeAfter;
	return nodeAfter && node.isInline ? node.mark(nodeAfter.marks) : node;
}

export function docHasChanged(prevState: EditorState, newState: EditorState): boolean {
	return !prevState.doc.eq(newState.doc);
}

export function localeHasChanged(state: EditorState, currentLocale: string): boolean {
	return getInlineSuggestionState(state).locale !== currentLocale;
}

export const handleEscapeKey: Command = (state: EditorState, dispatch?: CommandDispatch) => {
	if (getInlineSuggestionState(state).inlineSuggestion) {
		clearSuggestion()(state, dispatch);
		return true;
	}
	return false;
};
