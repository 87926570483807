import { DecorationSet } from '@atlaskit/editor-prosemirror/view';

import { ACTIONS } from './states';
import type { InlineSuggestionState, InlineSuggestionAction } from './states';

export const reducer = (
	pluginState: InlineSuggestionState,
	action: InlineSuggestionAction,
): InlineSuggestionState => {
	switch (action.type) {
		case ACTIONS.CLEAR_INLINE_SUGGESTION:
			return {
				...pluginState,
				inlineSuggestion: null,
				decorationSet: DecorationSet.empty,
			};
		case ACTIONS.UPDATE_INLINE_SUGGESTION: {
			return {
				...pluginState,
				inlineSuggestion: action.payload.inlineSuggestion,
				decorationSet: action.payload.decorationSet,
			};
		}
		case ACTIONS.UPDATE_LOCALE: {
			return {
				...pluginState,
				locale: action.payload.locale,
			};
		}
		default:
			return pluginState;
	}
};
