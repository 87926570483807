import React from 'react';

import { useSharedPluginState } from '@atlaskit/editor-common/hooks';
import type { ExtractInjectionAPI, ToolbarUIComponentFactory } from '@atlaskit/editor-common/types';
import { WithPluginState } from '@atlaskit/editor-common/with-plugin-state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';

import type { AIPlugin } from '../../aiPluginType';
import type { AIButtonPluginState } from '../../pm-plugins/ai-button/ai-button-plugin';
import { aiButtonPluginKey } from '../../pm-plugins/ai-button/ai-button-plugin-key';
import { createOpenAIModalCommand } from '../../pm-plugins/decoration/actions';
import { type AIGlobalOptIn, type EditorPluginAIProvider } from '../../types/types';
import { AtlassianIntelligenceToolbarButton } from '../components/AtlassianIntelligenceToolbarButton/AtlassianIntelligenceToolbarButton';

type PrimaryToolbarComponentType = {
	api: ExtractInjectionAPI<AIPlugin>;
	editorPluginAIProvider: EditorPluginAIProvider;
	aiGlobalOptIn: AIGlobalOptIn;
	editorView: EditorView;
	disabled: boolean;
	aiButtonPluginState: AIButtonPluginState;
};

const PrimaryToolbarComponent = ({
	editorView,
	api,
	editorPluginAIProvider,
	aiGlobalOptIn,
	disabled,
	aiButtonPluginState,
}: PrimaryToolbarComponentType) => {
	const { connectivityState } = useSharedPluginState(api, ['connectivity']);
	const onClick = () => {
		const { state, dispatch } = editorView;
		const configItem = editorPluginAIProvider.baseGenerate;
		const openAIModalCommand = createOpenAIModalCommand({
			state,
			configItem,
			lastTriggeredFrom: 'mainToolbar',
			aiGlobalOptIn,
			triggeredFor: aiButtonPluginState.triggeredFor,
		});

		openAIModalCommand(state, dispatch);
	};

	const isOffline = connectivityState?.mode === 'offline' || false;

	return (
		<AtlassianIntelligenceToolbarButton
			api={api}
			ButtonWrapper={editorPluginAIProvider?.AIButtonWrapper}
			onClick={onClick}
			disabled={disabled || isOffline || !aiButtonPluginState?.isSelectionValidForAIExperience}
			view={editorView}
		/>
	);
};

export const getAIPrimaryToolbarComponent =
	({
		api,
		editorPluginAIProvider,
		aiGlobalOptIn,
	}: {
		api: ExtractInjectionAPI<AIPlugin>;
		editorPluginAIProvider: EditorPluginAIProvider;
		aiGlobalOptIn: AIGlobalOptIn;
	}): ToolbarUIComponentFactory =>
	({ editorView, disabled }) => {
		return (
			<WithPluginState
				plugins={{
					pluginState: aiButtonPluginKey,
				}}
				render={({ pluginState }) => (
					<PrimaryToolbarComponent
						editorView={editorView}
						api={api}
						editorPluginAIProvider={editorPluginAIProvider}
						aiGlobalOptIn={aiGlobalOptIn}
						disabled={disabled}
						aiButtonPluginState={pluginState}
					/>
				)}
			/>
		);
	};
