import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import type { MentionMap } from '@atlassian/ai-model-io/utils/mention-map';

import type {
	UpdateIdMap,
	EditorPluginAIConfigItem,
} from '../../prebuilt/config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../prebuilt/config-items/utils/slice-or-node-to-markdown';
import { convoAIStatelessApiURL } from '../../prebuilt/utils';
import type { IntentSchemaId } from '../../types/prompt-request-types';

import { getSupportedMarkdowns } from './get-supported-markdown-from-schema';
import type { AIExperienceMachineContextForRequest, RequestOptions } from './types';

type Options = {
	context: AIExperienceMachineContextForRequest;
	configItem: EditorPluginAIConfigItem;
	mentionMap?: MentionMap;
	intentSchemaId: IntentSchemaId;
	updateIdMap: UpdateIdMap;
};

// Ignored via go/ees005
// eslint-disable-next-line require-unicode-regexp
const sanitizeUrl = (url: string) => url.replace(/[^\w\-/.:%?=]+/g, '');

/**
 * A function that will make the payload for the Convo AI API.
 *
 * @see https://hello.atlassian.net/wiki/spaces/EDAI/pages/3416730365/API+Specs+assistance-service
 */
export const getRequestOptions4ConvoAI = ({
	context,
	configItem,
	mentionMap,
	intentSchemaId,
	updateIdMap,
}: Options): RequestOptions => {
	const {
		aiProvider,
		userInput,
		editorView,
		positions,
		intl,
		latestPromptResponse,
		advancedPromptValue,
	} = context;
	const { product } = aiProvider;
	const { experienceId, endpoint, agentId, lang } = configItem;
	const editorSchema = editorView.state.schema;
	const aiSessionId = context.analyticsContext?.aiSessionId;
	const idMap = {};

	// if there is no selection, we want to pass only the doc
	// if there is selection, we want to pass only the selection (if we pass doc and selection, the doc will be ignored by BE)
	let needDoc = context.isEmpty;
	let needSelection = !context.isEmpty;

	if (configItem.alwaysNoDocAndSelection) {
		// for Brainstorm, Jira Draft reply, don't send document (we have history feedback that LLM was using the document too much)
		needDoc = false;
		needSelection = false;
	} else if (configItem.alwaysNeedDoc) {
		// for Jira Improve description
		needDoc = true;
		needSelection = false;
	} else if (
		// for Free generate and interrogation
		configItem.canTogglePageKnowledge
	) {
		// For range selection and usePageKnowledge, we're sending both doc and selection
		needDoc = context.usePageKnowledge;
	}

	const doc = needDoc
		? sliceOrNodeToMarkdown({
				node: editorView.state.doc,
				editorView,
				convertTo: 'markdown-plus',
				existingIdMap: idMap,
				updateIdMap,
				mentionMap,
			})
		: undefined;

	const selection = needSelection
		? sliceOrNodeToMarkdown({
				slice: editorView.state.doc.slice(positions[0], positions[1]),
				editorView,
				convertTo: 'markdown-plus',
				existingIdMap: doc?.idMap,
				updateIdMap,
				mentionMap,
			})
		: undefined;

	const additionalProps = aiProvider.getAdditionalProps && aiProvider.getAdditionalProps?.();

	// for Jira Improve description
	const payload = configItem.getRequestPayload?.({
		aiSessionId,
		locale: intl.locale,
		isEmpty: context.isEmpty,
		doc: doc?.markdown,
		selection: selection?.markdown,
	});

	const serializedAdvancedPrompt: Record<string, string> | undefined = advancedPromptValue
		? Object.entries(advancedPromptValue).reduce((acc, [key, value]) => {
				return { ...acc, [key]: typeof value === 'string' ? value : value.text };
			}, {})
		: undefined;

	return {
		doc: doc?.markdown,
		selection: selection?.markdown,
		contentStatistics: doc?.contentStatistics || selection?.contentStatistics,
		isConvoAI: true,
		experienceId: experienceId || 'editor',
		endpoint: endpoint || convoAIStatelessApiURL,
		payload: JSON.stringify(
			payload || {
				recipient_agent_named_id: agentId || 'editor_intent_agent',
				// userInput for Free generate, Free generate interrogation, Brainstorm
				agent_input_prompt: userInput || undefined,
				agent_input_context: {
					document_title: editorExperiment('platform_editor_ai_jira_content_read_url', true, {
						exposure: true,
					})
						? additionalProps?.issue_summary
						: undefined,
					advanced_prompt: serializedAdvancedPrompt,
					ai_session_id: aiSessionId,
					product,
					locale: intl.locale,
					content_url: sanitizeUrl(window.document.location.href),
					input_mime_type: 'text/markdown',
					output_mime_type: 'text/markdown',
					supported_markdown_nodes: getSupportedMarkdowns(editorSchema),
					writing_intent: intentSchemaId,
					draft_full_content: doc?.markdown || undefined,
					// latestPromptResponse for Free generate interrogation, Refine response
					draft_selected_content: latestPromptResponse || selection?.markdown || undefined,
					// for translate
					transformation_subtype: lang,
				},
			},
		),
	};
};
