import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { SuggestTitleIcon } from '../../../../ui/icons/prompt/suggestTitle';
import {
	createInsertAtCurrentPosition,
	createInsertAtTop,
	createReplaceExistingConfluenceTitle,
} from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

import { messages } from './messages';

export const suggestTitle: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.SUGGEST_A_TITLE,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <SuggestTitleIcon shownAt={shownAt} />,
	intentSchemaId: 'suggest_title_intent_schema.json',
	actions: {
		empty: [
			createInsertAtCurrentPosition({ appearance: 'secondary' }),
			createInsertAtTop({ appearance: 'primary' }),
		],
		selection: [
			createInsertAtCurrentPosition({ appearance: 'secondary' }),
			createInsertAtTop({ appearance: 'primary' }),
		],
	},
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
};

export const suggestTitleWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: suggestTitle,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};

/**
 * EXPERIMENTAL: Util for experimental flow where 'Suggest title' in
 * Confluence title toolbar will trigger the 'Suggest a title' flow in Editor AI.
 */
export const suggestTitleForConfluenceTitleToolbar: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.SUGGEST_A_TITLE,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <SuggestTitleIcon shownAt={shownAt} />,
	intentSchemaId: 'suggest_title_intent_schema.json',
	actions: {
		empty: [createReplaceExistingConfluenceTitle({ appearance: 'primary' })],
		selection: [createReplaceExistingConfluenceTitle({ appearance: 'primary' })],
	},
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
};

export const suggestTitleForConfluenceTitleToolbarWithOptions: EditorPluginAIConfigItemWithOptions =
	{
		config: suggestTitleForConfluenceTitleToolbar,
		triggers: { empty: { docMinSize: 1 }, selection: true },
	};
