import { SPACE_OVERVIEW, COMPANY_HUB } from '@confluence/named-routes';
import { preloadSpaceOverviewId } from '@confluence/content-types-header/entry-points/preloadSpaceOverviewId';
import { preloadContentTypesHeader } from '@confluence/content-types-header/entry-points/preloadContentTypesHeader';
import { preloadSpaceOverview } from '@confluence/space-overview/entry-points/preloadSpaceOverview';
import { preloadPageTitleContentProperties } from '@confluence/content-topper/entry-points/preloadPageTitleContentProperties';
import { preloadShortcutsSmartLinks } from '@confluence/space-shortcuts/entry-points/preloadShortcutsSmartLinks';
import { preloadSideNavigation } from '@confluence/side-navigation/entry-points/preloadSideNavigation';
import { preloadSpaceGuestList } from '@confluence/space-guest-list/entry-points/preloadSpaceGuestList';
import { preloadSpaceDetail } from '@confluence/space-utils/entry-points';
import { preloadAudienceSegmentationContext } from '@confluence/custom-sites-extensions/entry-points/preloadAudienceSegmentationContext';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import { getCompanyHubSpaceKeyForSSR } from '@confluence/route-manager/entry-points/getCompanyHubSpaceKeyForSSR';
import {
	getSingleParam,
	getSingleParamInt,
} from '@confluence/route-manager-utils/entry-points/getSingleParam';
import type { RouteMatch } from '@confluence/route';
import { preloadSpaceViews } from '@confluence/space-views/entry-points/preloadSpaceViews';
import { fg } from '@confluence/feature-gating';

export const preloadSpaceOverviewRoute = async (match: RouteMatch, _url: string) => {
	const { isLicensed, userId } = await getPreloaderFnContext();
	const { spaceKey } = match.params;
	const homepageId = match.query
		? getSingleParam(match.query, SPACE_OVERVIEW.HOMEPAGE_ID_QUERY_KEY)
		: undefined;

	const tasks: Promise<any>[] = [];

	if (spaceKey) {
		if (process.env.REACT_SSR) {
			// Only load data for navigation in React SSR since we are delaying them in the SPA
			tasks.push(
				preloadShortcutsSmartLinks(spaceKey),
				preloadSideNavigation(spaceKey),
				// preloadSideNavigationNeedsContentId is not called here because we can't guarantee content id
				// It is called in preloadSpaceOverview
				preloadSpaceGuestList(spaceKey),
				preloadSpaceViews(spaceKey, isLicensed),
			);
		}
	}

	const isCompanyHub = match.name === COMPANY_HUB.name;
	const companyHubSpaceKey = isCompanyHub ? await getCompanyHubSpaceKeyForSSR() : undefined;
	const versionOverride =
		companyHubSpaceKey && match.query ? getSingleParamInt(match.query, 'versionOverride') : null;

	// Space Overview's homepageId is needed for the content types header, but may not be in the URL
	// depending on which route was used.  If it's not in the URL, we need to preload it first.
	if (!isCompanyHub && fg('confluence_frontend_object_header')) {
		const contentId =
			homepageId ||
			(await preloadSpaceOverviewId(spaceKey).then(
				({ result, hasErrors }) => (!hasErrors && result?.data?.space?.homepageId) || null,
			));
		tasks.push(preloadContentTypesHeader(contentId, fg('confluence_frontend_object_header')));
	}

	tasks.push(
		preloadSpaceOverview({
			spaceKey: spaceKey ?? companyHubSpaceKey,
			homepageId,
			versionOverride,
		}),
	);

	tasks.push(
		preloadPageTitleContentProperties({
			contentId: homepageId || '',
			spaceKey: spaceKey ?? companyHubSpaceKey,
		}),
	);

	tasks.push(preloadSpaceDetail(spaceKey));

	if (isCompanyHub && userId && fg('company_hub_audience_segmentation')) {
		tasks.push(
			preloadAudienceSegmentationContext({
				accountId: userId,
			}),
		);
	}

	return Promise.all(tasks);
};
