import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import noop from 'lodash/noop';

import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { IconButton } from '@atlaskit/button/new';
import ShowMoreHorizontalIcon from '@atlaskit/icon/utility/migration/show-more-horizontal--editor-more';
import { Text } from '@atlaskit/primitives';

import { useResponseHandler } from '@confluence/space-roles/entry-points/graphql';
import { SITE_RBAC_EDIT_EXPERIENCE } from '@confluence/experience-tracker';

import type { ActiveModalType } from '../RolesManagementView';
import { DeleteRoleModal } from '../../delete-role/DeleteRoleModal';
import type { RoleNodes } from '../../roles-list/useRoleList';
import { SetRoleModal } from '../../set-role/SetRoleModal';
import type { SpacePermissionNodes } from '../useSpacePermissions';

const i18n = defineMessages({
	viewPermissions: {
		id: 'default-space-permissions.table.custom-roles-menu.view-permissions',
		defaultMessage: 'View permissions',
		description: 'Option in Custom Roles Menu to view the permissions of the role',
	},
	editRole: {
		id: 'default-space-permissions.table.custom-roles-menu.edit-role',
		defaultMessage: 'Edit custom role',
		description: 'Option in Custom Roles Menu to edit a set of role permissions and details',
	},
	deleteRole: {
		id: 'default-space-permissions.table.custom-roles-menu.delete-role',
		defaultMessage: 'Delete custom role',
		description: 'Option in Custom Roles Menu to delete a custom role',
	},
});

export const CustomRolesMenu = ({
	roleId,
	roleName,
	roleDescription,
	rolePermissions,
	activeModal,
	setActiveModal,
	allRoles,
	allPermissions,
}: {
	roleId: string;
	roleName: string;
	roleDescription: string;
	rolePermissions: string[];
	activeModal: ActiveModalType;
	setActiveModal: React.Dispatch<React.SetStateAction<ActiveModalType>>;
	allRoles: RoleNodes;
	allPermissions: SpacePermissionNodes;
}) => {
	const { formatMessage } = useIntl();
	const { startExperience } = useResponseHandler({
		experience: SITE_RBAC_EDIT_EXPERIENCE,
	});

	const openModal = useCallback(
		(modal: ActiveModalType) => {
			startExperience();
			setActiveModal(modal);
		},
		[startExperience, setActiveModal],
	);

	return (
		<>
			<DropdownMenu<HTMLButtonElement>
				trigger={({ triggerRef, ...props }) => (
					<IconButton
						{...props}
						icon={ShowMoreHorizontalIcon}
						label="more"
						ref={triggerRef}
						testId="table-actions-menu"
					/>
				)}
				shouldRenderToParent
			>
				<DropdownItemGroup>
					<DropdownItem onClick={noop}>{formatMessage(i18n.viewPermissions)}</DropdownItem>
					<DropdownItem onClick={() => openModal('update')}>
						{formatMessage(i18n.editRole)}
					</DropdownItem>
					<DropdownItem onClick={() => openModal('delete')}>
						<Text color="color.text.accent.red">{formatMessage(i18n.deleteRole)}</Text>
					</DropdownItem>
				</DropdownItemGroup>
			</DropdownMenu>
			{activeModal === 'delete' && (
				<DeleteRoleModal
					roleId={roleId}
					roleName={roleName}
					onClose={() => setActiveModal(null)}
					allRoles={allRoles}
				/>
			)}
			{activeModal === 'update' && (
				<SetRoleModal
					isNewRole={false}
					roleId={roleId}
					roleName={roleName}
					roleDescription={roleDescription}
					rolePermissions={rolePermissions}
					onClose={() => setActiveModal(null)}
					allRoles={allRoles}
					allPermissions={allPermissions}
				/>
			)}
		</>
	);
};
