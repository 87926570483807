import { fg } from '@atlaskit/platform-feature-flags';
import { isFedRamp } from '@atlassian/atl-context';
import { ProductEnvironment, ForgeCDNEnvironment } from '@atlassian/forge-ui-types';

import type { Extension } from '../../../web-client';
import { getCDNEnvironment } from '../../../utils';
import { getEgressHash } from './getEgressHash';

export const productEnvToCdnEnv = {
	[ProductEnvironment.PRODUCTION]: 'prod',
	[ProductEnvironment.STAGING]: 'stg',
	[ProductEnvironment.DEVELOPMENT]: 'stg',
};

/*
  UUIDs are in the format of 32 hexadecimal characters with four hyphens:
  XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX.

  If we intend to concatenate two shortened UUIDs in a subdomain label in the format
  ${shortenedInstallationId}--${shortenedAppId}, where the max length of the label is
  63 characters (https://www.rfc-editor.org/rfc/rfc2181), then each id can have a max
  length of 30 characters. An underscore cannot be used as the delimiter because
  underscores are not allowed in domains. A single hyphen cannot be used because the
  delimiter needs to be unique and the ids themselves contain hyphens. Hence, the
  delimiter is a double-hyphen.

  The "highest" UUID is ffffffff-ffff-ffff-ffff-ffffffffffff. The base-36 string encoding
  (a-z, 0-9) is 1z141z3-1ekf-1ekf-1ekf-2rrvthnxtr, which has a length of 33. Too long.

  Combining the middle three segments in the UUID, i.e. ffffffff-ffffffffffff-ffffffffffff, and
  then encoding gives 1z141z3-2rrvthnxtr-2rrvthnxtr, which has a length of 29. This is what
  we will do.

  Precision should not be lost when decoding back to hexadecimal, because the longest segment
  in a UUID is 12 characters, while Number.MAX_SAFE_INTEGER.toString(16).length === 14.
*/
export const shortenUuid = (uuid: string) => {
	const [a, b, c, d, e] = uuid.split('-');
	const combinedUuid = [a, `${b}${c}${d}`, e];

	return combinedUuid
		.map((s) => parseInt(s, 16))
		.map((s) => s.toString(36))
		.join('-');
};

export const expandUuid = (shortenedUuid: string) => {
	const [a, b, c] = shortenedUuid
		.split('-')
		.map((s) => parseInt(s, 36))
		.map((s) => s.toString(16));

	// expands the middle segment into the original UUID
	// ffffffff-abcdabcdabcd-ffffffffffff ===> ffffffff-abcd-abcd-abcd-ffffffffffff
	const middleExpanded = b.match(/.{1,4}/g) || b;
	return [a, ...middleExpanded, c].join('-');
};

export const createSrcFromExtension = (
	appId: string,
	extension: Extension,
	environment: ProductEnvironment,
	entryPoint: string,
): string => {
	const cdnEnv = getCDNEnvironment(environment);

	// Map the dev environment to staging here for local development. The dev environment is not set up to load Forge apps.
	const urlEnv = cdnEnv === ForgeCDNEnvironment.DEVELOPMENT ? ForgeCDNEnvironment.STAGING : cdnEnv;

	const { installationId, environmentId, appVersion, properties } = extension;
	const resourceUploadId = properties.resourceUploadId || appVersion;

	const secondLevelDomain =
		isFedRamp() && cdnEnv !== ForgeCDNEnvironment.FEDRAMP_SANDBOX
			? 'atlassian-dev-us-gov-mod'
			: 'atlassian-dev';

	const domain = `${shortenUuid(installationId)}--${shortenUuid(
		appId,
	)}.cdn.${urlEnv}.${secondLevelDomain}.net`;

	/**
	 * Hostname is added here as a URI segment instead of a query string because the JS and CSS scripts inside of the index.html file need to be aware of the hostname as well.
	 * They are using relative paths that will ignore the appended query string here.
	 *
	 * The appId is also included in the subdomain so that it can be compared against the appId in the
	 * resource path. This comparison is performed by the routing Lambda@ Edge to prevent a malicious
	 * developer from appending their malicious app path to a legitimate installation id.
	 */
	if (fg('forge-ui-roa-analytics-egress')) {
		/*
			We want to ensure that responses with different CSP headers are cached appropriate.
			This is done by including a hash of the egress permissions and override toggles in the URL.
		*/
		const { egress = [], overrides = {} } = extension;
		const egressHash = getEgressHash(egress, overrides);

		return `https://${domain}/${appId}/${environmentId}/${resourceUploadId}/${entryPoint}/_hostname_${window.location.hostname}/_egressVersion_${egressHash}/`;
	}
	return `https://${domain}/${appId}/${environmentId}/${resourceUploadId}/${entryPoint}/_hostname_${window.location.hostname}/`;
};
