import { useCallback, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';
import { fg } from '@confluence/feature-gating';
import { useRouteActions } from '@confluence/route-manager';

const OPT_OUT_CACHE_EXPIRATION_SECONDS = 365 * 24 * 60 * 60; // 1 year

export const useNav4OptOut = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { loading, data } = useQuery(
		gql`
			query useNav4OptOutQuery {
				userPreferences {
					nav4OptOut
				}
			}
		`,
		{
			skip: !fg('confluence_nav_4') || !fg('confluence_nav_4_opt'),
		},
	);

	const [updateNav4OptOutServer] = useMutation(gql`
		mutation useNav4OptOutMutation($nav4OptOut: Boolean!) {
			updateUserPreferences(userPreferences: { nav4OptOut: $nav4OptOut }) {
				nav4OptOut
			}
		}
	`);

	const neverOptedOut = !loading && data?.userPreferences?.nav4OptOut === null;
	const { setQueryParams } = useRouteActions();
	const updateNav4OptOut = useCallback(
		async (newNav4OptOut: boolean) => {
			await updateNav4OptOutServer({ variables: { nav4OptOut: newNav4OptOut } });
			updateNav4OptOutCache(newNav4OptOut);

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'changed',
					actionSubject: 'navigationVersion',
					source: 'nav4OptOut',
					attributes: {
						originalNavVersion: newNav4OptOut ? '4' : '3',
						newNavVersion: newNav4OptOut ? '3' : '4',
					},
				},
			}).fire();

			if (newNav4OptOut && neverOptedOut) {
				// If this is the first time opting out, reload with query param to trigger Nav4OptOutSpotlight
				setQueryParams({ navOptOut: 'true' });
				window.location.reload();
			} else {
				window.location.reload();
			}
		},
		[updateNav4OptOutServer, createAnalyticsEvent, neverOptedOut, setQueryParams],
	);

	const optOutCache = getNav4OptOutCache();
	const optOutServer = Boolean(data?.userPreferences?.nav4OptOut);
	const nav4OptOut = fg('confluence_nav_4_opt') && (loading ? optOutCache : optOutServer);

	useEffect(() => {
		if (!loading && optOutServer !== optOutCache) {
			updateNav4OptOutCache(optOutServer);
		}
	}, [loading, optOutCache, optOutServer]);

	return {
		nav4OptOut,
		updateNav4OptOut,
	};
};

export const getNav4OptOutCache = () => {
	return localStorage?.getItem(keys.NAV4_OPT_OUT_CACHE) === 'true';
};

const updateNav4OptOutCache = (nav4OptOut: boolean) => {
	if (nav4OptOut) {
		localStorage.setItem(keys.NAV4_OPT_OUT_CACHE, true, OPT_OUT_CACHE_EXPIRATION_SECONDS);
	} else {
		localStorage.removeItem(keys.NAV4_OPT_OUT_CACHE);
	}
};
