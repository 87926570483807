import React, { lazy, useEffect } from 'react';
import {
	type Rendered,
	type ThreeLOPromptProps as PublicThreeLOPromptProps,
	type RenderFn,
} from '@atlassian/forge-ui-types';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import Button from '@atlaskit/button/new';
import { fg } from '@atlaskit/platform-feature-flags';
import { useAuth } from './useAuth';
import { AkButton } from '../UIKit1/button/Button';

const LegacySectionMessage = lazy(() => import('@atlaskit/section-message'));

const boxStyles = xcss({
	borderColor: 'color.border.warning',
	borderStyle: 'solid',
	borderWidth: 'border.width.indicator',
});

export type ThreeLOPromptProps = Rendered<PublicThreeLOPromptProps> &
	Dependencies & { onSuccess: () => Promise<void | (() => void)>; onInitialization?: () => void };

interface Dependencies {
	appName?: string;
	metadata?: {
		moduleType?: string;
	};
}

const ThreeLOPrompt = (props: ThreeLOPromptProps) => {
	const { loading, startAuth, promptMessage, buttonText } = useAuth(props);
	const { authUrl, onInitialization } = props;

	useEffect(() => {
		if (onInitialization) {
			onInitialization();
		}
	}, [onInitialization]);

	if (!authUrl || authUrl === '') {
		if (fg('fix-react-18-concurrent-upgrade-infinite-rendering')) {
			return (
				<SectionMessage appearance="error">
					<FormattedMessage {...messages.noAuthURLErrorMessage} />
				</SectionMessage>
			);
		}
		return (
			<LegacySectionMessage appearance="error">
				<FormattedMessage {...messages.noAuthURLErrorMessage} />
			</LegacySectionMessage>
		);
	}

	return (
		<React.Fragment>
			<Box
				onClick={(e: { stopPropagation: () => any }) => e.stopPropagation()}
				padding="space.250"
				xcss={boxStyles}
			>
				<Stack alignInline="start" space="space.250" grow="hug" testId="three-lo-prompt">
					<Box>{promptMessage}</Box>
					{fg('fix-react-18-concurrent-upgrade-infinite-rendering') ? (
						<Button onClick={startAuth} isLoading={loading}>
							{buttonText}
						</Button>
					) : (
						<AkButton onClick={startAuth} isLoading={loading}>
							{buttonText}
						</AkButton>
					)}
				</Stack>
			</Box>
		</React.Fragment>
	);
};

export default ThreeLOPrompt;

export const ThreeLOPromptRenderFn: RenderFn = ({ forgeDoc, dispatch }) => {
	const { message, authUrl, promptText } = forgeDoc.props as PublicThreeLOPromptProps;
	return (
		<ThreeLOPrompt
			message={message}
			authUrl={authUrl}
			promptText={promptText}
			onSuccess={() => dispatch({ type: 'render', extensionData: {} })}
		/>
	);
};

export const makeThreeLOPrompt = ({
	appName,
	onSuccess,
}: Dependencies & { onSuccess?: () => void }): RenderFn => {
	return function threeLOPrompt({ forgeDoc: { props }, dispatch }) {
		const { message, authUrl, promptText } = props as PublicThreeLOPromptProps;
		return (
			<ThreeLOPrompt
				message={message}
				authUrl={authUrl}
				promptText={promptText}
				appName={appName}
				onSuccess={() => {
					if (onSuccess) {
						onSuccess();
					}
					return dispatch({ type: 'render', extensionData: {} });
				}}
			/>
		);
	};
};

type ThreeLOPromptPropsForCustomUI = PublicThreeLOPromptProps &
	Dependencies & {
		onSuccess: () => Promise<void | (() => void)>;
		onInitialization?: () => void;
	};

export const makeThreeLOPromptForCustomUI = ({
	appName,
	onSuccess,
	message,
	onInitialization,
}: Dependencies & { onSuccess?: () => void; message?: string; onInitialization?: () => void }) => {
	return function threeLOPrompt(props: ThreeLOPromptPropsForCustomUI) {
		const { authUrl, promptText, onSuccess: onSuccessFromIframeComponent } = props;
		return (
			<ThreeLOPrompt
				message={message}
				authUrl={authUrl}
				promptText={promptText}
				appName={appName}
				onSuccess={() => {
					if (onSuccess) {
						onSuccess();
					}

					return Promise.resolve(onSuccessFromIframeComponent);
				}}
				onInitialization={onInitialization}
			/>
		);
	};
};

const messages = defineMessages({
	noAuthURLErrorMessage: {
		id: 'confluence.threeLOPrompt.no.authURL.error-message',
		defaultMessage: 'Consent URL not found. Please refresh the page and try again.',
		description:
			'This text is displayed when the URL that gives an app certain permissions is not present.',
	},
});
