import { preloadEditorData } from '@confluence/load-edit-page/entry-points/preloadEditorData';
import { preloadEditorOnboardingState } from '@confluence/onboarding-helpers/entry-points/preloadEditorOnboardingState';
import { preloadSpaceDetail } from '@confluence/space-utils/entry-points';
import { getPreloaderFnContext } from '@confluence/query-preloader-tools';
import type { RouteMatch } from '@confluence/route';
import { preloadDraft } from '@confluence/fabric-providers/entry-points/preloadDraft';
import { preloadBreadcrumbs } from '@confluence/breadcrumbs/entry-points/preloadBreadcrumbs';
import { preloadContentTypesHeader } from '@confluence/content-types-header/entry-points/preloadContentTypesHeader';
import { preloadClassificationContextForEditContent } from '@confluence/data-classification/entry-points/queries';
import { preloadMediaToken } from '@confluence/fabric-media-support/entry-points/preloadMediaToken';
import { preloadSuggestedTemplateQuery } from '@confluence/blank-page-quick-actions/entry-points/preloadSuggestedTemplateQuery';
import { fg } from '@confluence/feature-gating';

import { getIsEmbeddedConfluence } from './matchHelpers';
import { matchEditBlog } from './matchRoutes';
import { preloadNavigationTasks } from './preloadNavigationTasks';

export const preloadEditPageRoute = async (
	match: RouteMatch,
	_url: string,
	isTransition: boolean = false,
) => {
	const { userId, isLicensed, cloudId } = await getPreloaderFnContext();
	const tasks: Promise<any>[] = [];
	const { contentId, spaceKey } = match.params;

	const isBlog = Boolean(matchEditBlog(_url));

	const shouldPreloadMentionsProvider = Boolean(process.env.REACT_SSR);
	const isEmbeddedConfluence = getIsEmbeddedConfluence(match);
	const shouldPreloadNavigation = !isEmbeddedConfluence;

	tasks.push(
		preloadBreadcrumbs(spaceKey, contentId, false),
		preloadEditorData({
			spaceKey,
			userId,
			contentId,
			cloudId,
			preloadNativeCollabData: true,
			shouldPreloadMentionsProvider,
			shouldPreloadNativeEditorData: false,
			isBlog,
			isTransition,
		}),
	);

	if (fg('confluence_frontend_object_header')) {
		tasks.push(preloadContentTypesHeader(contentId, fg('confluence_frontend_object_header')));
	}

	if (spaceKey && fg('confluence_preload_blank_page_quick_actions')) {
		tasks.push(preloadSuggestedTemplateQuery({ spaceKey }));
	}

	if (isLicensed) {
		tasks.push(preloadEditorOnboardingState());
	}

	if (isTransition) {
		return Promise.all(tasks);
	}

	if (process.env.REACT_SSR) {
		// Navigation and page tree
		if (shouldPreloadNavigation) {
			tasks.push(...preloadNavigationTasks(spaceKey, contentId, isLicensed, isBlog));
		}

		tasks.push(
			preloadMediaToken(contentId),
			preloadClassificationContextForEditContent(contentId, spaceKey),
		);
	} else {
		tasks.push(preloadDraft(contentId));
	}

	tasks.push(preloadSpaceDetail(spaceKey));

	return Promise.all(tasks);
};
