import type { EditorPluginAIProvider } from '../types/types';

// Note: The intention of this function was to;
// 1. provide a nice DX around ensuring consumers are passing the right things
// ie.
// ```diff
// - const editorPluginAIProvider: EditorPluginAIProvider = {/** */}
// + const editorPluginAIProvider = createEditorPluginAIProvider({/** */})
// ```
// 2. Provide an easy to follow/find seam for defaults
// ie. defaults could have been done deeper on consumption -- but it would be
// harder for consumers to track that down and see the defaults occurring.
export function createEditorPluginAIProvider({
	baseGenerate,
	configItemWithOptions,
	disableQuickInsert,
	getSelectionToolbarDropdowns,
	disableAISelectionToolbar,
	getFetchCustomHeaders,
	getChannelVariables,
	onDocChangeByAgent,
	onAIProviderChanged,
	generativeAIApiUrl,
	hidePrimaryToolbarButton,
	product,
	onExperienceEvent,
	AIButtonWrapper,
	handleFeedbackSubmission,
	PromptEditor,
	disableInterrogation,
	aiUsageDisclaimer,
	actionSideEffects,
	actionOverrides,
	isRovoEnabled,
	providers,
	aiLifeCycleDynamicAttributesGetter,
	auditLogSettings,
	getAdditionalProps,
}: EditorPluginAIProvider): EditorPluginAIProvider {
	return {
		baseGenerate,
		configItemWithOptions,
		disableQuickInsert,
		getSelectionToolbarDropdowns,
		disableAISelectionToolbar,
		disableInterrogation,
		getFetchCustomHeaders,
		getChannelVariables,
		onDocChangeByAgent,
		onAIProviderChanged,
		generativeAIApiUrl,
		hidePrimaryToolbarButton,
		product,
		onExperienceEvent,
		AIButtonWrapper,
		handleFeedbackSubmission,
		PromptEditor,
		aiUsageDisclaimer,
		actionSideEffects,
		actionOverrides,
		isRovoEnabled,
		providers,
		aiLifeCycleDynamicAttributesGetter,
		auditLogSettings,
		getAdditionalProps,
	};
}
