import type { AdvancedPromptValue } from '../../prebuilt/config-items/config-items';

import type { AdvancedPromptAnalytics } from './analyticsFlowTypes';

export function createAdvancedPromptAnalytics(
	advancedPromptValue: AdvancedPromptValue | undefined,
): AdvancedPromptAnalytics | null {
	if (!advancedPromptValue) {
		return null;
	}

	return {
		taskLength:
			typeof advancedPromptValue.task === 'string'
				? advancedPromptValue.task.length
				: advancedPromptValue.task.text?.length ?? 0,
		instructionsLength:
			typeof advancedPromptValue.instructions === 'string'
				? advancedPromptValue.instructions.length
				: advancedPromptValue.instructions.text?.length ?? 0,
		rulesLength:
			typeof advancedPromptValue.rules === 'string'
				? advancedPromptValue.rules.length
				: advancedPromptValue.rules.text?.length ?? 0,
		tone: typeof advancedPromptValue.tone === 'string' ? advancedPromptValue.tone : '',
		creativity:
			typeof advancedPromptValue.creativity === 'string' ? advancedPromptValue.creativity : '',
	};
}
