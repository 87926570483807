import React from 'react';

import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { useRovoEntitlement } from '@atlassian/conversation-assistant-entitlement';

import { updateEnabled } from '../../../pm-plugins/rovo-agents/commands';
import { type EditorPluginAIProvider } from '../../../types/types';

export const useRovoEnabled = ({
	editorPluginAIProvider,
}: {
	editorPluginAIProvider: EditorPluginAIProvider;
}): boolean => {
	const { isRovoEnabled } = useRovoEntitlement();
	return (
		!!editorPluginAIProvider.isRovoEnabled &&
		isRovoEnabled &&
		typeof editorPluginAIProvider?.getChannelVariables === 'function'
	);
};

interface RovoEnabledProps {
	editorView: EditorView;
	children: React.ReactNode;
	editorPluginAIProvider: EditorPluginAIProvider;
}

/**
 * This component both:
 * - checks whether Rovo is enabled (flag + entitlement checks) before rendering its children
 * - syncs rovo plugin state with the current evaluated 'enabled' state
 */
export const RovoEnabled = (props: RovoEnabledProps) => {
	const { editorView, children } = props;
	const enabled = useRovoEnabled({ editorPluginAIProvider: props.editorPluginAIProvider });

	React.useEffect(() => {
		updateEnabled(enabled)(editorView.state, editorView.dispatch);
	}, [editorView.dispatch, editorView.state, enabled]);

	return enabled ? <>{children}</> : null;
};
