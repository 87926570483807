import React from 'react';
import type { Dispatch, ForgeDoc, ForgeExtensionPoints, RenderFn } from '@atlassian/forge-ui-types';
import { uiKit1Components } from './defaultComponents';
import { Root, Fragment } from '../components/UIKit/internalComponents';
import { type ComponentMap, type ModalExtension } from './util';
import { RendererNext } from './RendererNextComponent';
import { importMapMemo } from '../components/UIKit/importMap';
import { importMapChartsMemo } from '../components/UIKit-charts/importMapCharts';
import { ThreeLOPromptRenderFn } from '../components/threeLOPrompt/threeLOPrompt';
import { ComponentError } from '../error-boundary/ComponentErrorBoundary';
import { Strong } from '../components/UIKit/markup';
import { fg } from '@atlaskit/platform-feature-flags';

export const validComponentsHardcoded = new Set([
	'Badge',
	'Bleed',
	'Box',
	'Button',
	'ButtonGroup',
	'Calendar',
	'Checkbox',
	'CheckboxGroup',
	'Code',
	'CodeBlock',
	'DatePicker',
	'DynamicTable',
	'EmptyState',
	'ErrorMessage',
	'Flex',
	'Form',
	'FormFooter',
	'FormHeader',
	'FormSection',
	'Grid',
	'Heading',
	'HelperMessage',
	'Icon',
	'Inline',
	'InlineEdit',
	'Label',
	'LinkButton',
	'List',
	'ListItem',
	'LoadingButton',
	'Lozenge',
	'Modal',
	'ModalBody',
	'ModalFooter',
	'ModalHeader',
	'ModalTitle',
	'ModalTransition',
	'Popup',
	'ProgressBar',
	'ProgressTracker',
	'Radio',
	'RadioGroup',
	'Range',
	'RequiredAsterisk',
	'SectionMessage',
	'SectionMessageAction',
	'Select',
	'Spinner',
	'Stack',
	'Tab',
	'TabList',
	'TabPanel',
	'Tabs',
	'Tag',
	'TagGroup',
	'TextArea',
	'Textfield',
	'TimePicker',
	'Toggle',
	'Tooltip',
	'ValidMessage',
	'AdfRenderer',
	'Image',
	'Link',
	'Text',
	'Strong',
	'BarChart',
	'StackBarChart',
	'HorizontalStackBarChart',
	'HorizontalBarChart',
	'LineChart',
	'SingleValueChart',
	'PieChart',
	'String',
	'Em',
	'Strike',
	'Root',
	'Fragment',
	'ThreeLOPrompt',
	'User',
	'UserGroup',
	'UserPicker',
	'Frame',
	'ContentWrapper',
	'ValidationMessage',
	'ButtonSet',
	'DateLozenge',
	'ErrorPanel',
	'FormCondition',
	'InlineDialog',
	'ModalDialog',
	'StatusLozenge',
	'Table',
	'TextField',
	'View',
]);

const rendererNextComponents: ComponentMap = {
	ThreeLOPrompt: ThreeLOPromptRenderFn,
};

// This was extracted from RendererNext Component due to UnknownComponent Error being
// incompatible with running in a server-side context.
const customRenderErrorFn = (forgeDocType: string) => {
	// Buckets errors into two kinds, ComponentError - component errors we want to report on e.g. <Button/> vs ones we don't e.g. <Buton/> which will only show up in the users console
	if (validComponentsHardcoded.has(forgeDocType)) {
		throw new ComponentError(`Error rendering app - component ${forgeDocType}.`, forgeDocType);
	} else {
		throw new Error(`Error rendering app - encountered unknown component ${forgeDocType}.`);
	}
};

type Props = {
	/* ForgeDoc to be rendered */
	forgeDoc?: ForgeDoc;
	/* Map of component types to render functions */
	components?: (defaults: ComponentMap) => ComponentMap;
	// defaultComponents: ComponentMap;
	/* Function used by components to dispatch effects */
	dispatch: Dispatch;
	/* Error message to show the user. Set when an unexpected client-side error happens. */
	error?: string;
	/* Whether a dispatched effect is pending. Not included in function due to server side render*/
	loading?: boolean;
	/* Replace the default spinner with a custom loading component. */
	loadingComponent?: React.ReactNode;
	/* Object that stores all necessary properties for a Modal Extension */
	modalExtension?: ModalExtension;
	/* Contains the module type of the extension */
	extensionType?: ForgeExtensionPoints;
	/* Indicates whether a CSUIKit app is being rendered*/
	isNative?: boolean;
};

export const RendererNextLatest = (props: Props) => {
	const uiKitComponents = Object.keys(importMapMemo).reduce((acc, key) => {
		const Component = importMapMemo[key];
		return {
			...acc,
			[key]: ((args) => <Component {...args} key={args.forgeDoc.key} />) as RenderFn,
		};
	}, {});

	const uiKitChartsComponents = Object.keys(importMapChartsMemo).reduce((acc, key) => {
		const Component = importMapChartsMemo[key];
		return {
			...acc,
			[key]: ((args) => <Component {...args} key={args.forgeDoc.key} />) as RenderFn,
		};
	}, {});

	const components = {
		...uiKitComponents,
		...uiKitChartsComponents,
		String: uiKit1Components.String,
		Em: uiKit1Components.Em,
		Strong: fg('forge-ui-kit-fix-strong-element-size-for-macro') ? Strong : uiKit1Components.Strong,
		Strike: uiKit1Components.Strike,
		Root,
		Fragment,
	};

	const combinedComponents = {
		...components,
		...rendererNextComponents,
	};

	return (
		<RendererNext
			{...props}
			components={props.components ? props.components(combinedComponents) : combinedComponents}
			customRenderErrorFn={customRenderErrorFn}
		/>
	);
};

export const RendererNextLegacy = (props: Props) => {
	const combinedComponents = {
		...uiKit1Components,
		...rendererNextComponents,
	};

	return (
		<RendererNext
			{...props}
			components={props.components ? props.components(combinedComponents) : combinedComponents}
			customRenderErrorFn={customRenderErrorFn}
		/>
	);
};
