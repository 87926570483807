import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ModalDialog, { ModalBody, ModalTransition, ModalHeader } from '@atlaskit/modal-dialog';
import { Text, Stack } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';

import type { RoleNodes } from '../../roles-list/useRoleList';

import { EditConfirmationFooter } from './EditConfirmationFooter';
import { EditConfirmationResultSelection } from './EditConfirmationResultSelection';

const i18n = defineMessages({
	title: {
		id: 'default-space-permissions.roles-management.edit-confirmation-modal.title',
		defaultMessage: 'Edit role {roleName}?',
		description:
			'Title of a dialog for editing a custom role, it will have the role name interpolated',
	},
	body: {
		id: 'default-space-permissions.roles-management.edit-confirmation-modal.body',
		defaultMessage:
			'Changing these permissions will adjust permissions for everyone who has been assigned this role. <b>This can’t be undone.</b>',
		description:
			'Body of a dialog for editing a custom role with a warning about the consequences of the action',
	},
});

export type EditResultSelectionType = 'modify-permissions' | 'reassign-role';

export const EditConfirmationModal = ({
	roleId,
	roleName,
	onCancel,
	onConfirm,
	allRoles,
}: {
	roleId: string;
	roleName: string;
	onCancel: () => void;
	onConfirm: () => void;
	allRoles: RoleNodes;
}) => {
	const { formatMessage } = useIntl();
	const [editResult, setEditResult] = useState<EditResultSelectionType>('modify-permissions');

	return (
		<ModalTransition>
			<ModalDialog onClose={onCancel}>
				<ModalHeader>
					<Heading size="large">{formatMessage(i18n.title, { roleName })}</Heading>
				</ModalHeader>
				<ModalBody>
					<Stack space="space.200">
						<Text as="p">
							{formatMessage(i18n.body, {
								b: (text: string) => <b>{text}</b>,
							})}
						</Text>
						<EditConfirmationResultSelection
							editResult={editResult}
							setEditResult={setEditResult}
							allRoles={allRoles}
							roleId={roleId}
						/>
					</Stack>
				</ModalBody>
				<EditConfirmationFooter onCancel={onCancel} onConfirm={onConfirm} />
			</ModalDialog>
		</ModalTransition>
	);
};
