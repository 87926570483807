import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
};
/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::f833f139785528852d566fa3485452de>>
 * @codegenId #modal-transition
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen modal-transition
 * @codegenDependency ../../../../../../../design-system/motion/src/entering/exiting-persistence.tsx <<SignedSource::89d089fcd6f15c3f0d9e2134b0c9484f>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/modal-transition.tsx <<SignedSource::bae2a052900c29064455b9589ca79a0e>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ModalTransition as PlatformModalTransition } from '@atlaskit/modal-dialog';

type PlatformModalTransitionProps = React.ComponentProps<typeof PlatformModalTransition>;

export type ModalTransitionProps = Pick<
  PlatformModalTransitionProps,
  'children'
>;

/**
 * A modal dialog displays content that requires user interaction, in a layer above the page.
 */
export const ModalTransition = (props: Parameters<RenderFn>[0]) => {
  const {
  } = props.forgeDoc.props as ModalTransitionProps;
  return (
    <PlatformModalTransition
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
    />
  );
};
/**
 * @codegenEnd
 */
