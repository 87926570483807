import type { SpacePermission } from '../model/space-roles-types';

type PermissionItem = {
	id: string;
	displayName: string;
	priority: number;
	description: string;
	isAllowed: boolean;
	isDisabled: boolean;
};

export type NormalizedCheckboxesType = {
	group: {
		name: string;
		priority: number;
		checkboxes: PermissionItem[];
	};
};

export const getNormalizedPermissionItems = (
	permissions: string[] | undefined,
	allSpacePermissions: SpacePermission[],
): Record<number, NormalizedCheckboxesType> => {
	// Permission groups are sorted here, then checkboxes of permissions are sorted below
	const sortedSpacePermissions = [...allSpacePermissions].sort(
		(a, b) => a.groupPriority - b.groupPriority,
	);

	return Object.values(sortedSpacePermissions).reduce(
		(acc, permission) => {
			const isAllowed = Boolean(permissions?.includes(permission.key));
			const checkbox: PermissionItem = {
				id: permission.key,
				displayName: permission.actionName,
				priority: permission.priority,
				description: permission.description || '',
				isAllowed,
				isDisabled: false,
			};
			const group = {
				groupName: permission.groupName,
				groupPriority: permission.groupPriority,
			};
			if (!acc[group.groupName]) {
				acc[group.groupName] = {
					group: {
						name: group.groupName,
						priority: group.groupPriority,
						checkboxes: [],
					},
				};
			}
			acc[group.groupName].group.checkboxes.push(checkbox);
			acc[group.groupName].group.checkboxes.sort(
				(a: PermissionItem, b: PermissionItem) => a.priority - b.priority,
			);
			return acc;
		},
		{} as Record<number, NormalizedCheckboxesType>,
	);
};

export const buildPermissionDictionary = (
	allowedPermissions: string[],
): Record<string, boolean> => {
	return allowedPermissions.reduce(
		(acc, permission) => {
			acc[permission] = true;
			return acc;
		},
		{} as Record<string, boolean>,
	);
};
