import type { IntlShape, MessageDescriptor } from 'react-intl-next';

import { type IconButtonProps } from '@atlaskit/button/new';
import { type Keymap } from '@atlaskit/editor-common/keymaps';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { Fragment, NodeType } from '@atlaskit/editor-prosemirror/model';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown/serializer';
import type { MentionMap } from '@atlassian/ai-model-io/utils/mention-map';
import type { ContentStatistics } from '@atlassian/ai-model-io/utils/prosemirror-content-statistics';
import type { PromptEditor } from '@atlassian/generative-ai-modal/screens/UserInputCommandPalette';
import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import type { BackendModel, IntentSchemaId } from '../../types/prompt-request-types';
import type { RovoPublish } from '../../types/types';
import type { IconShownAt } from '../../ui/icons/prompt/types';
import { type ResponseObject } from '../../utils/request/types';

import { type CONFIG_ITEM_KEYS } from './config-item-keys';
import type { TranslationLanguageLabels } from './prompts/translate/types';

export type ActionAppearance = 'primary' | 'secondary' | 'cancel';

export type StatusLozengeType = 'new' | 'beta';

/**
 * For empty selections, the start and end will be the same
 */
export type Positions = [start: number, end: number];

export type SelectionType = 'range' | 'empty';

/**
 * This is a map of ids to node details, which is used when mapping markdown back to pm.
 */
export type UpdateIdMap = (_: { idMap: IDMap }) => void;

export type EditorPluginAIConfigItemMarkdownAction = {
	type: 'markdown';
	/**
	 * Title of action which is presented on the preview screen.
	 */
	title: MessageDescriptor;
	appearance: ActionAppearance;
	isRovoAgentAction?: boolean;
	// Note: as we flesh out the experience -- we'll likely want
	// to either have actions return a Transaction -- or some other type using
	// utils we provide.
	run: (data: {
		editorView: EditorView;
		positions: Positions;
		markdown: string;
		pmFragment: Fragment;
		triggeredFor?: NodeType;
	}) => void;
	getDisabledState?: ({
		markdown,
		formatMessage,
	}: {
		markdown: string;
		formatMessage: IntlShape['formatMessage'];
	}) => {
		isDisabled: boolean;
		tooltip?: string;
	};
};

export type EditorPluginAIConfigItemAgentAction = {
	type: 'agent';
	title: MessageDescriptor;
	appearance: ActionAppearance;
	/** An optional shortcut keymap for action. */
	shortcut?: Keymap;
	run: (data: { channelId?: string; publish?: RovoPublish }) => void;
	// Used when action buttons layout is in 'compact' mode
	icon: IconButtonProps['icon'];
	// Whether to preserve the AI highlight pseudo selection as
	// a true ProseMirror editor selection after the action function
	// executes
	preserveEditorSelectionOnComplete?: boolean;
};

type Dialogue = { content: string };

export type EditorPluginAIConfigItemGenericChatAction = Omit<
	EditorPluginAIConfigItemAgentAction,
	'run' | 'type'
> & {
	type: 'generic-chat';
	run: (data: {
		publish?: RovoPublish;
		name: string;
		dialogues: Array<{ human_message: Dialogue; agent_message: Dialogue }>;
	}) => void;
};

export type EditorPluginAIConfigItemAction =
	| EditorPluginAIConfigItemMarkdownAction
	| EditorPluginAIConfigItemAgentAction
	| EditorPluginAIConfigItemGenericChatAction;

export type ConfigItemInitialContext<S extends SelectionType = SelectionType> = S extends 'range'
	? InitialContextSelectionRange
	: InitialContextSelectionEmpty;

export type GetInitialContext = ({
	editorView,
	positions,
	intl,
}: {
	editorView: EditorView;
	positions: Positions;
	intl: IntlShape;
	intentSchemaId?: IntentSchemaId;
	updateIdMap: UpdateIdMap;
	mentionMap?: MentionMap;
	additionalIntentSchemaIds?: IntentSchemaId[];
}) => ConfigItemInitialContext<SelectionType>;

/** A context from analytics. */
export type AnalyticsContext = {
	/**
	 * The unique identifier for the current user journey.
	 *
	 * This identifier is used to track the user journey in the analytics flow.
	 */
	aiSessionId: string;
};

export type InitialContextSelectionEmpty = {
	document: string;
	// make reading selection easier for typescript
	selection?: undefined;
	userLocale: string;
	intentSchemaId: IntentSchemaId;
	contentStatistics?: ContentStatistics;
	targetTranslationLanguage?: TranslationLanguageLabels;
};

type InitialContextSelectionRange = {
	document?: string;
	selection: string;
	userLocale: string;
	intentSchemaId: IntentSchemaId;
	contentStatistics?: ContentStatistics;
	targetTranslationLanguage?: TranslationLanguageLabels;
};

/**
 * type guard to detect if the initial context is for range selection
 * based on selection prop availability
 */
export function isInitialContextSelectionRange(
	// Ignored via go/ees005
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: ConfigItemInitialContext<any>,
): value is InitialContextSelectionRange {
	return 'selection' in value && typeof value.selection === 'string';
}

export type AdvancedPromptFieldAdfValue = {
	text: string;
	adf: JSONDocNode;
};
export type AdvancedPromptValue = {
	[field: string]: string | AdvancedPromptFieldAdfValue;
};

export type AdvancedPromptConfig<Value extends AdvancedPromptValue = AdvancedPromptValue> = {
	/**
	 * A component that will allow to create a complex prompt builder for the config item.
	 * This is used for the advanced prompt config items.
	 */
	promptBuilder: React.ComponentType<EditorPluginAIPromptBuilderProps<Value>>;
	/**
	 * A function that will be used to build the history message for the config item.
	 *
	 * If not provided, the history message should have the format:
	 *
	 * ```markdown
	 * ### {fieldKey1}
	 * {field1Value}
	 *
	 * ### {field2Key}
	 * {field2Value}
	 * ```
	 */
	historyMessageBuilder?: (
		formatMessage: IntlShape['formatMessage'],
		advancedPromptValue: Value,
	) => string;
};

/** Props for the component that builds the prompt for the user. */
export type EditorPluginAIPromptBuilderProps<
	PromptValue extends AdvancedPromptValue = AdvancedPromptValue,
> = {
	promptEditor: PromptEditor;
	advancedPromptValue: PromptValue | undefined;
	onSubmit: (advancedPromptValue: PromptValue) => void;
};

/**
 * `EditorPluginAIConfigItem`s are used by EditorPluginAI to provide AI experiences.
 */
export type EditorPluginAIConfigItem = {
	// The key is used to identify the config item type and will be send to identify the item in analytics
	key: CONFIG_ITEM_KEYS;

	/** Appears in;
	 * - when the user searches for ai actions and gets a list (quick insert)
	 * - when the user highlights content and gets a list of ai actions (highlight toolbar)
	 * - when reviewing the generated content
	 */
	title: MessageDescriptor;

	/** Appears in;
	 * - when the user searches for ai actions and gets a list (quick insert)
	 */
	description: MessageDescriptor;

	/**
	 * optional icon which is shown;
	 * - in quick insert listing
	 * - and on review screen
	 * - prompt suggestions list
	 */
	icon?: ({
		formatMessage,
		shownAt,
	}: {
		formatMessage: IntlShape['formatMessage'];
		shownAt: IconShownAt;
	}) => React.ReactElement;

	/**
	 * This label is presented in the custom prompt form,
	 * as a prefix to the prompt input.
	 * If not present -- no user input prompt is collected
	 * for the prompt, and we move it straight into loading
	 * with any initial context it is set up to collect.
	 * (i.e. the 'Brainstorm' refinement tag)
	 */
	promptLabel?: MessageDescriptor;

	/**
	 * The prompt hint is used on the custom prompt screen
	 * to guide the user to expected prompt content.
	 * ie. for a promptLabel of "brainstorm" the promptHint
	 * would be "Tell me the topic...""
	 */
	promptHint?: MessageDescriptor;

	/**
	 * By default, the whole document is highlighted when the selection is empty.
	 * You can turn this off by setting this to true.
	 */
	doNotHighlightDocWhenSelectionIsEmpty?: boolean;

	/**
	 * If `true`, the config item will be shown in the Refine dropdown in the Preview screen.
	 */
	showInRefineDropdown?: boolean;

	// Quick insert priority
	quickInsertPriority?: number;

	/**
	 * This option allows the product to dictate if the config item is new or in beta, or other new status
	 */
	statusLozengeType?: StatusLozengeType;

	/**
	 * Used in the generative-ai-modal to group related config items under a single config item
	 */
	nestingConfig?: {
		parentTestId?: string;
		parentTitle: MessageDescriptor;
		shortTitle: MessageDescriptor;
		icon?: ({
			formatMessage,
			shownAt,
		}: {
			formatMessage: IntlShape['formatMessage'];
			shownAt: IconShownAt;
		}) => React.ReactElement;
	};

	/**
	 * The experience id to be used for the config item
	 */
	experienceId?: string;

	/**
	 * The endpoint to be used for the config item
	 */
	endpoint?: string;

	/**
	 * The agent id to be used for the config item
	 */
	agentId?: string;

	/**
	 * The intent schema id to be used for the config item
	 * latestPromptResponse is from AIExperienceMachineContext
	 */
	intentSchemaId: IntentSchemaId | ((_: { latestPromptResponse?: string }) => IntentSchemaId);

	/** Configuration for the advanced prompt that will be used instead of the default prompt input. */
	// The `any` is ok because we are supporting any advanced prompt value by streaming service. We non need a type here.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	advancedPrompt?: AdvancedPromptConfig<any>;

	/**
	 * for translate prompts, the language to translate to
	 */
	lang?: string;

	/**
	 * always need no doc and selection for the config item
	 */
	alwaysNoDocAndSelection?: boolean;

	/**
	 * always need doc for the config item
	 */
	alwaysNeedDoc?: boolean;

	/**
	 * request payload for the config item, used for
	 * - Jira Improve description
	 */
	getRequestPayload?: (_: {
		aiSessionId?: string;
		locale: string;
		isEmpty: boolean;
		doc?: string;
		selection?: string;
	}) => Record<string, unknown>;

	actions: {
		/**
		 * List of actions which can be taken with the prompt request response when there is no selection
		 */
		empty?: EditorPluginAIConfigItemAction[];
		/**
		 * List of actions which can be taken with the prompt request response when there is a selection
		 */
		selection?: EditorPluginAIConfigItemAction[];
	};

	/**
	 * List of secondary which can be taken with the prompt request response
	 * (for example with Rovo agents, this might be to 'Continue to Chat').
	 * These are left-aligned and grouped with other core secondary actions such as copy, retry, etc.
	 */
	secondaryActions?: EditorPluginAIConfigItemAgentAction[];

	/**
	 * BackendModel to be used for the config item
	 * Useful for running experiments to test different models
	 *
	 * To be passed into triggerPromptRequest - but placed out here to be accessed easily
	 * for other uses e.g. Feedback Collection
	 *
	 * isInterrogate is only passed in so that the interrogate prompt can be
	 * differentiated for turning on / off backend experiments
	 */
	getBackendModel?: (isInterrogate?: boolean) => BackendModel;

	/**
	 * This option allows the product to hide interrogation flow on the preview screen per config item
	 */
	disableInterrogation?: () => boolean;

	/**
	 * The Rovo Agent associated with this config item.
	 */
	agent?: EditorAgent;

	/**
	 * the pre-configured response for the config item, used for
	 * - Elevate free-generate
	 */
	response?: ResponseObject;

	/**
	 * This is used to determine if the user has the ability to toggle the page knowledge
	 * for free generate prompts which will send the full document in the request
	 */
	canTogglePageKnowledge?: boolean;
};

type EditorPluginAIConfigItemEmptyTrigger = {
	/**
	 * whether the prompt can be shown when there is no selection, default is true
	 */
	enabled?: boolean;
	/**
	 * when empty is enabled, this can be used to specify the min size of the document.
	 * default is 0, which means when there is no content, the config item will be shown
	 */
	docMinSize?: number;
	/**
	 * when empty is enabled, this can be used to specify the max size of the document
	 */
	docMaxSize?: number;
};

type EditorPluginAIConfigItemSelectionTrigger = {
	/**
	 * whether the prompt can be shown when there is a selection, default is true
	 */
	enabled?: boolean;
	/**
	 * when selection is true, this can be used to specify the min size of the selection
	 */
	selectionMinSize?: number;
	/**
	 * when selection is true, this can be used to specify the max size of the selection
	 */
	selectionMaxSize?: number;
};

type EditorPluginAIConfigItemTriggers = {
	/**
	 * whether the prompt can be shown when there is no selection
	 */
	empty?: boolean | EditorPluginAIConfigItemEmptyTrigger;

	/**
	 * whether the prompt can be shown when there is a selection
	 */
	selection?: boolean | EditorPluginAIConfigItemSelectionTrigger;

	/**
	 * whether the prompt can be shown on specific nodes
	 */
	// nodes?: string[];

	/**
	 * custom canShow condition for the config item. If provided, will be used to determine if the config item should be shown
	 * return true if the config item should be shown, false otherwise
	 */
	canShow?: (_: { editorView: EditorView; docSize: number; selectionSize: number }) => boolean;
};

export type EditorPluginAIConfigItemOptions = {
	// we can add other options for the config item here in the future
	triggers: EditorPluginAIConfigItemTriggers;
};

export type EditorPluginAIConfigItemWithOptions = {
	config: EditorPluginAIConfigItem;
} & EditorPluginAIConfigItemOptions;

export function isEditorPluginAIConfigItemWithOptions(
	item: EditorPluginAIConfigItemWithOptions | null | undefined | false,
): item is EditorPluginAIConfigItemWithOptions {
	return !!item;
}

export function getEditorPluginAIEmptyTrigger(
	empty: EditorPluginAIConfigItemTriggers['empty'],
): EditorPluginAIConfigItemEmptyTrigger {
	if (typeof empty === 'undefined') {
		return { enabled: false };
	}
	if (typeof empty === 'boolean') {
		return { enabled: empty };
	}

	const { enabled } = empty;
	return { ...empty, enabled: enabled ?? true };
}

export function getEditorPluginAISelectionTrigger(
	selection: EditorPluginAIConfigItemTriggers['selection'],
): EditorPluginAIConfigItemSelectionTrigger {
	if (typeof selection === 'undefined') {
		return { enabled: false };
	}
	if (typeof selection === 'boolean') {
		return { enabled: selection };
	}

	const { enabled } = selection;
	return { ...selection, enabled: enabled ?? true };
}

export function canShowEditorPluginAIConfigItem({
	editorView,
	positions,
	isEmpty,
	item,
}: {
	editorView: EditorView;
	positions: Positions;
	isEmpty: boolean;
	item: EditorPluginAIConfigItemWithOptions;
}): boolean {
	const { triggers } = item;

	const doc = editorView.state.doc;
	// Size of the doc with a single empty paragraph is 4
	const docSize = doc.nodeSize - 4;
	const selectionSize = positions[1] - positions[0];

	if (triggers.canShow && !triggers.canShow({ editorView, docSize, selectionSize })) {
		return false;
	}

	const emptyOptions = getEditorPluginAIEmptyTrigger(triggers.empty);
	const emptyEnabled = emptyOptions.enabled ?? true;
	const docMinSize = emptyOptions.docMinSize ?? 0;
	const docMaxSize = emptyOptions.docMaxSize ?? Number.MAX_SAFE_INTEGER;

	const selectionOptions = getEditorPluginAISelectionTrigger(triggers.selection);
	const selectionEnabled = selectionOptions.enabled ?? true;
	const selectionMinSize = selectionOptions.selectionMinSize ?? 1;
	const selectionMaxSize = selectionOptions.selectionMaxSize ?? Number.MAX_SAFE_INTEGER;

	if (isEmpty) {
		if (!emptyEnabled || docSize < docMinSize || docSize > docMaxSize) {
			return false;
		}
	} else {
		if (!selectionEnabled || selectionSize < selectionMinSize || selectionSize > selectionMaxSize) {
			return false;
		}
	}

	return true;
}

export function getEditorPluginAIConfigItemActions({
	isEmpty,
	configItem,
}: {
	isEmpty: boolean;
	configItem?: EditorPluginAIConfigItem;
}): EditorPluginAIConfigItemAction[] {
	if (!configItem) {
		return [];
	}

	const actions = (isEmpty ? configItem.actions.empty : configItem.actions.selection) ?? [];
	return actions;
}

export function getVisibleEditorPluginAIConfigItems({
	configItemWithOptions,
	editorView,
	positions,
	isEmpty,
}: {
	configItemWithOptions: EditorPluginAIConfigItemWithOptions[];
	editorView: EditorView;
	positions: Positions;
	isEmpty: boolean;
}): EditorPluginAIConfigItem[] {
	const arr: EditorPluginAIConfigItem[] = [];

	configItemWithOptions.forEach((item) => {
		if (!item) {
			return;
		}

		if (
			canShowEditorPluginAIConfigItem({
				editorView,
				positions,
				isEmpty,
				item,
			})
		) {
			arr.push(item.config);
		}
	});

	return arr;
}

/**
 * This function is used to get all the config items from the configItems array without checking if they can be shown
 */
export function getAllEditorPluginAIConfigItems({
	configItemWithOptions,
}: {
	configItemWithOptions: EditorPluginAIConfigItemWithOptions[];
}): EditorPluginAIConfigItem[] {
	const arr: EditorPluginAIConfigItem[] = [];

	configItemWithOptions.forEach((item) => {
		if (!item) {
			return;
		}
		arr.push(item.config);
	});

	return arr;
}

export function getTranslatedItemTitle(
	configItem: EditorPluginAIConfigItem,
	formatMessage: IntlShape['formatMessage'],
) {
	// for agents return the saved agent name in the same language as it was saved
	if (configItem.agent) {
		const presetTitle = `@${configItem.agent.name}`;
		return presetTitle || formatMessage(configItem.title);
	}

	// adding beta label for the translation prompts
	if (configItem.key.startsWith('Translate to')) {
		return formatMessage(configItem.title);
	}

	// for all other items return the i18n-ed title
	return formatMessage(configItem.title);
}

/**
 * use this function get the translated prompt label in the ui components
 */
export function getTranslatedPromptLabel(
	configItem: EditorPluginAIConfigItem,
	formatMessage: IntlShape['formatMessage'],
) {
	if (configItem.agent) {
		// for agents return the saved agent name in the same language as it was saved
		return `@${configItem.agent.name}`;
	}

	// intentionally fall back to undefined as this value is checked to verify if we need to show the prompt input
	return configItem.promptLabel ? formatMessage(configItem.promptLabel) : undefined;
}
