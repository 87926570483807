import { query } from '@confluence/query-preloader-tools';

import { TopNavigationQuery } from './TopNavigationQuery.graphql';
import { fg } from '@confluence/feature-gating';

export function preloadTopNavigation() {
	const topNavigationPromises = [
		query({
			query: TopNavigationQuery,
			variables: { includeEmails: fg('confluence_dev_tools_from_flag_icon') },
		}),
	];

	return Promise.all(topNavigationPromises);
}
