import { ApolloClient } from 'apollo-client';
import type { ApolloLink } from 'apollo-link';
import { from, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { UFOLoggerLink } from '@atlassian/ufo-apollo-log/link';

import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';
import { cfetch, NoNetworkError, causedByAbortError } from '@confluence/network';

import { deleteExtensionsLink } from './links/DeleteExtensionsLink';
import { NetworkStatus, networkStatusLink, writeNetworkStatus } from './links/NetworkStatusLink';
import { externalShareLink } from './links/ExternalShareLink';
import { addSuperAdminDirectiveLink } from './links/SuperAdminLink';
import { queryOverridesLink } from './links/QueryOverridesLink';
import { networkErrorRetryLink } from './links/NetworkErrorRetryLink';
import { createOnErrorLink } from './links/OnErrorLink';
import { statusCodeRetryLink } from './links/StatusCodeRetryLink';
import { graphqlSyntheticErrorGenerator } from './links/DevOnlyGraphqlErrorGenerator';
import { ExperimentalLink } from './links/ExperimentalLink';
import { webSocketLink } from './links/WebSocketLink';
import { SSRLink } from './links/SSRLink';
import { SSRTestLink } from './links/SSRTestLink';
import { fragmentMatcher } from './fragmentMatcher';
import { dataIdFromObject } from './dataIdFromObject';
import { cacheRedirects } from './cacheRedirects';
import { isExperimental, isWS } from './graphqlAssertions';
import { ClientSchema } from './types/ClientSchema.graphqls';
import { createWrappedFetch } from './createWrappedFetch';

const URI = '/cgraphql';
const MAX_CONCURRENCY_FOR_THROTTLED = 8;
const wrappedFetch = createWrappedFetch(true);

export function resetApolloStats() {
	window.__APOLLO_STATS__ = {
		// Used in node_modules/apollo-client/bundle.esm.js
		broadcast: {},
	};
}

const createCache = () => {
	return new InMemoryCache({
		fragmentMatcher,
		dataIdFromObject,
		cacheRedirects,
	});
};

/**
 * Override fetch function used in terminating (http batch) link, to throttle network requests.
 * Concurrent network requests are limited based on MAX_CONCURRENCY_FOR_THROTTLED constant.
 *
 * Realistically, this will be used when there are a large volume of queries are triggered together, upon page load. Therefore,
 * resolving requests in approximate order is sufficient.
 */
const requestPool: Promise<Response>[] = [];
const throttledFetch = (uri: string, options: RequestInit) => {
	if (requestPool.length >= MAX_CONCURRENCY_FOR_THROTTLED) {
		// pool is full, so queue up next request behind the first request in the pool
		const queuedFetchFn = async () => {
			await requestPool.shift();
			return wrappedFetch(uri, options);
		};

		const queuedFetch: Promise<Response> = queuedFetchFn();
		requestPool.push(queuedFetch);
		return queuedFetch;
	}

	// pool has available slot - add new request to pool and begin request immediately
	const nextFetch = wrappedFetch(uri, options);
	requestPool.push(nextFetch);
	return nextFetch;
};

const createLink = ({
	experimentalSchemaLinkOverride,
	ccGraphqlSchemaLinkOverride,
}: {
	experimentalSchemaLinkOverride?: ApolloLink;
	ccGraphqlSchemaLinkOverride?: ApolloLink;
}) => {
	const httpLinkOptions = {
		uri: URI,
		credentials: 'same-origin',
		fetch: wrappedFetch,
	};

	if (process.env.REACT_SSR) {
		const SSRLinks = [addSuperAdminDirectiveLink, SSRLink()];
		if (process.env.CLOUD_ENV === 'staging' || process.env.CLOUD_ENV === 'branch') {
			SSRLinks.push(SSRTestLink());
		}
		return from(SSRLinks).concat(
			ccGraphqlSchemaLinkOverride ||
				split(
					({ getContext }) => getContext().single,
					new HttpLink(httpLinkOptions),
					new BatchHttpLink({
						...httpLinkOptions,
						batchMax: 4,
						batchInterval: 0,
					}),
				),
		);
	} else {
		const httpLink = split(
			({ getContext }) => {
				return (
					getContext().single ||
					// In integration tests we deliberately block some queries in order to test the loading state
					// Randomly batching other queries with them will make UI fail to load
					// @ts-ignore TODO FIXME
					window.Cypress ||
					window.__CRITERION__ ||
					// @ts-ignore Property 'isPlaywright' does not exist on type 'Window & typeof globalThis'
					Boolean(window.isPlaywright) ||
					// we don't want query batching for contract tests
					// @ts-expect-error Property isContract doesn't exist on window
					Boolean(window.isContract) ||
					!StatsigConfigurations.isKillSwitchOn('confluence_spa_query_batching')
				);
			},
			new HttpLink(httpLinkOptions),
			split(
				({ getContext }) => !!getContext().throttle,
				new HttpLink({
					...httpLinkOptions,
					fetch: throttledFetch,
				}),
				new BatchHttpLink(httpLinkOptions),
			),
		);

		const processingLinks = [
			deleteExtensionsLink,
			addSuperAdminDirectiveLink,
			networkStatusLink(),
			statusCodeRetryLink,
			networkErrorRetryLink,
			externalShareLink,
			UFOLoggerLink,
		];

		let ccGraphLink: ApolloLink;
		if (process.env.NODE_ENV === 'testing') {
			// SSR and test env don't support websocket
			ccGraphLink = from(processingLinks).concat(httpLink);
		} else {
			ccGraphLink = from(processingLinks).split(isWS, webSocketLink(), httpLink);
		}

		const links = [createOnErrorLink(), graphqlSyntheticErrorGenerator()];

		// prevents adding bundle size for prod customers
		if (
			process.env.CLOUD_ENV === 'hello' ||
			process.env.CLOUD_ENV === 'staging' ||
			process.env.CLOUD_ENV === 'branch' ||
			(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'testing')
		) {
			links.unshift(queryOverridesLink());
		}

		return from(links).split(
			// Condition
			(operation) => isExperimental(operation),
			// If query contains @experimental directive
			experimentalSchemaLinkOverride || ExperimentalLink(),
			// Else
			ccGraphqlSchemaLinkOverride || ccGraphLink,
		);
	}
};

export const apolloCache = createCache();

export const createClient = (
	options: {
		experimentalSchemaLinkOverride?: ApolloLink;
		ccGraphqlSchemaLinkOverride?: ApolloLink;
		initializeNewCache?: boolean;
	} = {},
) => {
	// Creates window.__APOLLO_STATS__ call before creating the client
	resetApolloStats();

	const cache = options.initializeNewCache ? createCache() : apolloCache;
	if (typeof window['__APOLLO_STATE__'] === 'object') {
		cache.restore(window['__APOLLO_STATE__']);
	}
	const client = new ApolloClient({
		// Prevent cache and network query to refresh when they are already preloaded on server-side.
		// Refreshing data will cause component to fallback to loading state.
		ssrMode: Boolean(process.env.REACT_SSR),
		link: createLink(options),
		typeDefs: ClientSchema,
		cache,
	});

	if (!process.env.REACT_SSR) {
		// Don't initialize the client cache for tests so we can mock it
		if (!options.ccGraphqlSchemaLinkOverride) {
			writeNetworkStatus(client.cache, NetworkStatus.ONLINE);
		}

		cfetch.subscribe((response, error) => {
			if (response) {
				// It's NetworkStatusLink's responsibility/concern to interpret
				// cc-graphql errors (which may be carried in an OK HTTP response).
				if (response.url?.indexOf(URI) === -1) {
					writeNetworkStatus(client.cache, NetworkStatus.ONLINE);
				}
			} else if (error instanceof NoNetworkError && !error.ignore && !causedByAbortError(error)) {
				writeNetworkStatus(client.cache, NetworkStatus.OFFLINE);
			}
		});
	}

	return client;
};
