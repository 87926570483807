import React, { useMemo } from 'react';

import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { useAgentsList } from '@atlassian/conversation-assistant-agent';
import { mapToAssistanceServiceProduct } from '@atlassian/editor-ai-common/api/assistance-service';
import {
	A11yMateAgent,
	ChatAgent,
	CommsCrafterAgent,
	type EditorAgent,
	getEditorAgent,
	JobWriterAgent,
} from '@atlassian/generative-ai-modal/utils/agents';

import { updateAgents } from '../../../pm-plugins/rovo-agents/commands';
import type { EditorPluginAIProvider } from '../../../types/types';
import { shouldMockAgentData } from '../../../utils/agents/should-mock-agent-data';

interface AgentFetcherProps {
	editorPluginAIProvider: EditorPluginAIProvider;
	editorView: EditorView;
}

export const AgentFetcher = (props: AgentFetcherProps) => {
	const { editorPluginAIProvider, editorView } = props;
	const siteId = useMemo(
		() => editorPluginAIProvider?.getChannelVariables?.('rovo-agent')?.cloudId || '',
		[editorPluginAIProvider],
	);

	const agentsFromAssistanceService = useAgentsList({
		filter: 'favourites',
		assistanceServiceParams: {
			product: mapToAssistanceServiceProduct(editorPluginAIProvider.product),
			experienceId: 'ai-mate',
			touchpointSource: 'editor-agent-favourites-fetcher',
			siteId,
		},
	});

	const allAgentsFromAssistanceService = useAgentsList({
		assistanceServiceParams: {
			product: mapToAssistanceServiceProduct(editorPluginAIProvider.product),
			experienceId: 'ai-mate',
			touchpointSource: 'editor-agent-all-fetcher',
			siteId,
		},
	});

	const mockAgentData = shouldMockAgentData();

	// Request information about the latest Rovo agents and update the state accordingly
	React.useEffect(() => {
		if (!editorPluginAIProvider.getChannelVariables) {
			// Flag can be on, but off for this specific editor
			// eg. Confluence only wants it on for full page editors, not comment editors
			return;
		}

		// http://localhost:3100 is Playwright/Gemini
		const agents: EditorAgent[] = mockAgentData
			? [CommsCrafterAgent, JobWriterAgent, A11yMateAgent].map(getEditorAgent)
			: agentsFromAssistanceService.map(getEditorAgent);

		function getDefaultAgent() {
			const defaultAgent = mockAgentData
				? getEditorAgent(ChatAgent)
				: allAgentsFromAssistanceService.filter((agent) => agent.is_default).map(getEditorAgent)[0];

			return defaultAgent;
		}

		// Update the plugin state with the latest agents
		const command = updateAgents({
			agents,
			defaultAgent: getDefaultAgent(),
		});
		command(editorView.state, editorView.dispatch);
	}, [
		editorPluginAIProvider,
		agentsFromAssistanceService,
		allAgentsFromAssistanceService,
		mockAgentData,
		editorView.state,
		editorView.dispatch,
	]);

	return null;
};
