import { useQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import gql from 'graphql-tag';

import { SITE_RBAC_VIEW_EXPERIENCE } from '@confluence/experience-tracker';
import type { SpaceRolesByCriteriaQueryResult } from '@confluence/space-roles/entry-points/graphql';
import { useResponseHandler } from '@confluence/space-roles/entry-points/graphql';

export type RoleNodes = SpaceRolesByCriteriaQueryResult['spaceRolesByCriteria']['nodes'];

interface RoleList {
	roles: RoleNodes;
	loading: boolean;
}

const useRoleListRolesQuery = gql`
	query useRoleListRolesQuery {
		spaceRolesByCriteria {
			nodes {
				roleId
				roleDisplayName
				roleDescription
				roleType
				spacePermissionList {
					id
					priority
					displayName
					description
					requiredSpacePermissions
				}
			}
			pageInfo {
				endCursor
				hasNextPage
			}
		}
	}
`;

export const useRoleList = (): RoleList => {
	const [experienceStarted, setExperienceStarted] = useState(false);

	const { startExperience, handleResponse } = useResponseHandler({
		experience: SITE_RBAC_VIEW_EXPERIENCE,
	});

	const { data, loading } = useQuery<SpaceRolesByCriteriaQueryResult>(useRoleListRolesQuery, {
		onCompleted: () => {
			setExperienceStarted(false);
			handleResponse({});
		},
		onError: (error) => {
			setExperienceStarted(false);
			handleResponse({ error });
		},
	});

	useEffect(() => {
		if (loading && !experienceStarted) {
			startExperience();
			setExperienceStarted(true);
		}
	}, [loading, startExperience, experienceStarted]);

	return {
		roles: data?.spaceRolesByCriteria?.nodes ?? [],
		loading,
	};
};
