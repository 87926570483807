import throttle from 'lodash/throttle';

import { EditorState } from '@atlaskit/editor-prosemirror/state';
import { Decoration, EditorView, DecorationSet } from '@atlaskit/editor-prosemirror/view';

import { updateInlineSuggestion } from '../commands';
import { getPluginState as getInlineSuggestionsPluginState } from '../plugin-factory';
import { type InlineSuggestion } from '../states';

import { dateSuggestionHandler } from './date/date';
import type { SuggestionHandler, SuggestionMarker } from './types';
import { type TextPhrase, TextPhraseExtractor } from './utils';

export class SuggestionManager {
	private THROTTLE_MS: number = 100;

	public static readonly INLINE_SUGGESTION_DECORATION_CLASSES = {
		underline: 'proactive-ai-inline-suggestion-underline',
		highlight: 'proactive-ai-inline-suggestion-highlight',
		toolbarAnchor: 'proactive-ai-inline-suggestion-floating-toolbar-anchor',
	};

	private readonly handlers: SuggestionHandler[] = [dateSuggestionHandler];

	private detectFirstSuggestion(
		state: EditorState,
		cursorPhrases: TextPhrase[],
	): InlineSuggestion | null {
		for (const handler of this.handlers) {
			const match = handler.detect(state, cursorPhrases);
			if (match) {
				const node = handler.createReplacement(match, state.schema);
				return { ...match, suggestedNode: node };
			}
		}
		return null;
	}

	private throttledDetection = throttle(
		(view: EditorView) => {
			const { state, dispatch } = view;
			const pluginState = getInlineSuggestionsPluginState(state);
			const prevSug = pluginState.inlineSuggestion;
			const prevDecos = pluginState.decorationSet;

			const cursorPhrases = TextPhraseExtractor.getTextPhrasesAroundCursor(state);
			const currSug = this.detectFirstSuggestion(state, cursorPhrases);

			const isNoSuggestionChange = prevSug === null && currSug === null;
			if (isNoSuggestionChange) {
				return;
			}

			const updatedDecos = this.rebuildSuggestionDecorations(currSug, state, prevDecos);
			updateInlineSuggestion(currSug, updatedDecos)(state, dispatch);
		},
		this.THROTTLE_MS,
		{ leading: false, trailing: true },
	);

	public scheduleScanForSuggestions(view: EditorView) {
		this.throttledDetection(view);
	}

	public cancelSuggestionScans() {
		this.throttledDetection.cancel();
	}

	private createMarkerDecoration(marker: SuggestionMarker): Decoration {
		const addClass = SuggestionManager.INLINE_SUGGESTION_DECORATION_CLASSES[marker.style];
		const decoClass = [marker.cssClass, addClass].filter(Boolean).join(' ');
		return Decoration.inline(marker.from, marker.to, { class: decoClass.trim() });
	}

	/**
	 * Creates an inline decoration whose DOM element serves as a positioning
	 * anchor for the Suggestion popup (i.e. the floating toolbar).
	 */
	private createToolbarDecoration(from: number, to: number): Decoration {
		return Decoration.inline(from, to, {
			class: SuggestionManager.INLINE_SUGGESTION_DECORATION_CLASSES.toolbarAnchor,
		});
	}

	private rebuildSuggestionDecorations(
		suggestion: InlineSuggestion | null,
		state: EditorState,
		prevDecoSet: DecorationSet,
	): DecorationSet {
		if (!suggestion) {
			return DecorationSet.empty;
		}

		const regionDecos = prevDecoSet.find(suggestion.from, suggestion.to);
		const cleanSet = prevDecoSet.remove(regionDecos);

		const handler = this.handlers.find((handler) => handler.type === suggestion.type);
		if (!handler) {
			return cleanSet;
		}

		const markers = handler.createMarkers(suggestion, state);
		const markerDecos = markers.map((marker) => this.createMarkerDecoration(marker));
		const toolbarDeco = this.createToolbarDecoration(suggestion.from, suggestion.to);
		const newDecos = [...markerDecos, toolbarDeco];
		return cleanSet.add(state.doc, newDecos);
	}
}

export const suggestionManager = new SuggestionManager();
