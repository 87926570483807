import React from 'react';

import type { MessageDescriptor } from 'react-intl-next';

import ChangeToneIcon from '@atlaskit/icon/core/microphone';
import { fg } from '@atlaskit/platform-feature-flags';
import { token } from '@atlaskit/tokens';

import {
	type ChangeToneBusinessCasualIntentSchema,
	type ChangeToneEducationalIntentSchema,
	type ChangeToneEmpatheticIntentSchema,
	type ChangeToneNeutralIntentSchema,
	type ChangeToneProfessionalIntentSchema,
} from '../../../../types/prompt-request-types';
import { ToneCasualIcon } from '../../../../ui/icons/prompt/toneCasual';
import { ToneEducationalIcon } from '../../../../ui/icons/prompt/toneEducational';
import { ToneEmpatheticIcon } from '../../../../ui/icons/prompt/toneEmpathetic';
import { ToneNeutralIcon } from '../../../../ui/icons/prompt/toneNeutral';
import { ToneProfessionalIcon } from '../../../../ui/icons/prompt/toneProfessional';
import { createInsertBelow, createReplace } from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItem,
	EditorPluginAIConfigItemWithOptions,
} from '../../config-items';

import { messages } from './messages';

function createChangeToneOption({
	key,
	title,
	description,
	intentSchemaId,
	icon,
	shortTitle,
}: Pick<EditorPluginAIConfigItem, 'key' | 'title' | 'description' | 'icon'> & {
	shortTitle: MessageDescriptor;
	intentSchemaId:
		| ChangeToneBusinessCasualIntentSchema
		| ChangeToneEducationalIntentSchema
		| ChangeToneEmpatheticIntentSchema
		| ChangeToneNeutralIntentSchema
		| ChangeToneProfessionalIntentSchema;
}): EditorPluginAIConfigItem {
	return {
		key,
		title,
		description,
		icon,
		showInRefineDropdown: true,
		intentSchemaId,
		nestingConfig: {
			parentTestId: 'change-tone-to',
			parentTitle: messages.nestingParentTitlePostGA,
			shortTitle,
			icon: () => (
				<ChangeToneIcon label="" spacing="spacious" color={token('color.icon', '#44546F')} />
			),
		},
		actions: {
			empty: [
				createInsertBelow({ appearance: 'secondary' }),
				createReplace({ appearance: 'primary' }),
			],
			selection: [
				createInsertBelow({ appearance: 'secondary' }),
				createReplace({ appearance: 'primary' }),
			],
		},
		getBackendModel: () => {
			if (fg('platform_editor_ai_assistance_service')) {
				return 'assistance-service';
			}

			return 'xp-gen-ai';
		},
	};
}

export const changeToneToNeutral = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_NEUTRAL,
	title: messages.neutralToneTitle,
	shortTitle: messages.neutralToneShortTitle,
	description: messages.neutralToneDescription,
	intentSchemaId: 'change_tone_neutral_intent_schema.json',
	icon: ({ shownAt }) => <ToneNeutralIcon shownAt={shownAt} />,
});

export const changeToneToNeutralWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: changeToneToNeutral,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};

export const changeToneToProfessional = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_PROFESSIONAL,
	title: messages.professionalToneTitle,
	shortTitle: messages.professionalToneShortTitle,
	description: messages.professionalToneDescription,
	intentSchemaId: 'change_tone_professional_intent_schema.json',
	icon: ({ shownAt }) => <ToneProfessionalIcon shownAt={shownAt} />,
});

export const changeToneToProfessionalWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: changeToneToProfessional,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};

export const changeToneToCasual = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_CASUAL,
	title: messages.casualToneTitle,
	shortTitle: messages.casualToneShortTitle,
	description: messages.casualToneDescription,
	intentSchemaId: 'change_tone_business_casual_intent_schema.json',
	icon: ({ shownAt }) => <ToneCasualIcon shownAt={shownAt} />,
});

export const changeToneToCasualWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: changeToneToCasual,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};

export const changeToneToEducational = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_EDUCATIONAL,
	title: messages.educationalToneTitle,
	shortTitle: messages.educationalToneShortTitle,
	description: messages.educationalToneDescription,
	intentSchemaId: 'change_tone_educational_intent_schema.json',
	icon: ({ shownAt }) => <ToneEducationalIcon shownAt={shownAt} />,
});

export const changeToneToEducationalWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: changeToneToEducational,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};

export const changeToneToEmpathetic = createChangeToneOption({
	key: CONFIG_ITEM_KEYS.CHANGE_TONE_TO_EMPATHETIC,
	title: messages.empatheticToneTitle,
	shortTitle: messages.empatheticToneShortTitle,
	description: messages.empatheticToneDescription,
	intentSchemaId: 'change_tone_empathetic_intent_schema.json',
	icon: ({ shownAt }) => <ToneEmpatheticIcon shownAt={shownAt} />,
});

export const changeToneToEmpatheticWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: changeToneToEmpathetic,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};
