import React from 'react';

import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';

import { FreeGenerateIcon } from '../../../../ui/icons/prompt/freeGenerate';
import { AIPanelRenderer } from '../../ai-panel-renderer/AIPanelRenderer';
import type { AIManifest, AIPanelParameters } from '../../types';

import { messages } from './messages';

const buildSummarisePagePanelManifest = ({
	intl,
	providerFactory,
}: AIManifest): ExtensionManifest<AIPanelParameters> => {
	return {
		title: intl.formatMessage(messages.summarizePageTitle),
		type: 'com.atlassian.ai-blocks',
		key: 'ai-summary-block',
		description: intl.formatMessage(messages.summarisePageDescription),
		icons: {
			// Ignored via go/ees005
			// eslint-disable-next-line require-await
			'48': async () => () => (
				<FreeGenerateIcon alt={intl.formatMessage(messages.summarizePageTitle)} />
			),
		},
		modules: {
			nodes: {
				aiSummaryBodiedExtension: {
					type: 'bodiedExtension',
					// Ignored via go/ees005
					// eslint-disable-next-line require-await
					render:
						async () =>
						({ node }) => {
							return <AIPanelRenderer node={node} providerFactory={providerFactory} />;
						},
				},
			},
		},
	};
};

export default buildSummarisePagePanelManifest;
