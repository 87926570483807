import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

const lozengeStyle = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	fontWeight: token('font.weight.regular'),
	whiteSpace: 'nowrap',
	backgroundColor: 'color.background.neutral',
	color: 'color.text.accent.gray',
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
	borderRadius: '3px',
});

export const PermissionsLozenge = ({ permissionsCount }: { permissionsCount: number }) => (
	<Box xcss={lozengeStyle}>
		<FormattedMessage
			defaultMessage="{count, plural, one {# permission} other {# permissions}} selected"
			description="Label text indicating the number of permissions that are selected for a role that will be shown in a list"
			id="default-space-permissions.roles-management.role-list.permissions-count"
			values={{ count: permissionsCount }}
		/>
	</Box>
);
