import React from 'react';
import type { IntlShape } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';

import type { EditorActions } from '@atlaskit/editor-core';
import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';
import { token } from '@atlaskit/tokens';

import type { ExtensionSource } from '@confluence/change-edition/entry-points/PremiumExtensionsUpsell';
import { LoadableAfterPaint } from '@confluence/loadable';

import { getExtensionManifest } from '../shared-components/customSitesManifestBuilder';

import type { SpotlightParameters as SpotlightParametersType } from './spotlightTypes';
import {
	SpotlightButtonStyle,
	SpotlightHeight,
	SpotlightCornerStyle,
	SpotlightLayout,
} from './spotlightTypes';
import { spotlightExtensionType } from './spotlightConstants';
import type { SpotlightExtensionProps } from './SpotlightExtension';

const SpotlightExtension = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpotlightExtension" */ './SpotlightExtension'))
			.SpotlightExtension,
});

const i18n = defineMessages({
	title: {
		id: 'custom-sites-extensions.spotlight.manifest.macro-title',
		defaultMessage: 'Spotlight',
		description:
			'Title of spotlight extension to be displayed in toolbar/shortcut macros dropdown.',
	},
	description: {
		id: 'custom-sites-extensions.spotlight.manifest.macro-description',
		defaultMessage: 'Highlight important messages with a visual spotlight.',
		description:
			'Description of spotlight extension to be displayed in toolbar/shortcut macros dropdown',
	},
	titleNew: {
		id: 'custom-sites-extensions.spotlight.manifest.macro-title.new',
		defaultMessage: 'Spotlight [New]',
		description:
			'Title of spotlight extension to be displayed in toolbar/shortcut macros dropdown. The [New] text is a text label indicating that this feature is new.',
	},
	initialTitleParameter: {
		id: 'custom-sites-extensions.spotlight.manifest.initial-state.text-title',
		defaultMessage: 'Give your spotlight a title',
		description:
			'Initial text title parameter of spotlight extension to be displayed in the UI of the editor and view page.',
	},
	initialDescriptionParameter: {
		id: 'custom-sites-extensions.spotlight.manifest.initial-state.text-description',
		defaultMessage:
			'Spotlights highlight important messages for your team. Design your spotlight with an image or video, custom colors, flexible size, a short description, and a link.',
		description:
			'Initial description parameter of spotlight extension to be displayed in the UI of the editor and view page.',
	},
	initialButtonTextParameter: {
		id: 'custom-sites-extensions.spotlight.manifest.initial-state.button-text',
		defaultMessage: 'Learn More',
		description:
			'Initial button text parameter of spotlight extension to be displayed in the UI of the editor and view page.',
	},
});

const spotlightParameters = (intl: IntlShape): SpotlightParametersType => ({
	extensionTitle: intl.formatMessage(i18n.title),
	height: SpotlightHeight.MEDIUM,
	backgroundColor: token('color.background.accent.orange.subtlest'),
	cornerStyle: SpotlightCornerStyle.ROUNDED,
	layout: SpotlightLayout.SPLIT_IMAGE_RIGHT,
	content: {
		text: {
			title: intl.formatMessage(i18n.initialTitleParameter),
			description: intl.formatMessage(i18n.initialDescriptionParameter),
			color: '#000000',
		},
		button: {
			isButtonShown: true,
			text: intl.formatMessage(i18n.initialButtonTextParameter),
			link: 'https://atlassian.com',
			style: SpotlightButtonStyle.DEFAULT,
		},
		image: {
			// TODO Update image info with final content
			url: 'https://images.unsplash.com/photo-1733690577845-4f4641a456b3?q=80&w=2940',
			altText: 'An example spotlight image',
		},
	},
});

type SpotlightManifestArguments = {
	editorActions?: EditorActions;
	setUpsellModalSource?: (source: ExtensionSource) => void;
	openConfigPanel?: (localId: string) => void;
	intl: IntlShape;
} & Pick<SpotlightExtensionProps, 'contentId' | 'createAnalyticsEvent'>;

export const spotlightExtensionProvider = async ({
	editorActions,
	openConfigPanel,
	setUpsellModalSource,
	intl,
	...extensionPassThroughProps
}: SpotlightManifestArguments) => {
	const title = intl.formatMessage(i18n.titleNew);
	const description = intl.formatMessage(i18n.description);
	const type = spotlightExtensionType;
	const render =
		async () =>
		({ node }) => (
			<SpotlightExtension
				extensionNode={node}
				openConfigPanel={openConfigPanel}
				editorActions={editorActions}
				{...extensionPassThroughProps}
			/>
		);

	const icons = {
		// eslint-disable-next-line import/dynamic-import-chunkname
		'48': () =>
			import(
				/* webpackChunkName: "loadable-atlaskitIconCorePremium" */ '@atlaskit/icon/core/premium'
			).then(({ default: PremiumIcon }) => () => (
				<PremiumIcon color={token('color.icon.subtle')} label="" spacing="spacious" />
			)),
	};

	const parameters = spotlightParameters(intl);

	const update = async () => {
		const selectedNode = editorActions?.getSelectedNode()?.toJSON();
		const selectedLocalId = selectedNode?.attrs?.localId;

		if (!!selectedLocalId && openConfigPanel) {
			openConfigPanel(selectedLocalId);
		}
	};

	return new DefaultExtensionProvider([
		getExtensionManifest<SpotlightParametersType>({
			key: 'spotlight',
			icons,
			parameters,
			update,
			render,
			title,
			type,
			description,
			setUpsellModalSource,
			source: 'premiumSpotlightInsert',
		}),
	]);
};
