import { pluginFactory } from '@atlaskit/editor-common/utils';
import type { EditorState, ReadonlyTransaction } from '@atlaskit/editor-prosemirror/state';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';

import { inlineSuggestionPluginKey } from './inline-suggestion-plugin-key';
import { reducer } from './reducer';
import type { InlineSuggestionState } from './states';

function isSelectionNotInSuggestion({
	selectionFrom,
	selectionTo,
	suggestionFrom,
	suggestionTo,
}: {
	selectionFrom: number;
	selectionTo: number;
	suggestionFrom: number;
	suggestionTo: number;
}): boolean {
	return selectionTo < suggestionFrom || selectionFrom > suggestionTo;
}

function onSelectionChanged(
	tr: ReadonlyTransaction,
	pluginState: InlineSuggestionState,
): InlineSuggestionState {
	const { inlineSuggestion } = pluginState;
	if (!inlineSuggestion) {
		return pluginState;
	}

	const { from, to } = inlineSuggestion;
	const { from: selectionFrom, to: selectionTo } = tr.selection;

	if (
		isSelectionNotInSuggestion({
			selectionFrom: selectionFrom,
			selectionTo: selectionTo,
			suggestionFrom: from,
			suggestionTo: to,
		})
	) {
		return { ...pluginState, inlineSuggestion: null, decorationSet: DecorationSet.empty };
	}

	return pluginState;
}

function mapSuggestionPositions(
	tr: ReadonlyTransaction,
	pluginState: InlineSuggestionState,
	_editorState: EditorState,
): InlineSuggestionState {
	const { inlineSuggestion } = pluginState;
	if (!inlineSuggestion) {
		return pluginState;
	}
	return {
		...pluginState,
		inlineSuggestion: {
			...inlineSuggestion,
			from: tr.mapping.map(inlineSuggestion.from),
			to: tr.mapping.map(inlineSuggestion.to),
		},
		decorationSet: pluginState.decorationSet.map(tr.mapping, tr.doc),
	};
}

export const createInitialState = ({ locale }: { locale: string }): InlineSuggestionState => {
	return {
		inlineSuggestion: null,
		locale,
		decorationSet: DecorationSet.empty,
	};
};

export const { createPluginState, createCommand, getPluginState } = pluginFactory(
	inlineSuggestionPluginKey,
	reducer,
	{
		onSelectionChanged,
		mapping: mapSuggestionPositions,
	},
);
