import React from 'react';

import { ADMIN_HOME_ID } from '@confluence/app-navigation/entry-points/navigationIDs';
import { DefaultSpacePermissionsPage } from '@confluence/default-space-permissions/entry-points/DefaultSpacePermissionsPage';

export const RolesManagementRoute = () => <DefaultSpacePermissionsPage activeTab="roles" />;

RolesManagementRoute.NAVIGATION_PARAMS = () => ({
	Screen: {
		screenEvent: { name: 'rolesManagementScreen' },
	},
	MainLayout: {
		navView: ADMIN_HOME_ID,
	},
});
