import React, { useContext } from 'react';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import Page from '@atlaskit/page';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';
import { Link } from '@confluence/route-manager';
import {
	ADMIN_DEFAULT_SPACE_PERMISSIONS,
	ADMIN_ALL_SPACES_SPACE_PERMISSIONS,
	ADMIN_ROLES_MANAGEMENT,
} from '@confluence/named-routes';
import {
	ExperienceTrackerContext,
	VIEW_DEFAULT_SPACE_PERMISSIONS,
	VIEW_ALL_SPACES_TAB,
} from '@confluence/experience-tracker';
import { PaginationProvider } from '@confluence/space-permissions/entry-points/Pagination';
import { SpaceRolesLozenge } from '@confluence/space-roles';
import { useIsProductAdmin } from '@confluence/current-user';
import { RestrictedPage } from '@confluence/no-permission';

import { DefaultSettingsTabWithFlags } from './tabs/DefaultSettingsTab/DefaultSettingsTab';
import { IndividualSpacesTableComponentWithFlags } from './tabs/AllSpacesTab/IndividualSpacesComponent';
import { DefaultSettingsTabV2 } from './tabs/DefaultSettingsTabV2/DefaultSettingsTabV2';
import { SpaceRolesBanner } from './space-roles/onboarding/SpaceRolesBanner';
import { RolesManagementTab } from './tabs/RolesManagementTab/RolesManagementTab';

const i18n = defineMessages({
	spacePermissionsHeader: {
		id: 'default-space-permissions.space-permissions-header',
		defaultMessage: 'Space permissions',
		description: 'Header for the space permissions page',
	},
	allSpaceTabTitle: {
		id: 'default-space-permissions.all-space-tab-title',
		defaultMessage: 'All spaces',
		description: 'Title for the all spaces tab',
	},
	defaultsTabTitle: {
		id: 'default-space-permissions.defaults-tab-title',
		defaultMessage: 'Defaults for new spaces',
		description: 'Title for the defaults tab',
	},
	rolesManagementTabTitle: {
		id: 'default-space-permissions.roles-management-tab-title',
		defaultMessage: 'Roles',
		description: 'Title for the roles management tab',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const HeaderStyle = styled.h1({
	marginBottom: token('space.250', '20px'),
});

const pagePadding = xcss({
	padding: 'space.500',
});

const bannerStyle = xcss({
	paddingTop: 'space.200',
	paddingBottom: 'space.300',
});

const rolesTabStyle = xcss({
	width: '100%',
});

export const ALL_SPACES_ID = 'allspaces';
export const DEFAULTS_ID = 'defaults';
export const ROLES_ID = 'roles';

export type ActiveTab = typeof ALL_SPACES_ID | typeof DEFAULTS_ID | typeof ROLES_ID;

export const DefaultSpacePermissionsPage: FC<{ activeTab: ActiveTab }> = ({ activeTab }) => {
	const { formatMessage } = useIntl();
	const { isProductAdmin, isAdminCheckLoading } = useIsProductAdmin();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const DEFAULT_SPACE_PERMS_TABS = [
		{
			id: ALL_SPACES_ID,
			content: (
				<PaginationProvider>
					<IndividualSpacesTableComponentWithFlags />
				</PaginationProvider>
			),
		},
		{
			id: DEFAULTS_ID,
			content: fg('cc_perms_exp_rbac_fe_milestone_2') ? (
				<DefaultSettingsTabV2 />
			) : (
				<PaginationProvider>
					<DefaultSettingsTabWithFlags />
				</PaginationProvider>
			),
		},
		{
			id: ROLES_ID,
			content: fg('cc_perms_exp_rbac_fe_milestone_3') ? <RolesManagementTab /> : null,
		},
	];

	const selectedTabIndex: number =
		DEFAULT_SPACE_PERMS_TABS.findIndex((tab) => tab.id === activeTab) || 0;

	const isAllSpaceTab = activeTab === ALL_SPACES_ID;
	const isDefaultsTab = activeTab === DEFAULTS_ID;

	const switchTab = (tabId: 'defaults' | 'all-spaces') => {
		if (tabId === 'defaults') {
			experienceTracker.abort({ name: VIEW_ALL_SPACES_TAB, reason: 'Switching to Defaults tab' });
		} else if (tabId === 'all-spaces') {
			experienceTracker.abort({
				name: VIEW_DEFAULT_SPACE_PERMISSIONS,
				reason: 'Switching to All spaces tab',
			});
		}
	};

	// Note that the only reason we are looking at this is because the monolith
	// rendered page is still being presented in free editions.
	const isUpliftedPage =
		isDefaultsTab ||
		isAllSpaceTab ||
		(activeTab === ROLES_ID && fg('cc_perms_exp_rbac_fe_milestone_3'));

	if (isAdminCheckLoading) return null;

	if (!isProductAdmin) return <RestrictedPage />;

	return (
		<Box xcss={isUpliftedPage ? pagePadding : null}>
			<Page>
				{isUpliftedPage && <HeaderStyle>{formatMessage(i18n.spacePermissionsHeader)}</HeaderStyle>}
				{fg('cc_perms_exp_rbac_fe_milestone_2') && (
					<Box xcss={bannerStyle}>
						<SpaceRolesBanner isDefaultsTab={isDefaultsTab} />
					</Box>
				)}
				<Tabs id="default-space-permissions" selected={selectedTabIndex}>
					<div
						style={{
							marginBottom: isAllSpaceTab ? token('space.250', '20px') : token('space.400', '32px'),
						}}
					>
						<TabList>
							<Tab key={ALL_SPACES_ID}>
								<Link href={ADMIN_ALL_SPACES_SPACE_PERMISSIONS.toUrl()}>
									{formatMessage(i18n.allSpaceTabTitle)}
								</Link>
							</Tab>
							<Tab key={DEFAULTS_ID}>
								<Link
									href={ADMIN_DEFAULT_SPACE_PERMISSIONS.toUrl()}
									onClick={() => switchTab('defaults')}
								>
									<Inline alignBlock="baseline" space="space.050">
										{formatMessage(i18n.defaultsTabTitle)}
										<SpaceRolesLozenge destination="default-roles-tab" />
									</Inline>
								</Link>
							</Tab>
							{fg('cc_perms_exp_rbac_fe_milestone_3') && (
								<Tab key={ROLES_ID}>
									<Link href={ADMIN_ROLES_MANAGEMENT.toUrl()}>
										{formatMessage(i18n.rolesManagementTabTitle)}
									</Link>
								</Tab>
							)}
						</TabList>
					</div>
					<TabPanel key={ALL_SPACES_ID}>
						{DEFAULT_SPACE_PERMS_TABS[selectedTabIndex].content}
					</TabPanel>
					<TabPanel key={DEFAULTS_ID}>
						<Box>{DEFAULT_SPACE_PERMS_TABS[selectedTabIndex].content}</Box>
					</TabPanel>
					<TabPanel key={ROLES_ID}>
						<Box xcss={rolesTabStyle}>{DEFAULT_SPACE_PERMS_TABS[selectedTabIndex].content}</Box>
					</TabPanel>
				</Tabs>
			</Page>
		</Box>
	);
};
