import { fg } from '@atlaskit/platform-feature-flags';
import type { MentionMap } from '@atlassian/ai-model-io/utils/mention-map';
import { checkAssistanceServiceFreeGenerateFg } from '@atlassian/editor-ai-common/utils/check-assistance-service-fg';

import { CONFIG_ITEM_KEYS } from '../../prebuilt/config-items/config-item-keys';
import type {
	EditorPluginAIConfigItem,
	UpdateIdMap,
} from '../../prebuilt/config-items/config-items';
import { sliceOrNodeToMarkdown } from '../../prebuilt/config-items/utils/slice-or-node-to-markdown';
import { type IntentSchemaId } from '../../types/prompt-request-types';

import { getSupportedMarkdowns } from './get-supported-markdown-from-schema';
import type { AIExperienceMachineContextForRequest, RequestOptions } from './types';

type ContextList =
	// Brainstorm with and without full content turned on
	// And Free generate without full content turned on.
	| [
			{
				type: 'text';
				entity: '';
				relationship: undefined;
			},
	  ]
	// Interrogation
	| [
			{
				type: 'text' | 'ADF_MARKDOWN_V1';
				entity: string;
				relationship: 'PREVIOUS_RESPONSE';
			},
	  ]
	// Selection actions
	| [
			{
				type: 'text' | 'ADF_MARKDOWN_V1';
				entity: string;
				relationship: 'SELECTION';
			},
	  ]
	// Empty action with full content
	| [
			{
				type: 'text' | 'ADF_MARKDOWN_V1';
				entity: string;
				relationship: 'FULL_CONTENT';
			},
	  ]
	// Empty action with no content
	| [
			{
				type: 'text';
				entity: string;
			},
	  ]
	// For use when no relevant context is available (e.g. empty document)
	| [];

const keyList4XPGenAI: CONFIG_ITEM_KEYS[] = [
	// Brainstorm
	CONFIG_ITEM_KEYS.BRAINSTORM,
	// Find action items
	CONFIG_ITEM_KEYS.FIND_ACTION_ITEMS,
	// Suggest a title
	CONFIG_ITEM_KEYS.SUGGEST_A_TITLE,
	// Summarize writing
	CONFIG_ITEM_KEYS.SUMMARISE_WRITING,
	// Improve writing
	CONFIG_ITEM_KEYS.IMPROVE_WRITING,
	// Fixing Spelling & Grammar
	CONFIG_ITEM_KEYS.FIX_SPELLING_AND_GRAMMAR,
	// Make shorter
	CONFIG_ITEM_KEYS.MAKE_SHORTER,
];

/**
 * key is more accurate than intentSchemaId for deciding whether to use xp-gen-ai or convo-ai
 */
export function shouldUseXPGenAI({
	configItem,
	latestPromptResponse,
}: {
	configItem: EditorPluginAIConfigItem;
	latestPromptResponse?: string;
}) {
	const { key } = configItem;

	// bbc Create pull request description
	if (key === CONFIG_ITEM_KEYS.CREATE_PULL_REQUEST_DESCRIPTION) {
		return false;
	}

	// free-generate and free-generate interrogation
	// this whole function will be removed when xp-gen-ai is disabled, so we don't need to optimize the multiple if here
	if (key === CONFIG_ITEM_KEYS.FREE_GENERATE) {
		if (!latestPromptResponse && checkAssistanceServiceFreeGenerateFg()) {
			return false;
		}
		if (latestPromptResponse && fg('platform_editor_ai_interrogate_with_convo_ai')) {
			return false;
		}
		return true;
	}

	const canUseXPGenAI =
		// Change tone
		key.startsWith('Change tone to ') ||
		// Translate
		key.startsWith('Translate to ') ||
		keyList4XPGenAI.includes(key);

	if (canUseXPGenAI) {
		return !fg('platform_editor_ai_assistance_service');
	}

	return false;
}

function getXPGenAIContextList({
	configItem,
	intentSchemaId,
	latestPromptResponse,
	document,
	selection,
}: {
	configItem: EditorPluginAIConfigItem;
	intentSchemaId: IntentSchemaId;
	latestPromptResponse?: string;
	document?: string;
	selection?: string;
}): ContextList {
	if (intentSchemaId === 'brainstorm_intent_schema.json') {
		return [{ type: 'text', entity: '', relationship: undefined }];
	} else if (
		intentSchemaId === 'custom_with_interrogation_intent_schema.json' &&
		latestPromptResponse
	) {
		return [
			{
				type: 'ADF_MARKDOWN_V1',
				entity: latestPromptResponse,
				relationship: 'PREVIOUS_RESPONSE',
			},
		];
	} else if (intentSchemaId === 'suggest_title_intent_schema.json') {
		if (document) {
			return [
				{
					type: 'text',
					entity: document,
					relationship: 'FULL_CONTENT',
				},
			];
		}
		if (selection) {
			return [
				{
					type: 'text',
					entity: selection,
					relationship: 'SELECTION',
				},
			];
		}
	}

	return [
		{
			type: 'ADF_MARKDOWN_V1',
			entity: document || selection || '',
			relationship: 'SELECTION',
		},
	];
}

type Options = {
	context: AIExperienceMachineContextForRequest;
	configItem: EditorPluginAIConfigItem;
	mentionMap?: MentionMap;
	intentSchemaId: IntentSchemaId;
	updateIdMap: UpdateIdMap;
};

/**
 * A function that will make the payload for the XP Gen AI API.
 *
 * @see https://hello.atlassian.net/wiki/spaces/CA3/pages/2485466879/Generative+AI+API+Specs
 */
export const getRequestOptions4XPGenAI = ({
	context,
	configItem,
	mentionMap,
	intentSchemaId,
	updateIdMap,
}: Options): RequestOptions => {
	const { aiProvider, editorView, positions, intl, latestPromptResponse, userInput } = context;
	const { generativeAIApiUrl, product } = aiProvider;
	const { endpoint, lang } = configItem;
	const editorSchema = editorView.state.schema;

	// if there is no selection, we want to pass only the doc
	// if there is selection, we want to pass only the selection
	let needDoc = context.isEmpty;
	let needSelection = !context.isEmpty;

	// for Brainstorm, Jira Draft reply, don't send document (we have history feedback that LLM was using the document too much)
	if (configItem.alwaysNoDocAndSelection) {
		needDoc = false;
		needSelection = false;
	}

	const doc = needDoc
		? sliceOrNodeToMarkdown({
				node: editorView.state.doc,
				editorView,
				convertTo: 'markdown-plus',
				updateIdMap,
				mentionMap,
			})
		: undefined;

	const selection = needSelection
		? sliceOrNodeToMarkdown({
				slice: editorView.state.doc.slice(positions[0], positions[1]),
				editorView,
				convertTo: 'markdown-plus',
				updateIdMap: !needDoc ? updateIdMap : () => {},
				mentionMap,
			})
		: undefined;

	return {
		doc: doc?.markdown,
		selection: selection?.markdown,
		contentStatistics: doc?.contentStatistics || selection?.contentStatistics,
		experienceId: 'editor',
		endpoint: endpoint || generativeAIApiUrl,
		payload: JSON.stringify({
			intentSchemaId: intentSchemaId,
			userInstruction: userInput || undefined,
			targetTranslationLanguage: lang,
			product: product,
			modelPlatform: 'OPENAI',
			streaming: true,
			contextList: getXPGenAIContextList({
				configItem,
				intentSchemaId,
				latestPromptResponse,
				document: doc?.markdown,
				selection: selection?.markdown,
			}),
			outputLocale: intl.locale,
			outputFormat: {
				outputFormatType: 'MARKDOWN',
				markdowns: getSupportedMarkdowns(editorSchema),
			},
		}),
	};
};
