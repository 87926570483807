import { Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import { DecorationSet } from '@atlaskit/editor-prosemirror/view';

export type SuggestionType = 'date';

type DateMetadata = {
	date: Date;
};

export interface InlineSuggestion {
	id: string;
	type: SuggestionType;
	from: number;
	to: number;
	metadata: DateMetadata;
	suggestedNode: PMNode;
}

export interface InlineSuggestionState {
	inlineSuggestion: InlineSuggestion | null;
	locale: string;
	decorationSet: DecorationSet;
}

export enum ACTIONS {
	CLEAR_INLINE_SUGGESTION = 'CLEAR_INLINE_SUGGESTION',
	UPDATE_INLINE_SUGGESTION = 'UPDATE_INLINE_SUGGESTION',
	UPDATE_LOCALE = 'UPDATE_LOCALE',
}

export type InlineSuggestionAction =
	| { type: ACTIONS.CLEAR_INLINE_SUGGESTION }
	| {
			type: ACTIONS.UPDATE_INLINE_SUGGESTION;
			payload: { inlineSuggestion: InlineSuggestion; decorationSet: DecorationSet };
	  }
	| { type: ACTIONS.UPDATE_LOCALE; payload: { locale: string } };
