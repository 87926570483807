import {
	type CrossFlowExtensions,
	type JSONValue,
	type TargetType,
} from '@atlassiansox/cross-flow-base-types';
import { type APIv0 } from './api/v0';

export type ExperimentalOptions = Record<string, JSONValue>;

export const Journeys = {
	GET_STARTED: 'get-started',
	DECIDE: 'decide',
	DISCOVER: 'discover',
} as const;

export type JourneyType = (typeof Journeys)[keyof typeof Journeys];

export type AnalyticsAttributes = Record<string, unknown>;

export type JourneyTargetProductDefinition =
	| {
			journey: typeof Journeys.GET_STARTED | typeof Journeys.DECIDE;
			targetProduct: TargetType;
	  }
	| {
			journey?: typeof Journeys.DISCOVER;
	  };

export type Options = JourneyTargetProductDefinition & {
	sourceComponent: string;
	sourceContext: string;
	experimentalOptions?: ExperimentalOptions;
	extensions?: CrossFlowExtensions;
	extraAnalyticsAttributes?: AnalyticsAttributes;
	zIndex?: number;
};

export interface CompletionStatus {
	success?: boolean;
}

export type OnOpen = (options: Options) => Promise<CompletionStatus>;

export const Reasons = {
	NO_API_SUPPORT: 'Could not provide requested API version',
	NO_PROVIDER: 'Provider not found',
} as const;

export type ReasonType = (typeof Reasons)[keyof typeof Reasons]; // equivalent of `typeof Reason.NO_API_SUPPORT | typeof Reason.NO_PROVIDER | ...`

export interface ContextDisabled {
	isEnabled: false;
	reason: ReasonType;
}

export interface ContextEnabled<T> {
	isEnabled: true;
	api: T;
}

export type CrossFlowContextType = ContextEnabled<APIv0> | ContextDisabled;

export type NegotiateCrossFlowAPI = (version: number) => CrossFlowContextType;

export interface WithCrossFlowProps {
	crossFlow: CrossFlowContextType;
}
