import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import type { Schema } from '@atlaskit/editor-prosemirror/model';
import { Fragment } from '@atlaskit/editor-prosemirror/model';
import { convertMarkdownToProsemirror } from '@atlassian/ai-model-io/convert-markdown-to-prosemirror';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown/serializer';
import { ErrorUtils } from '@atlassian/editor-ai-common/utils/errors';

import type { FailedSource } from '../types/analytics';

import type { useFireAIAnalyticsEvent } from './analytics/utils';

type PmFragmentPreviewData = {
	pmFragment: Fragment;
	htmlConvertedToFallbackNodes: number;
	htmlConvertedToNodes: number;
	isTextFallbackUsed: boolean;
};

export function getPMFragmentWithFallback({
	markdown,
	schema,
	source,
	fireAIAnalyticsEvent,
	idMap,
}: {
	markdown: string;
	schema: Schema;
	source: FailedSource;
	fireAIAnalyticsEvent: ReturnType<typeof useFireAIAnalyticsEvent>;
	idMap: IDMap;
}): PmFragmentPreviewData {
	try {
		const { pmFragment, htmlConvertedToFallbackNodes, htmlConvertedToNodes } =
			convertMarkdownToProsemirror({
				schema,
				markdown,
				idMap,
				featureToggles: {
					markdownPlus: true,
					markdownPlusExtensions: true,
					markdownPlusPanels: true,
					markdownPlusDecisions: true,
				},
			});

		return {
			pmFragment,
			htmlConvertedToFallbackNodes,
			htmlConvertedToNodes,
			isTextFallbackUsed: false,
		};
	} catch (error) {
		// When convertMarkdownToProsemirror a fragment
		// we log an analytics event to call out that markdown conversion has failed
		fireAIAnalyticsEvent({
			payload: {
				action: 'failed',
				actionSubject: 'editorPluginAI',
				actionSubjectId: 'markdownParser',
				attributes: {
					source,
					errorType: 'markdownParser',
					errorMessage: ErrorUtils.extractErrorMessage(error),
				},
				eventType: EVENT_TYPE.OPERATIONAL,
			},
		});

		// Instead of erroring, we fallback to rendering as plaintext in a single paragraph.
		const fallbackPMFragment = Fragment.from(
			schema.nodes.paragraph.create(null, schema.text(markdown)),
		);
		return {
			pmFragment: fallbackPMFragment,
			htmlConvertedToFallbackNodes: 0,
			htmlConvertedToNodes: 0,
			isTextFallbackUsed: true,
		};
	}
}
