import { OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { sendEvent, extensionIdToAnalyticsAttributes } from '@atlassian/forge-ui/analytics';
import { emit } from '@atlassian/forge-ui/events';
import {
	createLegacyInvokeAuxEffectsInput,
	handleInvokeAuxEffectsResponse,
	invokeAuxEffectsMutation,
	APIError,
} from '@atlassian/forge-ui/internal';
import { type ForgeDoc, type ForgeUIExtensionType } from '@atlassian/forge-ui-types';

import {
	type ForgeExtensionParameters,
	type ForgeExtensionProviderParams,
	type ForgeExtensionProviderSharedParams,
} from '../types';
import { ValidationError, createSchemaFromForgeDoc } from './createConfigSchema';
import { getConnectMacroId } from '../utils/getConnectMacroId';
import {
	AnalyticsAction,
	type AnalyticsClient,
} from '../extension-provider/extension-provider-refactor/analytics';

// We keep track of the macro IDs that were rendered as Forge macros, for analytics purposes
const connectRenderedAsForgeMacroIds = new Set<string>();

export const getConfig = ({ config, guestParams }: ForgeExtensionParameters) =>
	guestParams || config;

export const createUIKitOneGetFieldsDefinitionFunction =
	({
		analyticsWebClient,
		apolloClient,
		contextIds,
		cloudId,
		extensionData,
	}: Pick<
		ForgeExtensionProviderParams,
		'analyticsWebClient' | 'apolloClient' | 'contextIds' | 'cloudId' | 'extensionData'
	>) =>
	async (data: ForgeExtensionParameters) => {
		const { extensionId, localId, extension } = data;
		const config = getConfig(data);
		try {
			const input = createLegacyInvokeAuxEffectsInput(
				{
					contextIds,
					extensionId,
					localId,
					functionId: 'config',
				},
				{
					effects: [
						{
							type: 'initialize',
						},
					],
					state: {},
					config,
					context: {
						isConfig: true,
						cloudId,
					},
				},
				{
					...extensionData,
					config,
					isConfig: true,
				},
			);
			const mutationResult = await apolloClient.mutate({
				mutation: invokeAuxEffectsMutation,
				variables: { input },
			});
			const effect = handleInvokeAuxEffectsResponse(mutationResult, (message) => {
				throw new APIError(message);
			})[0];
			return createSchemaFromForgeDoc(effect.type === 'result' ? effect.forgeDoc : effect.aux);
		} catch (error) {
			const { name, message } = error as Error;
			if (!(error instanceof ValidationError)) {
				analyticsWebClient &&
					sendEvent(analyticsWebClient)({
						eventType: OPERATIONAL_EVENT_TYPE,
						action: 'errored',
						actionSubject: 'forge.ui.extension',
						source: 'editPageScreen',
						attributes: {
							errorName: name,
							errorMessage: message,
							...extensionIdToAnalyticsAttributes(extensionId),
							extensionType: extension?.type,
							forgeEnvironment: extension?.environmentType,
						},
					});
			}
			throw new Error(message || '');
		}
	};

export const createUIKitTwoGetFieldsDefinitionFunction =
	({
		analyticsClient,
		extension,
		hasCustomConfig,
	}: {
		analyticsClient: AnalyticsClient;
		extension: ForgeUIExtensionType;
		hasCustomConfig: boolean;
	}) =>
	async (parameters: ForgeExtensionParameters) => {
		const { extensionId, localId } = parameters;
		const config = getConfig(parameters);

		const id = `${extensionId}-${localId}`;

		// Until Editor forceably closes the config panel, we need to clear the fields in the panel otherwise
		// config values will be overwritten by previous fields
		if (hasCustomConfig) {
			return [];
		}

		const getForgeDoc = new Promise<ForgeDoc>((resolve, reject) => {
			emit(`GET_CONFIG_FORGE_DOC_${id}`, { resolve, reject, config });
		});

		try {
			const forgeDoc = await getForgeDoc;
			return createSchemaFromForgeDoc(forgeDoc);
		} catch (error: unknown) {
			const { message } = error as Error;
			if (!(error instanceof ValidationError)) {
				const { sendEvent } = analyticsClient;
				sendEvent(AnalyticsAction.FORGE_EXTENSION_ERRORED, {
					extension,
					error,
				});
			}
			throw new Error(message || '');
		}
	};

export const createConnectToForgeGetFieldsDefinitionFunction =
	({
		analyticsClient,
		connectToForgeBlockedExtensions,
		eventSource,
		extension,
		hasCustomConfig,
		hasUiKitOneConfig,
		isCustomUI,
		isUiKitTwo,
		uiKitOneGetFieldsDefinitionFunction,
	}: {
		analyticsClient: AnalyticsClient;
		connectToForgeBlockedExtensions: Set<unknown>;
		eventSource: string;
		extension: ForgeUIExtensionType;
		hasCustomConfig: boolean;
		hasUiKitOneConfig: boolean;
		isCustomUI: boolean;
		isUiKitTwo: boolean;
		uiKitOneGetFieldsDefinitionFunction: ForgeExtensionProviderSharedParams['getFieldsDefinitionFunction'];
	}) =>
	async (parameters: ForgeExtensionParameters) => {
		const macroId = getConnectMacroId(parameters);
		if (!parameters.extensionId) {
			/* If extensionId is missing, then we are dealing with a Connect or core macro instead of Forge
			 * This can happen when
			 *  - reverting a page with an upgraded Connect -> Forge addon back to Connect,
			 *  - if the Connect macro wasn't upgraded for some reason (eg. bodiedExtension / rich text macro)
			 *  - there is also a bug when loading a Forge macro, then clicking on a Connect macro that is uninstalled - https://product-fabric.atlassian.net/jira/servicedesk/projects/DTR/queues/issue/DTR-2569
			 *
			 * We can't edit/store the config in this state, so safer to just prevent panel loading
			 */

			// We start by assuming it's a core macro
			let userMessage: string =
				'Macro configuration failed to load. Please close this panel and try again.';
			let errorMessage: string = 'non-forge-macro';

			// Macro ID is only defined for Connect/core macros, not Forge macros (for now, this is subject to change)
			if (!macroId) {
				throw new Error(userMessage);
			}

			// Whether we report this as a warning (expected) or an error (unexpected)
			let isWarning = false;
			const isBodied = connectToForgeBlockedExtensions.has(macroId);

			if (isBodied) {
				userMessage = `Editing multi-bodied macros is not currently supported.`;
				errorMessage = 'bodied-macro-blocked';
				isWarning = true;
			} else if (connectRenderedAsForgeMacroIds.has(macroId)) {
				// We know that at some point this macro was rendered as a Forge macro, so it must have been reverted
				userMessage = 'This macro has been reverted. Please close this panel and try again.';
				errorMessage = 'macro-reverted';
				isWarning = true;
			}

			// Only report h11n errors for harmonised Connect macros
			if (extension.migrationKey) {
				const { sendEvent } = analyticsClient;

				if (isWarning) {
					sendEvent(AnalyticsAction.H11N_CONFIG_WARNING, {
						extension,
						error: errorMessage,
						macroId,
						macroTitle: parameters.macroMetadata?.title,
					});
				} else {
					sendEvent(AnalyticsAction.H11N_CONFIG_ERROR, {
						extension,
						error: errorMessage,
						macroId,
						macroTitle: parameters.macroMetadata?.title,
					});
				}
			}
		}
		// If we make it here, we are dealing with a Forge macro with an extensionId
		if (macroId) {
			connectRenderedAsForgeMacroIds.add(macroId);
		}

		const uiKitTwoGetFieldsDefinitionFunction = createUIKitTwoGetFieldsDefinitionFunction({
			analyticsClient,
			extension,
			hasCustomConfig,
		});

		return (isCustomUI || isUiKitTwo) && !hasUiKitOneConfig
			? uiKitTwoGetFieldsDefinitionFunction(parameters)
			: uiKitOneGetFieldsDefinitionFunction(parameters);
	};
