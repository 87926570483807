import React, { createContext, type ReactNode, useContext, useMemo } from 'react';

import { di } from 'react-magnetic-di';

import { type DocNode } from '@atlaskit/adf-schema';
import { fg } from '@atlaskit/platform-feature-flags';
import {
	type ExperienceTracker,
	useAIMateExperienceTracker,
} from '@atlassian/conversation-assistant-instrumentation';

import { setRovoChatProduct } from '../../common/utils/product-config';

import { type AgentAction } from './agent-actions/types';
import { type MessageActionPayload, type MessageActionResponse } from './message-actions/types';
import { readJsonStream } from './read-json-stream';
import {
	type Agent,
	type AgentDetails,
	type AgentFileUploadToken,
	type AgentKnowledgeConfigurationResponse,
	type AgentPermissionResponse,
	type AssistanceService,
	type AssistanceServiceConfig,
	type AssistanceServiceProduct,
	type Chat,
	type ChatMessage,
	type ConversationChannel,
	type Dialogues,
	type Features,
	type FetchStreamReturn,
	type FileMetadataResponseItem,
	type FileUploadResponse,
	type GeneratedConversationStarters,
	type GetAgentPermissionsPayload,
	isHumanMessage,
	type PostAgentPayload,
	type RawChat,
	type SendMessageBrowserContext,
	type SendMessageEditorContext,
	type StreamConversationStarter,
	type StreamMessage,
} from './types';
import { addPath, getTraceIdFromResponse, processResponse } from './utils/client';
import { generateConversationName } from './utils/conversation';
import { toStreamResponse } from './utils/data';
import { fetchFn } from './utils/fetch';

type EndpointGroup = 'chat' | 'agent' | 'agentConfiguration';

type FetchOptions = {
	path: string;
	options: RequestInit;
	endpointGroup?: EndpointGroup;
};

const PRODUCT_HEADER = 'x-product';
const EXPERIENCE_ID_HEADER = 'x-experience-id';

type PrivateAssistanceServiceConfig = AssistanceServiceConfig &
	Required<Pick<AssistanceServiceConfig, 'baseUrl' | 'headers'>>;

export abstract class AssistanceServiceBase {
	private config: PrivateAssistanceServiceConfig;
	protected endpointSpecificBaseUrl = {
		chat: '/gateway/api/assist/chat/v1',
		agent: '/gateway/api/assist/agents/v1',
		agentConfiguration: '/gateway/api/assist/agents/configuration/v1',
	} satisfies Record<EndpointGroup, string>;

	public getConfig = () => {
		return {
			...this.config,
		};
	};

	constructor(props: AssistanceServiceConfig) {
		const defaultBaseUrl = '/gateway/api/assist';

		const defaultConfig = {
			baseUrl: defaultBaseUrl,
			headers: {},
		};

		this.config = {
			...props,
			baseUrl: props.baseUrl || defaultConfig.baseUrl,
			credentials: props.credentials,
			headers: {
				...(props.headers || defaultConfig.headers),
			},
		};
	}

	protected createHeaders(init?: HeadersInit): Headers {
		return new Headers({
			[PRODUCT_HEADER]: this.config.product,
			[EXPERIENCE_ID_HEADER]: this.config.experienceId,
			...(this.config.headers || {}),
			...(init || {}),
		});
	}

	protected async fetch({ path, options, endpointGroup }: FetchOptions) {
		const { baseUrl: configBaseUrl, credentials: configCredentials } = this.config;
		let baseUrlOverride: string | undefined = endpointGroup
			? this.endpointSpecificBaseUrl[endpointGroup]
			: undefined;
		const baseUrl = baseUrlOverride ?? configBaseUrl;

		const requestUrl = addPath(baseUrl, path);
		const response = await fetchFn(requestUrl, {
			credentials: configCredentials,
			...options,
		});

		return processResponse({ response });
	}

	protected async fetchJson<T>(options: FetchOptions): Promise<T> {
		const response = await this.fetch(options);
		const json = await response.json();
		return json;
	}

	protected async fetchStream<T>(options: FetchOptions): Promise<FetchStreamReturn<T>> {
		const response = await this.fetch(options);
		return {
			responseData: {
				traceId: getTraceIdFromResponse(response),
			},
			generator: readJsonStream<T>(response),
		};
	}
}

export class AssistanceServiceImpl extends AssistanceServiceBase implements AssistanceService {
	constructor(props: AssistanceServiceConfig) {
		super(props);

		if (fg('rovo_convo_ai_migration')) {
			this.endpointSpecificBaseUrl.chat = '/gateway/api/assist/rovo/v1/chat';
			this.endpointSpecificBaseUrl.agent = '/gateway/api/assist/rovo/v1/agents';
			this.endpointSpecificBaseUrl.agentConfiguration =
				'/gateway/api/assist/rovo/v1/agents/configuration';
		}
	}

	public async sendMessageStream({
		agentNamedId,
		agentId,
		message,
		conversationId,
		controller,
		storeMessage = false,
		citationsEnabled = true,
		editorContext,
		browserContext,
		additionalContext,
	}: {
		conversationId: string;
		message: string | DocNode;
		agentNamedId: string;
		agentId?: string;
		controller?: AbortController;
		storeMessage?: boolean;
		citationsEnabled?: boolean;
		editorContext?: SendMessageEditorContext;
		browserContext?: SendMessageBrowserContext;
		additionalContext?: Record<string, unknown>;
	}): Promise<FetchStreamReturn<StreamMessage>> {
		const headers = this.createHeaders({
			'content-type': 'application/json;charset=UTF-8',
		});
		// If browserContext received and context is undefined, assume we're unable to retrieve the context of the page in focus
		const url = browserContext ? browserContext.context?.browserUrl : window?.location?.href;
		const body = {
			content: message,
			context: {
				browser_url: url,
				...(editorContext ? { editor: editorContext } : {}),
				// If browserUrl is inaccessable, fallback to htmlBody or canvasText if they exist
				...(browserContext
					? {
							browser: {
								htmlBody: browserContext.context?.htmlBody,
								canvasText: browserContext.context?.canvasText,
							},
						}
					: {}),
				...additionalContext,
			},
			recipient_agent_named_id: agentNamedId,
			agent_id: agentId,
			mimeType: typeof message === 'string' ? 'text/markdown' : 'text/adf',
			// don't allow store message if feature flag is off
			store_message: fg('ai_mate_show_store_messages') ? storeMessage : false,
			citations_enabled: citationsEnabled,
		};
		const options: RequestInit = {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(body),
			signal: controller?.signal,
		};

		return this.fetchStream<StreamMessage>({
			path: `/${fg('rovo_convo_ai_migration') ? 'conversation' : 'channel'}/${conversationId}/message/stream`,
			options,
			endpointGroup: 'chat',
		});
	}

	public async getChat(conversationId: string): Promise<Chat> {
		const headers = this.createHeaders();
		const options = {
			method: 'GET',
			headers: headers,
		};

		// Clean up `RawChat | { items: RawChat }` when fg('rovo_convo_ai_migration') is removed
		const rawChat = await this.fetchJson<
			| RawChat
			| {
					items: RawChat;
			  }
		>({
			path: `/${fg('rovo_convo_ai_migration') ? 'conversation' : 'channel'}/${conversationId}/messages`,
			options,
			endpointGroup: 'chat',
		});

		const chatArray = 'items' in rawChat ? rawChat.items : rawChat;
		return chatArray.map(
			(message): ChatMessage =>
				// Must be converted into StreamResponse
				isHumanMessage(message) ? message : toStreamResponse(message),
		);
	}

	public async deleteChat(conversationId: string, controller?: AbortController): Promise<void> {
		const headers = this.createHeaders();
		const options = {
			method: 'DELETE',
			headers: headers,
			signal: controller?.signal,
		};
		await this.fetch({
			path: `/${fg('rovo_convo_ai_migration') ? 'conversation' : 'channel'}/${conversationId}`,
			options,
			endpointGroup: 'chat',
		});
	}

	public async getFeatures(controller?: AbortController) {
		const headers = this.createHeaders();
		const options = {
			method: 'GET',
			headers: headers,
			signal: controller?.signal,
		};
		return this.fetchJson<Features>({ path: 'features', options });
	}

	public async getAgents(controller?: AbortController): Promise<Agent[]> {
		const headers = this.createHeaders();
		const options = {
			method: 'GET',
			headers: headers,
			signal: controller?.signal,
		};
		const response = await this.fetchJson<
			| Agent[]
			| {
					items: Agent[];
			  }
		>({ path: '', options, endpointGroup: 'agent' });

		return 'items' in response ? response.items : response;
	}

	public async getAgentDetails(id: string, controller?: AbortController): Promise<AgentDetails> {
		const headers = this.createHeaders();
		const options = {
			method: 'GET',
			headers: headers,
			signal: controller?.signal,
		};
		return this.fetchJson<AgentDetails>({ path: `/${id}`, options, endpointGroup: 'agent' });
	}

	public async getAgentDetailsByIdentityAccountId(
		identityAccountId: string,
		controller?: AbortController,
	): Promise<AgentDetails> {
		const headers = this.createHeaders();
		const options = {
			method: 'GET',
			headers: headers,
			signal: controller?.signal,
		};
		return this.fetchJson<AgentDetails>({
			path: `accountid/${identityAccountId}`,
			options,
			endpointGroup: 'agent',
		});
	}

	public async getAgentFileUploadToken(
		controller?: AbortController,
	): Promise<AgentFileUploadToken> {
		const headers = this.createHeaders();
		const options = {
			method: 'GET',
			headers: headers,
			signal: controller?.signal,
		};

		return this.fetchJson<AgentFileUploadToken>({
			path: 'agents/v1/media/upload/credentials',
			options,
		});
	}

	public async createAgent(
		payload: PostAgentPayload,
		controller?: AbortController,
	): Promise<Agent> {
		const headers = this.createHeaders({
			'content-type': 'application/json;charset=UTF-8',
		});
		const options = {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(payload),
			signal: controller?.signal,
		};
		return this.fetchJson<Agent>({
			path: '',
			options,
			endpointGroup: 'agent',
		});
	}

	public async updateAgent(
		id: string,
		payload: PostAgentPayload,
		controller?: AbortController,
	): Promise<Agent> {
		const headers = this.createHeaders({
			'content-type': 'application/json;charset=UTF-8',
		});
		const options = {
			method: 'PUT',
			headers: headers,
			body: JSON.stringify(payload),
			signal: controller?.signal,
		};
		const response = await this.fetchJson<Agent>({
			path: `/${id}`,
			options,
			endpointGroup: 'agent',
		});

		return response;
	}

	public async deleteAgent(id: string, controller?: AbortController): Promise<void> {
		const headers = this.createHeaders();
		const options = {
			method: 'DELETE',
			headers: headers,
			signal: controller?.signal,
		};
		return this.fetchJson<void>({
			path: `/${id}`,
			options,
			endpointGroup: 'agent',
		});
	}

	public async favouriteAgent(id: string, controller?: AbortController): Promise<void> {
		const headers = this.createHeaders();
		const options = {
			method: 'POST',
			headers: headers,
			signal: controller?.signal,
		};
		this.fetch({
			path: `/${id}/favourite`,
			options,
			endpointGroup: 'agent',
		});
	}

	public async unfavouriteAgent(id: string, controller?: AbortController): Promise<void> {
		const headers = this.createHeaders();
		const options = {
			method: 'DELETE',
			headers: headers,
			signal: controller?.signal,
		};
		this.fetch({
			path: `/${id}/favourite`,
			options,
			endpointGroup: 'agent',
		});
	}

	public async createConversationChannel(options?: { name?: string; dialogues?: Dialogues[] }) {
		return this.fetchJson<ConversationChannel & Required<Pick<ConversationChannel, 'name'>>>({
			path: `/${fg('rovo_convo_ai_migration') ? 'conversation' : 'channel'}`,
			options: {
				method: 'POST',
				headers: this.createHeaders({
					'content-type': 'application/json;charset=UTF-8',
				}),
				body: JSON.stringify({
					name: options?.name ?? generateConversationName(),
					dialogues: options?.dialogues ?? undefined,
				}),
			},
			endpointGroup: 'chat',
		});
	}

	public async getConversationChannels(): Promise<ConversationChannel[]> {
		// Clean up `ConversationChannel[] | { items: ConversationChannel[] }` when fg('rovo_convo_ai_migration') is removed
		const result = await this.fetchJson<
			| ConversationChannel[]
			| {
					items: ConversationChannel[];
			  }
		>({
			path: `/${fg('rovo_convo_ai_migration') ? 'conversations' : 'channels'}`,
			options: {
				method: 'GET',
				headers: this.createHeaders(),
			},
			endpointGroup: 'chat',
		});

		const channels = 'items' in result ? result.items : result;
		return channels;
	}

	public updateConversationChannel(id: string, payload: Partial<ConversationChannel>) {
		return this.fetchJson<ConversationChannel>({
			path: `/${fg('rovo_convo_ai_migration') ? 'conversation' : 'channel'}/${id}`,
			options: {
				method: 'PUT',
				headers: this.createHeaders({
					'content-type': 'application/json;charset=UTF-8',
				}),
				body: JSON.stringify(payload),
			},
			endpointGroup: 'chat',
		});
	}

	public async resolveConversationAction(id: string, payload: MessageActionPayload) {
		return this.fetchJson<MessageActionResponse>({
			path: `/${fg('rovo_convo_ai_migration') ? 'conversation' : 'channel'}/${id}/action`,
			options: {
				method: 'POST',
				headers: this.createHeaders({
					'content-type': 'application/json;charset=UTF-8',
				}),
				body: JSON.stringify({
					...payload,
					context: {
						...payload.context,
						browser_url: window.location.href,
					},
				}),
			},
			endpointGroup: 'chat',
		});
	}

	public async speechToText(blob: Blob) {
		const formData = new FormData();
		formData.append('audio_recording', blob);

		const response = await this.fetchJson<{
			transcription: string;
		}>({
			path: '/speech/inference',
			options: {
				method: 'POST',
				headers: this.createHeaders(),
				body: formData,
			},
		});

		return response.transcription;
	}

	public async generateConversationStarters(payload: {
		name?: string;
		description?: string;
		instructions?: string;
	}): Promise<GeneratedConversationStarters> {
		const headers = this.createHeaders({
			'content-type': 'application/json;charset=UTF-8',
		});
		const options = {
			method: 'POST',
			headers,
			body: JSON.stringify(payload),
		};
		return this.fetchJson<GeneratedConversationStarters>({
			path: '/suggest-conversation-starter',
			options,
			endpointGroup: 'agent',
		});
	}

	public async generateContextualConversationStarters(payload: {
		recipient_agent_named_id: string;
		context: {
			browser_url: string;
		};
	}): Promise<FetchStreamReturn<StreamConversationStarter>> {
		const headers = this.createHeaders({
			'content-type': 'application/json;charset=UTF-8',
		});
		const options = {
			method: 'POST',
			headers,
			body: JSON.stringify(payload),
		};
		return this.fetchStream<StreamConversationStarter>({
			path: '/conversation-starter',
			options,
			endpointGroup: 'agent',
		});
	}

	public getAgentActions = async (): Promise<AgentAction[]> => {
		const response = await this.fetchJson<{
			actions: AgentAction[];
		}>({
			path: '/actions',
			options: {
				method: 'GET',
				headers: this.createHeaders(),
			},
			endpointGroup: 'agentConfiguration',
		});

		return response.actions;
	};

	public getAgentKnowledgeConfiguration = (): Promise<AgentKnowledgeConfigurationResponse> => {
		return this.fetchJson<AgentKnowledgeConfigurationResponse>({
			path: '/knowledge',
			options: {
				method: 'GET',
				headers: this.createHeaders(),
			},
			endpointGroup: 'agentConfiguration',
		});
	};

	public getAgentPermissions = (
		agentId: string,
		payload: GetAgentPermissionsPayload,
	): Promise<AgentPermissionResponse> => {
		return this.fetchJson({
			path: `/api/rovo/v2/permissions/agents/${agentId}`,
			options: {
				method: 'POST',
				headers: this.createHeaders({
					'content-type': 'application/json;charset=UTF-8',
				}),
				body: JSON.stringify(payload),
			},
		});
	};

	public getRecommendedAgents = ({
		limit,
		query,
	}: {
		limit: number;
		query: string;
	}): Promise<{ agents: Agent[] }> => {
		return this.fetchJson({
			path: `/recommend/v2?limit=${limit}&query=${query}`,
			options: {
				method: 'GET',
				headers: this.createHeaders(),
			},
			endpointGroup: 'agent',
		});
	};

	public getConversationDebugLogs = async (conversationId: string): Promise<Chat> => {
		const response = await this.fetchJson<{
			items: Chat;
		}>({
			path: `/rovo/v1/chat/conversation/${conversationId}/debug-logs`,
			options: {
				method: 'GET',
				headers: this.createHeaders(),
			},
		});

		return response.items;
	};

	public uploadFilesToConversation = async (payload: {
		conversationId: string;
		files: FileList;
	}): Promise<FileUploadResponse> => {
		const formData = new FormData();

		for (const file of payload.files) {
			formData.append('files', file);
		}

		const response = await this.fetchJson<FileUploadResponse>({
			path: `/rovo/v1/chat/conversation/${payload.conversationId}/message/file`,
			options: {
				method: 'POST',
				headers: this.createHeaders({
					'content-type': 'multipart/form-data',
				}),
				body: formData,
			},
		});

		return response;
	};

	public getConversationFiles = (
		conversationId: string,
	): Promise<{
		items: FileMetadataResponseItem[];
	}> => {
		return this.fetchJson({
			path: `/rovo/v1/chat/conversation/${conversationId}/files`,
			options: {
				method: 'GET',
				headers: this.createHeaders(),
			},
		});
	};
}

const AssistanceServiceContext = createContext<AssistanceService>(
	new AssistanceServiceImpl({
		baseUrl: '',
		product: '',
		experienceId: '',
	}),
);

export const AssistanceServiceProvider = ({
	value,
	children,
}: {
	value: AssistanceService;
	children: ReactNode;
}) => (
	<AssistanceServiceContext.Provider value={value}>{children}</AssistanceServiceContext.Provider>
);

export type UseAssistanceServiceParams = {
	/** If a parent AssistanceServiceProvider is not rendered, this field MUST be provided in order for agents to work */
	product?: AssistanceServiceProduct;
	/** Defaults to `ai-mate` */
	experienceId?: string;
	headers?: Record<string, string> | undefined;
};

/**
 * If these props are provided and one of them are populated, they will take precedence over the `AssistanceServiceProvider` with the missing props filled in
 *
 * @deprecated Please use factory function `createHookWithAssistanceService` instead. We had problems where the consumer of this hook are missing the parent context or not passing in all required paramters to call convo-ai. {@link https://hello.atlassian.net/wiki/spaces/AA6/pages/4323250987/RFC-004+Rovo+components+missing+headers+if+called+outside+chat}
 **/
export const useAssistanceService = (props?: UseAssistanceServiceParams) => {
	di(useContext, AssistanceServiceImpl);

	const service = useContext(AssistanceServiceContext);

	setRovoChatProduct(props?.product ?? service.getConfig().product);

	if (!props || (!props.experienceId && !props.product && !props.headers)) {
		return service;
	}

	const product = props.product ?? service.getConfig().product;
	const experienceId = props.experienceId ?? service.getConfig().experienceId;
	const headers = props.headers ?? service.getConfig().headers;

	return new AssistanceServiceImpl({
		...service.getConfig(),
		product,
		experienceId,
		headers,
	});
};

type CustomHeaders = Record<Lowercase<string>, string> | undefined;

export type AssistanceServiceInputParam = {
	assistanceServiceParams: {
		/**
		 * This parameter is used as tracing in instrumentation
		 * pass a string that defines where the hook is being called from
		 * make it as granular as possible per touchpoint
		 */
		touchpointSource: string;
		product: AssistanceServiceProduct;
		experienceId: string;
		siteId: string;
		headers?: CustomHeaders;
	};
};

export type AssistanceServiceInjectedParams = {
	assistanceServiceInjectedParams: {
		experienceTracker: ExperienceTracker;
		assistanceService: AssistanceService;
		touchpointSource: string;
		product: AssistanceServiceProduct;
		experienceId: string;
		siteId: string;
		headers?: CustomHeaders;
	};
};

/**
 * Created for https://hello.atlassian.net/wiki/spaces/AA6/pages/4323250987/RFC-004+Rovo+components+missing+headers+if+called+outside+chat
 * many hooks that require `assistanceService`, often are missing parameters that causes convo-ai call to be 400
 * this hook factory is created to ensure that the `assistanceService` is always called with the correct parameters in any of those hooks
 *
 * Input: react hook that will receive extra prop `assistanceServiceInjectedParams`
 * Output: react hook that enforce `assistanceServiceParams` as a required parameter
 *
 * e.g.
 * const useSomething = createHookWithAssistanceService(
 * 	(hookParams: { customProp: string } & AssistanceServiceInjectedParams) => {
 * 		// this inner hook will get `assistanceServiceInjectedParams` as a prop
 * 		const { customProp, assistanceServiceInjectedParams: { assistanceService } } = hookParams;
 * 		return { ... };
 * 	}
 * );
 *
 * now `useSomething` will have `assistanceServiceParams`, as a required param
 * e.g.
 *
 * useSomething({
 * 	customProp: 'value',
 * 	assistanceServiceParams: { product: 'confluence', experienceId: 'ai-mate', ... }
 * });
 */
export const createHookWithAssistanceService = <P extends AssistanceServiceInjectedParams, R>(
	originalHook: (props: P) => R,
) => {
	return (props: Omit<P, 'assistanceServiceInjectedParams'> & AssistanceServiceInputParam): R => {
		di(AssistanceServiceImpl, useAIMateExperienceTracker);
		const { assistanceServiceParams, ...restProps } = props;

		const experienceTracker = useAIMateExperienceTracker({
			touchpointSource: assistanceServiceParams.touchpointSource,
		});

		const headers: Record<string, string> = useMemo(() => {
			return {
				'x-cloudid': props.assistanceServiceParams.siteId,
				...props.assistanceServiceParams.headers,
			};
		}, [props.assistanceServiceParams.siteId, props.assistanceServiceParams.headers]);

		const assistanceService = useMemo(() => {
			return new AssistanceServiceImpl({
				product: assistanceServiceParams.product,
				experienceId: assistanceServiceParams.experienceId,
				headers,
			});
		}, [assistanceServiceParams.experienceId, assistanceServiceParams.product, headers]);

		const result = originalHook({
			...restProps,
			assistanceServiceInjectedParams: {
				...assistanceServiceParams,
				headers,
				experienceTracker,
				assistanceService,
			},
		} as unknown as P);

		return result;
	};
};
