import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	title: true,
	description: true,
	children: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::0d7f32aec70f3f979825ad0aef5910c8>>
 * @codegenId #form-header
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen form-header
 * @codegenDependency ../../../../../../../design-system/form/src/form-header.tsx <<SignedSource::2cb11ce4ec409ab150c2da25df8d56cc>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { FormHeader as PlatformFormHeader } from '@atlaskit/form';

type PlatformFormHeaderProps = React.ComponentProps<typeof PlatformFormHeader>;

export type FormHeaderProps = Pick<
  PlatformFormHeaderProps,
  'title' | 'description' | 'children'
>;

/**
 * A form allows users to input information.
 */
export const FormHeader = (props: Parameters<RenderFn>[0]) => {
  const {
    title,
    description,
  } = props.forgeDoc.props as FormHeaderProps;
  return (
    <PlatformFormHeader
      title={title}
      description={description}
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
    />
  );
};
/**
 * @codegenEnd
 */
