import React, { useCallback, useMemo } from 'react';

import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { useSubscribe } from '@atlaskit/rovo-triggers';
import { convertMarkdownToProsemirror } from '@atlassian/ai-model-io/convert-markdown-to-prosemirror';
import { useAgentsList } from '@atlassian/conversation-assistant-agent';
import { mapToAssistanceServiceProduct } from '@atlassian/editor-ai-common/api/assistance-service';
import { type EditorAgent, getEditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import {
	beforeDispatchAIRovoAgentTransaction,
	insertAIContentAtCurrentPosition,
	replaceWithAIContent,
} from '../../../prebuilt/config-items/utils/action-utils';
import { type EditorPluginAIProvider } from '../../../types/types';

import { isLastActiveEditorView, useTrackLastActiveEditorView } from './utils';

type SubscribeParams = Parameters<typeof useSubscribe>;

const topic: SubscribeParams[0] = {
	topic: 'ai-mate',
};

export const SubscribeToRovo = ({
	editorView,
	product,
	onDocChangeByAgent,
	onAIProviderChanged,
	editorPluginAIProvider,
	positions,
}: {
	editorView: EditorView;
	product: EditorPluginAIProvider['product'];
	onDocChangeByAgent: EditorPluginAIProvider['onDocChangeByAgent'];
	onAIProviderChanged: EditorPluginAIProvider['onAIProviderChanged'];
	editorPluginAIProvider: EditorPluginAIProvider;
	positions?: [number, number];
}) => {
	const siteId = useMemo(
		() => editorPluginAIProvider?.getChannelVariables?.('rovo-agent')?.cloudId || '',
		[editorPluginAIProvider],
	);

	const agents = useAgentsList({
		assistanceServiceParams: {
			product: mapToAssistanceServiceProduct(product),
			experienceId: 'ai-mate',
			touchpointSource: 'editor-agent-subscribe-to-rovo',
			siteId,
		},
	});

	useTrackLastActiveEditorView(editorView);

	const callback = useCallback<SubscribeParams[1]>(
		(payload) => {
			if (payload.type === 'agent-changed' && onAIProviderChanged) {
				onAIProviderChanged(
					'rovo-chat',
					payload.data.agent
						? {
								type: 'rovo',
								agent: payload.data.agent,
							}
						: undefined,
				);
			}

			if (payload.type === 'editor-suggestion' && isLastActiveEditorView(editorView)) {
				const { state } = editorView;

				switch (payload.data.mode) {
					case 'insert': {
						const { pmFragment } = convertMarkdownToProsemirror({
							markdown: payload.data.content,
							schema: state.schema,
							idMap: {},
						});

						const tr = insertAIContentAtCurrentPosition({
							editorView,
							positions: positions ?? [state.selection.from, state.selection.to],
							pmFragment,
						});
						beforeDispatchAIRovoAgentTransaction(tr);
						editorView.dispatch(tr);
						editorView.focus();
						break;
					}
					case 'replace': {
						const { pmFragment } = convertMarkdownToProsemirror({
							markdown: payload.data.content,
							schema: state.schema,
							idMap: {},
						});

						const tr = replaceWithAIContent({
							editorView,
							positions: positions ?? [state.selection.from, state.selection.to],
							pmFragment,
							options: { replaceDocOnEmptySelection: false },
						});
						beforeDispatchAIRovoAgentTransaction(tr);
						editorView.dispatch(tr);
						editorView.focus();
						break;
					}
				}

				const matchedAgent = agents.find((agent) => agent.id === payload.data.agentId);

				if (matchedAgent && onDocChangeByAgent) {
					const agent: EditorAgent = getEditorAgent(matchedAgent);
					onDocChangeByAgent(agent);
				}
			}
		},
		[onAIProviderChanged, editorView, agents, onDocChangeByAgent, positions],
	);
	useSubscribe(topic, callback);

	return <></>;
};
