import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import { type ExtractInjectionAPI } from '@atlaskit/editor-common/types';
import { type EditorView } from '@atlaskit/editor-prosemirror/view';

import type { AIPlugin } from '../../../aiPluginType';
import { CONFIG_ITEM_KEYS } from '../../../prebuilt/config-items/config-item-keys';
import { type EditorPluginAIConfigItem } from '../../../prebuilt/config-items/config-items';
import { addAnalytics } from '../../../utils/analytics/utils';

export const firePromptConfigNotFoundError = ({
	editorView,
	dispatch,
	prompt,
	adjustSelection,
	promptText,
}: {
	editorView: EditorView;
	dispatch: EditorView['dispatch'];
	prompt?: string;
	promptText?: string;
	adjustSelection?: string;
}) => {
	const errorEventTr = addAnalytics({
		editorState: editorView.state,
		tr: editorView.state.tr,
		payload: {
			action: 'error',
			actionSubject: 'editorPluginAI',
			actionSubjectId: 'eventHubPlugin',
			attributes: {
				errorType: 'promptConfigItemNotFound',
				prompt: prompt,
				promptText: promptText,
				adjustSelection: adjustSelection,
			},
			eventType: EVENT_TYPE.OPERATIONAL,
		},
	});
	dispatch(errorEventTr);
};

function fireSuggestedTitleAnalyticsEvent({
	api,
	analyticSourceId,
	additionalAttributes,
}: {
	api: ExtractInjectionAPI<AIPlugin> | undefined;
	analyticSourceId: string;
	additionalAttributes?: Record<string, unknown>;
}) {
	api?.analytics?.actions.fireAnalyticsEvent({
		// @ts-expect-error - Temporarily added analytic event for proactive title generation project
		action: 'streamingCompleted',
		// @ts-expect-error - Temporarily added analytic event for proactive title generation project
		actionSubject: 'editorPluginAI',
		// @ts-expect-error - Temporarily added analytic event for proactive title generation project
		actionSubjectId: 'eventHubPlugin',
		attributes: {
			promptType: CONFIG_ITEM_KEYS.SUGGEST_A_TITLE,
			modalHidden: true,
			analyticSourceId,
			...additionalAttributes,
		},
		eventType: EVENT_TYPE.TRACK,
	});
}

export const fireFallbackSuggestedTitleReturnedEvent = ({
	api,
	analyticSourceId,
}: {
	api: ExtractInjectionAPI<AIPlugin> | undefined;
	analyticSourceId: string;
}) => {
	fireSuggestedTitleAnalyticsEvent({
		api,
		analyticSourceId,
		additionalAttributes: {
			fallbackSuggestedTitleReturned: true,
		},
	});
};

async function hashString(input: string): Promise<string> {
	const encoder = new TextEncoder();
	const data = encoder.encode(input);
	const hashBuffer = await crypto.subtle.digest('SHA-256', data);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
	return hashHex;
}

export const isSuggestTitle = (configItem: EditorPluginAIConfigItem): boolean =>
	configItem.key === CONFIG_ITEM_KEYS.SUGGEST_A_TITLE;

export const fireSuggestedTitleCompletedEvent = async ({
	api,
	analyticSourceId,
	suggestedTitle,
}: {
	api: ExtractInjectionAPI<AIPlugin> | undefined;
	analyticSourceId: string;
	suggestedTitle: string;
}) => {
	const suggestedTitleFingerprint = await hashString(suggestedTitle);
	fireSuggestedTitleAnalyticsEvent({
		api,
		analyticSourceId,
		additionalAttributes: {
			fallbackSuggestedTitleReturned: false,
			suggestedTitleFingerprint,
		},
	});
};
