// Grow is being excluded at the moment as it requires a Box component to have `flexGrow: 1` as a property
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	alignBlock: true,
	alignInline: true,
	spread: true,
	grow: true,
	space: true,
	shouldWrap: true,
	separator: true,
	rowSpace: true,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::35cb362708a7987ce44411102e524789>>
 * @codegenId #inline
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen inline
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/inline.tsx <<SignedSource::b0fbadb98198a9aa77770d37c3748408>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/types.tsx <<SignedSource::9529629bd4dfcf934c9dfc41516de683>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/style-maps.partial.tsx <<SignedSource::764fa2a11181a03d6d87ec2b92e13239>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/xcss/xcss.tsx <<SignedSource::4f9c0f527ba99a4723d89b2126ae70b1>>
 * @codegenDependency ../../../../../../../design-system/primitives/src/components/flex.tsx <<SignedSource::bd7b084a24732a0bb7d29b4cc3211e29>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { Inline as PlatformInline } from '@atlaskit/primitives';

type PlatformInlineProps = React.ComponentProps<typeof PlatformInline>;

export type InlineProps = Pick<
  PlatformInlineProps,
  'children' | 'alignBlock' | 'alignInline' | 'spread' | 'grow' | 'space' | 'shouldWrap' | 'separator' | 'rowSpace' | 'testId'
>;

/**
 * Primitives are token-backed low-level building blocks.
 */
export const Inline = (props: Parameters<RenderFn>[0]) => {
  const {
    alignBlock,
    alignInline,
    spread,
    grow,
    space,
    shouldWrap,
    separator,
    rowSpace,
    testId,
  } = props.forgeDoc.props as InlineProps;
  return (
    <PlatformInline
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      alignBlock={alignBlock}
      alignInline={alignInline}
      spread={spread}
      grow={grow}
      space={space}
      shouldWrap={shouldWrap}
      separator={separator}
      rowSpace={rowSpace}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
