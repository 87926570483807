import React from 'react';

import { Stack, xcss } from '@atlaskit/primitives';

import { SpaceRoleType } from '@confluence/space-roles/entry-points/graphql';

import { useRoleList } from '../roles-list/useRoleList';

import { CustomRolesSection } from './role-sections/CustomRolesSection';
import { DefaultRolesSection } from './role-sections/DefaultRolesSection';
import { useSpacePermissions } from './useSpacePermissions';

const roleManagementViewStyle = xcss({
	padding: 'space.100',
	gap: 'space.400',
});

const defaultRoleSectionStyle = xcss({
	gap: 'space.300',
});

const customRoleSectionStyle = xcss({
	gap: 'space.200',
});

export type ActiveModalType = 'add' | 'update' | 'delete' | 'view' | null;

export const RolesManagementView = () => {
	const { roles } = useRoleList();
	const { permissions } = useSpacePermissions();
	const [activeModal, setActiveModal] = React.useState<ActiveModalType>(null);

	return (
		<Stack xcss={roleManagementViewStyle}>
			<Stack xcss={defaultRoleSectionStyle}>
				<DefaultRolesSection
					defaultRoleNodes={roles.filter((role) => role.roleType === SpaceRoleType.SYSTEM)}
				/>
			</Stack>
			<Stack xcss={customRoleSectionStyle}>
				<CustomRolesSection
					customRoleNodes={roles.filter((role) => role.roleType === SpaceRoleType.CUSTOM)}
					allRoles={roles}
					allPermissions={permissions}
					activeModal={activeModal}
					setActiveModal={setActiveModal}
				/>
			</Stack>
		</Stack>
	);
};
