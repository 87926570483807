import { useCallback, useEffect, useMemo, useState } from 'react';

import type { AnnouncementBanner } from '@atlassian/generative-ai-modal/screens/UserInputCommandPalette';

import { useEngagementPlatform } from './useEngagementPlatform';

/**
 * Hook that returns an announcement banner configuration for the
 * given Engagement Platform external message id and text.
 *
 * If message should not be displayed, hook returns undefined.
 */
export function useEngagementPlatformAnnouncementBanner(
	messageId: string,
	text: string,
): AnnouncementBanner | undefined {
	const { stopMessage, startMessage } = useEngagementPlatform();
	const [isStarted, setIsStarted] = useState(false);

	useEffect(() => {
		startMessage(messageId).then((isStarted) => {
			setIsStarted(isStarted);
		});
	}, [messageId, startMessage]);

	const handleClose = useCallback(() => {
		// We should hide message immediately without waiting for EngagementPlatform response
		setIsStarted(false);
		stopMessage(messageId);
	}, [messageId, stopMessage]);

	return useMemo(() => {
		if (isStarted) {
			return {
				id: messageId,
				text,
				onClose: handleClose,
			};
		}

		return undefined;
	}, [messageId, text, handleClose, isStarted]);
}
