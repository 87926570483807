import type { Route } from '@confluence/route';

import * as route from './routes';

/**
 * DO NOT USE THIS. Use route export directly
 * eg import { HOME } from '@confluence/named-routes'
 */
export const namedRoutes: { [name: string]: Route } = [
	route.BASE,
	route.WIKI,
	route.HOME,
	route.TEMPLATE_GALLERY,
	route.CALENDAR,
	route.MY_CALENDAR,
	route.QUESTIONS,
	route.CONTENT_HISTORY,
	route.CONTENT_HISTORY_LEGACY,
	route.EDIT_PAGE,
	route.EDIT_BLOG,
	route.EDIT_PAGE_V2,
	route.EDIT_BLOG_V2,
	route.EDIT_PAGE_EMBED,
	route.CREATE_CONTENT,
	route.CREATE_PAGE,
	route.CREATE_BLOG,
	route.DELETE_PAGE,
	route.ONBOARDING,
	route.SPACE_DIRECTORY,
	route.SPACE_SETTINGS,
	route.SPACE_SETTINGS_REORDERPAGES,
	route.SPACE_PERMISSION,
	route.SPACE_OVERVIEW_REDIRECT_LEGACY,
	route.DELETE_SPACE_LEGACY,
	route.SPACE_INDEX,
	route.SPACE_OVERVIEW,
	route.COMPANY_HUB,
	route.COMPANY_HUB_ANALYTICS,
	route.COMPANY_HUB_EDIT,
	route.COMPANY_HUB_SETTINGS,
	route.VIEW_PAGE,
	route.NEXT_VIEW_PAGE,
	route.BLOG_HOME,
	route.VIEW_BLOG,
	route.SPACE_JIRA_PROJECT_BOARD,
	route.WHITEBOARD_HOME,
	route.WHITEBOARD_BOARD,
	route.CONTACT_ADMINISTRATOR,
	route.ADMIN_SETTINGS,
	route.ADMIN_GLOBAL_AUTOMATION,
	route.ADMIN_EDIT_EXTERNAL_SHARING,
	route.ADMIN_FORGE_APPS,
	route.ADMIN_NEW_GLOBAL_PERMISSIONS,
	route.ADMIN_COMPANY_HUB_PERMISSIONS,
	route.ADMIN_COMPANY_HOME_BUILDER,
	route.ADMIN_CUSTOMIZE_NAVIGATION,
	route.ADMIN_NEW_GLOBAL_PERMISSIONS_USER_DETAILS,
	route.ADMIN_SPACE_PERMISSIONS,
	route.ADMIN_DEFAULT_SPACE_PERMISSIONS,
	route.ADMIN_ALL_SPACES_SPACE_PERMISSIONS,
	route.ADMIN_ROLES_MANAGEMENT,
	route.SPACE_PERMISSIONS_APPS,
	route.EDIT_SPACE_PERMISSIONS_APPS,
	route.ADMIN_AUDIT_LOG,
	route.ADMIN_BROWSE_SHORTCUTS,
	route.ADMIN_CREATE_SHORTCUT,
	route.ADMIN_REMOVE_SHORTCUT,
	route.ADMIN_DARK_FEATURES,
	route.ADMIN_ENABLE_DARK_FEATURE,
	route.ADMIN_DISABLE_DARK_FEATURE,
	route.ADMIN_BETA_FEATURES,
	route.ADMIN_NEW_FEATURES,
	route.ADMIN_LIVE_EDIT,
	route.ADMIN_WORKFLOWS,
	route.SPACE_CALENDAR,
	route.SPACE_CALENDARS,
	route.SPACE_CALENDARS_SUBCALENDAR,
	route.DISCOVER,
	route.ALL_UPDATES,
	route.POPULAR,
	route.MY_WORK_HOME,
	route.MY_WORK_DRAFTS,
	route.MY_WORK_RECENT,
	route.MY_RECENTLY_VISITED,
	route.MY_SAVED_FOR_LATER,
	route.RECENT_PAGES,
	route.RECENT_VISITED,
	route.RECENT_DRAFTS,
	route.RECENT_SAVED,
	route.TEMPLATE_MIGRATION_PREVIEW,
	route.EDIT_TEMPLATE,
	route.EDIT_TEMPLATE_BLUEPRINT,
	route.EDIT_TEMPLATE_SPACE,
	route.CREATE_TEMPLATE,
	route.CREATE_TEMPLATE_SPACE,
	route.HELLO_WORLD,
	route.DASHBOARD_ACTION_LEGACY,
	route.SPA_ACTION_LEGACY,
	route.INDEX_ACTION_LEGACY,
	route.CREATE_PAGE_ACTION_LEGACY,
	route.CREATE_BLOG_ACTION_LEGACY,
	route.CREATE_PAGE_VARIABLES_ACTION_LEGACY,
	route.RESUME_DRAFT_LEGACY,
	route.COPY_PAGE_LEGACY,
	route.VIEW_PAGE_ACTION_LEGACY,
	route.SPACE_OVERVIEW_INDEX_ACTION_LEGACY,
	route.SPACE_INDEX_ACTION_LEGACY,
	route.SPACE_PAGES,
	route.SPACE_BLOGS,
	route.VIEW_BLOG_DATE_LEGACY,
	route.ACTION_REDIRECT_LEGACY,
	route.ADVANCED_SEARCH_LEGACY,
	route.ADVANCED_SEARCH,
	route.EXTERNAL_SHARE,
	route.PDF_EXPORT_PAGE,
	route.PDF_EXPORT_SPACE,
	route.VIEW_PAGE_MOBILE,
	route.VIEW_BLOG_MOBILE,
	route.PEOPLE_DIRECTORY_SEARCH,
	route.CONFLUENCE_PEOPLE_DIRECTORY_SEARCH,
	route.MARKETPLACE_FIND_APPS,
	route.MARKETPLACE_APP_REQUESTS,
	route.MARKETPLACE_MANAGE_PROMOTIONS,
	route.PEOPLE_DIRECTORY,
	route.SPACE_RESTRICTED_PAGES,
	route.SPACE_ARCHIVED_PAGES,
	route.PAGE_DIFF_BY_VERSION,
	route.SITE_ANALYTICS,
	route.SITE_ANALYTICS_PERMISSIONS,
	route.SPACE_ANALYTICS,
	route.SPACE_ANALYTICS_PERMISSIONS,
	route.PAGE_ANALYTICS,
	route.SPACE_QUESTIONS,
	route.SPACE_QUESTIONS_NATIVE,
	route.SPACE_QUESTION_NATIVE,
	route.SPACE_CUSTOM_CONTENT_LIST,
	route.CUSTOM_CONTENT_VIEW,
	route.SPACE_BULK_TOOLS,
	route.SPACE_SETTINGS_LANDING_PAGE,
	route.SPACE_SETTINGS_LANDING_PAGE_ALT,
	route.SPACE_SETTINGS_AUTOMATION,
	route.SPACE_SETTINGS_ARCHIVE_SPACE,
	route.SPACE_SETTINGS_TRASH_SPACE,
	route.SPACE_FORGE_APPS,
	route.GLOBAL_PAGE_FORGE,
	route.SPACE_SETTINGS_FORGE_APPS,
	route.SPACE_SETTINGS_CONTENT_STATE,
	route.SPACE_SETTINGS_DATA_CLASSIFICATION,
	route.SPACE_SETTINGS_EXPORT_SPACE,
	route.SPACE_SETTINGS_EXPORT_SPACE_UNAVAILABLE,
	route.SPACE_SETTINGS_HIDDEN_PAGES,
	route.SPACE_SETTINGS_RELATED_PAGES,
	route.SPACE_SETTINGS_UNDEFINED_PAGES,
	route.SPACE_SETTINGS_ATTACHMENTS,
	route.SPACE_SETTINGS_APPLICATION_LINKS,
	route.SPACE_SETTINGS_RSS_FEEDS,
	route.SPACE_SETTINGS_EDITOR_CONVERSION,
	route.SPACE_LOOK_AND_FEEL_SETTINGS,
	route.SPACE_TRASH,
	route.MOBILE_TAKEOVER_PAGE,
	route.DEFAULT_ADMIN_MANAGE_APPS,
	route.ADMIN_APP_AUDIT_LOGS,
	route.SPACE_SETTINGS_TEMPLATE_LIST_PAGE_LEGACY,
	route.ADMIN_TEMPLATES_AND_BLUEPRINTS,
	route.ADMIN_APPLICATION_NAVIGATOR,
	route.CUSTOM_CONTENTS_ON_PAGE_LIST,
	route.SPACE_PERMISSIONS_SETTINGS,
	route.USER_ACCESS,
	route.ADMIN_ANNOUNCEMENT_BANNER_RENDERER,
	route.SPACE_MANAGER,
	route.ADMIN_CENTER,
	route.ADMIN_CENTER_SPACE,
	route.ADMIN_TEAM_CALENDARS,
	route.ADMIN_CONFIGURATION,
	route.ADMIN_DEFAULT_SPACE_LOGO,
	route.ADMIN_HEADER_AND_FOOTER,
	route.ADMIN_SECURITY_CONFIGURATION,
	route.ADMIN_SPACE_REPORTS,
	route.ADMIN_CODE_MACRO_CONFIGURATION,
	route.ADMIN_COLOR_SCHEME,
	route.DATABASE_VIEW,
	route.DATABASE_CUSTOM_OVERVIEW,
	route.DATABASE_CUSTOM_VIEW,
	route.EMBED_VIEW,
	route.FOLDER_VIEW,
	route.SPACE_SETTINGS_EDIT_FEATURES,
	route.ADMIN_BULK_PERMISSIONS,
	route.ADMIN_NOTIFICATIONS,
	route.ADMIN_TRASHED_SPACES,
	route.ADMIN_JIRA_MACRO_REPAIR,
	route.ADMIN_IMPORT_SPACES,
	route.ADMIN_MACRO_USAGE,
	route.ADMIN_MACRO_USAGE_LEGACY,
	route.SPACE_MEMBERS,
	route.ADMIN_THEMES,
	route.ADMIN_PDF_EXPORT,
	route.CREATE_TEMPLATE_SITE_LEGACY,
	route.CREATE_TEMPLATE_SPACE_LEGACY,
	route.SITE_SETTINGS_EDIT_TEMPLATE_LEGACY,
	route.SPACE_SETTINGS_TEMPLATES,
	route.SPACE_SETTINGS_EDIT_TEMPLATE_LEGACY,
	route.TOPICS,
	route.SITE_SETTINGS_EDIT_SYSTEM_TEMPLATE_LEGACY,
	route.EDIT_DEFAULT_BLUEPRINT_LEGACY,
	route.SPACE_SETTINGS_DETAILS,
	route.WORKFLOWS,
].reduce(
	(acc, route) => {
		acc[route.name] = route;
		return acc;
	},
	{} as { [name: string]: Route },
);
