import { useMemo } from 'react';

import { useIntl } from 'react-intl-next';

import { getTranslatedItemTitle } from '../../prebuilt/config-items/config-items';
import type { LatestPrompt } from '../../utils/xstate/get-ai-experience-service';

export function useLocalizedLatestPrompt(latestPrompt: LatestPrompt | undefined) {
	const { formatMessage } = useIntl();

	return useMemo(() => {
		return latestPrompt?.presetTitle
			? {
					presetTitle: getTranslatedItemTitle(latestPrompt.presetTitle, formatMessage),
					userInput: latestPrompt?.userInput,
					userADFInput: latestPrompt?.userADFInput,
				}
			: {
					userInput: latestPrompt?.userInput || '',
					userADFInput: latestPrompt?.userADFInput,
				};
	}, [latestPrompt, formatMessage]);
}
