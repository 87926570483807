/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { type DocNode } from '@atlaskit/adf-schema';
import { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type {
	Command,
	FloatingToolbarItem,
	FloatingToolbarConfig,
} from '@atlaskit/editor-common/types';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import type { Node as PMNode } from '@atlaskit/editor-prosemirror/model';
import { type EditorState, type Selection } from '@atlaskit/editor-prosemirror/state';
import Pressable from '@atlaskit/primitives/pressable';
import { ReactRenderer } from '@atlaskit/renderer';
import { RendererActionsContext } from '@atlaskit/renderer/actions';
import { token } from '@atlaskit/tokens';

import { insertInlineSuggestion } from './commands';
import { SuggestionManager } from './handlers/suggestion-manager';
import { getPluginState as getInlineSuggestionsPluginState } from './plugin-factory';
import { type InlineSuggestion } from './states';

const dateNodeHoveredStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
	'& span.date-node: hover': {
		backgroundColor: token('color.background.neutral.hovered'),
	},
});

function shouldShowInlineSuggestionToolbar(
	suggestion: InlineSuggestion,
	selection: Selection,
): boolean {
	return suggestion.from <= selection.from && suggestion.to >= selection.to;
}

function buildSuggestionPreviewDoc(state: EditorState, suggestedNode: PMNode): DocNode | null {
	const { doc, paragraph } = state.schema.nodes;
	const previewDoc = doc.createAndFill({}, paragraph.createAndFill({}, suggestedNode));
	if (!previewDoc) {
		return null;
	}
	const serializer = new JSONTransformer(state.schema);
	return serializer.encode(previewDoc) as DocNode;
}

function createSuggestionToolbarItem({
	inlineSuggestion,
	providerFactory,
}: {
	inlineSuggestion: InlineSuggestion;
	providerFactory: ProviderFactory;
}): FloatingToolbarItem<Command> {
	return {
		type: 'custom',
		render: (view) => {
			if (!view) {
				return null;
			}
			const docNode = buildSuggestionPreviewDoc(view.state, inlineSuggestion.suggestedNode);
			if (!docNode) {
				return null;
			}
			return (
				<Pressable
					onClick={() => {
						insertInlineSuggestion()(view.state, view.dispatch);
						view.focus();
					}}
					backgroundColor="color.background.neutral.subtle"
				>
					<RendererActionsContext>
						<div css={dateNodeHoveredStyle}>
							<ReactRenderer
								document={docNode}
								useSpecBasedValidator
								dataProviders={providerFactory}
							/>
						</div>
					</RendererActionsContext>
				</Pressable>
			);
		},
		fallback: [],
	};
}

function buildInlineSuggestionToolbarConfig({
	inlineSuggestion,
	providerFactory,
	state,
}: {
	inlineSuggestion: InlineSuggestion;
	providerFactory: ProviderFactory;
	state: EditorState;
}): FloatingToolbarConfig {
	const toolbarItem = createSuggestionToolbarItem({ inlineSuggestion, providerFactory });
	return {
		title: 'Inline suggestions',
		getDomRef: () => {
			const anchor = document.querySelector(
				`.${SuggestionManager.INLINE_SUGGESTION_DECORATION_CLASSES.toolbarAnchor}`,
			);
			if (anchor instanceof HTMLElement) {
				return anchor;
			}
			return undefined;
		},
		nodeType: state.schema.nodes.paragraph,
		items: [toolbarItem],
		align: 'left',
	};
}

/**
 * Checks if there's an active inline suggestion that covers the current selection,
 * and if so, returns a floating toolbar config. Otherwise, returns undefined.
 */
export function maybeBuildInlineSuggestionToolbar(
	state: EditorState,
	providerFactory: ProviderFactory,
): FloatingToolbarConfig | undefined {
	const { inlineSuggestion } = getInlineSuggestionsPluginState(state);
	if (!inlineSuggestion) {
		return undefined;
	}
	if (shouldShowInlineSuggestionToolbar(inlineSuggestion, state.selection)) {
		return buildInlineSuggestionToolbarConfig({
			inlineSuggestion: inlineSuggestion,
			providerFactory,
			state,
		});
	}
	return undefined;
}
