import { useContext } from 'react';
import { useIntl } from 'react-intl-next';

import { ExperienceTrackerContext } from '@confluence/experience-tracker';
import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution, getStatusCodeError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';

import { useStyledFlags } from '../../dialogs/useStyledFlags';

interface ResponseError {
	message: string | null;
	extensions: ({ errorType: string | null; statusCode: number | null } | null)[] | null;
}

export interface HandlerArgs {
	error?: Error;
	errors?: ResponseError[] | null;
	expectedErrors?: AllowedError[];
}

type AllowedError = { [statusCode: number]: string };

/**
 * A hook that can be used to handle responses from RBAC GraphQL mutations or queries to
 * track experiences, submit errors, and display flags.
 */
export const useResponseHandler = ({
	experience,
	successMessage,
	errorMessage,
}: {
	experience?: string;
	successMessage?: string;
	errorMessage?: string;
}) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { showSuccessFlag, showErrorFlag } = useStyledFlags();
	const { formatMessage } = useIntl();

	const start = () => {
		experience &&
			experienceTracker.start({
				name: experience,
			});
	};

	const abort = () => {
		experience &&
			experienceTracker.abort({
				name: experience,
				reason: 'User aborted',
			});
	};

	const succeed = () => {
		experience &&
			experienceTracker.succeed({
				name: experience,
			});
		successMessage && showSuccessFlag(`${experience}.succeed`, successMessage);
	};

	const fail = ({ error, errors, expectedErrors }: HandlerArgs) => {
		if (expectedErrors) {
			const schemaErrorCode = errors?.[0]?.extensions?.[0]?.statusCode;
			const unhandledErrorCode = getStatusCodeError(error);
			const errorCodeToCheck = Number(schemaErrorCode) || Number(unhandledErrorCode);

			const expectedErrorMessage = expectedErrors.find((e) => e.hasOwnProperty(errorCodeToCheck))?.[
				errorCodeToCheck
			];
			if (expectedErrorMessage) {
				error && markErrorAsHandled(error);
				showErrorFlag(`${experience}.fail`, expectedErrorMessage);
				return;
			}
		}

		const experienceFailure =
			error ||
			new Error(errors?.[0]?.message || errors?.[0]?.extensions?.[0]?.statusCode?.toString()) ||
			new Error('An error occurred');
		if (error) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.PERMISSIONS_EXPERIENCE,
			});
		}
		experience &&
			experienceTracker.fail({
				name: experience,
				error: experienceFailure,
			});
		showErrorFlag(
			`${experience}.fail`,
			errorMessage ||
				formatMessage({
					id: 'space-roles.response-handler.error',
					defaultMessage: 'Something went wrong',
					description:
						'Default error message for when an action fails and no specific error message is provided, will be shown in a flag',
				}),
		);
	};

	const handleResponse = ({ error, errors, expectedErrors }: HandlerArgs) => {
		// Ignore experience reports if the feature is not enabled
		if (!fg('cc_perms_exp_rbac_fe_milestone_2')) {
			return;
		}
		if (error || (errors && errors?.length > 0)) {
			fail({ error, errors, expectedErrors });
		} else {
			succeed();
		}
	};

	return { handleResponse, startExperience: start, abortExperience: abort };
};
