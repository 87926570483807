/**
 * Type for date picker had to be defined here because the type could not be imported from the datepicker module.
 * It is being declared in typings/atlaskit.d.ts which is preventing imports. If removed, type errors are introduced in components
 * outside of this package.
 *
 */
import type { DateTimePickerSelectProps } from '@atlaskit/datetime-picker';
import type { FieldProps } from '@atlassian/forge-ui-types';
import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	appearance: true,
	defaultIsOpen: true,
	autoFocus: true,
	defaultValue: true,
	disabled: true,
	disabledDateFilter: false,
	maxDate: true,
	minDate: true,
	isOpen: {
		replaceLine: true,
		value: '{...(isOpen ? { isOpen }: undefined)}',
		comment: 'undefined is evaluated as true when isOpen is being explicitly set',
	},
	nextMonthLabel: true,
	parseInputValue: false,
	formatDisplayLabel: false,
	previousMonthLabel: true,
	selectProps: true,
	spacing: true,
	dateFormat: true,
	shouldShowCalendarButton: true,
	placeholder: true,
	locale: true,
	testId: true,
	weekStartDay: true,
	onChange: true,
	value: {
		replaceLine: true,
		value: '{...(value !== undefined ? { value }: undefined)}',
		comment: 'Explicitly setting value to undefined is causing issues',
	},
};
// Type copied from packages/design-system/datetime-picker/src/components/date-picker.tsx
type Appearance = 'default' | 'subtle' | 'none';
type Spacing = 'compact' | 'default';

export type DatePickerProps = {
	appearance?: Appearance;
	autoFocus?: boolean;
	defaultIsOpen?: boolean;
	defaultValue?: string;
	disabled?: string[];
	maxDate?: string;
	minDate?: string;
	isDisabled?: boolean;
	isOpen?: boolean;
	nextMonthLabel?: string;
	previousMonthLabel?: string;
	spacing?: Spacing;
	value?: string;
	isInvalid?: boolean;
	dateFormat?: string;
	placeholder?: string;
	locale?: string;
	testId?: string;
	weekStartDay?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
	onChange?: (value: string) => void;
	selectProps?: DateTimePickerSelectProps;
	shouldShowCalendarButton?: boolean;
} & Partial<Omit<FieldProps, 'onChange'>>;

/**
 * Excluded props:
 * - selectProps - not supporting to simplify the API
 *
 * Excluded function props:
 * - disabledDateFilter - workaround is to use disabled array prop
 * - parseInputValue
 * - formatDisplayLabel
 * - Reason: these functions are being passed by Post Robot, where the response can be resolved but the function cannot be
 * - Possible fix: add custom logic in @forge/react package, or the original component is modified to cater for async functions
 *
 */

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::bbfd87d41daa219e022a1f215dd02100>>
 * @codegenId #date-picker
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen date-picker
 * @codegenParams { "defineOwnType": true, "hasFieldProps": { "value": false, "onChange": false} }
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/types.tsx <<SignedSource::565058cc799c249288166aa59a14e471>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsEvents.tsx <<SignedSource::912bf75b9266c0c95b2e6bf4d8e3c915>>
 * @codegenDependency ../../../../../../../analytics/analytics-next/src/hocs/withAnalyticsContext.tsx <<SignedSource::fe8cfa3b5df7c0625832b02a14245144>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/internal/ff-component.tsx <<SignedSource::0c653d7644911ea31dab287689801197>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/components/date-picker-class.tsx <<SignedSource::ffbc837ec6e8988d752174615ab87c1c>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/components/date-picker-fc.tsx <<SignedSource::b600c46500573290b91049af997acf67>>
 * @codegenDependency ../../../../../../../design-system/datetime-picker/src/components/date-picker.tsx <<SignedSource::0764c6f20c3f1cda8f12a33f9985528a>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { DatePicker as PlatformDatePicker } from '@atlaskit/datetime-picker';
import { adaptEventHandler } from "../../../utils";

// Define the type for DatePicker as DatePickerProps at the top of this file

/**
 * A date time picker allows the user to select an associated date and time.
 */
export const DatePicker = (props: Parameters<RenderFn>[0]) => {
  const {
    appearance,
    defaultIsOpen,
    autoFocus,
    defaultValue,
    disabled,
    maxDate,
    minDate,
    isOpen,
    nextMonthLabel,
    previousMonthLabel,
    selectProps,
    spacing,
    dateFormat,
    shouldShowCalendarButton,
    placeholder,
    locale,
    testId,
    weekStartDay,
    onChange,
    value,
    // Field props
    id,
    isRequired,
    isDisabled,
    isInvalid,
    onBlur,
    onFocus,
    "aria-invalid": ariaInvalid,
    "aria-labelledby": ariaLabelledby,
    name,
  } = props.forgeDoc.props as DatePickerProps;
  // warning: appearance is deprecated;
  // warning: defaultIsOpen is deprecated;
  return (
    <PlatformDatePicker
      appearance={appearance}
      defaultIsOpen={defaultIsOpen}
      autoFocus={autoFocus}
      defaultValue={defaultValue}
      disabled={disabled}
      maxDate={maxDate}
      minDate={minDate}
      // undefined is evaluated as true when isOpen is being explicitly set
      {...(isOpen ? { isOpen }: undefined)}
      nextMonthLabel={nextMonthLabel}
      previousMonthLabel={previousMonthLabel}
      selectProps={selectProps}
      spacing={spacing}
      dateFormat={dateFormat}
      shouldShowCalendarButton={shouldShowCalendarButton}
      placeholder={placeholder}
      locale={locale}
      testId={testId}
      weekStartDay={weekStartDay}
      onChange={onChange}
      // Explicitly setting value to undefined is causing issues
      {...(value !== undefined ? { value }: undefined)}
      // Field props
      id={id}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      onBlur={adaptEventHandler(onBlur)}
      onFocus={adaptEventHandler(onFocus)}
      aria-invalid={ariaInvalid}
      aria-labelledby={ariaLabelledby}
      name={name}
    />
  );
};
/**
 * @codegenEnd
 */
