/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx, css } from '@emotion/react';
import { type RenderFn, type ForgeDoc } from '@atlassian/forge-ui-types';
import { token } from '@atlaskit/tokens';
import { Box, xcss } from '@atlaskit/primitives';
import { useIsMacro } from '../../utils/useIsMacro';

export interface ListProps {
	type: 'ordered' | 'unordered';
	children: React.ReactNode;
}

const defaultMarginStyle = css({
	// indentation spacing is 24px
	marginLeft: token('space.negative.200'),
});

const macroMarginStyle = xcss({
	// push List to the right by 4px to avoid numbered bullet points being cut off in Confluence Macro
	marginLeft: 'space.050',
	// place top margins on List components of 8px using space tokens (closest to 10px), to prevent it being overridden in Confluence Macro
	marginTop: 'space.100',
});

const defaultStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
	lineHeight: token('space.300'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> *:not(:first-of-type)': {
		// throws console error when first-child is used, using first-of-type instead
		marginTop: token('space.050'),
	},
});

// !important is required because macro extension point contains CSS that is overriding these styles
const discStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
	listStyleType: 'disc !important',
});
const circleStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
	listStyleType: 'circle !important',
});
const squareStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
	listStyleType: 'square !important',
});
const decimalStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
	listStyleType: 'decimal !important',
});
const loweralphaStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
	listStyleType: 'lower-alpha !important',
});
const lowerromanStyle = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
	listStyleType: 'lower-roman !important',
});

// bullet style ordering
const UNORDERED_LIST_STYLES = [discStyle, circleStyle, squareStyle];
const ORDERED_LIST_STYLES = [decimalStyle, loweralphaStyle, lowerromanStyle];

// create a type ForgeDocWihData that includes an indentLevel object, to avoid exposing indentLevel prop in ListProps
type ForgeDocWithData = Omit<ForgeDoc, 'children'> & {
	data: { indentLevel: number };
	children: ForgeDocWithData[];
};

export const List = (props: Parameters<RenderFn>[0]) => {
	const { forgeDoc, render } = props;
	const { type } = props.forgeDoc.props as ListProps;

	const forgeDocWithData = forgeDoc as ForgeDocWithData;
	// initialise indentLevel to 0 and add to forgeDocWithData
	if (forgeDocWithData.data === undefined) {
		forgeDocWithData.data = { indentLevel: 0 };
	}

	// the indentLevel is used to determine the list item style that should be rendered for that level
	const { indentLevel } = forgeDocWithData.data;

	const isMacro = useIsMacro();

	// set the list style according to the list type
	const listType = type === 'ordered' ? ORDERED_LIST_STYLES : UNORDERED_LIST_STYLES;

	// set list style
	const listItemStyle = listType[indentLevel % listType.length];

	// pass iterated indentLevel to child
	forgeDocWithData.children.forEach((child) => {
		if (child.type === 'List') {
			child.data = { ...child.data, indentLevel: indentLevel + 1 };
		}
	});

	const listChildren = forgeDocWithData.children.map((child) => render(child));

	const listComponent =
		type === 'ordered' ? (
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
			<ol css={[defaultStyle, listItemStyle, defaultMarginStyle]} key={forgeDocWithData.key}>
				{listChildren}
			</ol>
		) : (
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
			<ul css={[defaultStyle, listItemStyle, defaultMarginStyle]} key={forgeDocWithData.key}>
				{listChildren}
			</ul>
		);

	if (isMacro && indentLevel === 0) {
		// Applying an additional margin when used in a macro extension point as the editor contains styling that causes decimal bullets to overflow and be cut off. The additional margin shifts the List component to prevent the cut off.
		return <Box xcss={macroMarginStyle}>{listComponent}</Box>;
	}
	return listComponent;
};
