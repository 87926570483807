/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment, useEffect, useMemo } from 'react';

import { jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled
import { bind } from 'bind-event-listener';

import DeprecatedButton, { type ButtonProps } from '@atlaskit/button';
import { browser } from '@atlaskit/editor-common/browser';
import type { Keymap } from '@atlaskit/editor-common/keymaps';

import { Shortcut } from '../Shortcut/Shortcut';

import { isKeyboardEventMatchShortcut } from './isKeyboardEventMatchShortcut';
import { buttonAutoFocusRingFix } from './styles';

interface Props extends Omit<ButtonProps, 'onClick'> {
	/** The keymap object containing the shortcut information. */
	shortcut?: Keymap;
	/** The function to be called when the button is clicked. */
	onClick?: () => void;
}

/**
 * Component that renders a button with an optional keyboard shortcut.
 *
 * It binds a keydown event listener to the document to trigger the `onClick` function
 * when the `shortcut` is pressed.
 */
export function ButtonWithShortcut({ shortcut, children, onClick, ...rest }: Props) {
	useEffect(() => {
		const listener = (event: KeyboardEvent) => {
			if (shortcut?.common && isKeyboardEventMatchShortcut(event, shortcut.common)) {
				event.preventDefault();
				event.stopPropagation();
				onClick?.();
			}
		};

		return bind(document, { type: 'keydown', listener });
	}, [onClick, shortcut]);

	const content = useMemo(() => {
		if (!shortcut) {
			return <Fragment>{children}</Fragment>;
		}

		return (
			<Fragment>
				<span aria-keyshortcuts={browser.mac ? shortcut?.mac : shortcut?.windows}>{children}</span>
				<span aria-hidden="true">
					<Shortcut shortcut={shortcut} />
				</span>
			</Fragment>
		);
	}, [shortcut, children]);

	return (
		// Using DeprecatedButton allows css overriding.
		// Ignored via go/ees005
		// eslint-disable-next-line react/jsx-props-no-spreading, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/no-unsafe-style-overrides
		<DeprecatedButton css={buttonAutoFocusRingFix} onClick={onClick} {...rest}>
			{content}
		</DeprecatedButton>
	);
}
