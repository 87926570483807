import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { ModalFooter } from '@atlaskit/modal-dialog';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/new';

const i18n = defineMessages({
	saveButton: {
		id: 'default-space-permissions.edit-confirmation-modal.save-button',
		defaultMessage: 'Save',
		description: 'Text for the save button',
	},
	cancelButton: {
		id: 'default-space-permissions.edit-confirmation-modal.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Text for the cancel button',
	},
});

export const EditConfirmationFooter = ({
	onConfirm,
	onCancel,
}: {
	onConfirm: () => void;
	onCancel: () => void;
}) => {
	const { formatMessage } = useIntl();

	return (
		<ModalFooter>
			<ButtonGroup>
				<Button appearance="subtle" onClick={onCancel}>
					{formatMessage(i18n.cancelButton)}
				</Button>
				<Button appearance="primary" onClick={onConfirm}>
					{formatMessage(i18n.saveButton)}
				</Button>
			</ButtonGroup>
		</ModalFooter>
	);
};
