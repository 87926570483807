import { parse, isValid, addYears } from 'date-fns';

import type { EditorState } from '@atlaskit/editor-prosemirror/state';

import { getPluginState } from '../../plugin-factory';

import { DATE_FORMATS_BY_LOCALE_LIST, LOCALES_LIST } from './date-formats-by-locale';

export class LocaleManager {
	public static readonly LOCALES = LOCALES_LIST;

	public static getSelectedLocale(state: EditorState): string {
		const pluginState = getPluginState(state);
		return pluginState?.locale || 'en-US';
	}
}

export class DateParser {
	private static readonly MIN_DATE_LENGTH: number = 3;
	private static readonly MAX_DATE_LENGTH: number = 20;
	private static readonly DATE_FORMATS_BY_LOCALE = DATE_FORMATS_BY_LOCALE_LIST;

	private static isPotentialDateString(input: string): boolean {
		return (
			/\d/u.test(input) &&
			input.length >= this.MIN_DATE_LENGTH &&
			input.length <= this.MAX_DATE_LENGTH
		);
	}

	private static normalizeYear(date: Date): Date {
		const yearStr = date.getFullYear().toString();
		return yearStr.length < 4 ? addYears(date, 2000) : date;
	}

	private static containsAmbiguousMonth(input: string): boolean {
		const matches = input.match(/[A-Za-z]+/gu);
		if (!matches) {
			return false;
		}
		const longestTokenLength = matches.reduce((max, token) => Math.max(max, token.length), 0);
		return longestTokenLength <= 2;
	}

	public static parse(input: string, locale: string): Date | null {
		if (!this.isPotentialDateString(input)) {
			return null;
		}
		if (this.containsAmbiguousMonth(input)) {
			return null;
		}
		const dateFnsLocale = LocaleManager.LOCALES[locale];
		const formats = this.DATE_FORMATS_BY_LOCALE[locale];
		for (const format of formats) {
			const parsed = parse(input, format, new Date(), { locale: dateFnsLocale });
			if (isValid(parsed)) {
				return this.normalizeYear(parsed);
			}
		}
		return null;
	}
}

export class DateUtils {
	public static getDateOnlyUtcTimestamp(date: Date): number {
		return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
	}
}
