/* eslint-disable @atlaskit/editor/no-re-export */
import { enUS, enGB } from 'date-fns/locale';

// Numeric Date Formats for en-US
const NUMERIC_FORMATS_EN_US = [
	// ====================================================
	// Numeric Date Formats
	// ====================================================
	// Full Dates (4-digit year)
	'M/d/yyyy', // e.g. 1/1/2025
	'M-d-yyyy', // e.g. 1-1-2025
	'M.d.yyyy', // e.g. 1.1.2025
	'MM/dd/yyyy', // e.g. 01/01/2025
	'MM-dd-yyyy', // e.g. 01-01-2025
	'MM.dd.yyyy', // e.g. 01.01.2025
	'yyyy-MM-dd', // ISO style: 2025-01-01

	// Full Dates (2-digit year)
	'M/d/yy', // e.g. 1/1/25
	'M-d-yy', // e.g. 1-1-25
	'M.d.yy', // e.g. 1.1.25
	'MM/dd/yy', // e.g. 01/01/25
	'MM-dd-yy', // e.g. 01-01-25
	'MM.dd.yy', // e.g. 01.01.25

	// Partial Dates (Numeric, no year – defaults to current year)
	'M/d', // e.g. 1/1 or 12/5
	'M-d', // e.g. 1-1 or 12-5
	'M.d', // e.g. 1.1 or 12.5
	'MM/dd', // e.g. 01/01 or 12/05
	'MM-dd', // e.g. 01-01 or 12-05
	'MM.dd', // e.g. 01.01 or 12.05
];

// Numeric Date Formats for en-GB
const NUMERIC_FORMATS_EN_GB = [
	// ====================================================
	// Numeric Date Formats
	// ====================================================
	// Full Dates (4-digit year)
	'd/M/yyyy', // e.g. 1/1/2025
	'd-M-yyyy', // e.g. 1-1-2025
	'd.M.yyyy', // e.g. 1.1/2025
	'dd/MM/yyyy', // e.g. 01/01/2025
	'dd-MM-yyyy', // e.g. 01-01-2025
	'dd.MM.yyyy', // e.g. 01.01.2025
	'yyyy-MM-dd', // ISO style: 2025-01-01

	// Full Dates (2-digit year)
	'd/M/yy', // e.g. 1/1/25
	'd-M-yy', // e.g. 1-1-25
	'd.M.yy', // e.g. 1.1.25
	'dd/MM/yy', // e.g. 01/01/25
	'dd-MM-yy', // e.g. 01-01-25
	'dd.MM.yy', // e.g. 01.01.25

	// Partial Dates (Numeric, no year – defaults to current year)
	'd/M', // e.g. 1/1 or 12/5
	'd-M', // e.g. 1-1 or 12-5
	'd.M', // e.g. 1.1 or 12.5
	'dd/MM', // e.g. 01/01 or 12/05
	'dd-MM', // e.g. 01-01 or 12-05
	'dd.MM', // e.g. 01.01 or 12.05
];

// Shared Month Name Formats (used by both en-US and en-GB)
const SHARED_MONTH_NAME_FORMATS = [
	// ====================================================
	// Month Name Date Formats
	// ====================================================
	// Full Dates (4-digit year)
	'd MMM yyyy', // e.g. 1 Jan 2025
	'd MMMM yyyy', // e.g. 1 January 2025
	'dd MMM yyyy', // e.g. 01 Jan 2025
	'dd MMMM yyyy', // e.g. 01 January 2025
	"MMMM d', 'yyyy", // e.g. January 1, 2025
	'MMMM d yyyy', // e.g. January 1 2025
	"MMM d', 'yyyy", // e.g. Jan 1, 2025
	'MMM d yyyy', // e.g. Jan 1 2025

	// Partial Dates (Month Name, no year – defaults to current year)
	'd MMMM', // e.g. 1 January
	'd MMM', // e.g. 1 Jan
	'do MMMM', // e.g. 1st January
	'do MMM', // e.g. 1st Jan
	"do 'of' MMMM", // e.g. 1st of January
	"do 'of' MMM", // e.g. 1st of Jan
	'MMMM d', // e.g. January 1
	'MMM d', // e.g. Jan 1
];

export const DATE_FORMATS_BY_LOCALE_LIST: Record<string, string[]> = {
	'en-US': [...NUMERIC_FORMATS_EN_US, ...SHARED_MONTH_NAME_FORMATS],
	'en-GB': [...NUMERIC_FORMATS_EN_GB, ...SHARED_MONTH_NAME_FORMATS],
};

export const LOCALES_LIST: Record<string, Locale> = {
	enUS,
	enGB,
};
