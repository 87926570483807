import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { checkAssistanceServiceFreeGenerateFg } from '@atlassian/editor-ai-common/utils/check-assistance-service-fg';
import sharedMessages from '@atlassian/generative-ai-modal/utils/messages';

import { FreeGenerateIcon } from '../../../../ui/icons/prompt/freeGenerate';
import {
	createInsertAtCurrentPosition,
	createInsertBelow,
	createReplace,
} from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type { EditorPluginAIConfigItem } from '../../config-items';

import { messages } from './messages';

export const freeGenerate: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.FREE_GENERATE,
	title: sharedMessages.aiButtonTitle,
	description: sharedMessages.aiUniversalLongCallToAction,
	promptHint: messages.promptHint,
	icon: ({ formatMessage }) => (
		<FreeGenerateIcon alt={formatMessage(sharedMessages.aiButtonTitle)} />
	),
	doNotHighlightDocWhenSelectionIsEmpty: true,
	agentId: 'editor_agent',
	canTogglePageKnowledge: true,
	quickInsertPriority: Number.NEGATIVE_INFINITY,
	intentSchemaId: ({ latestPromptResponse }) => {
		return latestPromptResponse
			? 'custom_with_interrogation_intent_schema.json'
			: 'custom_free_generate_intent_schema.json';
	},
	actions: {
		empty: [createInsertAtCurrentPosition({ appearance: 'primary' })],
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
	getBackendModel: (isInterrogate) => {
		if (isInterrogate && fg('platform_editor_ai_interrogate_with_convo_ai')) {
			return 'assistance-service';
		}

		if (!isInterrogate && checkAssistanceServiceFreeGenerateFg()) {
			return 'assistance-service';
		}

		// If we're not interrogating, we use xp-gen-ai
		return 'xp-gen-ai';
	},
};
