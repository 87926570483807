import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { SummarizeIcon } from '../../../../ui/icons/prompt/summarize';
import _messages from '../../../messages';
import {
	createInsertAtCurrentPosition,
	createInsertBelow,
	createReplace,
} from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

import { messages } from './messages';

export const summarizeWriting: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.SUMMARISE_WRITING,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <SummarizeIcon shownAt={shownAt} />,
	showInRefineDropdown: true,
	intentSchemaId: 'summarize_intent_schema.json',
	actions: {
		empty: [createInsertAtCurrentPosition({ appearance: 'primary' })],
		selection: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
	},
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
};

export const summarizeWritingWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: summarizeWriting,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};

export const summarizeWritingWithOptionsForAtlas: EditorPluginAIConfigItemWithOptions = {
	config: { ...summarizeWriting, description: _messages.atlasSummarizePageConfigItemDescription },
	triggers: { empty: { docMinSize: 1 }, selection: false },
};
