import React from 'react';
import uuid from 'uuid/v4';
import type { IntlShape } from 'react-intl-next';
import { defineMessages } from 'react-intl-next';

import type { EditorActions } from '@atlaskit/editor-core';
import type { ExtensionManifest } from '@atlaskit/editor-common/extensions';
import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';

import type { ExtensionSource } from '@confluence/change-edition/entry-points/PremiumExtensionsUpsell';
import { LoadableAfterPaint } from '@confluence/loadable';
import { fg } from '@confluence/feature-gating';

import type { LinkCardsParameters } from '../linkCardsTypes';
import { RenderVariant, ImagePosition } from '../linkCardsTypes';
import { linkCardsExtensionType } from '../linkCardsExtensionType';
import { CARDS_VERSION_1, CARDS_VERSION_2 } from '../linkCardsVersion';
import { DEFAULT_CARDS_SIZE } from '../LinkCardsConfigPanel/components/Fields/SizeField';

import type { LinkCardsExtensionProps } from './LinkCardsExtension';

const LinkCardsExtension = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LinkCardsExtension" */ './LinkCardsExtension'))
			.LinkCardsExtension,
});

const cardImages = [
	{
		src: 'https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?auto=format&fit=crop&q=80&w=1080',
		alt: 'green mountain across body of water',
	},
	{
		src: 'https://images.unsplash.com/photo-1501436513145-30f24e19fcc8?auto=format&fit=crop&q=80&w=1080',
		alt: 'calm body of water during golden hour',
	},
	{
		src: 'https://images.unsplash.com/photo-1580100586938-02822d99c4a8?auto=format&fit=crop&q=80&w=1080',
		alt: 'body of water near mountain during daytime',
	},
];

const i18n = defineMessages({
	linkCardsMacroTitle: {
		id: 'custom-sites-extensions.link-cards.manifest.macro-title',
		defaultMessage: 'Cards',
		description: 'Title of link cards macro to be displayed in toolbar/shortcut macros dropdown.',
	},
	linkCardsMacroDescription: {
		id: 'custom-sites-extensions.link-cards.manifest.macro-description',
		defaultMessage: 'Display links as cards',
		description:
			'Description of link cards macro to be displayed in toolbar/shortcut macros dropdown',
	},
	linkCardsMacroTitleNew: {
		id: 'custom-sites-extensions.link-cards.manifest.macro-title.new',
		defaultMessage: 'Cards [New]',
		description:
			'Title of link cards macro to be displayed in toolbar/shortcut macros dropdown. The [New] text is a text label indicating that this feature is new',
	},
});

export const createCard = (imageSrc: string, imageAltText: string) => ({
	cardId: uuid(),
	link: '',
	isLinkUserEdited: false,
	title: '',
	isTitleUserEdited: false,
	description: '',
	isDescriptionUserEdited: false,
	imageSrc,
	imagePosition: ImagePosition.MIDDLE,
	imageAltText,
});

let cards: ReturnType<typeof createCard>[] | null = null;
export const getDefaultCards = () => {
	if (cards) {
		return cards;
	}
	return (cards = cardImages.map(({ src, alt }) => createCard(src, alt)));
};

const linkCardsParameters = (
	extensionTitle: string,
	isDynamicCardsEnabled: boolean,
): LinkCardsParameters => {
	const parameters: LinkCardsParameters = {
		size: DEFAULT_CARDS_SIZE,
		cards: getDefaultCards(),
		isAvatarShown: true,
		isPublishDateShown: false,
		extensionTitle,
		version: undefined as any,
		renderVariant: undefined as any,
		isSegmentationShown: false,
	};

	if (isDynamicCardsEnabled) {
		parameters.version = CARDS_VERSION_2;
		parameters.renderVariant = RenderVariant.MANUAL;
	} else {
		parameters.version = CARDS_VERSION_1;
	}

	return parameters;
};

type LinkCardsManifestArguments = {
	editorActions?: EditorActions;
	openLinkCardsConfigPanel?: (localId: string) => void;
	intl: IntlShape;
	setUpsellModalSource?: (source: ExtensionSource) => void;
} & Pick<LinkCardsExtensionProps, 'contentId' | 'createAnalyticsEvent'>;

export const getLinkCardsExtensionManifest = ({
	editorActions,
	openLinkCardsConfigPanel,
	intl,
	setUpsellModalSource,
	...extensionPassThroughProps
}: LinkCardsManifestArguments): ExtensionManifest<LinkCardsParameters> => {
	return {
		title: intl.formatMessage(i18n.linkCardsMacroTitleNew),
		type: linkCardsExtensionType,
		key: 'cards',
		description: intl.formatMessage(i18n.linkCardsMacroDescription),
		icons: {
			'48': () =>
				import(
					/* webpackChunkName: "loadable-CardMacroIcon" */
					'@confluence/icons/entry-points/CardMacroIcon'
				).then((mod) => mod.CardMacroIcon),
		},
		modules: {
			quickInsert: setUpsellModalSource
				? [
						{
							key: 'cards-upsell',
							action: async () => setUpsellModalSource('premiumCardsInsert'),
						},
					]
				: [
						{
							key: 'default-cards',
							action: {
								type: 'node',
								key: 'default',
								parameters: linkCardsParameters(
									intl.formatMessage(i18n.linkCardsMacroTitle),
									fg('confluence_company_hub_dynamic_cards'),
								),
							},
						},
					],
			nodes: {
				default: {
					type: 'extension',
					render:
						async () =>
						({ node }) => {
							return (
								<LinkCardsExtension
									extensionNode={node}
									openLinkCardsConfigPanel={openLinkCardsConfigPanel}
									editorActions={editorActions}
									{...extensionPassThroughProps}
								/>
							);
						},
					update: async () => {
						const selectedNode = editorActions?.getSelectedNode()?.toJSON();
						const selectedLocalId = selectedNode?.attrs?.localId;

						if (!!selectedLocalId && openLinkCardsConfigPanel) {
							openLinkCardsConfigPanel(selectedLocalId);
						}
					},
				},
			},
		},
	};
};

export const linkCardsExtensionProvider = async ({
	contentId,
	editorActions,
	openLinkCardsConfigPanel,
	intl,
	setUpsellModalSource,
	createAnalyticsEvent,
}: LinkCardsManifestArguments) =>
	new DefaultExtensionProvider<any>([
		getLinkCardsExtensionManifest({
			contentId,
			editorActions,
			openLinkCardsConfigPanel,
			intl,
			setUpsellModalSource,
			createAnalyticsEvent,
		}),
	]);
