import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import cloneDeep from 'lodash/cloneDeep';

import { Stack, xcss, Inline, media, Grid, Box } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import Checkbox from '@atlaskit/checkbox';
import { token } from '@atlaskit/tokens';

import type { NormalizedCheckboxesType } from '@confluence/space-roles/entry-points/normalizers';

const i18n = defineMessages({
	rolePermissionsHeading: {
		id: 'default-space-permissions.set-role-modal.role-permissions-heading',
		defaultMessage: 'Select role permissions<asterisk>*</asterisk>',
		description: 'Heading for the input field where the user can enter the role permissions',
	},
});

const rolePermissionSectionStyle = xcss({
	gap: 'space.300',
	marginTop: 'space.400',
	paddingTop: 'space.400',
	borderTop: `1px solid ${token('color.border')}`,
});

const rolePermissionsHeadingStyle = xcss({
	paddingBottom: 'space.100',
});

const checkboxContainerStyle = xcss({
	rowGap: 'space.200',
	columnGap: 'space.600',
	[media.above.sm]: { gridTemplateColumns: 'repeat(2, 1fr)' },
});

const checkboxGroupStyle = xcss({
	gap: 'space.150',
});

const getCheckboxUpdates = (
	checkboxes: Record<string, NormalizedCheckboxesType>,
	checkboxId: string,
) => {
	const updatedCheckboxes = cloneDeep(checkboxes);
	Object.keys(updatedCheckboxes).forEach((groupName) => {
		updatedCheckboxes[groupName].group.checkboxes = updatedCheckboxes[
			groupName
		].group.checkboxes.map((checkbox) => {
			if (checkbox.id === checkboxId) {
				return {
					...checkbox,
					isAllowed: !checkbox.isAllowed,
				};
			}
			return checkbox;
		});
	});
	return updatedCheckboxes;
};

export const SetRolePermissionsSection = ({
	rolePermissionsObject,
	setRolePermissionsObject,
	asterisk,
}: {
	rolePermissionsObject: Record<number, NormalizedCheckboxesType>;
	setRolePermissionsObject: React.Dispatch<
		React.SetStateAction<Record<number, NormalizedCheckboxesType>>
	>;
	asterisk: () => React.ReactNode;
}) => {
	const { formatMessage } = useIntl();

	const onCheckBoxChange = useCallback(
		(checkboxId: string) => {
			const updatedCheckboxes = getCheckboxUpdates(rolePermissionsObject, checkboxId);
			setRolePermissionsObject(updatedCheckboxes);
		},
		[rolePermissionsObject, setRolePermissionsObject],
	);

	return (
		<Stack xcss={rolePermissionSectionStyle}>
			<Heading size="xsmall">
				<Inline xcss={rolePermissionsHeadingStyle}>
					{formatMessage(i18n.rolePermissionsHeading, {
						asterisk,
					})}
				</Inline>
			</Heading>
			<Grid xcss={checkboxContainerStyle}>
				{Object.entries(rolePermissionsObject).map(([groupName, grouping]) => (
					<Stack key={groupName} xcss={checkboxGroupStyle}>
						<Heading size="small">{groupName}</Heading>
						<Box>
							{grouping.group.checkboxes.map((checkboxInfo) => (
								<Checkbox
									key={checkboxInfo.id}
									testId={checkboxInfo.id}
									isChecked={checkboxInfo.isAllowed}
									isDisabled={checkboxInfo.isDisabled}
									onChange={() => onCheckBoxChange(checkboxInfo.id)}
									label={checkboxInfo.displayName}
								/>
							))}
						</Box>
					</Stack>
				))}
			</Grid>
		</Stack>
	);
};
