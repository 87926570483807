import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { FindActionItemsIcon } from '../../../../ui/icons/prompt/findActionItems';
import {
	createInsertAtCurrentPosition,
	createInsertAtTop,
	createInsertBelow,
} from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

import { messages } from './messages';

export const findActionItems: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.FIND_ACTION_ITEMS,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <FindActionItemsIcon shownAt={shownAt} />,
	intentSchemaId: 'action_items_intent_schema.json',
	actions: {
		empty: [
			createInsertAtTop({ appearance: 'secondary' }),
			createInsertAtCurrentPosition({ appearance: 'primary' }),
		],
		selection: [
			createInsertAtTop({ appearance: 'secondary' }),
			createInsertBelow({ appearance: 'primary' }),
		],
	},
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
};

export const findActionItemsWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: findActionItems,
	triggers: { empty: { docMinSize: 1 }, selection: true },
};
