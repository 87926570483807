import React, { useCallback, useState } from 'react';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';

import ModalTransition from '@atlaskit/modal-dialog';

import { useResponseHandler } from '@confluence/space-roles/entry-points/graphql';
import { SITE_RBAC_EDIT_EXPERIENCE } from '@confluence/experience-tracker';
import type { NormalizedCheckboxesType } from '@confluence/space-roles/entry-points/normalizers';
import {
	getNormalizedPermissionItems,
	normalizeSpacePermissionAll,
} from '@confluence/space-roles/entry-points/normalizers';

import type { RoleNodes } from '../roles-list/useRoleList';
import type { SpacePermissionNodes } from '../page/useSpacePermissions';

import { EditConfirmationModal } from './edit-confirmation/EditConfirmationModal';
import { RoleFormModal } from './role-form/RoleFormModal';

type OpenDialogType = 'form' | 'edit-confirmation';

type NewRoleProps = {
	isNewRole: true;
	roleId?: never;
	roleName?: never;
	roleDescription?: never;
	rolePermissions?: never;
};

type ExistingRoleProps = {
	isNewRole: false;
	roleId: string;
	roleName: string;
	roleDescription: string;
	rolePermissions: string[];
};

type SetRoleModalProps = {
	allRoles: RoleNodes;
	allPermissions: SpacePermissionNodes;
	onClose: () => void;
} & (NewRoleProps | ExistingRoleProps);

export const SetRoleModal = ({
	isNewRole,
	allRoles,
	allPermissions,
	onClose,
	roleId = '',
	roleName = '',
	roleDescription = '',
	rolePermissions = [],
}: SetRoleModalProps) => {
	const [newRoleName, setNewRoleName] = useState<string>(roleName);
	const [newRoleDescription, setNewRoleDescription] = useState<string>(roleDescription);
	const [newRolePermissionsObject, setNewRolePermissionsObject] = useState<
		Record<number, NormalizedCheckboxesType>
	>(getNormalizedPermissionItems(rolePermissions, normalizeSpacePermissionAll(allPermissions)));
	const newRolePermissions = Object.values(newRolePermissionsObject)
		.flatMap((group) => group.group.checkboxes)
		.filter((checkbox) => checkbox.isAllowed)
		.map((checkbox) => checkbox.id);
	const [openDialog, setOpenDialog] = useState<OpenDialogType>('form');

	const { abortExperience } = useResponseHandler({
		experience: SITE_RBAC_EDIT_EXPERIENCE,
	});

	const onConfirm = useCallback(() => {
		// TODO: Fire mutation
		noop();
		onClose();
	}, [onClose]);

	const onSave = useCallback(() => {
		if (!isNewRole && !isEqual(rolePermissions, newRolePermissions)) {
			setOpenDialog('edit-confirmation');
		} else {
			onConfirm();
		}
	}, [isNewRole, rolePermissions, newRolePermissions, onConfirm]);

	const onCancel = useCallback(() => {
		abortExperience();
		onClose();
	}, [abortExperience, onClose]);

	return (
		<ModalTransition>
			{openDialog === 'form' ? (
				<RoleFormModal
					newRoleName={newRoleName}
					newRoleDescription={newRoleDescription}
					setNewRoleName={setNewRoleName}
					setNewRoleDescription={setNewRoleDescription}
					newRolePermissionsObject={newRolePermissionsObject}
					setNewRolePermissionsObject={setNewRolePermissionsObject}
					areChangesStaged={
						!isEqual(roleName, newRoleName) ||
						!isEqual(roleDescription, newRoleDescription) ||
						!isEqual(rolePermissions, newRolePermissions)
					}
					hasRequiredFields={[newRoleName, newRoleDescription, newRolePermissions.length].every(
						Boolean,
					)}
					onSave={onSave}
					onCancel={onCancel}
				/>
			) : (
				<EditConfirmationModal
					roleId={roleId}
					roleName={newRoleName}
					onCancel={() => setOpenDialog('form')}
					onConfirm={onConfirm}
					allRoles={allRoles}
				/>
			)}
		</ModalTransition>
	);
};
