import React, { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import ModalDialog, { ModalBody, ModalTransition, ModalHeader } from '@atlaskit/modal-dialog';
import { Text, Stack } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';

import { useResponseHandler } from '@confluence/space-roles/entry-points/graphql';
import { SITE_RBAC_EDIT_EXPERIENCE } from '@confluence/experience-tracker';

import type { RoleNodes } from '../roles-list/useRoleList';

import { DeleteRoleFooter } from './DeleteRoleFooter';
import { DeletionResultSelection } from './DeletionResultSelection';

const i18n = defineMessages({
	title: {
		id: 'default-space-permissions.roles-management.delete-role-modal.title',
		defaultMessage: 'Delete custom role {roleName}?',
		description:
			'Title of a dialog for the deleting a custom role, it will have the role name interpolated',
	},
	body: {
		id: 'default-space-permissions.roles-management.delete-role-modal.body',
		defaultMessage:
			'Deleting this role will leave users will affect users across all spaces that are assigned this role. You can reassign them a different role or you can choose to not reassign. <b>This can’t be undone.</b>',
		description:
			'Body of a dialog for the deleting a custom role with a warning about the consequences of the action',
	},
});

export type DeletionResultSelectionType = 'delete' | 'reassign';

export const DeleteRoleModal = ({
	roleId,
	roleName,
	onClose,
	allRoles,
}: {
	roleId: string;
	roleName: string;
	onClose: () => void;
	allRoles: RoleNodes;
}) => {
	const { formatMessage } = useIntl();
	const [deletionResult, setDeletionResult] = useState<DeletionResultSelectionType>('reassign');
	const { abortExperience } = useResponseHandler({
		experience: SITE_RBAC_EDIT_EXPERIENCE,
	});
	const onCancel = useCallback(() => {
		abortExperience();
		onClose();
	}, [abortExperience, onClose]);

	return (
		<ModalTransition>
			<ModalDialog onClose={onCancel}>
				<ModalHeader>
					<Heading size="large">{formatMessage(i18n.title, { roleName })}</Heading>
				</ModalHeader>
				<ModalBody>
					<Stack space="space.200">
						<Text as="p">
							{formatMessage(i18n.body, {
								b: (text: React.ReactNode) => <b>{text}</b>,
							})}
						</Text>
						<DeletionResultSelection
							roleId={roleId}
							deletionResult={deletionResult}
							setDeletionResult={setDeletionResult}
							allRoles={allRoles}
						/>
					</Stack>
				</ModalBody>
				<DeleteRoleFooter deletionResult={deletionResult} onClose={onClose} onCancel={onCancel} />
			</ModalDialog>
		</ModalTransition>
	);
};
