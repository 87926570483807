import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::c55b5461bf4028c7d58e3ec05ba11551>>
 * @codegenId #modal-header
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen modal-header
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/modal-header.tsx <<SignedSource::dd01003e02ca9bf5479280186b929e3f>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/hooks.tsx <<SignedSource::5062e27591547b31bd9e5702d12d3685>>
 * @codegenDependency ../../../../../../../design-system/modal-dialog/src/internal/constants.tsx <<SignedSource::ada7ffa5504f59d6cd6568e5046b743c>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { ModalHeader as PlatformModalHeader } from '@atlaskit/modal-dialog';

type PlatformModalHeaderProps = React.ComponentProps<typeof PlatformModalHeader>;

export type ModalHeaderProps = Pick<
  PlatformModalHeaderProps,
  'children' | 'testId'
>;

/**
 * A modal dialog displays content that requires user interaction, in a layer above the page.
 */
export const ModalHeader = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as ModalHeaderProps;
  return (
    <PlatformModalHeader
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
