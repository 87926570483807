import React, { useState } from 'react';
import { useUID } from 'react-uid';
import { type FieldChildrenProps, type NativeUserPickerProps } from '@atlassian/forge-ui-types';

import AKFormField from '@atlaskit/form/Field';
import AKUserPicker from '@atlaskit/user-picker';
import AKSmartUserPicker from '@atlaskit/smart-user-picker';
import { HelperMessage as AKHelperMessage, ErrorMessage as AKErrorMessage } from '@atlaskit/form';

import { PortalConsumer } from '../../../context';
import { FormStateChangeNotifier } from '../../UIKit1/form';

import { type UserPickerOption } from '../../UIKit1/form/transformFormData';
import { type UserQuery } from '../../UIKit1/userPicker/providers/useMentionResource';
import type { Dependencies } from './types';

const BITBUCKET_FIELD_ID = 'BitbucketMentions';

const validate = (value: any) =>
	!value || (Array.isArray(value) && value.length === 0) ? 'EMPTY' : undefined;

type InnerUserPickerProps = Omit<NativeUserPickerProps, 'defaultValue'> &
	Omit<Dependencies, 'client'> & {
		id: string;
		width: string;
		query?: UserQuery;
		defaultValue?: UserPickerOption | UserPickerOption[];
	};

// the value prop in UserPicker component can only take undefined for empty value.
// null is not allowed and will resulted in unexpected behavior.
const ensureUserPickerValue: <T>(value: T) => T | undefined = (value) => {
	if (!value || (Array.isArray(value) && value.length === 0)) {
		return undefined;
	}
	return value;
};

// by forcing a key update when defaultValue is present, we force the SmartUserPicker to re-render.
// this is because the SmartUserPicker does not re-render when the defaultValue changes in its
// current implementation.
const makeUserPickerKey = (id: string, defaultValue?: UserPickerOption | UserPickerOption[]) => {
	if (!defaultValue || (Array.isArray(defaultValue) && defaultValue.length === 0)) {
		return id;
	}
	return `${id}-with-default`;
};

const RawUserPicker = ({
	id,
	description,
	placeholder,
	isMulti,
	includeUsers = true,
	includeGroups = false,
	accountId: currentAccountId,
	cloudId: suppliedCloudId,
	productKey,
	baseUrl,
	onChange,
	query,
	defaultValue,
	value: providedValue,
	width,
	productAttributes,
}: InnerUserPickerProps & {
	value?: UserPickerOption | UserPickerOption[];
}) => {
	const [value, setValue] = useState<any>(defaultValue);

	return (
		<PortalConsumer>
			{(portal) => (
				<>
					{suppliedCloudId && productKey ? (
						<AKSmartUserPicker
							key={makeUserPickerKey(id, defaultValue)}
							value={ensureUserPickerValue(providedValue ?? value)}
							onChange={(user: any) => {
								setValue(user);
								onChange?.(user);
							}}
							isMulti={isMulti}
							menuPortalTarget={portal || undefined}
							noOptionsMessage={() => null}
							placeholder={placeholder}
							width={width}
							fieldId={
								// Bitbucket User Recommendations does not support generic contextType
								productKey === 'bitbucket' ? BITBUCKET_FIELD_ID : id
							}
							debounceTime={400}
							includeUsers={includeUsers}
							includeGroups={includeGroups}
							siteId={suppliedCloudId}
							productAttributes={productAttributes}
							// If principalId === 'Context', upstream attempts to use other context
							// to discover the principal ID
							principalId={currentAccountId ?? 'Context'}
							productKey={productKey}
							baseUrl={baseUrl}
							inputId={id}
							defaultValue={defaultValue}
						/>
					) : (
						<AKUserPicker
							value={ensureUserPickerValue(providedValue ?? value)}
							onChange={(user: any) => {
								setValue(user);
								onChange?.(user);
							}}
							isMulti={isMulti}
							menuPortalTarget={portal || undefined}
							noOptionsMessage={() => null}
							placeholder={placeholder}
							width={width}
							loadOptions={query}
							fieldId={null}
							inputId={id}
							defaultValue={defaultValue}
						/>
					)}
					{description && <AKHelperMessage>{description}</AKHelperMessage>}
				</>
			)}
		</PortalConsumer>
	);
};

const FormUserPicker = ({
	id,
	name,
	label,
	description,
	placeholder,
	isRequired,
	isMulti,
	includeUsers = true,
	includeGroups = false,
	accountId,
	cloudId,
	productKey,
	baseUrl,
	onChange,
	query,
	defaultValue,
	width,
	productAttributes,
}: InnerUserPickerProps) => {
	return (
		<AKFormField
			id={id}
			name={name}
			label={label}
			defaultValue={defaultValue}
			validate={isRequired ? validate : undefined}
			isRequired={isRequired}
		>
			{({ fieldProps, error }: FieldChildrenProps & { error?: string }) => {
				const { id, value } = fieldProps;

				return (
					<>
						<FormStateChangeNotifier name={name} value={value} />
						<RawUserPicker
							id={id}
							name={name}
							label={label}
							value={value}
							placeholder={placeholder}
							isMulti={isMulti}
							includeUsers={includeUsers}
							includeGroups={includeGroups}
							accountId={accountId}
							cloudId={cloudId}
							productAttributes={productAttributes}
							productKey={productKey}
							baseUrl={baseUrl}
							onChange={(user) => {
								fieldProps.onChange(user);
								onChange?.(user);
							}}
							query={query}
							defaultValue={defaultValue}
							width={width}
						/>
						{error === 'EMPTY' && <AKErrorMessage>This field is required.</AKErrorMessage>}
						{description && <AKHelperMessage>{description}</AKHelperMessage>}
					</>
				);
			}}
		</AKFormField>
	);
};

// This is a wrapper containing the core logic of user picker
// It has been separated to take query as props so that it can be testable in Jest and rendered in Storybook
export const UserPicker = (props: Omit<InnerUserPickerProps, 'id' | 'width'>) => {
	const uid = useUID();

	return <FormUserPicker id={uid} width="100%" {...props} />;
};
