import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { BrainstormIcon } from '../../../../ui/icons/prompt/brainstorm';
import { createInsertAtCurrentPosition } from '../../actions/create-actions';
import { CONFIG_ITEM_KEYS } from '../../config-item-keys';
import type {
	EditorPluginAIConfigItemWithOptions,
	EditorPluginAIConfigItem,
} from '../../config-items';

import { messages } from './messages';

export const brainstorm: EditorPluginAIConfigItem = {
	key: CONFIG_ITEM_KEYS.BRAINSTORM,
	title: messages.title,
	description: messages.description,
	icon: ({ shownAt }) => <BrainstormIcon shownAt={shownAt} />,
	promptLabel: messages.promptLabel,
	promptHint: messages.promptHint,
	doNotHighlightDocWhenSelectionIsEmpty: true,
	agentId: 'editor_agent',
	intentSchemaId: 'brainstorm_intent_schema.json',
	alwaysNoDocAndSelection: true,
	actions: {
		empty: [createInsertAtCurrentPosition({ appearance: 'primary' })],
	},
	getBackendModel: () => {
		if (fg('platform_editor_ai_assistance_service')) {
			return 'assistance-service';
		}

		return 'xp-gen-ai';
	},
};

export const brainstormWithOptions: EditorPluginAIConfigItemWithOptions = {
	config: brainstorm,
	triggers: { empty: { docMinSize: 0 }, selection: false },
};
