import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { Global } from '@emotion/react';

import { EVENT_TYPE } from '@atlaskit/editor-common/analytics';
import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { SafePlugin } from '@atlaskit/editor-common/safe-plugin';
import type {
	EditorPlugin,
	PMPluginFactoryParams,
	QuickInsertHandler,
} from '@atlaskit/editor-common/types';
import { WithPluginState } from '@atlaskit/editor-common/with-plugin-state';
import type { EditorState, PluginKey } from '@atlaskit/editor-prosemirror/state';
import { DecorationSet, type EditorView } from '@atlaskit/editor-prosemirror/view';
import { isResolvingMentionProvider, type MentionProvider } from '@atlaskit/mention/resource';
import { isPromise } from '@atlaskit/mention/types';
import { fg } from '@atlaskit/platform-feature-flags';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';
import { isFedRamp } from '@atlassian/atl-context';
import { useAIThemeColor } from '@atlassian/generative-ai-modal/styles/theme';

import type { AIPlugin } from './aiPluginType';
import { openAIModal } from './editor-actions/actions';
import { createAIAuditLogPlugin } from './pm-plugins/ai-audit-logs/ai-audit-log-plugin';
import { createAIButtonPlugin } from './pm-plugins/ai-button/ai-button-plugin';
import { createAIEventHubPlugin } from './pm-plugins/ai-event-hub/ai-event-hub-plugin';
import { createEndAIExperienceCommand } from './pm-plugins/decoration/actions';
import { createDecorationPlugin } from './pm-plugins/decoration/decoration-plugin';
import { getPluginState } from './pm-plugins/decoration/decoration-plugin-factory';
import { aiExperienceDecorationPluginKey } from './pm-plugins/decoration/decoration-plugin-key';
import type { AIDecorationExperiencePluginState } from './pm-plugins/decoration/reducer';
import { hasGeneratedContentDecorations } from './pm-plugins/decoration/utils/hasGeneratedContentDecorations';
import {
	getAIExperienceButton,
	getImproveWritingAIButton,
} from './pm-plugins/floating-toolbar-button/floating-toolbar-button';
import { messages as selectionToolbarMessages } from './pm-plugins/floating-toolbar-button/selection-toolbar-messages';
import { createGetEditorViewPlugin } from './pm-plugins/get-editor-view/get-editor-view-plugin';
import { createInlineSuggestionPlugin } from './pm-plugins/inline-suggestions/inline-suggestion-plugin';
import { rovoAgentsPluginKey } from './pm-plugins/rovo-agents/plugin-key';
import { createRovoAgentsPlugin } from './pm-plugins/rovo-agents/rovo-agents-plugin';
import { createSelectionPreviewPlugin } from './pm-plugins/selection-preview/selection-preview-plugin';
import { createSelectionPlugin } from './pm-plugins/selection/selection-plugin';
import { selectionPluginKey as aiExperienceSelectionPluginKey } from './pm-plugins/selection/selection-plugin-key';
import {
	getVisibleEditorPluginAIConfigItems,
	type EditorPluginAIConfigItem,
} from './prebuilt/config-items/config-items';
import { mapConfigItemsToQuickInsertItems } from './prebuilt/config-items/utils/map-config-items-to-quick-insert-items';
import { type EditorPluginAIInitAEP } from './types/analytics';
import type { AIGlobalOptIn, EditorPluginAIProvider, EndExperience } from './types/types';
import { AgentFetcher } from './ui/components/AgentFetcher/AgentFetcher';
import { LoadableBrowseRovoAgentsModal } from './ui/components/BrowseRovoAgentsModal/BrowseRovoAgentsModal';
import { ExperienceApplication } from './ui/components/ExperienceApplication/ExperienceApplication';
import { ModalRegionErrorBoundary } from './ui/components/ExperienceApplicationErrorBoundary/ExperienceApplicationErrorBoundary';
import { ModalRegion } from './ui/components/ModalRegion/ModalRegion';
import { globalStyles } from './ui/components/ModalRegion/styles';
import { PublishToRovo } from './ui/components/PublishToRovo/PublishToRovo';
import { RovoEnabled } from './ui/components/RovoEnabled/RovoEnabled';
import { SubscribeToRovo } from './ui/components/SubscribeToRovo/SubscribeToRovo';
import { getFloatingToolbarConfig } from './ui/toolbars/floating-toolbar';
import { getAIPrimaryToolbarComponent } from './ui/toolbars/primary-toolbar';
import { getPrimaryToolbarLegacyComponent } from './ui/toolbars/primary-toolbar-legacy';
import { getAIHighlightPositions } from './utils/selection';
import { aiActorCache } from './utils/xstate/ai-actor-cache';

const GlobalWrapper = ({ children }: { children: React.ReactNode }) => {
	const aiThemeColor = useAIThemeColor();

	return (
		<>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
			<Global styles={globalStyles(aiThemeColor)} />
			{children}
		</>
	);
};

/**
 * This is an {@link EditorPlugin}.
 * [Editor Plugins](https://product-fabric.atlassian.net/wiki/spaces/E/pages/56115413/New+Editor+Architecture#NewEditorArchitecture-Implementation)
 * are the mechanism for extending the editor with additional functionality such as nodes, marks, UI components etc.
 *
 * This EditorPlugin is responsible for adding such functionality for the generative ai experiences in the Editor.
 *
 * It is setup with an {@link EditorPluginAIProvider} which provides the configuration for the plugin including;
 * - the api to use for generating responses to prompts
 * - and the list of prompts that should be available in the editor grouped by category (empty, range)
 * @see {@link EditorPluginAIProvider} for more details.
 */
export class EditorPluginAI implements EditorPlugin {
	name = 'aiExperience' as const;
	editorPluginAIProvider: EditorPluginAIProvider;

	// Facade EditorPlugin methods
	pmPlugins?: EditorPlugin['pmPlugins'];
	pluginsOptions?: EditorPlugin['pluginsOptions'];
	primaryToolbarComponent?: EditorPlugin['primaryToolbarComponent'];
	contentComponent?: EditorPlugin['contentComponent'];

	private nextPlugin?: ReturnType<AIPlugin>;

	constructor({
		editorPluginAIProvider,
		aiGlobalOptIn,
	}: {
		editorPluginAIProvider: EditorPluginAIProvider;
		aiGlobalOptIn: AIGlobalOptIn;
	}) {
		this.editorPluginAIProvider = editorPluginAIProvider;

		/**
		 * WARNING: Do not remove the isFedRamp check.
		 * This is to ensure that AI functionality is not enabled in FedRamp environments.
		 */
		if (aiGlobalOptIn.status === 'disabled' || isFedRamp()) {
			return;
		}

		this.nextPlugin = NextEditorPluginAI({
			config: {
				editorPluginAIProvider,
				aiGlobalOptIn,
			},
		});

		this.pluginsOptions = this.nextPlugin.pluginsOptions;
		this.pmPlugins = this.nextPlugin.pmPlugins;
		this.primaryToolbarComponent = this.nextPlugin.primaryToolbarComponent;
		this.contentComponent = this.nextPlugin.contentComponent;
	}
}

/**
 * This is exported out to unblock the eventual deprecation of
 * `dangerouslyAppendPlugins`, when plugins can be supplied/added to presets in
 * EditorNext - there should be no functional differences between a
 * `NextEditorPluginAI` & `EditorPluginAI`.
 */
export const NextEditorPluginAI: AIPlugin = ({
	config: { editorPluginAIProvider, aiGlobalOptIn },
	api,
}) => {
	let editorView: EditorView | undefined;
	const setEditorView = (newEditorView: EditorView) => {
		editorView = newEditorView;
	};

	let providerFactory: ProviderFactory;
	const setProviderFactory = (newProviderFactory: ProviderFactory) => {
		providerFactory = newProviderFactory;
	};

	let mentionProvider: MentionProvider | undefined;

	/**
	 * Helper function to retrieve the name details for a mention from the mentionProvider
	 */
	const getMentionNameDetails = async (id: string) => {
		if (!mentionProvider || !isResolvingMentionProvider(mentionProvider)) {
			return;
		}

		const nameDetails = mentionProvider.resolveMentionName(id);

		if (isPromise(nameDetails)) {
			const mention = await nameDetails;
			return mention;
		}

		return nameDetails;
	};

	const setMentionProvider = () => {
		const handler = (_name: string, providerPromise?: Promise<MentionProvider>) => {
			if (providerPromise) {
				providerPromise.then((provider) => {
					mentionProvider = provider;
					providerFactory.unsubscribe('mentionProvider', handler);
				});
			}
		};
		providerFactory.subscribe('mentionProvider', handler);
	};

	const setMediaProvider = () => {
		if (editorPluginAIProvider?.providers?.mediaProvider) {
			providerFactory.setProvider(
				'mediaProvider',
				Promise.resolve(editorPluginAIProvider.providers.mediaProvider),
			);
		}
	};

	const setEmojiProvider = () => {
		const { emojiProvider } = api?.emoji?.sharedState.currentState() || {};
		if (!!emojiProvider) {
			providerFactory.setProvider('emojiProvider', Promise.resolve(emojiProvider));
		} else {
			const unsub = api?.emoji?.sharedState.onChange((sharedState) => {
				if (!!sharedState.nextSharedState?.emojiProvider) {
					providerFactory.setProvider(
						'emojiProvider',
						Promise.resolve(sharedState.nextSharedState.emojiProvider),
					);
					unsub?.();
				}
			});
		}
	};

	// TODO: clean up once this version is implemented in confluence and aiGlobalOptIn is made mandatory
	const aiOptIn = aiGlobalOptIn || {
		status: 'enabled',
		triggerOptInFlow: () => {},
	};

	const quickInsert: QuickInsertHandler = (intl) => {
		const configItems: EditorPluginAIConfigItem[] = [];

		if (!editorPluginAIProvider.disableQuickInsert) {
			configItems.push(
				editorPluginAIProvider.baseGenerate,
				...getVisibleEditorPluginAIConfigItems({
					configItemWithOptions: editorPluginAIProvider.configItemWithOptions,
					// The editor view will always be available when quick insert items are being created
					// Ignored via go/ees005
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					editorView: editorView!,
					positions: [0, 0],
					isEmpty: true,
				}),
			);
		}

		const quickInsertItems = mapConfigItemsToQuickInsertItems({
			configItems,
			formatMessage: intl.formatMessage,
			aiGlobalOptIn: aiOptIn,
			// The editor view will always be available when quick insert items are being created
			// Ignored via go/ees005
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			editorView: editorView!,
		});

		return quickInsertItems;
	};

	quickInsert.disableMemo = true;

	if (!editorPluginAIProvider?.hidePrimaryToolbarButton) {
		api?.primaryToolbar?.actions.registerComponent({
			name: 'aiExperience',
			component: getAIPrimaryToolbarComponent({
				api,
				editorPluginAIProvider,
				aiGlobalOptIn: aiOptIn,
			}),
		});
	}

	return {
		name: 'aiExperience',
		pmPlugins: () => {
			const plugins = [
				{
					name: 'ai-experience-actor',
					plugin: () =>
						new SafePlugin({
							view: (editorView) => {
								// This sets up an [actor](https://xstate.js.org/docs/guides/actors.html#actor-api) using
								// the editor-plugin-ai machine and starts it up.
								const editorPluginAIActor = aiActorCache.getActor(editorView);
								return {
									destroy: () => {
										// This cleansup any listeners from the actor -- to ensure we don't leak memory.
										editorPluginAIActor?.stop();
										aiActorCache.delete(editorView);
									},
								};
							},
						}),
				},
				{
					name: 'aiExperience',
					plugin: (options: PMPluginFactoryParams) => {
						const payload: EditorPluginAIInitAEP = {
							action: 'init',
							actionSubject: 'editor',
							actionSubjectId: 'editorPluginAI',
							eventType: EVENT_TYPE.TRACK,
							attributes: {
								aiGlobalOptIn: aiOptIn?.status,
							},
						};
						// @ts-expect-error - This prevent type errors when trying to pass EditorPluginAI payload to AnalyticsEventPayload
						options.dispatchAnalyticsEvent(payload);

						return createDecorationPlugin({
							dispatchAnalyticsEvent: options.dispatchAnalyticsEvent,
							dispatch: options.dispatch,
							api,
						});
					},
				},
				{
					name: 'ai-experience-get-editor-view-and-provider-factory',
					plugin: (options: PMPluginFactoryParams) => {
						setProviderFactory(options.providerFactory);
						setMentionProvider();
						setMediaProvider();
						setEmojiProvider();
						return createGetEditorViewPlugin({
							setEditorView: setEditorView,
						});
					},
				},
				{
					name: 'ai-experience-ai-button',
					plugin: (options: PMPluginFactoryParams) =>
						createAIButtonPlugin({ dispatch: options.dispatch }),
				},
				{
					name: 'ai-experience-selection-preview',
					plugin: () => createSelectionPreviewPlugin(),
				},
			];

			plugins.push({
				name: 'ai-event-hub',
				plugin: ({ getIntl }: PMPluginFactoryParams) =>
					createAIEventHubPlugin({
						aiGlobalOptIn: aiOptIn,
						editorPluginAIProvider,
						getIntl,
						api,
					}),
			});

			if (editorPluginAIProvider.isRovoEnabled) {
				plugins.push({
					name: 'editor-ai-rovo-agents',
					plugin: (options: PMPluginFactoryParams) => createRovoAgentsPlugin(options),
				});
				plugins.push({
					name: 'ai-experience-selection',
					plugin: () => createSelectionPlugin(),
				});
			}

			if (
				editorPluginAIProvider.auditLogSettings &&
				editorPluginAIProvider.auditLogSettings.enabled &&
				fg('platform_editor_ai_audit_logs_events')
			) {
				const eventData = editorPluginAIProvider.auditLogSettings.eventData;
				plugins.push({
					name: 'ai-audit-log',
					plugin: ({ dispatch }: PMPluginFactoryParams) =>
						createAIAuditLogPlugin({
							dispatch,
							eventData,
						}),
				});
			}

			if (fg('platform_editor_ai_inline_suggestions')) {
				plugins.push({
					name: 'inline-suggestions',
					plugin: ({ dispatch, getIntl }: PMPluginFactoryParams) =>
						createInlineSuggestionPlugin({
							dispatch,
							getIntl,
						}),
				});
			}

			return plugins;
		},

		actions: {
			openAIModal: ({ invokedFrom }) => {
				openAIModal({
					invokedFrom,
					editorView,
					aiOptIn,
					configItem: editorPluginAIProvider.baseGenerate,
				});
			},
		},
		getSharedState(state: EditorState | undefined) {
			if (!state) {
				return {
					nonCursorSelectionChangeCount: 0,
				};
			}

			return {
				nonCursorSelectionChangeCount: (aiExperienceSelectionPluginKey.getState(state) ?? {})
					?.nonCursorSelectionChangeCount,
			};
		},
		pluginsOptions: {
			selectionToolbar: (state, intl) => {
				if (editorPluginAIProvider.disableAISelectionToolbar) {
					return;
				}
				const disabled =
					fg('platform_editor_offline_editing_mvp') &&
					api?.connectivity?.sharedState?.currentState()?.mode === 'offline';

				const isAiButtonPulse =
					api?.engagementPlatform?.sharedState.currentState()?.messageStates[
						'cc-editor-ai_ai-toolbar-button-pulse'
					];
				const handleAiButtonMount = () => {
					// Multiple calls to startMessage with the same message key will not result in multiple messages being shown.
					// That's why we can call it here without worrying about it being called multiple times.
					api?.engagementPlatform?.actions.startMessage('cc-editor-ai_ai-toolbar-button-pulse');
				};

				const improveWritingBtn = getImproveWritingAIButton({
					intl,
					hideTitle: false,
					aiGlobalOptIn: aiOptIn,
					disabled,
				});

				const aiExperienceBtn = getAIExperienceButton({
					intl,
					rangeBaseGenerate: editorPluginAIProvider.baseGenerate,
					hideTitle: true,
					aiGlobalOptIn: aiOptIn,
					title: selectionToolbarMessages.tryAIToolbarIconTitle,
					tooltip: selectionToolbarMessages.tryAIToolbarIconTooltip,
					pulse: isAiButtonPulse,
					onMount: handleAiButtonMount,
					disabled,
				});

				const simplifiedAiBtn = getAIExperienceButton({
					intl,
					rangeBaseGenerate: editorPluginAIProvider.baseGenerate,
					hideTitle: false,
					aiGlobalOptIn: aiOptIn,
					title: selectionToolbarMessages.simplifiedAIToolbarIconTitle,
					tooltip: selectionToolbarMessages.tryAIToolbarIconTooltip,
					pulse: isAiButtonPulse,
					onMount: handleAiButtonMount,
					disabled,
				});

				if (
					editorExperiment('contextual_formatting_toolbar', true, { exposure: true }) ||
					editorExperiment('platform_editor_contextual_formatting_toolbar_v2', 'variant2', {
						exposure: true,
					})
				) {
					return {
						rank: 9,
						isToolbarAbove: true,
						items: [aiExperienceBtn, improveWritingBtn],
					};
				} else if (
					editorExperiment('platform_editor_contextual_formatting_toolbar_v2', 'variant1', {
						exposure: true,
					})
				) {
					return {
						rank: 9,
						isToolbarAbove: true,
						items: [simplifiedAiBtn],
					};
				} else {
					return {
						rank: -10,
						isToolbarAbove: true,
						items: [improveWritingBtn, aiExperienceBtn],
					};
				}
			},
			quickInsert: quickInsert,
			floatingToolbar: getFloatingToolbarConfig({
				aiGlobalOptIn: aiOptIn,
				editorPluginAIProvider,
				api,
			}),
		},

		contentComponent: ({ editorView, wrapperElement, appearance, providerFactory }) => {
			const endExperience: EndExperience = (options) => {
				if (editorPluginAIProvider.onAIProviderChanged) {
					editorPluginAIProvider.onAIProviderChanged('command-palette');
				}
				// TODO POSTEAP
				// When the full experience is moved to the state machine -- the intention is
				// to have the modal experience ended via state transitions like "action taken"
				// or "modal dismissed" and have side effects (actions) which run as part of
				// those transition to do any clean up.
				// Also to note -- this is not currently the end of the AI experience
				// following this being called -- we decorate the document with a highlight following
				// actions taken.

				const state = getPluginState(editorView.state);

				const decoSet =
					hasGeneratedContentDecorations(state) || options?.preserveEditorSelectionOnComplete
						? state.modalDecorationSet
						: undefined;

				const endAIExperienceCommand = createEndAIExperienceCommand(
					decoSet,
					options?.preserveEditorSelectionOnComplete,
				);
				endAIExperienceCommand(editorView.state, editorView.dispatch);

				editorView.focus();
			};

			// We use this point to render the modal if needed instead of decorators
			// The reason this is preferrable is it has access to mount points
			// where decorators are rendered with a mount point within the Editor content
			// and longer modals get clipped as a result.
			const renderModalRegion = (
				aiExperienceDecorationPluginState?: AIDecorationExperiencePluginState,
			) => {
				// If there are no active decorations -- then we don't want to render the ai experience.
				if (
					!aiExperienceDecorationPluginState ||
					aiExperienceDecorationPluginState.modalDecorationSet === DecorationSet.empty
				) {
					return null;
				}

				if (hasGeneratedContentDecorations(aiExperienceDecorationPluginState)) {
					return null;
				}

				const modalDecorationElement =
					editorView?.dom.querySelector<HTMLElement>('.ai-modal-end-widget') ||
					editorView?.dom.querySelector<HTMLElement>('.ai-selection-node');

				// This protects against cases where there is an active decoration
				// but the document has not been updated to include it
				if (!modalDecorationElement) {
					return null;
				}

				// Prioritise getting position from inlineDecoration,
				// otherwise get decoration position from start or end widget
				let positions: [number, number];
				let decoration =
					aiExperienceDecorationPluginState.modalDecorationSet
						.find(undefined, undefined, (spec) => spec.key === 'inlineDecoration')
						.shift() ||
					aiExperienceDecorationPluginState.modalDecorationSet
						.find(
							undefined,
							undefined,
							(spec) => spec.key === 'startWidgetDecoration' || spec.key === 'endWidgetDecoration',
						)
						.shift();

				if (decoration) {
					positions = [decoration.from, decoration.to];
				} else {
					const nodeDecorations = aiExperienceDecorationPluginState.modalDecorationSet.find(
						undefined,
						undefined,
						(spec) => spec.key === 'ai-nodeDecoration',
					);
					if (nodeDecorations.length > 0) {
						const startPos = Math.min(...nodeDecorations.map((decoration) => decoration.from));
						const endPos = Math.max(...nodeDecorations.map((decoration) => decoration.to));
						positions = [startPos, endPos];
					}
					decoration = nodeDecorations.shift();
				}

				if (!decoration) {
					return null;
				}

				return (
					<ModalRegion
						lastTriggeredFrom={aiExperienceDecorationPluginState.lastTriggeredFrom}
						key={aiExperienceDecorationPluginState.modalMountedTimeStamp}
						decoration={decoration}
						modalDecorationElement={modalDecorationElement}
						editorView={editorView}
						editorRelativeWrapper={wrapperElement?.offsetParent}
						endExperience={endExperience}
						appearance={appearance}
						decorationSet={aiExperienceDecorationPluginState.modalDecorationSet}
					>
						{aiExperienceDecorationPluginState.configItem && editorView && (
							<ExperienceApplication
								configItem={aiExperienceDecorationPluginState.configItem}
								editorPluginAIProvider={editorPluginAIProvider}
								endExperience={endExperience}
								// Ignored via go/ees005
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								positions={positions!}
								editorView={editorView}
								providerFactory={providerFactory}
								// TODO POSTEAP
								// This prop is being used for analytics but is temporary.
								// Plugin re-architecture will change this.
								// https://product-fabric.atlassian.net/wiki/spaces/EUXQ/pages/3573548013/Introducing+AI+Dev+Documentation+TODO+AIFOLLOWUP+audit#Plugin-re-architecture
								lastTriggeredFrom={aiExperienceDecorationPluginState.lastTriggeredFrom}
								triggerMethod={aiExperienceDecorationPluginState.triggerMethod}
								appearance={appearance}
								initialPrompt={aiExperienceDecorationPluginState?.initialPrompt}
								getMentionNameDetails={getMentionNameDetails}
								triggeredFor={aiExperienceDecorationPluginState.triggeredFor}
								engagementPlatformApi={api?.engagementPlatform?.actions}
							/>
						)}
					</ModalRegion>
				);
			};

			const pluginsToTrack: Record<string, PluginKey> = {
				aiExperienceDecorationPluginState: aiExperienceDecorationPluginKey,
				rovoAgentsPluginState: rovoAgentsPluginKey,
			};

			const renderedSubscribeToRovo = (
				<WithPluginState
					editorView={editorView}
					plugins={{ aiExperienceDecorationPluginState: aiExperienceDecorationPluginKey }}
					render={({ aiExperienceDecorationPluginState }) => {
						const positions = getAIHighlightPositions({
							modalDecorationSet: aiExperienceDecorationPluginState?.modalDecorationSet,
						});
						return (
							<SubscribeToRovo
								positions={positions}
								editorView={editorView}
								product={editorPluginAIProvider.product}
								onDocChangeByAgent={editorPluginAIProvider.onDocChangeByAgent}
								onAIProviderChanged={editorPluginAIProvider.onAIProviderChanged}
								editorPluginAIProvider={editorPluginAIProvider}
							/>
						);
					}}
				/>
			);

			return (
				<GlobalWrapper>
					<RovoEnabled editorView={editorView} editorPluginAIProvider={editorPluginAIProvider}>
						<PublishToRovo
							editorView={editorView}
							getMentionNameDetails={getMentionNameDetails}
							editorPluginAIProvider={editorPluginAIProvider}
							editorApi={api}
						/>
						<AgentFetcher editorPluginAIProvider={editorPluginAIProvider} editorView={editorView} />
						{renderedSubscribeToRovo}
					</RovoEnabled>
					<WithPluginState
						plugins={pluginsToTrack}
						render={({ aiExperienceDecorationPluginState, rovoAgentsPluginState }) => {
							// The WithPluginState function does not support typing the plugin states it returns
							const modalRegion = renderModalRegion(aiExperienceDecorationPluginState);

							// TODO POSTEAP
							// This error boundary will be unexpectedly positioned
							// as it's not wrapped in the ModalRegion.
							// In the case the modal region throws an exception
							// we should come up with an alternative positioning
							// strategy for error feedback.
							return (
								<ModalRegionErrorBoundary onCloseFallback={endExperience}>
									{modalRegion ?? null}
									{rovoAgentsPluginState?.isBrowseModalOpen && (
										<LoadableBrowseRovoAgentsModal
											editorView={editorView}
											aiGlobalOptIn={aiOptIn}
											editorPluginAIProvider={editorPluginAIProvider}
											startingConfig={aiExperienceDecorationPluginState.configItem}
											positions={rovoAgentsPluginState?.positions}
										/>
									)}
								</ModalRegionErrorBoundary>
							);
						}}
					/>
				</GlobalWrapper>
			);
		},

		primaryToolbarComponent:
			!api?.primaryToolbar && !editorPluginAIProvider.hidePrimaryToolbarButton
				? getPrimaryToolbarLegacyComponent({
						api,
						editorPluginAIProvider,
						aiGlobalOptIn: aiOptIn,
					})
				: undefined,
	};
};

/**
 * TODO: Remove this when making a major change as this was originally exported here
 * (Probably when deprecating the legacy plugin)
 * This type is currently used in some products.
 */
// eslint-disable-next-line @atlaskit/editor/no-re-export
export type { AIPlugin } from './aiPluginType';
