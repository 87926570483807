import React, { lazy, useEffect, useState, useContext } from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { RendererContext } from '../../../context';
import { withMacroStyleWrapper } from '../macroStyleWrapper';

const PlatformSingleValue = lazy(() =>
	import(
		/* webpackChunkName: '@forge-ui_singlevaluechart' */
		'@atlassian/visualization/charts'
	).then((module) => ({
		default: module.SingleValue,
	})),
);

const Title = lazy(() =>
	import(
		/* webpackChunkName: '@forge-ui_singlevaluechart' */
		'@atlassian/visualization/charts'
	).then((module) => ({
		default: module.SingleValue.elements.Title,
	})),
);

const SubTitle = lazy(() =>
	import(
		/* webpackChunkName: '@forge-ui_singlevaluechart' */
		'@atlassian/visualization/charts'
	).then((module) => ({
		default: module.SingleValue.elements.SubTitle,
	})),
);

type PlatformSingleValueProps = React.ComponentProps<typeof PlatformSingleValue>;

/**
 * Excluded props:
 * - children - we will not be supporting primitives/elements to compose the chart
 */

export type SingleValueChartProps = Pick<
	PlatformSingleValueProps,
	'height' | 'width' | 'showBorder'
> & {
	title?: string;
	subtitle?: string;
	data: number | number[];
};

const BaseSingleValueChart = (props: Parameters<RenderFn>[0]) => {
	const [, setIsMounted] = useState(false);
	const { data, height, width, showBorder, subtitle, title } = props.forgeDoc
		.props as SingleValueChartProps;

	// Stop viz components from being rendered in Prod Forge environment
	const { forgeEnvironment } = useContext(RendererContext);
	if (forgeEnvironment === 'PRODUCTION') {
		throw new Error(
			'EAP features are only accessible to users with EAP signup and not supported or recommended for use in production.',
		);
	}

	return (
		// Add span to differentiate single value text p component with legend p component
		<span>
			<PlatformSingleValue
				dataValue={data}
				height={height ? height : 120}
				width={width}
				showBorder={showBorder}
			>
				{title && <Title>{title}</Title>}
				{subtitle && <SubTitle>{subtitle}</SubTitle>}
				<OnMount
					onMount={() => {
						setIsMounted(true);
					}}
				/>
			</PlatformSingleValue>
		</span>
	);
};

const OnMount = ({ onMount }: any) => {
	useEffect(() => {
		onMount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};

export const SingleValueChart = withMacroStyleWrapper(BaseSingleValueChart);
