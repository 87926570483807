import React, { PropsWithChildren, useMemo } from 'react';
import PropTypes from 'prop-types';

import { EngagementProvider as EngagementProviderWithoutConfig } from '@atlassiansox/engagekit-ts';
import { ProductIds } from '@atlassian/ipm-choreographer';

import { getAnalyticsWebClient, getWorkspaceId } from '@confluence/analytics-web-client';
import { useAnalyticsEnvironment } from '@confluence/choreographer-services';
import { useSessionData, useBooleanFeatureFlag } from '@confluence/session-data';
export function EngagementProviderComponent(props: PropsWithChildren<{}>) {
	const { cloudId, locale, userId, activationId, environment } = useSessionData();

	const workspaceId = getWorkspaceId(cloudId, activationId);

	if (sessionStorage.getItem('confluence.disable-engagement-for-tests') === 'true') {
		return <>{props.children}</>;
	}

	const config = {
		cloudId,
		aaid: userId as string,
		locale,
		product: 'confluence',
		stargateUrl: '/gateway/api',
		workspaceId,
		isFedRAMPEnvironment: false,
	};

	const envType = useAnalyticsEnvironment(environment);

	const coordinationOptions = useMemo(
		() => ({
			enableChoreographer: true,
			envType,
			additionalData: { sourceProductId: ProductIds.CONFLUENCE },
		}),
		[envType],
	);

	return (
		<EngagementProviderWithoutConfig
			{...props}
			children={<>{props.children}</>}
			config={config}
			analyticsClientInstance={getAnalyticsWebClient()}
			coordinationOptions={coordinationOptions}
		/>
	);
}

EngagementProviderComponent.propTypes = {
	children: PropTypes.element,
	locale: PropTypes.string,
};
