import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Inline, Pressable, xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import AddIcon from '@atlaskit/icon/core/add';

import { useResponseHandler } from '@confluence/space-roles/entry-points/graphql';
import { SITE_RBAC_EDIT_EXPERIENCE } from '@confluence/experience-tracker';

import { SetRoleModal } from '../../set-role/SetRoleModal';
import type { RoleNodes } from '../../roles-list/useRoleList';
import type { SpacePermissionNodes } from '../useSpacePermissions';

const buttonStyle = xcss({
	padding: 'space.150',
	width: '100%',
	height: '69px',
	border: `1px solid ${token('color.border')}`,
	borderRadius: '5px',
	backgroundColor: 'elevation.surface',
	':hover': {
		backgroundColor: 'elevation.surface.hovered',
	},
	':active': {
		backgroundColor: 'elevation.surface.pressed',
	},
});

const buttonTextStyle = xcss({
	color: 'color.link',
	font: token('font.heading.xsmall'),
});

export const CustomRoleAddition = ({
	allRoles,
	allPermissions,
}: {
	allRoles: RoleNodes;
	allPermissions: SpacePermissionNodes;
}) => {
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const { startExperience } = useResponseHandler({
		experience: SITE_RBAC_EDIT_EXPERIENCE,
	});

	const onClick = useCallback(() => {
		startExperience();
		setIsModalOpen(true);
	}, [startExperience, setIsModalOpen]);

	return (
		<>
			<Pressable xcss={buttonStyle} onClick={onClick}>
				<Inline alignBlock="baseline" space="space.150">
					<AddIcon label="" color={token('color.link')} />
					<Box xcss={buttonTextStyle}>
						<FormattedMessage
							defaultMessage="Add custom role"
							id="default-space-permissions.roles-management.custom-roles-add"
							description="Button text to add a permissions custom role"
						/>
					</Box>
				</Inline>
			</Pressable>
			{isModalOpen && (
				<SetRoleModal
					isNewRole
					onClose={() => setIsModalOpen(false)}
					allRoles={allRoles}
					allPermissions={allPermissions}
				/>
			)}
		</>
	);
};
