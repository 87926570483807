import React, { useCallback } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import Button, { LinkButton } from '@atlaskit/button/new';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { OptionData } from '@atlaskit/user-picker';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import ChevronLeftLargeIcon from '@atlaskit/icon/utility/migration/chevron-left--chevron-left-large';

import { useIsProductAdmin } from '@confluence/current-user';
import {
	SitePermissionTypeFilter,
	SitePermissionType,
	UserAndGroupSearchPicker,
} from '@confluence/user-and-group-search';
import { ADMIN_ALL_SPACES_SPACE_PERMISSIONS } from '@confluence/named-routes';
import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import type { FlagsStateContainer } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { useSessionData } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';

import {
	SpaceSelectionRadioButtons,
	SpaceSelection,
} from './page-components/SpaceSelectionRadioButtons';
import {
	ActionSelectionRadioButtons,
	ActionSelection,
} from './page-components/ActionSelectionRadioButtons';
import { RoleSelectionSection } from './page-components/RoleSelectionSection';
import { RestrictedView } from './restricted/RestrictedView';
import { i18nBulk } from './BulkSpacePermissionsPage';

export const ANALYTICS_SOURCE = 'bulkAccessManagementScreen';

const i18n = defineMessages({
	bulkRoleAccessHeader: {
		id: 'bulk-permissions.space.role-access-header',
		defaultMessage: 'Manage space access in bulk',
		description: 'Header text of the bulk role access page',
	},
	userPickerAccessHeader: {
		id: 'bulk-permissions.space.user-picker-access-header',
		defaultMessage: 'Who<asterisk>*</asterisk>',
		description:
			'Header text of the user picker for bulk access management, with an asterisk indicating a required field',
	},
	userPickerPlaceholder: {
		id: 'bulk-permissions.space.user-picker-placeholder',
		defaultMessage: 'Enter a person or group',
		description: 'Placeholder text for the user picker',
	},
});

const bulkAddRolesHeaderStyle = xcss({
	font: token('font.heading.large'),
	paddingBottom: 'space.100',
	paddingTop: 'space.300',
});

const bulkRolesSubtextStyle = xcss({
	font: token('font.body'),
});

const principlePickerHeaderStyle = xcss({
	font: token('font.body.large'),
	fontWeight: token('font.weight.medium'),
	display: 'flex',
	flexDirection: 'row',
	paddingTop: 'space.300',
	paddingBottom: 'space.100',
});

const asteriskStyle = xcss({
	color: 'color.text.accent.red',
});

const principlePickerFineTextStyle = xcss({
	color: 'color.text.accent.gray',
	marginTop: 'space.050',
	font: token('font.body.small'),
});

const contentStyle = xcss({
	marginLeft: 'space.1000',
	marginRight: 'space.1000',
});

const backButtonStyle = xcss({
	marginLeft: 'space.negative.200',
	paddingTop: 'space.300',
});

const principlePickerStyle = xcss({
	paddingBottom: 'space.050',
});

const requirementTextStyle = xcss({
	font: token('font.body'),
	paddingTop: 'space.300',
});
export interface PrincipalOption extends OptionData {
	extra?: any;
}

// Leaving for use once APIs are implemented
// const getPrincipalId = (principal: PrincipalOption | undefined) => {
// 	return principal?.type === 'user' ? principal?.extra?.accountId : principal?.extra?.id;
// };

export const BulkAccessManagementPage = withFlags(
	withErrorBoundary({
		attribution: Attribution.PERMISSIONS_EXPERIENCE,
	})(({ flags }: { flags: FlagsStateContainer }) => {
		const intl = useIntl();
		const { edition } = useSessionData();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { isProductAdmin, isAdminCheckLoading } = useIsProductAdmin();
		const [stagedPrincipal, setStagedPrincipal] = React.useState<PrincipalOption | undefined>(
			undefined,
		);
		const [spaceSelection, setSpaceSelection] = React.useState<SpaceSelection>(
			SpaceSelection.AllExcludingPersonal,
		);

		const [actionSelection, setActionSelection] = React.useState<ActionSelection>(
			ActionSelection.AddOrChangeAccess,
		);

		const isRemoval = actionSelection === ActionSelection.RemoveAccess;

		const handleBackToDefaultSpaceAccessClick = useCallback(() => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					actionSubject: 'button',
					action: 'clicked',
					actionSubjectId: 'defaultspaceperms',
					source: ANALYTICS_SOURCE,
				},
			}).fire();
		}, [createAnalyticsEvent]);

		const onSpaceTypeRadioChange = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				setSpaceSelection(e.target.value as SpaceSelection);
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						actionSubject: 'radio',
						action: 'selected',
						actionSubjectId: 'bulkAccessManagementSpace',
						source: ANALYTICS_SOURCE,
						attributes: {
							spaceSelection: e.target.value,
							isRBACEnabled: true,
						},
					},
				}).fire();
			},
			[createAnalyticsEvent],
		);

		const onActionRadioChange = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>) => {
				setActionSelection(e.target.value as ActionSelection);
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						actionSubject: 'radio',
						action: 'selected',
						actionSubjectId: 'bulkAccessManagementAction',
						source: ANALYTICS_SOURCE,
						attributes: {
							actionSelection: e.target.value,
							isRBACEnabled: true,
						},
					},
				}).fire();
			},
			[createAnalyticsEvent],
		);

		const onPrincipalChange = useCallback(
			(received: OptionData | undefined) => {
				// Only send an analytic event if a principal isn't deselected
				if (received) {
					createAnalyticsEvent({
						type: 'sendUIEvent',
						data: {
							actionSubject: 'principal',
							action: 'selected',
							actionSubjectId: 'bulkAccessManagementPrincipal',
							source: ANALYTICS_SOURCE,
							attributes: {
								principal: received.type,
								isRBACEnabled: true,
							},
						},
					}).fire();
				}
				setStagedPrincipal(received);
			},
			[createAnalyticsEvent],
		);

		// Don't render the page until we know if the user is a product admin
		// to avoid a flicker of the restricted view.
		if (isAdminCheckLoading) {
			return null;
		}

		if (!isProductAdmin || edition === ConfluenceEdition.FREE) {
			return <RestrictedView />;
		}

		return (
			<>
				<Box xcss={contentStyle}>
					<Box xcss={backButtonStyle}>
						<LinkButton
							appearance="subtle"
							onClick={handleBackToDefaultSpaceAccessClick}
							href={ADMIN_ALL_SPACES_SPACE_PERMISSIONS.toUrl()}
							iconBefore={ChevronLeftLargeIcon}
						>
							{intl.formatMessage(i18nBulk.backToSpacePermissions)}
						</LinkButton>
					</Box>

					<Box xcss={bulkAddRolesHeaderStyle}>{intl.formatMessage(i18n.bulkRoleAccessHeader)}</Box>
					<Box xcss={bulkRolesSubtextStyle}>{intl.formatMessage(i18nBulk.titleSubtext)}</Box>

					<Box xcss={principlePickerHeaderStyle}>
						{intl.formatMessage(i18n.userPickerAccessHeader, {
							asterisk: (chunks: React.ReactNode[]) => <Box xcss={asteriskStyle}>{chunks}</Box>,
						})}
					</Box>
					<Box xcss={principlePickerStyle}>
						<UserAndGroupSearchPicker
							data-test-id="user-and-group-search"
							fieldId="bulk-permissions-user-picker"
							includeGroup
							withUsers
							isMulti={false}
							smart={false}
							placeholder={intl.formatMessage(i18n.userPickerPlaceholder)}
							sitePermissionTypeFilter={SitePermissionTypeFilter.NONE}
							// @ts-ignore - Type 'null' is not assignable to type 'string | ((input: { inputValue: string; }) => string | null)'
							// Using `ts-ignore` and not `ts-expect-error` here as TypeScript will fail on this line without `ts-ignore` but with `ts-expect-error`
							// This error was introduced after upgrading to TypeScript 5
							noOptionsMessage={null}
							onChange={(received) => {
								// @ts-ignore - Argument of type 'Value' is not assignable to parameter of type 'OptionData | undefined'
								// Using `ts-ignore` and not `ts-expect-error` here as TypeScript will fail on this line without `ts-ignore` but with `ts-expect-error`
								// This error was introduced after upgrading to TypeScript 5
								onPrincipalChange(received);
							}}
							value={stagedPrincipal || []}
							filterSearchResults={(value) =>
								value?.extra?.permissionType !== SitePermissionType.EXTERNAL
							}
						/>
					</Box>
					<Box xcss={principlePickerFineTextStyle}>
						{intl.formatMessage(i18nBulk.userPickerSubtext)}
					</Box>

					<ActionSelectionRadioButtons
						actionSelection={actionSelection}
						onRadioChange={onActionRadioChange}
					/>

					<SpaceSelectionRadioButtons
						spaceSelection={spaceSelection}
						onRadioChange={onSpaceTypeRadioChange}
					/>
					<RoleSelectionSection />

					<Button
						appearance="primary"
						onClick={() =>
							flags.showSuccessFlag({
								id: 'bulk-space-permissions.placeholder.success',
								title: 'Placeholder success flag!',
								description: 'A placeholder for the success flag',
								isAutoDismiss: true,
							})
						}
						isDisabled={stagedPrincipal === undefined}
					>
						{intl.formatMessage(
							isRemoval ? i18nBulk.applyButtonTextRemove : i18nBulk.applyButtonTextAdd,
						)}
					</Button>

					<Inline xcss={requirementTextStyle}>
						{intl.formatMessage(i18nBulk.requirementMessage, {
							asterisk: (chunks: React.ReactNode[]) => <Box xcss={asteriskStyle}>{chunks}</Box>,
						})}
					</Inline>
				</Box>
			</>
		);
	}),
);
