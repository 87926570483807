import type { MentionMap } from '@atlassian/ai-model-io/utils/mention-map';

import type {
	UpdateIdMap,
	EditorPluginAIConfigItem,
} from '../../prebuilt/config-items/config-items';

import { getRequestOptions4ConvoAI } from './request-options-convo-ai';
import { getRequestOptions4RovoChat } from './request-options-rovo-agent';
import { getRequestOptions4XPGenAI, shouldUseXPGenAI } from './request-options-xp-gen-ai';
import type { AIExperienceMachineContextForRequest, RequestOptions, ResponseObject } from './types';

export function getRequestOptions({
	callback,
	context,
	configItem,
	mentionMap,
}: {
	callback: (event: ResponseObject) => void;
	context: AIExperienceMachineContextForRequest;
	configItem: EditorPluginAIConfigItem;
	mentionMap?: MentionMap;
}): RequestOptions {
	// elevate free generate is disabled and returns the pre-configured response immediately
	if (configItem.response) {
		return { response: configItem.response };
	}

	const { latestPromptResponse } = context;
	const intentSchemaId =
		typeof configItem.intentSchemaId === 'function'
			? configItem.intentSchemaId({ latestPromptResponse })
			: configItem.intentSchemaId;
	const updateIdMap: UpdateIdMap = ({ idMap }) => {
		callback({
			type: 'update idMap',
			context: {
				idMap,
			},
		});
	};

	let requestOptions: RequestOptions;

	if (shouldUseXPGenAI({ configItem, latestPromptResponse })) {
		requestOptions = getRequestOptions4XPGenAI({
			context,
			configItem,
			mentionMap,
			intentSchemaId,
			updateIdMap,
		});
	} else if (configItem.agent) {
		requestOptions = getRequestOptions4RovoChat({
			context,
			configItem,
			mentionMap,
			updateIdMap,
		});
	} else {
		requestOptions = getRequestOptions4ConvoAI({
			context,
			configItem,
			mentionMap,
			intentSchemaId,
			updateIdMap,
		});
	}

	if ('contentStatistics' in requestOptions && requestOptions.contentStatistics) {
		callback({
			type: 'storeContextStatistics',
			contextStatistics: { inputDocument: requestOptions.contentStatistics },
		});
	}

	return requestOptions;
}
