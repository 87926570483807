import { getBscGlobalState } from '../../../common/utils';
import { LATEST_SCHEMA_KEY, LEGACY_ATLASSIAN_GDPR_COOKIE_KEY } from '../../../constants';
import type { ConsentPreference } from '../../../types';
import { getConsentToken } from '../../cookie-controls/get-consent-token';
import { getConsentTokenForSchemaVersion } from '../../cookie-controls/get-consent-token-for-schema-version';
import { getCookie } from '../../cookie-controls/get-cookie';
import { unpackUserPreferencesCookie } from '../../cookie-controls/transformer';

export async function getPreferences(): Promise<ConsentPreference | undefined> {
	// when localConsentMode is on, don't make calls to ConsentHub
	if (getBscGlobalState().localConsentMode) {
		const packedPrefs = getCookie(LEGACY_ATLASSIAN_GDPR_COOKIE_KEY);

		if (!packedPrefs) {
			return undefined;
		}

		return unpackUserPreferencesCookie(packedPrefs);
	} else {
		let packedLocalPrefs = getConsentTokenForSchemaVersion(LATEST_SCHEMA_KEY);

		if (!packedLocalPrefs) {
			const consentToken = await getConsentToken();
			return consentToken ? unpackUserPreferencesCookie(consentToken) : undefined;
		}

		return unpackUserPreferencesCookie(packedLocalPrefs);
	}
}
