import React from 'react';

import { bindAll } from 'bind-event-listener';
import { useIntl } from 'react-intl-next';

import type { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import type { EditorAppearance } from '@atlaskit/editor-common/types';
import { isEmptyDocument } from '@atlaskit/editor-common/utils';
import { type NodeType } from '@atlaskit/editor-prosemirror/model';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { type MentionNameDetails } from '@atlaskit/mention';
import { usePublish } from '@atlaskit/rovo-triggers';

import type {
	AnalyticsContext,
	EditorPluginAIConfigItem,
	SelectionType,
} from '../../../prebuilt/config-items/config-items';
import {
	type EditorPluginAIProvider,
	type EndExperience,
	isConvoAIKnownMessageTemplate,
} from '../../../types/types';
import type {
	InvokedFrom,
	InvokedFromTriggerMethod,
} from '../../../utils/analytics/analyticsFlowTypes';
import {
	AnalyticsFlowContextProvider,
	useAnalyticsFlow,
	useCreateAnalyticsFlow,
} from '../../../utils/analytics/analyticsFlowUtils';
import { shouldDiscardEndExperienceImmediately } from '../../../utils/discard-handling';
import { mapLoadingStatusToMessageTemplate } from '../../../utils/map-loading-status-to-message-template';
import type {
	AIExperienceMachineContext,
	AIExperienceMachineState,
} from '../../../utils/xstate/get-ai-experience-service';
import {
	AIExperienceCommonDataContext,
	type AIExperienceCommonDataContextData,
	useAIExperienceCommonDataContext,
} from '../../hooks/useAIExperienceCommonData';
import { useAIExperienceService } from '../../hooks/useAIExperienceService';
import { ModalRegionErrorBoundary } from '../ExperienceApplicationErrorBoundary/ExperienceApplicationErrorBoundary';

import { messages as experienceMessages } from './messages';
import { DiscardScreenWithLogic } from './screens-with-logic/DiscardScreenWithLogic';
import { ErrorScreenWithLogic } from './screens-with-logic/ErrorScreenWithLogic';
import { LoadingScreenWithLogic } from './screens-with-logic/LoadingScreenWithLogic';
import { PreviewScreenWithLogic } from './screens-with-logic/PreviewScreenWithLogic';
import { UserInputCommandPaletteWithLogic } from './screens-with-logic/UserInputCommandPaletteWithLogic';

type ExperienceApplicationProps = {
	configItem: EditorPluginAIConfigItem;
	editorPluginAIProvider: EditorPluginAIProvider;
	providerFactory: ProviderFactory;
	// all props under here may change based on how we wire things together
	editorView: EditorView;
	/**
	 * For empty selections, the start and end will be the same
	 */
	positions: [start: number, end: number];
	/**
	 * Called to end the experience
	 *
	 * Note: the ExperienceApplication will do additional cleanup work after calling this in
	 * its react unmount cleanup.
	 */
	endExperience: EndExperience;
	lastTriggeredFrom?: InvokedFrom;
	triggerMethod?: InvokedFromTriggerMethod;
	appearance: EditorAppearance;
	initialPrompt?: string;
	getMentionNameDetails?: (id: string) => Promise<MentionNameDetails | undefined>;
	triggeredFor?: NodeType;
	/** Engagement platform plugin API for starting and stopping messages. */
	engagementPlatformApi?: AIExperienceCommonDataContextData['engagementPlatformApi'];
};

type ExperienceApplicationStateManagerProps = {
	context: AIExperienceMachineContext;
	state: AIExperienceMachineState;
	initialPrompt?: string;
};
export function ExperienceApplicationStateManager(props: ExperienceApplicationStateManagerProps) {
	/**
	 * Note -- because we are on an old version of xstate -- when adding new states
	 * they will not automatically be typed.
	 *
	 * You need to add these in the `AIExperienceMachineState` type in the
	 * `get-experience-service.ts` file
	 **/
	const state = props.state;
	/**
	 * Note -- because we are on an old version of xstate -- when adding new events
	 * they will not automatically be typed.
	 *
	 * You need to add these in the `AIExperienceMachineEvent` type in the
	 * `get-experience-service.ts` file
	 **/
	const context = props.context;
	const aiExperienceCommonData = useAIExperienceCommonDataContext();

	const { formatMessage } = useIntl();

	const publish = usePublish('ai-mate');

	React.useEffect(() => {
		const send = aiExperienceCommonData.sendToAIExperienceMachine;
		if (send && state === 'started state') {
			send({ type: 'kick off' });
		}
	}, [state, aiExperienceCommonData.sendToAIExperienceMachine]);

	/**
	 * Used to pass the backendModel to analytics and
	 * feedback submission metadata
	 */
	const backendModel = context?.configItem?.getBackendModel?.(!!context?.latestPromptResponse);

	const analyticsFlow = useAnalyticsFlow();

	React.useEffect(() => {
		analyticsFlow.updateCommonAttributes({
			backendModel,
			channelId: context.channelId,
			...analyticsFlow.getUserFlowCommonAttributes(context.configItem),
		});
	}, [analyticsFlow, backendModel, context.channelId, context.configItem]);

	const aiLifeCycleDynamicAttributesGetter = context.aiLifeCycleDynamicAttributesGetter;
	React.useEffect(() => {
		if (aiLifeCycleDynamicAttributesGetter) {
			analyticsFlow.registerAiLifeCycleDynamicAttributesGetter(aiLifeCycleDynamicAttributesGetter);
		}
	}, [analyticsFlow, aiLifeCycleDynamicAttributesGetter]);

	// Note -- the intention is to move the creation of the state machine higher
	// and for the ExperienceApplication (react component that appears in the modal)
	// to only be started once the machine is in the modal active state.
	if (state === 'started state') {
		return null;
	}

	const isModalActiveState = typeof state !== 'string' && state['modal active state'];

	if (!isModalActiveState) {
		throw new Error('AI machine state is not: modal active state or modal error state');
	}

	const experienceState = state['modal active state']['experience'];

	if (
		typeof experienceState === 'object' &&
		'error state' in experienceState &&
		experienceState['error state'] === 'active'
	) {
		return <ErrorScreenWithLogic errorInfo={context.apiErrorInfo} />;
	}

	if (
		typeof state['modal active state'] === 'object' &&
		state['modal active state']['discard'] === 'active'
	) {
		return (
			<DiscardScreenWithLogic
				state={experienceState}
				channelId={context.channelId}
				editCount={context.editCount}
				retryPromptCount={context.retryPromptCount}
				history={context.responseHistory}
				latestPrompt={context.latestPrompt}
				onceDiscard={context.onceDiscard}
			/>
		);
	}

	if (typeof experienceState === 'string' && experienceState === 'command palette state') {
		return (
			<UserInputCommandPaletteWithLogic context={context} initialPrompt={props.initialPrompt} />
		);
	}

	if (typeof experienceState === 'object' && 'loading state' in experienceState) {
		const loadingState = experienceState['loading state'];
		if (loadingState === 'loading') {
			const getLoadingMessage = (status?: string) => {
				if (!status || !isConvoAIKnownMessageTemplate(status)) {
					return status;
				}

				const messageTemplate = mapLoadingStatusToMessageTemplate(status);
				if (messageTemplate) {
					return formatMessage(messageTemplate);
				}

				return status;
			};

			/**
			 * Short title for the submenu if the user selects a submenu via a parent (`Change tone` -> `Neutral`)
			 * If the user selects a submenu directly, this will be empty (search and select `Change tone to Neutral`)
			 */
			const childShortTitle =
				context.parentPresetPromptLabel && context.configItem.nestingConfig
					? formatMessage(context.configItem.nestingConfig.shortTitle)
					: '';
			const loadingMessage = getLoadingMessage(context?.loadingStatus);

			return (
				<LoadingScreenWithLogic
					parentPresetPromptLabel={context.parentPresetPromptLabel}
					childShortTitle={childShortTitle}
					title={loadingMessage ? loadingMessage : formatMessage(experienceMessages.loadingTitle)}
					markdown={context.markdown}
					latestPrompt={context.latestPrompt}
					promptTrigger={context.promptTrigger}
					refinementCount={context.refinementCount}
					latestPromptRetried={context.latestPromptRetried}
					idMap={context.idMap}
					contextStatistics={context.contextStatistics}
				/>
			);
		}
	}

	if (typeof experienceState !== 'string' && 'review state' in experienceState) {
		const action = context.rovoActions ? context.rovoActions[context.selectionType] : undefined;

		return (
			<PreviewScreenWithLogic
				aiExperienceMachineContext={context}
				isInputActive={experienceState['review state'] === 'refinement'}
				backendModel={backendModel}
				rovoAction={action}
				rovoPublish={publish}
			/>
		);
	}

	if (typeof experienceState !== 'string' && 'error state' in experienceState) {
		return <ErrorScreenWithLogic errorInfo={context.apiErrorInfo} />;
	}

	throw new Error('unhandled state');
}

type ExperienceApplicationStateWrapperProps = ExperienceApplicationProps & {
	/** The analytics context for the experience */
	analyticsContext: AnalyticsContext;
};

function ExperienceApplicationStateWrapper(props: ExperienceApplicationStateWrapperProps) {
	const intl = useIntl();
	const modalRef = React.useRef<HTMLDivElement>(null);

	const positions = props.positions;
	const isEmpty = positions[0] === positions[1];
	const selectionType: SelectionType = isEmpty ? 'empty' : 'range';

	const { context, state, send } = useAIExperienceService({
		context: {
			analyticsContext: props.analyticsContext,
			aiProvider: props.editorPluginAIProvider,
			intl,
			editorView: props.editorView,
			positions: positions,
			isEmpty,
			selectionType,
			baseGenerate: props.editorPluginAIProvider.baseGenerate,
			configItem: props.configItem,
			aiLifeCycleDynamicAttributesGetter:
				props.editorPluginAIProvider?.aiLifeCycleDynamicAttributesGetter,
			markdown: '',
			latestPromptResponse: undefined,
			editCount: 0,
			refinementCount: 0,
			retryPromptCount: 0,
			latestPromptRetried: false,
			userInput: '',
			apiErrorInfo: {},
			responseHistory: { positionFromEnd: 0, entries: [], totalSize: 0, traversals: 0 },
			channelId: undefined,
			getMentionNameDetails: props.getMentionNameDetails,
			triggeredFor: props.triggeredFor,
			usePageKnowledge: true,
		},
		endExperience: props.endExperience,
	});

	React.useEffect(() => {
		// Note -- the intention is to have the state machine created at a higher
		// level, and have the positions updated without being passed into this react
		// component.
		send({ type: 'update positions', positions: props.positions });
	}, [send, props.positions]);

	React.useEffect(() => {
		if (!modalRef.current) {
			return;
		}

		/**
		 * KeyDown (as opposed to KeyPress) event is used to handle the escape key so that
		 * other escape key handlers will not override this behaviour.
		 * Additionally this aligns with other escape listeners e.g. for closing typeahead popup.
		 */
		function handleEscapeKeyDown(event: KeyboardEvent) {
			// Exit early if the key is not Escape
			if (event.key !== 'Escape') {
				return;
			}

			// Continue only if the AI modal is open / in active state
			if (typeof state === 'string' || !('modal active state' in state)) {
				return;
			}

			event.preventDefault();
			event.stopPropagation();

			const modalActiveState = state?.['modal active state'];
			const experience = modalActiveState?.experience;
			if (
				typeof experience === 'object' &&
				'loading state' in experience &&
				experience['loading state'] === 'loading'
			) {
				if (modalActiveState?.discard === 'active') {
					send({ type: 'disable discard' });
				} else {
					send({ type: 'enable discard' });
				}
			} else {
				if (shouldDiscardEndExperienceImmediately(context.promptTrigger, context.responseHistory)) {
					props.endExperience();
					return;
				}

				send({ type: 'escape' });
			}
		}

		/**
		 * Handle KeyUp event to prevent unexpected escape behaviours when
		 * there are other escape handlers outside of component (e.g. in product)
		 */
		function handleEscapeKeyUp(event: KeyboardEvent) {
			// Exit early if the key is not Escape
			if (event.key !== 'Escape') {
				return;
			}

			// Continue only if the AI modal is open / in active state
			if (typeof state === 'string' || !('modal active state' in state)) {
				return;
			}

			event.preventDefault();
			event.stopPropagation();
		}

		return bindAll(modalRef.current, [
			{
				type: 'keydown',
				listener: handleEscapeKeyDown,
			},
			{
				type: 'keyup',
				listener: handleEscapeKeyUp,
			},
		]);
	}, [modalRef, state, send, context.promptTrigger, context.responseHistory, props]);

	const aiExperienceCommonData = React.useMemo(
		() => ({
			appearance: props.appearance,
			// configItem can be changed within state machine, so always get it from machine context.
			configItem: context.configItem,
			editorPluginAIProvider: props.editorPluginAIProvider,
			endExperience: props.endExperience,
			lastTriggeredFrom: props.lastTriggeredFrom,
			providerFactory: props.providerFactory,
			editorView: props.editorView,
			positions: props.positions,
			triggerMethod: props.triggerMethod,
			triggeredFor: props.triggeredFor,
			sendToAIExperienceMachine: send,
			modalRef,
			engagementPlatformApi: props.engagementPlatformApi,
			includeKnowledgeFromCurrentPage: context.usePageKnowledge,
		}),
		[
			context.configItem,
			context.usePageKnowledge,
			props.editorView,
			props.editorPluginAIProvider,
			props.appearance,
			props.endExperience,
			props.lastTriggeredFrom,
			props.providerFactory,
			props.positions,
			props.triggerMethod,
			props.triggeredFor,
			props.engagementPlatformApi,
			send,
			modalRef,
		],
	);

	return (
		<div
			// We set these to ensure that when focus initially moves to
			// an element within the modal, that context about the modal
			// (e.g. "Atlassian Intelligence Dialog") is announced too
			role="dialog"
			// ED-19787
			// We would like to use aria-labelledBy so we can label this
			// dynamically for each screen, however it's not working as expected
			// in Chrome and Firefox (works in Safari). For now we'll use the dialog
			// role and make the first element of each screen a screen-reader accessible
			// heading for the experience.
			// aria-label={intl.formatMessage(experienceMessages.dialogAriaLabel)}
			ref={modalRef}
		>
			<AIExperienceCommonDataContext.Provider value={aiExperienceCommonData}>
				<ExperienceApplicationStateManager
					state={state}
					context={context}
					initialPrompt={props.initialPrompt}
				/>
			</AIExperienceCommonDataContext.Provider>
		</div>
	);
}

export function ExperienceApplication(props: ExperienceApplicationProps) {
	const analyticsFlow = useCreateAnalyticsFlow({
		invokeAttributes: {
			invokedFrom: props.lastTriggeredFrom,
			invokedFor: props.triggeredFor ? props.triggeredFor.name : 'text',
			triggerMethod: props.triggerMethod,
			experienceName: props.configItem.key,
		},
	});

	React.useEffect(() => {
		const isInvokedFromEmptyPage = isEmptyDocument(props.editorView.state.doc);
		analyticsFlow.updateCommonAttributes({
			isInvokedFromEmptyPage,
		});
	}, [analyticsFlow, props.editorView]);

	// finish analytics flow and fire remaining steps on unmount
	React.useEffect(() => {
		return () => {
			// have to postpone until animation frame to make sure we end analytics flow after child components unmount
			// which is where we enqueue last events
			window.requestAnimationFrame(() => {
				analyticsFlow.fireQueuedStep();
			});
		};
	}, [analyticsFlow]);

	// This makes sure that the selection preview is hidden when the experience is unmounted
	React.useEffect(() => {
		return () => {
			const tr = props.editorView.state.tr;
			tr.setMeta('hideSelectionPreview', true);
			props.editorView.dispatch(tr);
		};
	}, [props.editorView]);

	return (
		<ModalRegionErrorBoundary
			onCloseFallback={() => {
				props.endExperience();
			}}
		>
			<AnalyticsFlowContextProvider value={analyticsFlow}>
				<ExperienceApplicationStateWrapper
					// Ignored via go/ees005
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
					analyticsContext={{ aiSessionId: analyticsFlow.aiSessionId }}
				/>
			</AnalyticsFlowContextProvider>
		</ModalRegionErrorBoundary>
	);
}
