import { IntlShape } from 'react-intl-next';

import { messages } from './messages';
import type { PromptValue } from './types';

export function historyMessageBuilder(
	formatMessage: IntlShape['formatMessage'],
	advancedPromptValue: PromptValue,
) {
	const tone = (() => {
		switch (advancedPromptValue.tone) {
			case 'professional':
				return formatMessage(messages.toneProfessional);
			case 'empathetic':
				return formatMessage(messages.toneEmpathetic);
			case 'casual':
				return formatMessage(messages.toneCasual);
			case 'neutral':
				return formatMessage(messages.toneNeutral);
			case 'educational':
				return formatMessage(messages.toneEducational);
			default:
				assertUnreachable(advancedPromptValue.tone);
		}
	})();

	const creativity = (() => {
		switch (advancedPromptValue.creativity) {
			case 'high':
				return formatMessage(messages.creativityHigh);
			case 'medium':
				return formatMessage(messages.creativityMedium);
			case 'low':
				return formatMessage(messages.creativityLow);
			default:
				assertUnreachable(advancedPromptValue.creativity);
		}
	})();

	const fields = {
		[formatMessage(messages.taskLabel)]: advancedPromptValue.task.text,
		[formatMessage(messages.instructionsLabel)]: advancedPromptValue.instructions.text,
		[formatMessage(messages.rulesLabel)]: advancedPromptValue.rules.text,
		[formatMessage(messages.toneLabel)]: tone,
		[formatMessage(messages.creativityLabel)]: creativity,
	};

	return Object.entries(fields).reduce((message, [label, value]) => {
		return message + `### ${label}\n${value}\n\n`;
	}, '');
}

function assertUnreachable(x: never): never {
	throw new Error(`Unexpected value: ${x}`);
}
