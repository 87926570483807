import { continueInRovoChat } from '@atlaskit/editor-common/keymaps';
import { type EditorAgent } from '@atlassian/generative-ai-modal/utils/agents';

import type {
	ActionAppearance,
	EditorPluginAIConfigItemAgentAction,
	EditorPluginAIConfigItemGenericChatAction,
} from '../../../prebuilt/config-items/config-items';
import { type EditorPluginAIProvider } from '../../../types/types';
import { ChatIcon } from '../../../ui/icons/prompt/chat';

import { messages } from './messages';

type CreateContinueInChatProps = {
	appearance: ActionAppearance;
	product: EditorPluginAIProvider['product'];
	actionSideEffect?: () => void;
	actionOverride?: ({ channelId }: { channelId: string }) => void;
};

export const createContinueInChat = ({
	appearance,
	product,
	actionSideEffect,
}: CreateContinueInChatProps): EditorPluginAIConfigItemGenericChatAction => ({
	type: 'generic-chat',
	title: messages.continueInChatLabel,
	appearance,
	shortcut: continueInRovoChat,
	run: ({ name, dialogues, publish }) => {
		if (!publish || !dialogues.length) {
			return;
		}

		publish({
			type: 'chat-new',
			product,
			data: {
				dialogues,
				name,
			},
			source: 'editor',
		});

		actionSideEffect?.();
	},
	preserveEditorSelectionOnComplete: true,
	icon: ChatIcon,
});

type CreateContinueInChatForAgentProps = CreateContinueInChatProps & { agent: EditorAgent };

export const createContinueInChatForAgent = ({
	appearance,
	agent,
	product,
	actionSideEffect,
	actionOverride,
}: CreateContinueInChatForAgentProps): EditorPluginAIConfigItemAgentAction => ({
	type: 'agent',
	title: messages.continueInChatLabel,
	shortcut: continueInRovoChat,
	appearance,
	run: ({ channelId, publish }) => {
		if (!channelId || !publish) {
			return;
		}

		if (typeof actionOverride === 'function') {
			actionOverride({ channelId });
			return;
		}

		publish({
			type: 'chat-open',
			product,
			data: {
				channelId,
				agentId: agent.namedId,
			},
			source: 'editor',
		});

		actionSideEffect?.();
	},
	preserveEditorSelectionOnComplete: true,
	icon: ChatIcon,
});
