import { type ComponentPropsConfig } from '../../../utils/codegen';

export const componentPropsConfig: ComponentPropsConfig = {
	children: true,
	testId: true,
};

/**
 * THIS SECTION WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * This component was generated against an existing platform component.
 * If you are seeing lint errors in this file, it means that this file's dependencies have been modified and the component will need to be regenerated.
 *
 * Please view https://hello.atlassian.net/wiki/spaces/ECO/pages/3358130330/How+to+Regenerate+UI+Kit+Components for more information on how to proceed.
 *
 * For further questions, please ping the #help-forge-ui channel.
 *
 * @codegen <<SignedSource::49b4c6754a4f4a74683b611379d2f153>>
 * @codegenId #tab-panel
 * @codegenCommand yarn workspace @atlassian/forge-ui codegen tab-panel
 * @codegenDependency ../../../../../../../design-system/tabs/src/types.tsx <<SignedSource::f2168d42d44e8f2d02748d5db80bad13>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/hooks.tsx <<SignedSource::a657d685e36a99316aa148260b467552>>
 * @codegenDependency ../../../../../../../design-system/tabs/src/components/tab-panel.tsx <<SignedSource::882da7bbeca38cb4d1cd7d853166a50d>>
 */
/* eslint @repo/internal/codegen/signed-source-integrity: "warn" */
import React from 'react';
import type { RenderFn } from '@atlassian/forge-ui-types';
import { TabPanel as PlatformTabPanel } from '@atlaskit/tabs';

type PlatformTabPanelProps = React.ComponentProps<typeof PlatformTabPanel>;

export type TabPanelProps = Pick<
  PlatformTabPanelProps,
  'children' | 'testId'
>;

/**
 * Tabs are used to organize content by grouping similar information on the same page.
 */
export const TabPanel = (props: Parameters<RenderFn>[0]) => {
  const {
    testId,
  } = props.forgeDoc.props as TabPanelProps;
  return (
    <PlatformTabPanel
      children={props.forgeDoc.children.map(c => <React.Fragment key={c.key}>{props.render(c)}</React.Fragment>)}
      testId={testId}
    />
  );
};
/**
 * @codegenEnd
 */
