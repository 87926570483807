import React, { type ReactNode, useContext } from 'react';
import {
	ConfluenceExtension,
	type Dispatch,
	type ForgeDoc,
	type ForgeExtensionPoints,
} from '@atlassian/forge-ui-types';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import cloneDeep from 'lodash/cloneDeep';
import { fg } from '@atlaskit/platform-feature-flags';

import { Box, xcss, Text } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { type ComponentMap, type ModalExtension } from './util';
import { getAkModalWidth } from '../utils';
import { useSendNodeCountAnalytics } from '../utils/useSendNodeCountAnalytics';
import { RendererContext } from '../context';
import { type FormProps } from '../components/UIKit';
import SectionMessage from '@atlaskit/section-message';

const ModalWrapperStyles = xcss({
	paddingBottom: 'space.300',
});

export const NeverLoads = React.lazy(() => {
	return new Promise(() => {});
});

export const getComponent = (componentMap: ComponentMap, componentType: string) => {
	if (componentType === 'User' || componentType === 'Avatar') {
		return componentMap['User'] || componentMap['Avatar'];
	}
	if (componentType === 'UserGroup' || componentType === 'AvatarStack') {
		return componentMap['UserGroup'] || componentMap['AvatarStack'];
	}
	return componentMap[componentType];
};

const isAlignFormButtonsEnd = (extensionType: string, forgeDoc: ForgeDoc) => {
	// Confluence Byline and ContextMenu Forge apps with a Form component as
	// there only child shifts the form buttons to the end in the footer
	return (
		(extensionType === ConfluenceExtension.BYLINE ||
			extensionType === ConfluenceExtension.CONTEXT_MENU) &&
		forgeDoc.children[0].type === 'Form'
	);
};

export interface RendererNextProps {
	/* ForgeDoc to be rendered */
	forgeDoc?: ForgeDoc;
	/* Map of component types to render functions */
	components: ComponentMap;
	// defaultComponents: ComponentMap;
	/* Function used by components to dispatch effects */
	dispatch: Dispatch;
	/* Error message to show the user. Set when an unexpected client-side error happens. */
	error?: string;
	/* Error to throw when render function is not recognized */
	customRenderErrorFn?: (forgeDocType: string) => void;
	/* Whether a dispatched effect is pending. Not included in function due to server side render*/
	loading?: boolean;
	/* Replace the default spinner with a custom loading component. */
	loadingComponent?: React.ReactNode;
	/* Object that stores all necessary properties for a Modal Extension */
	modalExtension?: ModalExtension;
	/* Contains the module type of the extension */
	extensionType?: ForgeExtensionPoints;
	/* Indicates whether a CSUIKit app is being rendered*/
	isNative?: boolean;
}

export type MemoizedComponentProps = {
	hasComponentUpdated: boolean;
	children: () => ReactNode;
};

const forgeDocWithModalComponent = (
	forgeDoc: ForgeDoc,
	modalExtension: ModalExtension,
): ForgeDoc => {
	const { closeModalExtension, title, modalWidth, onSubmitSuccess } = modalExtension;

	// ModalDialog component sets the default width to `medium`
	const akModalWidth = modalWidth ? getAkModalWidth(modalWidth) : undefined;

	const forgeDocWithModal = {
		...forgeDoc,
		children: [
			{
				type: 'ModalDialog',
				props: {
					onClose: closeModalExtension,
					header: title,
					width: akModalWidth,
					onSubmitSuccess: onSubmitSuccess,
				},
				children: forgeDoc.children,
			},
		],
	};

	return forgeDocWithModal;
};

const forgeDocWithFormEndButtons = (forgeDoc: ForgeDoc) => {
	return {
		...forgeDoc,
		children: [
			{
				...forgeDoc.children[0],
				props: { ...forgeDoc.children[0].props, alignFooterButtons: 'end' },
			},
		],
	};
};

export const RendererNext = ({
	forgeDoc,
	loading = false,
	error,
	customRenderErrorFn,
	components,
	dispatch,
	modalExtension,
	extensionType,
	isNative,
}: RendererNextProps) => {
	const { forgeEnvironment } = useContext(RendererContext);

	useSendNodeCountAnalytics({
		forgeDoc,
		forgeEnvironment: forgeEnvironment ?? 'DEVELOPMENT',
		UIKitVersion: isNative ? '2' : '1',
	});

	const render = (forgeDoc: ForgeDoc) => {
		const renderFn = getComponent(components, forgeDoc.type);
		if (!renderFn) {
			if (customRenderErrorFn) {
				customRenderErrorFn(forgeDoc.type);
			} else {
				throw new Error(`Error rendering app - encountered unknown component ${forgeDoc.type}.`);
			}
		}

		return renderFn({ forgeDoc, dispatch, render });
	};

	// true while fetching initial forgeDoc
	if (!fg('fix-react-18-concurrent-upgrade-infinite-rendering')) {
		if (loading && !forgeDoc) {
			return <NeverLoads />;
		}
	}

	if (error) {
		return (
			<SectionMessage title="Something went wrong" appearance="error">
				<Text>{error}</Text>
			</SectionMessage>
		);
	}

	if (!forgeDoc) {
		return null;
	}

	// Extensions rendered in a modal for version 10 and onwards are handled here.
	// This is the default behavior for handles model extensions across product modules.
	// When the modelExtension prop is present, and the forgeReactMajorVersion is 10 or higher,
	// the provided foreDoc is rendered within a Modal component with the provided modalExtension props.
	// This setup is to ensure that the modal extension is rendered correctly across all product modules,
	// and is the resolution for the HOT described in: https://ops.internal.atlassian.com/jira/browse/HOT-108146
	if (forgeDoc.forgeReactMajorVersion && forgeDoc.forgeReactMajorVersion >= 10 && modalExtension) {
		const {
			closeModalExtension,
			title,
			modalWidth,
			onSubmitSuccess,
			shouldCloseOnOverlayClick,
			shouldCloseOnEscapePress,
		} = modalExtension;
		const isFirstChildAForm = forgeDoc.children[0].type === 'Form';

		// injects onSubmitSuccess to into the Form component submit handler
		if (isFirstChildAForm) {
			// doing a deep clone here to make a copy of onSubmit
			const formPropsCopy = cloneDeep(forgeDoc.children[0].props) as FormProps;
			forgeDoc.children[0].props = {
				...formPropsCopy,
				onSubmit: async () => {
					const isSuccess = await formPropsCopy.onSubmit();
					if (onSubmitSuccess && (isSuccess === true || isSuccess === undefined)) {
						onSubmitSuccess();
					}
				},
			};
		}

		const akModalWidth = modalWidth ? getAkModalWidth(modalWidth) : undefined;

		return (
			<Modal
				shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
				shouldCloseOnEscapePress={shouldCloseOnEscapePress}
				onClose={closeModalExtension}
				width={akModalWidth}
			>
				<ModalHeader>
					<ModalTitle>{title}</ModalTitle>
					<IconButton
						appearance="subtle"
						icon={CrossIcon}
						label="Close Modal"
						onClick={closeModalExtension}
					/>
				</ModalHeader>
				<ModalBody>
					<Box xcss={ModalWrapperStyles}>{render(forgeDoc)}</Box>
				</ModalBody>
			</Modal>
		);
	}

	const newForgeDoc =
		extensionType && isAlignFormButtonsEnd(extensionType, forgeDoc)
			? forgeDocWithFormEndButtons(forgeDoc)
			: forgeDoc;

	const extensionForgeDoc = modalExtension
		? forgeDocWithModalComponent(newForgeDoc, modalExtension)
		: newForgeDoc;

	return <>{render(extensionForgeDoc)}</>;
};
