import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Stack, Box, xcss } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';

import type { SpaceRole } from '@confluence/space-roles/entry-points/space-role-types';
import { RoleSelector } from '@confluence/space-roles/entry-points/TableRow';

import type { RoleNodes } from '../../roles-list/useRoleList';

import type { EditResultSelectionType } from './EditConfirmationModal';

const i18n = defineMessages({
	reassignOption: {
		id: 'default-space-permissions.roles-management.edit-confirmation-modal.reassign-option',
		defaultMessage: 'Change all permissions that are associated with this role',
		description:
			'Label for a radio button that allows the user to change all permissions associated with a role',
	},
	deleteOption: {
		id: 'default-space-permissions.roles-management.edit-confirmation-modal.delete-option',
		defaultMessage: 'Reassign all users that hold this role to a different role',
		description:
			'Label for a radio button that allows the user to reassign all users that hold a role to a different role',
	},
});

const roleSelectorStyle = xcss({
	paddingTop: 'space.100',
	paddingLeft: 'space.300',
	marginLeft: 'space.050',
});

// The prefilled role will be Collaborator, due to product requirements
const ROLE_PREFILL = '0c103ff5-44d0-4b2a-b80d-8690442c95f0';

export const EditConfirmationResultSelection = ({
	roleId,
	editResult,
	setEditResult,
	allRoles,
}: {
	roleId: string;
	editResult: EditResultSelectionType;
	setEditResult: React.Dispatch<React.SetStateAction<EditResultSelectionType>>;
	allRoles: RoleNodes;
}) => {
	const { formatMessage } = useIntl();
	const pickerOptions: SpaceRole[] = allRoles
		.filter((role) => role.roleId !== roleId)
		.map((role) => ({
			id: role.roleId,
			name: role.roleDisplayName,
			description: role.roleDescription,
			permissions: role.spacePermissionList.map((permission) => permission.displayName),
		}));

	const [rolePickerValue, setRolePickerValue] = useState<SpaceRole | null>(null);

	return (
		<Stack space="space.200">
			<Box>
				<Stack space="space.100">
					<Radio
						isChecked={editResult === 'modify-permissions'}
						onChange={() => setEditResult('modify-permissions')}
						name="modify-permissions"
						value="modify-permissions"
						label={formatMessage(i18n.reassignOption)}
					/>
					<Radio
						isChecked={editResult === 'reassign-role'}
						onChange={() => setEditResult('reassign-role')}
						name="reassign-role"
						value="reassign-role"
						label={formatMessage(i18n.deleteOption)}
					/>
				</Stack>
				{editResult === 'reassign-role' && (
					<Box xcss={roleSelectorStyle}>
						<RoleSelector
							value={rolePickerValue?.id || ROLE_PREFILL}
							onChange={(value) => setRolePickerValue(value)}
							displayType="table"
							appearance="default"
							spaceRoleOptions={pickerOptions}
						/>
					</Box>
				)}
			</Box>
		</Stack>
	);
};
