/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';
import { OPERATIONAL_EVENT_TYPE, TRACK_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import {
	type ExtensionAPI,
	type ExtensionManifest,
	type ExtensionProvider,
	type FieldDefinition,
} from '@atlaskit/editor-common/extensions';
import type { EditorActions } from '@atlaskit/editor-core';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import { fg } from '@atlaskit/platform-feature-flags';
import { ForgeExtensionProvider, type SendAnalyticsFunc } from './forgeExtensionProvider';
import { shouldBlockExtensionDueToAppAccessPolicy } from '../render';
import { parse } from '@atlassian/cs-ari';
import { getExtensionType, isCustomUI as getIsCustomUI } from '@atlassian/forge-ui';
import type { GQLExtensionContextsFilter } from '@atlassian/forge-ui/provider';
import { getForgeUIExtensionsAsync } from '@atlassian/forge-ui/provider';
import { emit } from '@atlassian/forge-ui/events';

import {
	type AnalyticsWebClient,
	extensionIdToAnalyticsAttributes,
	type ForgeUIAnalyticsContext,
	sendEvent,
} from '@atlassian/forge-ui/analytics';
import { type ForgeDoc, type ForgeUIExtensionType } from '@atlassian/forge-ui-types';
import type ApolloClient from 'apollo-client';
import { css, jsx } from '@compiled/react';
import type { Node } from 'prosemirror-model';
import {
	CONNECT_ADDON_ACTION_SUBJECT,
	CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE,
	EXTENSION_NAMESPACE,
	EXTENSION_TYPE_MULTI_BODIED,
	MODULE_NAME,
} from '../utils/constants';
import {
	type ForgeConfigPayload,
	validateForgeConfigPayload,
	handleNodeUpdateError,
	CONFIG_USER_PICKER_PROVIDER,
	ValidationError,
	createSchemaFromForgeDoc,
} from '../config';
import { createTitleWithEnvironmentInfo } from './getForgeExtensionProvider';
import { createLocalId } from '../utils/createLocalId';
import { extractKeyFromId } from '../utils/extractKeyFromId';
import { getConnectMacroId } from '../utils/getConnectMacroId';
import Icon from './icon';
import {
	type ConnectToForgeParameters,
	type CustomEditContext,
	type ForgeCreateExtensionProps,
	type ForgeExtension,
	type ForgeExtensionManifest,
	type ForgeExtensionParameters,
} from '../types';

const manifestIcons = {
	'16': () => import('./icon'),
	'24': () => import('./icon'),
	'48': () => import('./icon'),
};

// We keep track of the reason that an extension failed to upgrade, for analytics purposes
const connectToForgeBlockedExtensions = new Set();
// We keep track of the macro IDs that were rendered as Forge macros, for analytics purposes
const connectRenderedAsForgeMacroIds = new Set<string>();

const transformConfigBefore = (parameters: ForgeExtensionParameters) => getConfig(parameters);
const transformConfigAfter = (parameters: any) => ({ guestParams: parameters });

const getConfig = ({ config, guestParams }: ForgeExtensionParameters) => guestParams || config;

type UpdateADFEntity = Parameters<Parameters<ExtensionAPI['doc']['update']>[1]>[0];

const bodiedExtensionHelper: {
	setRenderer: (renderer: (() => JSX.Element) | null) => void;
} = {
	setRenderer: () => {},
};

const hideIframe = css({
	display: 'none',
});

/**
 * A component that allows us to render Forge UI Extensions directly in the editor
 * Normally, they are rendered as part of the extension manifest's render function,
 * but that function is not called for bodied extensions, so we need to render them separately.
 */
export const EditorForgeExtensionConfigHelperRenderer = () => {
	const [renderer, setRenderer] = React.useState<(() => JSX.Element) | null>(null);
	bodiedExtensionHelper.setRenderer = (renderer) =>
		setRenderer(
			// We wrap the renderer function here, otherwise setState thinks we're passing a state mutation function
			() => renderer,
		);

	return renderer ? renderer() : null;
};

export const upgradeConnectNodeToForge = (
	node: UpdateADFEntity,
	extensionManifest: ForgeExtensionManifest,
): UpdateADFEntity => {
	const { attrs } = node;
	// Ensure extension hasn't been upgraded since the last check
	if (attrs?.extensionType === CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE) {
		// Use the existing Connect macro ID as the Forge localId
		const macroId = getConnectMacroId(attrs.parameters);
		const extensionProps = extensionManifest?.createExtensionProps(macroId);
		return {
			...node,
			attrs: {
				...attrs,
				...extensionProps.attrs,
				// We shouldn't override the Editor localId of an existing node once it's been created
				localId: attrs.localId || extensionProps.attrs.localId,
				parameters: {
					...convertConnectParametersToForge(attrs.parameters),
					...extensionProps.attrs.parameters,
				},
			},
		};
	}
	return node;
};

const convertConnectParametersToForge = ({
	guestParams = {},
	macroParams = {},
	...restParams
}: ConnectToForgeParameters = {}): ConnectToForgeParameters => {
	const migratedGuestParams = { ...guestParams };
	Object.entries(macroParams).forEach(([key, obj]) => {
		// Don't override existing entries
		if (typeof migratedGuestParams[key] === 'undefined' && obj && 'value' in obj) {
			migratedGuestParams[key] = obj.value;
		}
	});
	return {
		macroParams,
		guestParams: migratedGuestParams,
		...restParams,
	};
};

const mergeNodeWithPayload = <T extends Record<string, unknown>>(
	attrs: T,
	{ config, body }: ForgeConfigPayload,
): { attrs: T } & Pick<ForgeCreateExtensionProps, 'content'> => {
	return {
		attrs: {
			...attrs,
			parameters: {
				...(attrs.parameters || {}),
				...transformConfigAfter(config),
			},
		},
		...(body ? { content: body.content } : {}),
	};
};

interface ForgeExtensionProviderSharedParams {
	accountId?: string;
	apolloClient: ApolloClient<object>;
	cloudId: string;
	contextIds: string[];
	forgeUIAnalyticsContext: ForgeUIAnalyticsContext;
	page: string;
	product: string;
	analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient> | undefined;
	createRenderFunction: (
		extension?: ForgeUIExtensionType,
		customEditContext?: CustomEditContext,
	) => Promise<({ node }: { node: ForgeExtension }) => JSX.Element>;
	connectExtensionProvider?: Promise<ExtensionProvider>;
	editorActions?: EditorActions;
	getFieldsDefinitionFunction: (data: ForgeExtensionParameters) => Promise<FieldDefinition[]>;
	extensionsFilter?: GQLExtensionContextsFilter[];
	dataClassificationTagIds?: string[];
	locale?: string;
}

export async function getForgeExtensionProviderShared({
	accountId,
	apolloClient,
	cloudId,
	contextIds,
	forgeUIAnalyticsContext,
	page,
	product,
	analyticsWebClient,
	createRenderFunction,
	getFieldsDefinitionFunction,
	connectExtensionProvider,
	editorActions,
	extensionsFilter,
	dataClassificationTagIds,
	locale,
}: ForgeExtensionProviderSharedParams) {
	let extensionProvider: ForgeExtensionProvider;
	const { extensions } = await getForgeUIExtensionsAsync({
		client: apolloClient,
		contextIds,
		moduleType: MODULE_NAME,
		expandAppOwner: true,
		extensionsFilter,
		dataClassificationTagIds,
		locale,
	});

	const eventSource = editorActions ? 'editPageScreen' : 'viewPageScreen';

	const upgradeConnectExtensionToForge = async (
		attrs: Record<string, string>,
		actions: ExtensionAPI<ForgeExtensionParameters>,
	): Promise<UpdateADFEntity> => {
		const { localId, extensionKey } = attrs;
		const extensionManifest = await extensionProvider.getConnectToForgeExtension(extensionKey);
		if (!extensionManifest || !extensionManifest.createExtensionProps) {
			throw new Error(
				`Connect to Forge macro upgrade: No ${
					!extensionManifest ? 'extension manifest' : 'createExtensionProps function'
				} found`,
			);
		}

		return new Promise<UpdateADFEntity>((resolve) => {
			actions.doc.update(localId, (currentValue) => {
				const updatedNode = upgradeConnectNodeToForge(currentValue, extensionManifest);
				resolve(updatedNode);
				return updatedNode;
			});
		});
	};

	const maybeUpgradeConnectExtensionToForge = async (
		actions?: ExtensionAPI<ForgeExtensionParameters>,
	) => {
		// Only passed through when we are in the Editor view
		if (editorActions) {
			const node = editorActions.getSelectedNode();
			const attrs = node?.attrs;
			if (attrs?.extensionType === CONNECT_CONFLUENCE_MACRO_EXTENSION_TYPE) {
				const { extensionKey, localId: nodeLocalId } = attrs;
				const macroId = getConnectMacroId(attrs.parameters);
				const extensionType = node?.type.name;

				if (extensionType === EXTENSION_TYPE_MULTI_BODIED) {
					// Upgrade to forge blocked because:
					//     Extension is multi-bodied which forge doesn't support
					connectToForgeBlockedExtensions.add(macroId);
					if (analyticsWebClient) {
						sendEvent(analyticsWebClient)({
							source: eventSource,
							...forgeUIAnalyticsContext,
							eventType: OPERATIONAL_EVENT_TYPE,
							action: 'h11n_macro_upgrade_rich_text_skipped',
							actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
							actionSubjectId: extensionKey,
							attributes: {
								extensionType,
								extensionKey,
								nodeLocalId,
							},
						});
					}
					return;
				}

				/* At this point, we know the extension should be upgraded from Connect to Forge
				 * If anything goes wrong after this point, it should be logged as an upgrade failure */
				try {
					if (!actions?.doc?.update) {
						throw new Error('Connect to Forge macro upgrade: Missing ExtensionAPI update action');
					}

					const updatedNode = await upgradeConnectExtensionToForge(attrs, actions);

					if (analyticsWebClient) {
						const { localId, extensionId } = (
							updatedNode.attrs as ForgeCreateExtensionProps['attrs']
						).parameters;
						sendEvent(analyticsWebClient)({
							source: eventSource,
							...forgeUIAnalyticsContext,
							eventType: TRACK_EVENT_TYPE,
							action: 'h11n_macro_upgrade_success',
							actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
							actionSubjectId: extensionKey,
							attributes: {
								extensionKey,
								...extensionIdToAnalyticsAttributes(extensionId),
								localId,
								nodeLocalId,
								extensionType,
							},
						});
					}
				} catch (e) {
					if (analyticsWebClient) {
						sendEvent(analyticsWebClient)({
							source: eventSource,
							...forgeUIAnalyticsContext,
							eventType: OPERATIONAL_EVENT_TYPE,
							action: 'h11n_macro_upgrade_failed',
							actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
							actionSubjectId: extensionKey,
							attributes: {
								errorMessage: e instanceof Error ? e.message : String(e),
								extensionKey,
								nodeLocalId,
								extensionType,
							},
						});
					}
					throw e;
				}
			}
		}
	};

	const updateFunction = async (
		parameters: ForgeExtensionParameters,
		actions?: ExtensionAPI<ForgeExtensionParameters>,
		extension?: ForgeUIExtensionType,
		hasCustomConfig?: boolean,
	): Promise<ForgeExtensionParameters | void> => {
		await maybeUpgradeConnectExtensionToForge(actions);

		const node = editorActions?.getSelectedNode();

		const isBodiedExtension = node?.type.name === 'bodiedExtension';
		if (node && extension && actions && (hasCustomConfig || isBodiedExtension)) {
			const forgeExtensionNode = convertNodeToForgeExtension(node);

			// No need to wait for config to render to start showing the panel (for non-custom config)
			// noinspection ES6MissingAwait
			renderConfigHelper({
				extension,
				forgeExtensionNode,
				hasCustomConfig: Boolean(hasCustomConfig),
				isInitialInsertion: false,
				onSubmit: (payload) => updateNodeWithPayload(actions, node.attrs.localId, payload),
				onValidate: (data) =>
					validateForgeConfigPayload(data, {
						isBodiedExtension,
						isInitialInsertion: false,
						schema: editorActions?._privateGetEditorView?.()?.state.schema,
						useStrictParameterValidation: fg('enable-strict-forge-macro-parameter-validation'),
					}),
			});
		}

		if (!hasCustomConfig) {
			return actions!.editInContextPanel(transformConfigBefore, async (params) =>
				transformConfigAfter(params),
			);
		}
	};

	const updateNodeWithPayload = (
		actions: ExtensionAPI,
		localId: string,
		payload: ForgeConfigPayload,
	) => {
		try {
			actions.doc.update(localId, (currentValue) =>
				mergeNodeWithPayload(currentValue?.attrs || {}, payload),
			);
		} catch (error: unknown) {
			handleNodeUpdateError(error);
		}
	};

	/**
	 * Converts a ProseMirror Node (received by EditorActions API) to a ForgeExtension (provided to render function)
	 * This includes:
	 * - Extracting the node's attributes
	 * - Extracting the node's type
	 * - Transforming the node's content from ProseMirror to ADF JSON (and handling any errors)
	 */
	const convertNodeToForgeExtension = (node: Node): ForgeExtension => {
		const forgeExtension = {
			...node.attrs,
			type: node.type.name,
		} as ForgeExtension;
		try {
			const { content } = new JSONTransformer().encode(node);
			return { ...forgeExtension, content };
		} catch (error: unknown) {
			const errorMessage =
				error instanceof Error
					? error.message
					: typeof error === 'string'
						? error
						: JSON.stringify(error);

			// failed to encode node to JSON
			// continue rendering custom config without content and emit analytics error event
			if (analyticsWebClient) {
				const { extensionKey, localId, environmentType } = node?.attrs;

				sendEvent(analyticsWebClient)({
					source: 'editPageScreen',
					...forgeUIAnalyticsContext,
					eventType: OPERATIONAL_EVENT_TYPE,
					action: 'failed',
					actionSubject: 'forge.ui.extension',
					attributes: {
						method: 'convertNodeToForgeExtension',
						errorMessage,
						localId,
						extensionKey,
						forgeEnvironment: environmentType,
					},
				});
			}
			return forgeExtension;
		}
	};

	const renderConfigHelper = async ({
		extension,
		forgeExtensionNode,
		hasCustomConfig,
		isInitialInsertion,
		onSubmit,
		onValidate,
	}: {
		extension: ForgeUIExtensionType;
		forgeExtensionNode: ForgeExtension;
		hasCustomConfig: boolean;
		isInitialInsertion: boolean;
		onSubmit: (payload: ForgeConfigPayload) => void;
		onValidate: (data: unknown) => ForgeConfigPayload;
	}) => {
		let gqlExtension: ForgeUIExtensionType = extension;
		let customEditContext: CustomEditContext | undefined;

		if (hasCustomConfig) {
			gqlExtension = {
				...extension,
				properties: {
					...extension.properties,
					resource: extension.properties?.config?.resource,
					render: extension.properties?.config?.render,
					title: extension.properties?.config?.title,
					viewportSize: extension.properties?.config?.viewportSize,
				},
			};

			const onModalClose = () => bodiedExtensionHelper.setRenderer(null);
			customEditContext = {
				isInserting: isInitialInsertion,
				bridge: {
					close: onModalClose,
					submit: ({ data }) => {
						// This can throw and propagate exceptions back down to the app
						const payload = onValidate(data);

						// This can throw and propagate exceptions back down to the app
						onSubmit(payload);

						if (!payload.keepEditing) {
							onModalClose();
						}
						return true;
					},
				},
				modalExtension: {
					type: 'macroConfig',
					title: extension.properties?.config?.title,
					closeModalExtension: onModalClose,
					modalWidth: extension.properties?.config?.viewportSize,
					shouldCloseOnOverlayClick: false,
					shouldCloseOnEscapePress: false,
				},
			};
		}

		const renderFunction = await createRenderFunction(gqlExtension, customEditContext);

		// The Forge Doc config iframe should be hidden and resolve the config in the background
		// The loading component for custom config is unaffected as it is rendered in a Portal
		bodiedExtensionHelper.setRenderer(() => (
			<div css={hideIframe}>{renderFunction({ node: forgeExtensionNode })}</div>
		));
	};

	const sendConnectToForgeResolveAnalytics: SendAnalyticsFunc = (extensionKey, errorMessage) => {
		if (analyticsWebClient) {
			sendEvent(analyticsWebClient)({
				source: eventSource,
				...forgeUIAnalyticsContext,
				eventType: OPERATIONAL_EVENT_TYPE,
				action: 'h11n_macro_resolve_failed',
				actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
				actionSubjectId: extensionKey,
				attributes: {
					errorMessage,
					extensionKey,
				},
			});
		}
	};

	const fieldsModules: ExtensionManifest<ForgeExtensionParameters>['modules']['fields'] = {
		user: {
			[CONFIG_USER_PICKER_PROVIDER]: {
				provider: async () => ({
					siteId: cloudId,
					// If principalId === 'Context', upstream attempts to use other context to discover the principal ID
					principalId: accountId || 'Context',
					fieldId: 'forgeConfigUserPicker',
					productKey: product as 'confluence',
				}),
			},
		},
	};

	const extensionManifests: ForgeExtensionManifest[] = (extensions || [])
		.filter(({ id }) => parse(id).resourceId !== undefined)
		.map((extension) => {
			const { id, properties, environmentType, migrationKey, environmentKey } = extension;
			const title = createTitleWithEnvironmentInfo(
				properties.title || 'Forge UI Macro',
				environmentType,
				environmentKey,
			);

			const extensionIdWithoutPrefix = parse(id).resourceId!;
			const isCustomUI = getIsCustomUI(extension);
			const isUiKitTwo = extension.properties.render === 'native';

			const hasUiKitOneConfig = !!properties.config && !!properties.config.function;
			const hasConfig = isUiKitTwo || isCustomUI ? !!properties.config : hasUiKitOneConfig;
			const hasCustomConfig = (isUiKitTwo || isCustomUI) && !!properties.config?.resource;

			const connectModuleKey = extractKeyFromId(extensionIdWithoutPrefix);
			const createExtensionProps = (macroId?: string): ForgeCreateExtensionProps => {
				const localId = macroId || createLocalId();
				const extensionId = id;
				const type = getExtensionType(extension);
				return {
					type: type,
					attrs: {
						extensionKey: extensionIdWithoutPrefix,
						extensionType: EXTENSION_NAMESPACE,
						parameters: {
							localId,
							extensionId,
							extensionTitle: title,
						},
						text: title,
						localId,
					},
					...(type === 'bodiedExtension'
						? {
								// include empty content so bodiedExtension doesn't throw error
								content: [{ type: 'paragraph', content: [] }],
							}
						: {}),
				};
			};

			const uiKitTwoGetFieldsDefinitionFunction = async (parameters: ForgeExtensionParameters) => {
				const { extensionId, localId } = parameters;
				const config = getConfig(parameters);

				const id = `${extensionId}-${localId}`;

				// Until Editor forceably closes the config panel, we need to clear the fields in the panel otherwise
				// config values will be overwritten by previous fields
				if (hasCustomConfig) {
					return [];
				}

				const getForgeDoc = new Promise<ForgeDoc>((resolve, reject) => {
					emit(`GET_CONFIG_FORGE_DOC_${id}`, { resolve, reject, config });
				});

				try {
					const forgeDoc = await getForgeDoc;
					return createSchemaFromForgeDoc(forgeDoc);
				} catch (error: unknown) {
					const { name, message } = error as Error;
					if (!(error instanceof ValidationError)) {
						analyticsWebClient &&
							sendEvent(analyticsWebClient)({
								eventType: OPERATIONAL_EVENT_TYPE,
								action: 'errored',
								actionSubject: 'forge.ui.extension',
								source: 'editPageScreen',
								attributes: {
									errorName: name,
									errorMessage: message,
									...extensionIdToAnalyticsAttributes(extensionId),
									extensionType: extension.type,
									forgeEnvironment: extension.environmentType,
								},
							});
					}
					throw new Error(message || '');
				}
			};

			const connectToForgeGetFieldsDefinitionFunction = async (
				parameters: ForgeExtensionParameters,
			) => {
				const macroId = getConnectMacroId(parameters);
				if (!parameters.extensionId) {
					/* If extensionId is missing, then we are dealing with a Connect or core macro instead of Forge
					 * This can happen when
					 *  - reverting a page with an upgraded Connect -> Forge addon back to Connect,
					 *  - if the Connect macro wasn't upgraded for some reason (eg. bodiedExtension / rich text macro)
					 *  - there is also a bug when loading a Forge macro, then clicking on a Connect macro that is uninstalled - https://product-fabric.atlassian.net/jira/servicedesk/projects/DTR/queues/issue/DTR-2569
					 *
					 * We can't edit/store the config in this state, so safer to just prevent panel loading
					 */

					// We start by assuming it's a core macro
					let userMessage: string =
						'Macro configuration failed to load. Please close this panel and try again.';
					let errorMessage: string = 'non-forge-macro';

					// Macro ID is only defined for Connect/core macros, not Forge macros (for now, this is subject to change)
					if (macroId) {
						// Whether we report this as a warning (expected) or an error (unexpected)
						let isWarning = false;
						const isBodied = connectToForgeBlockedExtensions.has(macroId);

						if (isBodied) {
							userMessage = `Editing multi-bodied macros is not currently supported.`;
							errorMessage = 'bodied-macro-blocked';
							isWarning = true;
						} else if (connectRenderedAsForgeMacroIds.has(macroId)) {
							// We know that at some point this macro was rendered as a Forge macro, so it must have been reverted
							userMessage = 'This macro has been reverted. Please close this panel and try again.';
							errorMessage = 'macro-reverted';
							isWarning = true;
						}

						// Only report h11n errors for harmonised Connect macros
						if (analyticsWebClient && extension.migrationKey) {
							sendEvent(analyticsWebClient)({
								source: eventSource,
								...forgeUIAnalyticsContext,
								eventType: OPERATIONAL_EVENT_TYPE,
								action: isWarning ? 'h11n_macro_config_warning' : 'h11n_macro_config_error',
								actionSubject: CONNECT_ADDON_ACTION_SUBJECT,
								actionSubjectId: extension.id,
								attributes: {
									errorMessage,
									macroId,
									macroTitle: parameters.macroMetadata?.title,
									macroKey: extension.key,
									extensionId: extension.id,
									migrationKey: extension.migrationKey,
								},
							});
						}
					}

					throw new Error(userMessage);
				}
				// If we make it here, we are dealing with a Forge macro with an extensionId
				if (macroId) {
					connectRenderedAsForgeMacroIds.add(macroId);
				}
				return (isCustomUI || isUiKitTwo) && !hasUiKitOneConfig
					? uiKitTwoGetFieldsDefinitionFunction(parameters)
					: getFieldsDefinitionFunction(parameters);
			};

			const icon = <Icon label={properties?.title ?? ''} imgUrl={properties.icon} />;
			const macroIcons =
				properties.icon && properties.icon.length > 0
					? {
							'16': () => Promise.resolve(() => icon),
							'24': () => Promise.resolve(() => icon),
							'48': () => Promise.resolve(() => icon),
						}
					: manifestIcons;

			return {
				title,
				description: properties.description,
				type: EXTENSION_NAMESPACE,
				key: extensionIdWithoutPrefix,
				icons: macroIcons,
				categories: properties.categories,
				connectModuleKey,
				migrationKey,
				createExtensionProps,
				modules: {
					...(shouldBlockExtensionDueToAppAccessPolicy(extension)
						? {}
						: {
								quickInsert: [
									{
										key: 'forge-macro',
										action: async (actions) => {
											let extensionProps = createExtensionProps();
											const { localId, extensionId } = extensionProps.attrs.parameters;
											const { type: extensionType } = extensionProps;
											const isBodiedExtension = extensionType === 'bodiedExtension';

											if (hasCustomConfig) {
												const forgeExtensionNode = {
													...extensionProps.attrs,
													type: extensionType,
												};
												const payload = await new Promise<ForgeConfigPayload>((resolve) => {
													let isInitialInsertion = true;
													renderConfigHelper({
														extension,
														forgeExtensionNode,
														hasCustomConfig,
														isInitialInsertion: true, // We don't use the variable here because this is only executed once
														onSubmit: (payload) => {
															if (isInitialInsertion) {
																isInitialInsertion = false;
																resolve(payload);
															} else if (actions) {
																// Update existing node
																updateNodeWithPayload(actions, localId, payload);
															}
														},
														onValidate: (data) =>
															validateForgeConfigPayload(data, {
																isBodiedExtension,
																isInitialInsertion,
																schema: editorActions?._privateGetEditorView?.()?.state.schema,
															}),
													});
												});
												extensionProps = {
													...extensionProps,
													...mergeNodeWithPayload(extensionProps.attrs, payload),
												};
											}

											if (analyticsWebClient) {
												sendEvent(analyticsWebClient)({
													source: eventSource,
													eventType: OPERATIONAL_EVENT_TYPE,
													action: 'inserted',
													actionSubject: 'forge.ui.extension',
													...forgeUIAnalyticsContext,
													attributes: {
														localId,
														...extensionIdToAnalyticsAttributes(extensionId),
														extensionType,
														forgeEnvironment: extension.environmentType,
													},
												});
											}

											return extensionProps;
										},
									},
								],
							}),
					nodes: {
						default: {
							type: 'extension',
							render: () => createRenderFunction(extension),
							update: hasConfig
								? (params, actions) => updateFunction(params, actions, extension, hasCustomConfig)
								: undefined,
							getFieldsDefinition: connectToForgeGetFieldsDefinitionFunction,
						},
					},
					fields: fieldsModules,
				},
			};
		});

	const legacyMacroManifest: ExtensionManifest<ForgeExtensionParameters> = {
		/**
		 * title, description and icons are required but will not be used anywhere,
		 * since this manifest is used solely for rendering legacy nodes
		 **/
		title: 'Forge UI Macro',
		description: 'Supports existing Forge UI Macro nodes',
		type: EXTENSION_NAMESPACE,
		key: 'xen:macro',
		icons: manifestIcons,
		modules: {
			nodes: {
				default: {
					type: 'extension',
					render: createRenderFunction,
					update: updateFunction,
					getFieldsDefinition: getFieldsDefinitionFunction,
				},
			},
			fields: fieldsModules,
		},
	};

	const manifests = [legacyMacroManifest, ...extensionManifests];
	extensionProvider = new ForgeExtensionProvider(
		manifests,
		connectExtensionProvider,
		sendConnectToForgeResolveAnalytics,
	);
	return extensionProvider;
}
