import gql from 'graphql-tag';
import { contentUnifiedContentFragment } from './fragments/contentUnifiedContentFragment.fragment';
import { contentUnifiedSpaceFragment } from './fragments/contentUnifiedSpaceFragment.fragment';

export const ContentUnifiedWithSpaceKeyQuery = gql`
	query ContentUnifiedWithSpaceKeyQuery(
		$spaceKey: String!
		$versionOverride: Int
		$isSSR: Boolean = false
		$useNewContentTopper: Boolean = false
		$preloadLabels: Boolean = false
	) @SLA {
		spaceHomepage(spaceKey: $spaceKey, version: $versionOverride) {
			...contentUnifiedContentFragment
		}
		space(key: $spaceKey) {
			...contentUnifiedSpaceFragment
		}
	}
	${contentUnifiedContentFragment}
	${contentUnifiedSpaceFragment}
`;
