import React from 'react';
import { useIntl } from 'react-intl-next';

import Popup from '@atlaskit/popup';
import EditorPanelIcon from '@atlaskit/icon/core/migration/information--editor-panel';
import Button from '@atlaskit/button/new';

export const ViewPermissionsInfoButton = ({
	content,
	isOpen,
	onClick,
}: {
	content: React.ReactNode;
	isOpen: boolean;
	onClick: () => void;
}) => {
	const { formatMessage } = useIntl();
	return (
		<>
			<Popup
				isOpen={isOpen}
				content={() => content}
				placement="bottom"
				trigger={(triggerProps) => (
					// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
					<span {...triggerProps} onClick={onClick}>
						<Button
							appearance="subtle"
							aria-haspopup
							iconBefore={EditorPanelIcon}
							onClick={onClick}
							aria-label={formatMessage({
								id: 'bulk-access.role-select.info-popup',
								description:
									'Aria label for an info button that pops up a description of the selected role permissions',
								defaultMessage: 'View info',
							})}
						>
							{null}
						</Button>
					</span>
				)}
			/>
		</>
	);
};
