import React from 'react';

import type { ExtractInjectionAPI, ToolbarUIComponentFactory } from '@atlaskit/editor-common/types';
import { WithPluginState } from '@atlaskit/editor-common/with-plugin-state';

import type { AIPlugin } from '../../aiPluginType';
import type { AIButtonPluginState } from '../../pm-plugins/ai-button/ai-button-plugin';
import { aiButtonPluginKey } from '../../pm-plugins/ai-button/ai-button-plugin-key';
import { createOpenAIModalCommand } from '../../pm-plugins/decoration/actions';
import { type AIGlobalOptIn, type EditorPluginAIProvider } from '../../types/types';
import { AtlassianIntelligenceToolbarButton } from '../components/AtlassianIntelligenceToolbarButton/AtlassianIntelligenceToolbarButton';

export const getPrimaryToolbarLegacyComponent =
	({
		api,
		editorPluginAIProvider,
		aiGlobalOptIn,
	}: {
		api: ExtractInjectionAPI<AIPlugin> | undefined;
		editorPluginAIProvider: EditorPluginAIProvider;
		aiGlobalOptIn: AIGlobalOptIn;
	}): ToolbarUIComponentFactory =>
	({ editorView }) => {
		return (
			<WithPluginState
				plugins={{
					pluginState: aiButtonPluginKey,
				}}
				render={({ pluginState }) => {
					const aiButtonPluginState: AIButtonPluginState = pluginState;

					const onClick = () => {
						const { state, dispatch } = editorView;
						const configItem = editorPluginAIProvider.baseGenerate;
						const openAIModalCommand = createOpenAIModalCommand({
							state,
							configItem,
							lastTriggeredFrom: 'mainToolbar',
							aiGlobalOptIn,
							triggeredFor: aiButtonPluginState.triggeredFor,
						});

						openAIModalCommand(state, dispatch);
					};

					return (
						<AtlassianIntelligenceToolbarButton
							ButtonWrapper={editorPluginAIProvider?.AIButtonWrapper}
							onClick={onClick}
							disabled={!aiButtonPluginState?.isSelectionValidForAIExperience}
							view={editorView}
						/>
					);
				}}
			/>
		);
	};
