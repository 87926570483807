/*
 * !!! Please see for more details:
 * https://product-fabric.atlassian.net/wiki/spaces/EUXQ/pages/3732210222/Dev+Documentation+Editor+AI+Command+Palette#Current-Implementation
 *
 * This file contains the state machine that models the command palette.
 *
 *
 * If you modify this machine in any way, please look at the visual editor
 * and make sure that it is clear and readable.
 *
 * Include a screenshot of the visual editor in your pull request.
 *
 * If you add a new state to the machine -- you will need to update the
 * CommandPaletteState union type.
 *
 * If you add a new transition to the machine -- you will need to update the
 * CommandPaletteEvent union type.
 */
import { assign, createMachine } from 'xstate';

import { type JSONDocNode } from '@atlaskit/editor-json-transformer';

import type {
	AdvancedPromptValue,
	EditorPluginAIConfigItem,
} from '../../prebuilt/config-items/config-items';

// Typed explicitly due to old xstate version not having
// type generation.
// Whenever adding a new state to the machine -- you will need to update this union
export type CommandPaletteState = {
	['partial preset selection and command options list state']:
		| 'initialisation'
		| 'partial preset selected no options list'
		| 'no partial preset selected full options list'
		| 'no partial preset selected related options list';
};

export type CommandPaletteContext = {
	/**
	 * the latest contents of the user input field.
	 */
	textInput?: string;

	/**
	 * the latest contents of the user input in prompt editor.
	 */
	adfInput?: JSONDocNode;
	/** The latest contents of the advanced prompt. */
	advancedPromptValue?: AdvancedPromptValue;
	/**
	 * the current preset command that the user has selected.
	 */
	configItem: EditorPluginAIConfigItem;
	/**
	 * the default AI generation config
	 */
	baseGenerate?: EditorPluginAIConfigItem;
	/**
	 * the label for the currently selected preset command
	 */
	presetPromptLabel?: string;
};

// Typed explicitly due to old xstate version not having
// type generation.
// Whenever adding a new transistion to the machine -- you will need to update this union
export type CommandPaletteEvent =
	| {
			type: 'onAdvancedPromptValueChanged';
			advancedPromptValue: AdvancedPromptValue;
	  }
	| {
			type: 'onTextEntered';
			input: string;
	  }
	| {
			type: 'onADFEntered';
			adf?: JSONDocNode;
	  }
	| {
			type: 'onCancel';
	  }
	| {
			type: 'onPartialPresetSelected';
			configItem: EditorPluginAIConfigItem;
			presetPromptLabel?: string;
	  }
	| {
			type: 'onPartialPresetRemoved';
	  };

export type CommandPaletteStateMachineOptions = {
	context: CommandPaletteContext;
};

/**
 * Creates an instance of a state machine that models the command palette.
 *
 * We expect to use this at the start of the AI experience when the user
 * is providing a prompt/ preset to perform some AI action.
 */
export const commandPaletteStateMachine = ({ context }: CommandPaletteStateMachineOptions) =>
	createMachine(
		{
			/** @xstate-layout N4IgpgJg5mDOIC5QGMD2BbdBDAdhABAA5YA2YALuWPrOVlftsgBYCWOYAxKjgCpgAPcgFEcVAE6QA2gAYAuolCFUsVuVY9FIAYgBsAdgCsAOgBMAZkMBOKzJm7zVgBymrAGhABPRAFoLx8ydzU10nK30XJyDDXQBfWI80TFwCYjJKalp6aiY2DmNicXVSIklYChowMmR1HnwU-CTsPHxUQlqcWHwSVloaOipjHFQiLCLWEsIyivLqqgIAMwBXEhJW9o1O7t7ybhwABTHikn3p8gBlKrAa6XktZVUOrR0EABYnXWMnV9eZV-0ZE4ZABGQKWDzeN6g4yGfTmYGhYH6XRWUxOQzxRIYZqpUgUBhZBi5dhgApHCZrKZwGZXGqbeotJoNNodLo9PqE0nDUbjSZnSpzSD4SQkbIEFmbNk7PaHXknM6XQUQWQKJAgB5qTbPRBo4FmMJWXTAmTmV7mULuLyIIKfQym+zA9HGwyuTEgJktNL4zIDHJYFgksly0rU8gC64dBkED3ijY8KUc31B44h8ph2YRoXciXx7a0GXk0inUMAJTA6FQADdbqqlCpNZo1S8gZ8WzJ9K9QnZ9PpgRC9DITOZkXaTfDDDFXm6Y6N0gTfYx-XlSYUU1S0+G6XUGjOc1t2emk6uKamaUr8Nm4-vpTxZcdi2myxXq8rgbX1fWnk3EMCkXr7LYjqvIY-xIuY-YILog4BCO5hjsCE66FOCTutiDRehk-TZIuAb5MefKhpukY7mhLR7gmh7ZMY7CaqQvT0JsnAqvcn5at+CC-k4MIuu2VhgcagIQQ4MgBHaxpGoODjAshWLJJ6eKYZyOHLsmJ7rmeEb0iRcmxqyeaUYMNHHPRHRMW+LGPGxoAvKYjrGHCZoAqYvZwc5+gQXBrzGIOnZRO2Pw9vEKHDBAcBaDOGHzthxIcBZDY4NqCA+D8MJmvYILvHx1ivBBthfH8KKvL+PwIaC06kbic4+tFS6BvhlL8hmW44FGjQVesekHlhVBxV+1mIOYxjASijhOoCvyGOiEFIlxnmhGatl2KYPzlTps7et1fq4SuhYNYRTXEYy7Xkfpm1DCM9WnumtLzPgyyrB1kr6b1VnaIgRUiaavzAfCpggmiOVWggIQmEC-wyKYrgITEHarTi62KQuMU7cG6nXXMWlHWtJ1dZy508mujU3UKIpio9uYHi9jb9Qg8JWMY0kdo6SK9iChi5YaxhGj2E62FYE0yaha2RdVRK1Xhu1XURmPRsdV4UWdl1o0RWYjDjOxUwl7GdqYMK2fz4Rjoa5jgUDk265YcH88aQRhMCcPoQpUVi9tqkERuB0y212Py6deNGRSJmvRqfVvQghh6sNVijRH432B5IL5eDwJooYJoOMh8RAA */
			states: {
				'partial preset selection and command options list state': {
					states: {
						'no partial preset selected full options list': {
							description: `If no partial preset is selected and there is no text input:
- Show the full options list`,

							on: {
								onPartialPresetSelected: {
									target: 'partial preset selected no options list',
									actions: ['removeInputText', 'partialPresetSelected'],
								},
							},
						},

						'no partial preset selected related options list': {
							description: `If no partial preset is selected and the user has entered text:
- Show the related options list`,

							on: {
								onPartialPresetSelected: {
									target: 'partial preset selected no options list',
									actions: ['removeInputText', 'partialPresetSelected'],
								},
							},
						},

						'partial preset selected no options list': {
							on: {
								onPartialPresetRemoved: [
									{
										target: 'no partial preset selected related options list',
										guard: 'hasInputText',
										actions: 'partialPresetRemoved',
									},
									{
										target: 'no partial preset selected full options list',
										actions: 'partialPresetRemoved',
									},
								],
							},

							description: `If a partial preset is already selected:
- Don't show the options list
- Show the selected preset`,
						},

						initialisation: {
							always: [
								{
									target: 'partial preset selected no options list',
									guard: 'hasInitialPreset',
								},
								'no partial preset selected full options list',
							],
						},
					},

					initial: 'initialisation',
				},
			},

			context: context,

			types: {
				events: {} as CommandPaletteEvent,
			},

			type: 'parallel',
			id: 'command palette state machine',

			on: {
				onTextEntered: {
					actions: 'updateTextInput',
				},
				onADFEntered: {
					actions: 'updateADFInput',
				},
				onAdvancedPromptValueChanged: {
					actions: 'updateAdvancedPromptValue',
				},
			},
		},
		{
			guards: {
				/*
				 * There are test cases for this guard in the test suite.
				 * They ensure that the guard is working as expected as
				 * the simulation is not able to test this.
				 */
				['hasInputText']: ({ context }): boolean => {
					// context.textInput can be undefined, an empty string, or a non-empty string
					// empty strings are considered to be false in this guard
					return !!context.textInput;
				},
				['hasInitialPreset']: ({ context }): boolean => {
					const hasInitialPreset = context.presetPromptLabel !== undefined;
					return hasInitialPreset;
				},
			},
			actions: {
				updateTextInput: assign(({ event }) => {
					if ('onTextEntered' === event.type) {
						if ('input' in event) {
							return { textInput: event.input };
						}
						return { textInput: '' };
					}
					return {};
				}),
				updateADFInput: assign(({ event }) => {
					if ('onADFEntered' === event.type) {
						if ('adf' in event) {
							return { adfInput: event.adf };
						}
						return { adfInput: undefined };
					}
					return {};
				}),
				updateAdvancedPromptValue: assign(({ event }) => {
					if ('onAdvancedPromptValueChanged' === event.type) {
						if ('advancedPromptValue' in event) {
							return { advancedPromptValue: event.advancedPromptValue };
						}
						return { advancedPromptValue: undefined };
					}
					return {};
				}),
				partialPresetSelected: assign(({ event }) => {
					if (event.type === 'onPartialPresetSelected') {
						return {
							configItem: event.configItem,
							presetPromptLabel: event.presetPromptLabel,
						};
					}
					return {};
				}),
				partialPresetRemoved: assign(({ context, event }) => {
					if (event.type === 'onPartialPresetRemoved') {
						return {
							configItem: context.baseGenerate,
							presetPromptLabel: undefined,
						};
					}
					return { presetPromptLabel: undefined };
				}),
				removeInputText: assign(() => {
					return { textInput: '', adfInput: undefined, advancedPrompt: undefined };
				}),
			},
		},
	);
