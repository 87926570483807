type PK = string;
type PostOfficeData = unknown;

export type PostOfficeCache = Record<PK, PostOfficeData>;

export type ConfluenceGlobalThisWithPostOfficeCache = typeof globalThis & {
	__POST_OFFICE_QUERY_CACHE__: PostOfficeCache;
};

export class PostOfficeCacheClient {
	constructor(private cache: PostOfficeCache) {
		(globalThis as ConfluenceGlobalThisWithPostOfficeCache).__POST_OFFICE_QUERY_CACHE__ = cache;
	}

	private getKey(url: string): PK {
		const matches = url.match(/placements\/([\w\-\/]+)/);

		return matches?.[0] || url;
	}

	private get(key: PK): PostOfficeData {
		return this.cache[key];
	}

	private set(key: PK, value: PostOfficeData) {
		this.cache[key] = value;
	}

	getWithPlacementId(placementId: string): PostOfficeData {
		return this.get('placements/' + placementId);
	}

	getWithUrl(url: string): PostOfficeData {
		return this.get(this.getKey(url));
	}

	setWithUrl(url: string, value: PostOfficeData) {
		this.set(this.getKey(url), value);
	}
}
