import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { defineMessages, useIntl } from 'react-intl-next';

import Heading from '@atlaskit/heading';
import Checkbox from '@atlaskit/checkbox';
import { Box, Grid, Stack, xcss } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';
import { LinkButton } from '@atlaskit/button/new';

import type { SpacePermission, SpaceRole } from '../../model/space-roles-types';
import { FrictionModal } from '../FrictionModal';
import type { NormalizedCheckboxesType } from '../normalizePermissionItems';

const i18n = defineMessages({
	fricionModalTitle: {
		id: 'space-roles.update-principal-modal.role-deselection-friction.title',
		defaultMessage: 'Move off of roles?',
		description: 'Title for a friction modal',
	},
	frictionModalDescription: {
		id: 'space-roles.update-principal-modal.role-deselection-friction.description',
		defaultMessage:
			'You have selected a permission combination that is not part of a role and result in custom access, which can make permissions harder to manage. <learnmorelink> Learn more </learnmorelink>',
		description: 'Description for a friction modal',
	},
	frictionModalConfirmButton: {
		id: 'space-roles.update-principal-modal.role-deselection-friction.confirm',
		defaultMessage: 'Continue',
		description: 'Confirm button text for a friction modal',
	},
	frictionModalCancelButton: {
		id: 'space-roles.update-principal-modal.role-deselection-friction.cancel',
		defaultMessage: 'Cancel',
		description: 'Cancel button text for a friction modal',
	},
});

const LEARN_MORE_ROLES =
	'https://confluence-permissions-help.atlassian.net/wiki/external/MTllYzg0YjViNDUzNDMxYjgxZGM4Y2IwNDg4NmE4YzI';

const checkboxContainerStyle = xcss({
	rowGap: 'space.200',
	columnGap: 'space.600',
	[media.above.sm]: { gridTemplateColumns: 'repeat(2, 1fr)' },
});

const checkboxGroupStyle = xcss({
	gap: 'space.150',
});

const getRoleMatch = (allRoles: (SpaceRole | null)[], currentPermissionKeys: string[]) => {
	return allRoles.find((role) => {
		return (
			role?.permissions.every((permission) => currentPermissionKeys.includes(permission)) &&
			currentPermissionKeys.every((permission) => role?.permissions.includes(permission))
		);
	});
};

// This is the baseline permission needed to exist within the space.
// We can assume that anybody entering this view has this permission already.
const PREREQUISITE_PERMISSION = 'VIEWSPACE';

const getCheckboxUpdates = (
	checkboxes: Record<string, NormalizedCheckboxesType>,
	checkboxId: string,
	action: 'toggle' | 'setEnableability',
	isDisabled: boolean,
) => {
	const updatedCheckboxes = cloneDeep(checkboxes);
	Object.keys(updatedCheckboxes).forEach((groupName) => {
		updatedCheckboxes[groupName].group.checkboxes = updatedCheckboxes[
			groupName
		].group.checkboxes.map((checkbox) => {
			if (checkbox.id === checkboxId) {
				if (action === 'toggle') {
					return {
						...checkbox,
						isAllowed: !checkbox.isAllowed,
					};
				} else if (action === 'setEnableability') {
					return {
						...checkbox,
						isDisabled,
					};
				}
			}
			return checkbox;
		});
	});
	return updatedCheckboxes;
};

export const LegacyPermissionsView = ({
	normalizedCheckboxes,
	currentPermissionKeys,
	allRoles,
	allPermissions,
	initialRole,
	setRole,
	setNormalizedCheckboxes,
}: {
	normalizedCheckboxes: {
		[key: string]: NormalizedCheckboxesType;
	};
	currentPermissionKeys: string[];
	allRoles: (SpaceRole | null)[];
	allPermissions: SpacePermission[];
	initialRole: SpaceRole | undefined;
	setRole: React.Dispatch<React.SetStateAction<SpaceRole | null | undefined>>;
	setNormalizedCheckboxes: React.Dispatch<
		React.SetStateAction<{
			[key: string]: NormalizedCheckboxesType;
		}>
	>;
}) => {
	const { formatMessage } = useIntl();
	const [showValidationModal, setShowValidationModal] = useState(false);
	const [validationModalConfirmed, setValidationModalConfirmed] = useState(false);
	const [pendingCheckboxChange, setPendingCheckboxChange] = useState<string | null>(null);

	const dependencyToPreRequisiteMap = useMemo(
		() =>
			allPermissions.reduce(
				(acc, permission) => {
					permission.requiredPermissions?.forEach((prerequisitePermission) => {
						if (!prerequisitePermission) {
							return acc;
						}
						if (!(permission.key in acc)) {
							acc[permission.key] = [];
						}
						acc[permission.key].push(prerequisitePermission);
					});
					return acc;
				},
				{} as Record<string, string[]>,
			),
		[allPermissions],
	);

	const toggleCheckbox = useCallback(
		(checkboxId: string) => {
			const updatedCheckboxes = getCheckboxUpdates(
				normalizedCheckboxes,
				checkboxId,
				'toggle',
				false,
			);
			setNormalizedCheckboxes(updatedCheckboxes);
		},
		[normalizedCheckboxes, setNormalizedCheckboxes],
	);

	const shouldShowValidationModal = useCallback(
		(selectedCheckbox: string) => {
			if (!initialRole) return false;

			const isPermissionCurrentlyTrue = currentPermissionKeys.includes(selectedCheckbox);

			// If an admin is removing a user, we don't need to warn that they are being removed from a role.
			if (isPermissionCurrentlyTrue && selectedCheckbox === PREREQUISITE_PERMISSION) return false;

			const pendingPermissions = isPermissionCurrentlyTrue
				? currentPermissionKeys.filter((permission) => permission !== selectedCheckbox)
				: [...currentPermissionKeys, selectedCheckbox];

			return !validationModalConfirmed && !Boolean(getRoleMatch(allRoles, pendingPermissions));
		},
		[initialRole, currentPermissionKeys, allRoles, validationModalConfirmed],
	);

	const onValidationConfirm = useCallback(() => {
		if (pendingCheckboxChange) {
			toggleCheckbox(pendingCheckboxChange);
			setPendingCheckboxChange(null);
		}
		setValidationModalConfirmed(true);
		setShowValidationModal(false);
	}, [pendingCheckboxChange, toggleCheckbox]);

	const onCheckboxChange = useCallback(
		(checkboxId: string) => {
			if (shouldShowValidationModal(checkboxId)) {
				setPendingCheckboxChange(checkboxId);
				setShowValidationModal(true);
			} else {
				toggleCheckbox(checkboxId);
			}
		},
		[shouldShowValidationModal, toggleCheckbox],
	);

	// Set or drop a role based on the current permissions selection
	useEffect(() => {
		const roleMatch = getRoleMatch(allRoles, currentPermissionKeys);
		setRole(roleMatch || null);
	}, [allRoles, currentPermissionKeys, setRole]);

	//Enable or disable all dependent permissions who are affected by a prerequisite value
	useEffect(() => {
		const newCheckboxes = Object.entries(dependencyToPreRequisiteMap).reduce(
			(acc, [permission, prerequisites]) => {
				const isDisabled = prerequisites.some(
					(prerequisite) => !currentPermissionKeys.includes(prerequisite),
				);
				const updatedCheckboxes = getCheckboxUpdates(
					acc,
					permission,
					'setEnableability',
					isDisabled,
				);
				return updatedCheckboxes;
			},
			normalizedCheckboxes,
		);

		if (!isEqual(newCheckboxes, normalizedCheckboxes)) {
			setNormalizedCheckboxes(newCheckboxes);
		}
	}, [
		currentPermissionKeys,
		dependencyToPreRequisiteMap,
		normalizedCheckboxes,
		setNormalizedCheckboxes,
	]);

	return (
		<Box>
			<Grid xcss={checkboxContainerStyle}>
				{Object.entries(normalizedCheckboxes).map(([groupName, grouping]) => (
					<Stack key={groupName} xcss={checkboxGroupStyle}>
						<Heading size="xsmall">{groupName}</Heading>
						<Box>
							{grouping.group.checkboxes.map((checkboxInfo) => (
								<Checkbox
									key={checkboxInfo.id}
									testId={checkboxInfo.id}
									isChecked={checkboxInfo.isAllowed}
									isDisabled={checkboxInfo.isDisabled}
									onChange={() => onCheckboxChange(checkboxInfo.id)}
									label={checkboxInfo.displayName}
								/>
							))}
						</Box>
					</Stack>
				))}
			</Grid>
			{showValidationModal && (
				<FrictionModal
					title={formatMessage(i18n.fricionModalTitle)}
					body={formatMessage(i18n.frictionModalDescription, {
						learnmorelink: (chunks: React.ReactNode[]) => (
							<LinkButton appearance="subtle" href={LEARN_MORE_ROLES}>
								{chunks}
							</LinkButton>
						),
					})}
					buttons={[
						{
							onClick: () => setShowValidationModal(false),
							label: formatMessage(i18n.frictionModalCancelButton),
							appearance: 'subtle',
							key: 'update-modal-staged-cancel',
						},
						{
							onClick: onValidationConfirm,
							label: formatMessage(i18n.frictionModalConfirmButton),
							appearance: 'warning',
							key: 'update-modal-staged-confirm',
						},
					]}
					onClose={() => setShowValidationModal(false)}
					width="medium"
				/>
			)}
		</Box>
	);
};
