import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';
import { ErrorScreen } from '@atlassian/generative-ai-modal/screens/Error';

import { updateAIAuditLogPluginState } from '../../../../pm-plugins/ai-audit-logs/commands';
import { useAnalyticsFlowEventsQueue } from '../../../../utils/analytics/analyticsFlowUtils';
import { ErrorMessage, getStepName, retryEnabled } from '../../../../utils/error-handling';
import type { ErrorInfo } from '../../../../utils/error-handling';
import { FAILURE_REASON } from '../../../../utils/errors';
import { useAIExperienceCommonDataContext } from '../../../hooks/useAIExperienceCommonData';

export function ErrorScreenWithLogic({ errorInfo }: { errorInfo: ErrorInfo }) {
	const aiExperienceCommonData = useAIExperienceCommonDataContext();
	const { endExperience, sendToAIExperienceMachine, editorView } = aiExperienceCommonData;

	useAnalyticsFlowEventsQueue({
		stepName: getStepName(errorInfo),
		attributes: {
			errorType: errorInfo.failureReason ?? FAILURE_REASON.UNKNOWN,
			sawAupViolation: errorInfo.failureReason === FAILURE_REASON.AUP_VIOLATION,
			...errorInfo,
		},
	});

	React.useEffect(() => {
		if (fg('platform_editor_ai_audit_logs_events')) {
			if (errorInfo.failureReason === FAILURE_REASON.AUP_VIOLATION) {
				const command = updateAIAuditLogPluginState({
					'aup-violation': {
						eventStatus: 'send-event',
					},
				});
				command(editorView.state, editorView.dispatch);
			}
		}
	}, [errorInfo.failureReason, editorView]);

	return (
		<ErrorScreen
			text={ErrorMessage(errorInfo)}
			onCancel={() => endExperience()}
			onRetry={() => sendToAIExperienceMachine({ type: 'retry' })}
			showRetryButton={retryEnabled(errorInfo)}
		/>
	);
}
