import { fg } from '@atlaskit/platform-feature-flags';

import {
	ATLASSIAN_CONSENT_COOKIE_KEY,
	type SchemaKey,
	versionedConsentCookieKey,
} from '../../../constants';
import { getCookie } from '../get-cookie';
import { setStrictlyNecessaryCookie } from '../set-strictly-necessary-cookie';
import { checkTokenVersionValidity } from '../transformer';

/**
 * Starting with schema version 003, append the version number to the end of the
 * cookie key. This is in response to prior issues caused by different apps on
 * the same domain using different versions of the schema. See this HOT:
 * https://ops.internal.atlassian.com/jira/browse/PIR-21254
 */
export const getConsentTokenForSchemaVersion = (version: SchemaKey) => {
	// If the feature flag is off, we should always use the non-versioned cookie key.
	if (!fg('platform_moonjelly_bsc_versioned_consent_key')) {
		return getCookie(ATLASSIAN_CONSENT_COOKIE_KEY);
	}

	const versionedToken = getCookie(versionedConsentCookieKey(version));

	if (versionedToken) {
		return versionedToken;
	} else {
		// Copy the non-versioned cookie key value to the versioned cookie key value.
		const nonVersionedToken = getCookie(ATLASSIAN_CONSENT_COOKIE_KEY);

		if (nonVersionedToken) {
			const { isV003 } = checkTokenVersionValidity(nonVersionedToken);

			if (isV003) {
				setStrictlyNecessaryCookie(versionedConsentCookieKey(version), nonVersionedToken);
			}
		}

		return nonVersionedToken;
	}
};
