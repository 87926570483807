import { getBitValueFromBool, Logger } from '../../../common/utils';
import {
	ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY,
	ATLASSIAN_SHOW_BANNER_COOKIE_KEY,
	LATEST_SCHEMA_KEY,
	versionedConsentCookieKey,
} from '../../../constants';
import { getBannerVisibility } from '../../banner-controls/get-banner-visibility';
import { setStrictlyNecessaryCookie } from '../set-strictly-necessary-cookie';

export const setConsentTokenCookie = (consentToken: string) => {
	const inTenMinutes = new Date();
	inTenMinutes.setTime(inTenMinutes.getTime() + 10 * 60 * 1000);
	setStrictlyNecessaryCookie(versionedConsentCookieKey(LATEST_SCHEMA_KEY), consentToken, {
		expires: inTenMinutes,
		path: '/',
	});
	Logger.debug(`Set Consent Token ${versionedConsentCookieKey(LATEST_SCHEMA_KEY)} ${consentToken}`);
};

export const setConsentTokenFallbackCookie = (consentToken: string) => {
	const inOneYear = new Date();
	inOneYear.setFullYear(inOneYear.getFullYear() + 1);
	setStrictlyNecessaryCookie(ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY, consentToken, {
		expires: inOneYear,
		path: '/',
	});
	Logger.debug(
		`set fallback consent token: ${ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY} ${consentToken}`,
	);
};

export const setShouldShowBannerCookie = (consentToken: string, consentTimestamp?: string) => {
	// Since we have no good way to reference this as a global state object, we want to precompute and store the
	// banner visibility in a temporary, session-scope cookie (same expiry as the other cookies), which we can
	// lookup in the UI as needed
	const bannerVisibility = getBannerVisibility({
		consentToken,
		consentTimestamp,
	});
	const shouldShowBanner = getBitValueFromBool(!!bannerVisibility);

	const inTenMinutes = new Date();
	inTenMinutes.setTime(inTenMinutes.getTime() + 10 * 60 * 1000);
	setStrictlyNecessaryCookie(ATLASSIAN_SHOW_BANNER_COOKIE_KEY, shouldShowBanner, {
		expires: inTenMinutes,
		path: '/',
	});
	Logger.debug(
		`set shouldShowBannerCookie: ${ATLASSIAN_SHOW_BANNER_COOKIE_KEY} ${shouldShowBanner}`,
	);
};

export const setRequiredConsentCookies = (consentToken: string, consentTimestamps?: string) => {
	setConsentTokenCookie(consentToken);
	setShouldShowBannerCookie(consentToken, consentTimestamps);
};
