import type { AnyEventObject } from 'xstate';

import { getMentionMap } from '@atlassian/ai-model-io/utils/mention-map';

import { getRequestOptions } from './request/request-options';
import { startStreaming } from './request/streaming';
import type { AIExperienceMachineContextForRequest } from './request/types';

/**
 * Vary prompt request triggered - if latest response exists, we are interrogating,
 * and do not want to use the original config item (i.e. may possibly be summarise/transform)
 *
 * If it is a generate with tag, the response will be captured within
 * the latest "latest response, prompt", flow.
 *
 * REVISIT THIS: analytics is based on the config item, however
 * interrogation analytics uses base generate instead to complete;
 */
function getConfigItem({ context }: { context: AIExperienceMachineContextForRequest }) {
	return context.configItem;
}

/**
 * Get mention map for the current document
 * We can no longer use the node attrs to get the mention name
 * So we now need to get all the mentions and create a mapping that can be used by the
 * markdown serializer
 */
async function _getMentionMap({ context }: { context: AIExperienceMachineContextForRequest }) {
	let mentionMap = {};
	if (context.getMentionNameDetails) {
		mentionMap = await getMentionMap({
			node: context.editorView.state.doc,
			getMentionNameDetails: context.getMentionNameDetails,
		});
	}
	return mentionMap;
}

function hitCache({
	context,
	cacheKey,
}: {
	context: AIExperienceMachineContextForRequest;
	cacheKey: string;
}) {
	const { entries } = context.responseHistory;
	const lastHistoryItem = entries[entries.length - 1];
	return (
		lastHistoryItem &&
		cacheKey === lastHistoryItem.cacheKey &&
		context.promptTrigger !== 'interrogate'
	);
}

/**
 * This is responsible for processing the network request data and passing data into the
 * AIExperienceMachine over in createAIExperienceMachine().
 */
export function streaming(context: AIExperienceMachineContextForRequest) {
	async function callbackHandler(callback: (event: AnyEventObject) => void) {
		const abortController = new AbortController();
		const {
			userInput,
			configItem: { agent },
		} = context;

		const configItem = getConfigItem({ context });
		const mentionMap = await _getMentionMap({ context });

		const requestOptions = getRequestOptions({
			callback,
			context,
			configItem,
			mentionMap,
		});

		const cacheKey = JSON.stringify({ userInput, agent, requestOptions });

		if (hitCache({ context, cacheKey })) {
			callback({ type: 'same cache key' });
			return;
		}

		await startStreaming({
			abortController,
			cacheKey,
			selectionType: context.selectionType,
			product: context.aiProvider.product,
			getFetchCustomHeaders: context.aiProvider.getFetchCustomHeaders,
			channelId: context.channelId,
			callback,
			requestOptions,
		});

		// Cleanup function
		return () => {
			abortController.abort();
		};
	}

	return callbackHandler;
}
