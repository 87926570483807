import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { DynamicTableStateless as DynamicTable } from '@atlaskit/dynamic-table';
import { token } from '@atlaskit/tokens';
import Checkbox from '@atlaskit/checkbox';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { HeaderCell } from '@confluence/space-permissions/entry-points/SpacePermissionsSettingsTab';
import { isContentTypeEnabledInCurrentEnv } from '@confluence/content-types-utils';
import { mergeLiveDocI18n } from '@confluence/live-pages-utils/entry-points/mergeLiveDocI18n';

import { SpacePermissionType } from './graphql/__types__/BulkSpacePermissionsMutation';
import { ANALYTICS_SOURCE } from './BulkSpacePermissionsPage';

const groupI18nBase = defineMessages({
	selectAll: {
		id: 'bulk-permissions.space.table.header.group.select-all',
		description: "Title of the 'Select all' column",
		defaultMessage: 'Select all',
	},
	all: {
		id: 'bulk-permissions.space.table.header.group.all',
		description: "Title of the column group 'All'",
		defaultMessage: 'All',
	},
	pages: {
		id: 'bulk-permissions.space.table.header.group.pages',
		description: "Title of the column group for content types, named 'Pages'",
		defaultMessage: 'Pages',
	},
	pagesEmbeds: {
		id: 'bulk-permissions.space.table.header.group.pages-embeds',
		description: 'Title of the column group for the page and embed smart link content types',
		defaultMessage: 'Pages and Smart Links',
	},
	pagesWhiteboardsDatabases: {
		id: 'bulk-permissions.space.table.header.group.pages-whiteboards-databases',
		description: 'Title of the column group for the page, whiteboard, and database content types',
		defaultMessage: 'Pages, whiteboards, and databases',
	},
	pagesWhiteboardsEmbeds: {
		id: 'bulk-permissions.space.table.header.group.pages-whiteboards-embeds',
		description:
			'Title of the column group for the page, whiteboard, and embed smart link content types',
		defaultMessage: 'Pages, whiteboards, and Smart Links',
	},
	pagesDatabasesEmbeds: {
		id: 'bulk-permissions.space.table.header.group.pages-databases-embeds',
		defaultMessage: 'Pages, databases, and Smart Links',
		description:
			'Title of the column group for the page, database, and embed smart link content types',
	},
	pagesWhiteboardsDatabasesEmbeds: {
		id: 'bulk-permissions.space.table.header.group.pages-whiteboards-databases-embeds',
		defaultMessage: 'Pages, whiteboards, databases, and Smart Links',
		description:
			'Title of the column group for the page, whiteboard, database, and embed smart link content types',
	},
	blogs: {
		id: 'bulk-permissions.space.table.header.group.blogs',
		description: "Title of the column group 'Blogs'",
		defaultMessage: 'Blogs',
	},
	comments: {
		id: 'bulk-permissions.space.table.header.group.comments',
		description: "Title of the column group 'Comments'",
		defaultMessage: 'Comments',
	},
	attachments: {
		id: 'bulk-permissions.space.table.header.group.attachments',
		description: "Title of the column group 'Attachments'",
		defaultMessage: 'Attachments',
	},
	restrictions: {
		id: 'bulk-permissions.space.table.header.group.restrictions',
		description: "Title of the column group 'Restrictions'",
		defaultMessage: 'Restrictions',
	},
	mail: {
		id: 'bulk-permissions.space.table.header.group.mail',
		description: "Title of the column group 'Mail'",
		defaultMessage: 'Mail',
	},
	space: {
		id: 'bulk-permissions.space.table.header.group.space',
		description: "Title of the column group 'Space'",
		defaultMessage: 'Space',
	},
});

const groupI18nLiveDocs = defineMessages({
	pages: {
		id: 'bulk-permissions.space.table.header.group.pages.livedocs',
		defaultMessage: 'Pages and live docs',
		description: 'Updated version of bulk-permissions.space.table.header.group.pages',
	},

	pagesEmbeds: {
		id: 'bulk-permissions.space.table.header.group.pages-embeds.livedocs',
		defaultMessage: 'Pages, live docs, and Smart Links',
		description: 'Updated version of bulk-permissions.space.table.header.group.pages-embeds',
	},

	pagesWhiteboardsDatabases: {
		id: 'bulk-permissions.space.table.header.group.pages-whiteboards-databases.livedocs',
		defaultMessage: 'Pages, live docs, whiteboards, and databases',
		description:
			'Updated version of bulk-permissions.space.table.header.group.pages-whiteboards-databases',
	},

	pagesWhiteboardsEmbeds: {
		id: 'bulk-permissions.space.table.header.group.pages-whiteboards-embeds.livedocs',
		defaultMessage: 'Pages, live docs, whiteboards, databases, and Smart Links',
		description:
			'Updated version of bulk-permissions.space.table.header.group.pages-whiteboards-embeds',
	},

	pagesDatabasesEmbeds: {
		id: 'bulk-permissions.space.table.header.group.pages-databases-embeds.livedocs',
		defaultMessage: 'Pages, live docs, databases, and Smart Links',
		description:
			'Updated version of bulk-permissions.space.table.header.group.pages-databases-embeds',
	},

	pagesWhiteboardsDatabasesEmbeds: {
		id: 'bulk-permissions.space.table.header.group.pages-whiteboards-databases-embeds.livedocs',
		defaultMessage: 'Pages, live docs, whiteboards, databases, and Smart Links',
		description:
			'Updated version of bulk-permissions.space.table.header.group.pages-whiteboards-databases-embeds',
	},
});

const groupI18n = mergeLiveDocI18n(groupI18nBase, groupI18nLiveDocs);

const columnI18n = defineMessages({
	view: {
		id: 'bulk-permissions.space.table.header.column.view',
		description: "Title of the column 'View'",
		defaultMessage: 'View',
	},
	deleteOwn: {
		id: 'bulk-permissions.space.table.header.column.delete-own',
		description: "Title of the column 'Delete own'",
		defaultMessage: 'Delete own',
	},
	add: {
		id: 'bulk-permissions.space.table.header.column.Add',
		description: "Title of the column 'Add'",
		defaultMessage: 'Add',
	},
	delete: {
		id: 'bulk-permissions.space.table.header.column.delete',
		description: "Title of the column 'delete'",
		defaultMessage: 'Delete',
	},
	archive: {
		id: 'bulk-permissions.space.table.header.column.archive',
		description: "Title of the column 'Archive'",
		defaultMessage: 'Archive',
	},
	addDelete: {
		id: 'bulk-permissions.space.table.header.column.add-delete',
		description: "Title of the column 'Add/Delete'",
		defaultMessage: 'Add/Delete',
	},
	export: {
		id: 'bulk-permissions.space.table.header.column.export',
		description: "Title of the column 'Export'",
		defaultMessage: 'Export',
	},
	admin: {
		id: 'bulk-permissions.space.table.header.column.admin',
		description: "Title of the column 'admin'",
		defaultMessage: 'Admin',
	},
});

const i18n = defineMessages({
	allViewTooltipContent: {
		id: 'bulk-permissions.space.table.header.allViewTooltipContent',
		defaultMessage: 'View permission determines who has access to the space.',
		description: "Tooltip content for the 'All View' column header",
	},
	allViewTooltipIconLabel: {
		id: 'bulk-permissions.space.table.header.allViewTooltipIconLabel',
		defaultMessage: 'Learn more about view permissions',
		description: "Tooltip icon label for the 'All View' column header",
	},
	deleteOwnTooltipContent: {
		id: 'bulk-permissions.space.table.header.deleteOwnTooltipContent',
		defaultMessage:
			'Lets users delete their own content and attachments. (For comments, the “Add” permission includes “Delete Own”)',
		description: "Tooltip content for the 'Delete own' column header",
	},
	deleteOwnTooltipIconLabel: {
		id: 'bulk-permissions.space.table.header.deleteOwnTooltipIconLabel',
		defaultMessage: 'Learn more about delete own',
		description: "Tooltip icon label for the 'Delete own' column header",
	},
	restrictionsTooltipContent: {
		id: 'bulk-permissions.space.table.header.restrictionsTooltipContent',
		defaultMessage:
			'Lets users add and remove restrictions directly on content. Does not affect inherited restrictions.',
		description: "Tooltip content for the 'Restrictions' column header",
	},
	restrictionsTooltipIconLabel: {
		id: 'bulk-permissions.space.table.header.restrictionsTooltipIconLabel',
		defaultMessage: 'Restrictions Add/Delete tooltip',
		description: "Tooltip icon label for the 'Restrictions' column header",
	},
});

const COLUMN_KEY_SELECT_ALL = 'SELECT_ALL';

const CELL_BORDER = {
	borderLeft: `1px solid ${token('color.border', '#DCDFE4')}`,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableWrapper = styled.div({
	overflowX: 'auto',
	marginTop: token('space.250', '20px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckboxWrapper = styled.div({
	display: 'flex',
	justifyContent: 'center',
});

type BulkSpacePermissionsTableProps = {
	spacePermissions: SpacePermissionType[];
	setSpacePermissions: any;
};

export const InitCheckboxValues = {
	[SpacePermissionType.VIEW_SPACE]: false,
	[SpacePermissionType.REMOVE_OWN_CONTENT]: false,
	[SpacePermissionType.CREATE_EDIT_PAGE]: false,
	[SpacePermissionType.ARCHIVE_PAGE]: false,
	[SpacePermissionType.REMOVE_PAGE]: false,
	[SpacePermissionType.EDIT_BLOG]: false,
	[SpacePermissionType.REMOVE_BLOG]: false,
	[SpacePermissionType.COMMENT]: false,
	[SpacePermissionType.REMOVE_COMMENT]: false,
	[SpacePermissionType.CREATE_ATTACHMENT]: false,
	[SpacePermissionType.REMOVE_ATTACHMENT]: false,
	[SpacePermissionType.SET_PAGE_PERMISSIONS]: false,
	[SpacePermissionType.EXPORT_SPACE]: false,
	[SpacePermissionType.ADMINISTER_SPACE]: false,
};

export const BulkSpacePermissionsTable: FC<BulkSpacePermissionsTableProps> = ({
	spacePermissions,
	setSpacePermissions,
}) => {
	const isWhiteboardsEnabled = isContentTypeEnabledInCurrentEnv('whiteboard');
	const isDatabasesEnabled = isContentTypeEnabledInCurrentEnv('database');
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isSelectAllCheckbox, setSelectAllCheckbox] = useState<boolean>(false);
	spacePermissions.forEach((val) => {
		InitCheckboxValues[val] = true;
	});
	const [checkboxValues, setCheckboxValues] = useState(InitCheckboxValues);

	useEffect(() => {
		Object.keys(checkboxValues).forEach((val) => {
			checkboxValues[val] = false;
		});

		spacePermissions.forEach((val) => {
			checkboxValues[val] = true;
		});

		setCheckboxValues(checkboxValues);

		checkSelectAllCheckbox(checkboxValues);
	}, [spacePermissions, checkboxValues]);

	const getContentGroupName = (_isWhiteboardsEnabled: boolean, _isDatabasesEnabled: boolean) => {
		if (!_isWhiteboardsEnabled && !_isDatabasesEnabled) {
			return formatMessage(groupI18n.pagesEmbeds);
		} else if (_isWhiteboardsEnabled && !_isDatabasesEnabled) {
			return formatMessage(groupI18n.pagesWhiteboardsEmbeds);
		} else if (!_isWhiteboardsEnabled && _isDatabasesEnabled) {
			return formatMessage(groupI18n.pagesDatabasesEmbeds);
		} else {
			return formatMessage(groupI18n.pagesWhiteboardsDatabasesEmbeds);
		}
	};

	const groupNames = {
		SELECT_ALL: formatMessage(groupI18n.selectAll),
		ALL: formatMessage(groupI18n.all),
		CONTENT: getContentGroupName(isWhiteboardsEnabled, isDatabasesEnabled),
		BLOGS: formatMessage(groupI18n.blogs),
		COMMENTS: formatMessage(groupI18n.comments),
		ATTACHMENTS: formatMessage(groupI18n.attachments),
		RESTRICTIONS: formatMessage(groupI18n.restrictions),
		MAIL: formatMessage(groupI18n.mail),
		SPACE: formatMessage(groupI18n.space),
	};

	const columnNames = {
		VIEW: formatMessage(columnI18n.view),
		DELETE_OWN: formatMessage(columnI18n.deleteOwn),
		ADD: formatMessage(columnI18n.add),
		DELETE: formatMessage(columnI18n.delete),
		ARCHIVE: formatMessage(columnI18n.archive),
		ADD_DELETE: formatMessage(columnI18n.addDelete),
		EXPORT: formatMessage(columnI18n.export),
		ADMIN: formatMessage(columnI18n.admin),
	};

	const permissionsTableHeaders = [
		{
			key: COLUMN_KEY_SELECT_ALL,
			content: <HeaderCell groupName="" columnName={groupNames.SELECT_ALL} />,
		},
		{
			key: SpacePermissionType.VIEW_SPACE,
			content: (
				<HeaderCell
					groupName={groupNames.ALL}
					columnName={columnNames.VIEW}
					tooltipContent={i18n.allViewTooltipContent}
					tooltipIconLabel={i18n.allViewTooltipIconLabel}
					showGroupName
				/>
			),
			inlineStyles: CELL_BORDER,
		},
		{
			key: SpacePermissionType.REMOVE_OWN_CONTENT,
			content: (
				<HeaderCell
					groupName={groupNames.ALL}
					columnName={columnNames.DELETE_OWN}
					tooltipContent={i18n.deleteOwnTooltipContent}
					tooltipIconLabel={i18n.deleteOwnTooltipIconLabel}
				/>
			),
		},
		{
			key: SpacePermissionType.CREATE_EDIT_PAGE,
			content: <HeaderCell groupName={groupNames.CONTENT} columnName={columnNames.ADD} />,
			inlineStyles: CELL_BORDER,
		},
		{
			key: SpacePermissionType.ARCHIVE_PAGE,
			content: (
				<HeaderCell
					showGroupName
					groupName={groupNames.CONTENT}
					columnName={columnNames.ARCHIVE}
					centerGroupName
					setWidth
				/>
			),
			inlineStyles: {
				maxWidth: token('space.1000', '80px'),
			},
		},
		{
			key: SpacePermissionType.REMOVE_PAGE,
			content: <HeaderCell groupName={groupNames.CONTENT} columnName={columnNames.DELETE} />,
		},
		{
			key: SpacePermissionType.EDIT_BLOG,
			content: (
				<HeaderCell groupName={groupNames.BLOGS} columnName={columnNames.ADD} showGroupName />
			),
			inlineStyles: CELL_BORDER,
		},
		{
			key: SpacePermissionType.REMOVE_BLOG,
			content: <HeaderCell groupName={groupNames.BLOGS} columnName={columnNames.DELETE} />,
		},
		{
			key: SpacePermissionType.COMMENT,
			content: (
				<HeaderCell groupName={groupNames.COMMENTS} columnName={columnNames.ADD} showGroupName />
			),
			inlineStyles: CELL_BORDER,
		},
		{
			key: SpacePermissionType.REMOVE_COMMENT,
			content: <HeaderCell groupName={groupNames.COMMENTS} columnName={columnNames.DELETE} />,
		},
		{
			key: SpacePermissionType.CREATE_ATTACHMENT,
			content: (
				<HeaderCell groupName={groupNames.ATTACHMENTS} columnName={columnNames.ADD} showGroupName />
			),
			inlineStyles: CELL_BORDER,
		},
		{
			key: SpacePermissionType.REMOVE_ATTACHMENT,
			content: <HeaderCell groupName={groupNames.ATTACHMENTS} columnName={columnNames.DELETE} />,
		},
		{
			key: SpacePermissionType.SET_PAGE_PERMISSIONS,
			content: (
				<HeaderCell
					groupName={groupNames.RESTRICTIONS}
					columnName={columnNames.ADD_DELETE}
					tooltipContent={i18n.restrictionsTooltipContent}
					tooltipIconLabel={i18n.restrictionsTooltipIconLabel}
					showGroupName
				/>
			),
			inlineStyles: CELL_BORDER,
		},
		{
			key: SpacePermissionType.EXPORT_SPACE,
			content: (
				<HeaderCell groupName={groupNames.SPACE} columnName={columnNames.EXPORT} showGroupName />
			),
			inlineStyles: CELL_BORDER,
		},
		{
			key: SpacePermissionType.ADMINISTER_SPACE,
			content: <HeaderCell groupName={groupNames.SPACE} columnName={columnNames.ADMIN} />,
		},
	];

	const updateSpacePermissions = (checkboxValues) => {
		setSpacePermissions(Object.keys(checkboxValues).filter((val) => checkboxValues[val]));
	};

	const checkSelectAllCheckbox = (valuesObject) => {
		if (
			Object.values(valuesObject).every((v) => {
				return v;
			})
		) {
			setSelectAllCheckbox(true);
		} else {
			setSelectAllCheckbox(false);
		}
	};

	const getAriaLabel = (checkBox: SpacePermissionType) => {
		switch (checkBox) {
			case SpacePermissionType.VIEW_SPACE:
				return `${columnNames.VIEW} ${groupNames.ALL}`;
			case SpacePermissionType.REMOVE_OWN_CONTENT:
				return `${columnNames.DELETE_OWN} ${groupNames.ALL}`;
			case SpacePermissionType.CREATE_EDIT_PAGE:
				return `${columnNames.ADD} ${groupNames.CONTENT}`;
			case SpacePermissionType.ARCHIVE_PAGE:
				return `${columnNames.ARCHIVE} ${groupNames.CONTENT}`;
			case SpacePermissionType.REMOVE_PAGE:
				return `${columnNames.DELETE} ${groupNames.CONTENT}`;
			case SpacePermissionType.EDIT_BLOG:
				return `${columnNames.ADD} ${groupNames.BLOGS}`;
			case SpacePermissionType.REMOVE_BLOG:
				return `${columnNames.DELETE} ${groupNames.BLOGS}`;
			case SpacePermissionType.COMMENT:
				return `${columnNames.ADD} ${groupNames.COMMENTS}`;
			case SpacePermissionType.REMOVE_COMMENT:
				return `${columnNames.DELETE} ${groupNames.COMMENTS}`;
			case SpacePermissionType.CREATE_ATTACHMENT:
				return `${columnNames.ADD} ${groupNames.ATTACHMENTS}`;
			case SpacePermissionType.REMOVE_ATTACHMENT:
				return `${columnNames.DELETE} ${groupNames.ATTACHMENTS}`;
			case SpacePermissionType.SET_PAGE_PERMISSIONS:
				return `${columnNames.ADD_DELETE} ${groupNames.RESTRICTIONS}`;
			case SpacePermissionType.EXPORT_SPACE:
				return `${columnNames.EXPORT} ${groupNames.SPACE}`;
			case SpacePermissionType.ADMINISTER_SPACE:
				return `${columnNames.ADMIN} ${groupNames.SPACE}`;
		}
	};

	const PermissionsTableCheckBox = ({
		spacePermission,
	}: {
		spacePermission: SpacePermissionType;
	}) => {
		return (
			<CheckboxWrapper>
				<Checkbox
					testId={`bulk-space-permissions-table-checkbox-${spacePermission}`}
					aria-label={getAriaLabel(spacePermission)}
					isChecked={getCheckedValue(spacePermission)}
					isDisabled={spacePermission === SpacePermissionType.VIEW_SPACE ? false : isDisabled()}
					onChange={() => {
						checkboxValues[spacePermission] = !checkboxValues[spacePermission];

						setCheckboxValues(checkboxValues);
						checkSelectAllCheckbox(checkboxValues);
						updateSpacePermissions(checkboxValues);

						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								actionSubject: 'checkbox',
								action: 'clicked',
								actionSubjectId: spacePermission,
								source: ANALYTICS_SOURCE,
								attributes: {
									value: checkboxValues[spacePermission],
								},
							},
						}).fire();
					}}
				/>
			</CheckboxWrapper>
		);
	};

	const handleSelectAllChange = () => {
		setSelectAllCheckbox(!isSelectAllCheckbox);
		Object.keys(checkboxValues).map((val) => {
			checkboxValues[val] = !isSelectAllCheckbox;
		});
		setCheckboxValues(checkboxValues);
		updateSpacePermissions(checkboxValues);

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				actionSubject: 'checkbox',
				action: 'clicked',
				actionSubjectId: 'COLUMN_KEY_SELECT_ALL',
				source: ANALYTICS_SOURCE,
				attributes: {
					value: isSelectAllCheckbox,
				},
			},
		}).fire();
	};

	const isDisabled = () => {
		return !checkboxValues[SpacePermissionType.VIEW_SPACE];
	};
	const getCheckedValue = (itemId: SpacePermissionType): boolean => {
		return checkboxValues[itemId];
	};

	const checkboxes = [
		{
			key: COLUMN_KEY_SELECT_ALL,
			content: (
				<CheckboxWrapper>
					<Checkbox
						testId="bulk-space-permissions-table-checkbox-SELECT_ALL"
						aria-label={groupNames.SELECT_ALL}
						isChecked={isSelectAllCheckbox}
						isDisabled={false}
						onChange={handleSelectAllChange}
					/>
				</CheckboxWrapper>
			),
		},
		{
			key: SpacePermissionType.VIEW_SPACE,
			content: (
				<PermissionsTableCheckBox {...{ spacePermission: SpacePermissionType.VIEW_SPACE }} />
			),
			style: CELL_BORDER,
		},
		{
			key: SpacePermissionType.REMOVE_OWN_CONTENT,
			content: (
				<PermissionsTableCheckBox
					{...{ spacePermission: SpacePermissionType.REMOVE_OWN_CONTENT }}
				/>
			),
		},
		{
			key: SpacePermissionType.CREATE_EDIT_PAGE,
			content: (
				<PermissionsTableCheckBox {...{ spacePermission: SpacePermissionType.CREATE_EDIT_PAGE }} />
			),
			style: CELL_BORDER,
		},
		{
			key: SpacePermissionType.ARCHIVE_PAGE,
			content: (
				<PermissionsTableCheckBox {...{ spacePermission: SpacePermissionType.ARCHIVE_PAGE }} />
			),
		},
		{
			key: SpacePermissionType.REMOVE_PAGE,
			content: (
				<PermissionsTableCheckBox {...{ spacePermission: SpacePermissionType.REMOVE_PAGE }} />
			),
		},
		{
			key: SpacePermissionType.EDIT_BLOG,
			content: <PermissionsTableCheckBox {...{ spacePermission: SpacePermissionType.EDIT_BLOG }} />,
			style: CELL_BORDER,
		},
		{
			key: SpacePermissionType.REMOVE_BLOG,
			content: (
				<PermissionsTableCheckBox {...{ spacePermission: SpacePermissionType.REMOVE_BLOG }} />
			),
		},
		{
			key: SpacePermissionType.COMMENT,
			content: <PermissionsTableCheckBox {...{ spacePermission: SpacePermissionType.COMMENT }} />,
			style: CELL_BORDER,
		},
		{
			key: SpacePermissionType.REMOVE_COMMENT,
			content: (
				<PermissionsTableCheckBox {...{ spacePermission: SpacePermissionType.REMOVE_COMMENT }} />
			),
		},
		{
			key: SpacePermissionType.CREATE_ATTACHMENT,
			content: (
				<PermissionsTableCheckBox {...{ spacePermission: SpacePermissionType.CREATE_ATTACHMENT }} />
			),
			style: CELL_BORDER,
		},
		{
			key: SpacePermissionType.REMOVE_ATTACHMENT,
			content: (
				<PermissionsTableCheckBox {...{ spacePermission: SpacePermissionType.REMOVE_ATTACHMENT }} />
			),
		},
		{
			key: SpacePermissionType.SET_PAGE_PERMISSIONS,
			content: (
				<PermissionsTableCheckBox
					{...{ spacePermission: SpacePermissionType.SET_PAGE_PERMISSIONS }}
				/>
			),
			style: CELL_BORDER,
		},
		{
			key: SpacePermissionType.EXPORT_SPACE,
			content: (
				<PermissionsTableCheckBox {...{ spacePermission: SpacePermissionType.EXPORT_SPACE }} />
			),
			style: CELL_BORDER,
		},
		{
			key: SpacePermissionType.ADMINISTER_SPACE,
			content: (
				<PermissionsTableCheckBox {...{ spacePermission: SpacePermissionType.ADMINISTER_SPACE }} />
			),
		},
	];

	const rowData = [checkboxValues].map(() => ({
		key: `checkboxes`,
		cells: checkboxes,
	}));

	return (
		<TableWrapper>
			<DynamicTable
				head={{ cells: permissionsTableHeaders }}
				rows={rowData}
				testId="bulk-space-permissions-table"
			/>
		</TableWrapper>
	);
};
